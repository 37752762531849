import * as Three from 'three';
import { OBJLoader2 } from '../utils/obj-loader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader';
import { SVGLoader } from '../utils/svg-loader';
import FallbackTexture from '../images/missing-texture.png';

export const resourceCache = {};

const GLTFProxyLoader = {
  load: (path, onLoad, onProgress, onError) => {
    (new GLTFLoader()).load(
      path,
      ({ scene }) => {
        if (!scene || !scene.children) {
          return;
        }

        const model = new Three.Group();

        scene.children.forEach(submesh => {
          model.add(submesh.clone());
        });

        onLoad(model);
      },
      onProgress,
      onError
    );
  }
};

const DAEProxyLoader = {
  load: (path, onLoad, onProgress, onError) => {
    (new ColladaLoader()).load(
      path,
      ({ scene }) => {
        if (!scene || !scene.children) {
          return;
        }

        const model = new Three.Group();

        scene.children.forEach(submesh => {
          model.add(submesh.clone());
        });

        onLoad(model);
      },
      onProgress,
      onError
    );
  }
};

const SVGProxyLoader = {
  load: (path, onLoad, onProgress, onError) => {
    (new SVGLoader()).load(
      path,
      ({ paths }) => {
        if (!paths) {
          return;
        }

        const model = new Three.Object3D();

        paths.forEach(path => {
          const pathMaterial = new Three.MeshBasicMaterial({
            color: path.color,
            opacity: path.opacity,
            transparent: path.opacity !== 1,
            side: Three.DoubleSide
          });

          const shapes = path.toShapes(true);

          shapes.forEach(shape => {
            const shapeGeometry = new Three.ShapeBufferGeometry(shape);
            const mesh = new Three.Mesh(shapeGeometry, pathMaterial);

            model.add(mesh);
          });
        });

        onLoad(model);
      },
      onProgress,
      onError
    );
  }
};
// 'https://cdn.wtlstudio.com/', 'https://s3.amazonaws.com/wtl-studio.public.common.wtlstudio.com/'
export const textureLoader = Three.TextureLoader;
export const modelLoader = {
  obj: () => new OBJLoader2(),
  glb: () => GLTFProxyLoader,
  gltf: () => GLTFProxyLoader,
  dae: () => DAEProxyLoader,
  svg: () => SVGProxyLoader
};

const textureCache = {};

export const loadTexture = (src) => {
  if (!src) {
    return;
  }

  if (textureCache[src]) {
    return textureCache[src];
  }

  const loader = new textureLoader();
  textureCache[src] = loader.load(
    src.replace('https://cdn.wtlstudio.com/', 'https://s3.amazonaws.com/wtl-studio.public.common.wtlstudio.com/')  
  , undefined, undefined, () => {
    loadFallbackTexture(src);
  });

  return textureCache[src];
}

const loadFallbackTexture = (src) => {
  textureCache[src].format = Three.RGBAFormat;

  if (!textureCache.caFallback) {
    const texLoader = new textureLoader();
    const imageLoader = new Three.ImageLoader(texLoader.manager);
		imageLoader.setCrossOrigin(texLoader.crossOrigin);
		imageLoader.setPath(texLoader.path);

		imageLoader.load(FallbackTexture, (image) => {
      textureCache.caFallback = image;
      textureCache[src].image = textureCache.caFallback;
      textureCache[src].needsUpdate = true;
    });
  } else {
    textureCache[src].image = textureCache.caFallback;
    textureCache[src].needsUpdate = true;
  }
};
