import * as Three from 'three';
import { parseObject } from './object';

export const parseWall = (branch, context, options) => {
  const { _selfKey, parent } = options;
  const {
    positionX,
    positionZ,
    angle,
    color = 0x333333,
    previewOnly = false,
    archX1,
    archY1,
    archX2,
    archY2,
    materialA = 'unpainted-wall',
    materialB = 'unpainted-wall'
  } = branch;

  if (!parent) {
    return;
  }

  let instance = parent.getObjectByName(_selfKey);
  const { renderWalls } = context;

  const wallHeight = [
    2, // No walls
    20, // Walls, no floors above
    20 // Walls + floors
  ][renderWalls] || 20;

  if (!instance) {
    instance = new Three.Group();

    const isArch = typeof archX1 === 'number' && 
      typeof archY1 === 'number' && 
      typeof archX2 === 'number' && 
      typeof archY2 === 'number';

    const sideA = parseObject({
      ...branch,
      sizeX: 10,
      sizeY: wallHeight,
      sizeZ: .5,
      positionX: 5,
      positionY: (wallHeight / 2) + 0.5,
      positionZ: -.25,
      color,
      primitive: isArch ? 'arch' : 'cube',
      opacity: previewOnly ? 0.5 : 1.0,
      materialId: materialA,
      archX1: archX1,
      archY1: archY1,
      archX2: archX2,
      archY2: archY2,
    }, context, {
      ...options,
      parent: instance,
      selectable: true,
      selectionTarget: false
    });
    sideA.name = 'sideA';

    const sideB = parseObject({
      ...branch,
      sizeX: 10,
      sizeY: wallHeight,
      sizeZ: .5,
      positionX: 5,
      positionY: (wallHeight / 2) + 0.5,
      positionZ: .25,
      color,
      primitive: isArch ? 'arch' : 'cube',
      opacity: previewOnly ? 0.5 : 1.0,
      materialId: materialB,
      archX1: archX1,
      archY1: archY1,
      archX2: archX2,
      archY2: archY2,
    }, context, {
      ...options,
      parent: instance,
      selectable: true,
      selectionTarget: false
    });
    sideB.name = 'sideB';

    instance.position.x = positionX;
    instance.position.y = parent.position.y || 0;
    instance.position.z = positionZ;
    instance.rotation.y = angle || 0;

    instance.selectable = true;
    instance.selectionTarget = true;
    parent.add(instance);
  }

  return instance;
};
