import React from 'react';
import styled from 'styled-components';
import { getBranchHTML } from '../../wtl-schema/core/get-branch-html';
import StateService from '../services/state.service';
import EditorService from '../services/editor.service';
import { message } from 'antd';
import { isExternalUrl } from '../../wtl-schema/helpers/is-external-url';
import VariablesService from '../../wtl-schema/services/variable.service';
import { WtlContextWrapper } from '../../config';
import { SchemaRoot } from '../../wtl-schema/core/schema-root';

const PreviewOverlay = styled.div`
  display: block;
  background-color: #333;
  color: #fff;
  font-size: 10px;
  padding: 4px 10px;
  user-select: none;
  cursor: default;
  z-index: 10001;
`;

export default class Preview extends React.Component {
  pagePreview = React.createRef();

  constructor(props) {
    super(props);

    let initialPage = 'index';

    if (typeof window !== 'undefined') {
      const hash = window.location.hash.substr(1);
      const validatedHash = decodeURIComponent(hash);

      if (validatedHash) {
        initialPage = validatedHash;
      }
    }

    this.state = {
      page: initialPage,
      variablesSet: false
    };
  }

  componentDidMount() {
    if (typeof document !== 'undefined' && document.onreadystatechange) {
      document.onreadystatechange = () => {
        if (document.readyState === 'complete') {
          this.forceUpdate();
        }
      }
    }
  }

  preventLinkTransition(context) {
    return (event) => { 
      if (!context || !context.getAttribute || !context.getAttribute('href')) {
        return;
      }

      const link = context.getAttribute('href').substr(1);

      if (isExternalUrl(link)) {
        return;
      } else {
        event.preventDefault();
      }

      const validatedLink = (!link || link === '#' ? 'index' : link).trim();
      const pages = ['index', ...StateService.getSchemaPages()];

      if (!pages.includes(validatedLink)) {
        message.info(`Page "${validatedLink}" does not exist.`);
        return;
      }

      const page = !link || link === '#' ? 'index' : link;

      this.setState({ page });
      window.location.hash = `${encodeURIComponent(page)}`;
    }
  }

  componentDidUpdate() {
    if (!this.pagePreview.current) {
      return;
    }

    this.preventLinkTransition = this.preventLinkTransition.bind(this);

    this.pagePreview.current.querySelectorAll('a[href]').forEach(link => {
      link.removeEventListener('click', this.preventLinkTransition(link));
      link.addEventListener('click', this.preventLinkTransition(link));
    });
  }

  renderCustomFonts() {
    const config = StateService.getConfig();

    return (<>
      {(config.fonts || []).map((font, index) => {
        if (font.url.match(/https?\:\/\/fonts.googleapis.com\//)) {
          return (
            <link
              href={font.url}
              rel="stylesheet"
            />
          );
        } else {
          return (
            <style dangerouslySetInnerHTML={{ __html: `
              @font-face {
                font-family: ${font.name ? font.name : `Project Font ${index + 1}`};
                src: url(${font.url}) format(${({
                  'ttf': '"truetype"',
                  'otf': '"opentype"',
                  'woff': '"woff"',
                  'woff2': '"woff2"'
                })[font.url.split('.').splice(-1, 1)[0]]});
              }
            ` }}></style>
          );
        }
      })}
    </>);
  }

  setVariables() {
    if (!this.state.variablesSet && StateService.getConfig().initialVariables) {
      const variables = StateService.getConfig().initialVariables;

      Object.keys(variables).forEach((key) => VariablesService.setVar(key, variables[key]));

      this.state.variablesSet = true;
    }
  }

  render() {
    if (!EditorService.isLoggedIn()) {
      EditorService.refreshAuth(async () => {
        this.forceUpdate();

        await StateService.fetchProject();

        this.forceUpdate();
      });

      return null;
    }

    const schema = StateService.getSchema(this.state.page);
    const library = StateService.getLibrary();
    const pages = StateService.getSchemaPages();
    const behaviours = StateService.getBehaviours();
    const config = StateService.getConfig();

    return (
      <div ref={this.pagePreview}>
        {this.setVariables()}
        {this.renderCustomFonts()}
        <PreviewOverlay>
          <b><i className="fal fa-fw fa-eye" /> Preview-mode</b> This site is not public.
        </PreviewOverlay>
        <SchemaRoot
          schema={() => schema}
          library={library || []}
          options={{
            simulateBehaviours: true,
            schemaRef: schema,
            ...config,
            compat_align_v1: typeof config[`compat_align_v1_${this.state.page}`] !== 'undefined' ? config[`compat_align_v1_${this.state.page}`] : config.compat_align_v1
          }}
          behaviours={behaviours || []}
        />
      </div>
    );
  }
}