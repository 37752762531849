import { parseEnvironment } from '../elements/environment';
import { parseFloor } from '../elements/floor';
import { parseObject } from '../elements/object';
import { parseGroup } from '../elements/group';
import { extractVariable } from '../../wtl-schema/helpers/extract-variable';
import { parseWall } from '../elements/wall';
import { parseTile } from '../elements/tile';

export const parseSchema = (branch, context, options = {}) => {
  if (!context.renderer || !context.root) {
    return;
  }

  if (!branch) {
    return null;
  }

  if (typeof branch === 'string') {
    return extractVariable(branch);
  }

  const {
    id,
    type,
    _dirty
  } = branch;
  const { root, resyncSelectionMesh, effects } = context;
  const key = [options.key, id].filter(Boolean).join('-');
  const childrenOptions = {
    key: key,
    parentId: id,
    parent: root
  };

  if (branch instanceof Array) {
    return branch.map((subbranch, index) => parseSchema(subbranch, context, childrenOptions));
  }

  let objectOrGroup;
  let reselectObject = false;
  const richOptions = {
    ...options,
    _selfKey: key
  };

  let cacheExistingChildren;
  if (_dirty === 2) {
    const existingInstance = root.getObjectByName(key);
    
    if (effects) {
      reselectObject = effects.drag.object === existingInstance;
    }

    if (existingInstance) {
      if (reselectObject && effects) {
        effects.drag.detach();
      }

      cacheExistingChildren = existingInstance.children;

      existingInstance.parent.remove(existingInstance);
    }

    branch._dirty = 1;
  }
  
  switch (type) {
    case 'environment':
      objectOrGroup = parseEnvironment(branch, context, richOptions);
    break;
    case 'floor':
      objectOrGroup = parseFloor(branch, context, richOptions);
    break;
    case 'group':
      objectOrGroup = parseGroup(branch, context, richOptions);
    break;
    case 'wall':
      objectOrGroup = parseWall(branch, context, richOptions);
    break;
    case 'tile':
      objectOrGroup = parseTile(branch, context, richOptions);
    break;
    case 'object':
      objectOrGroup = parseObject(branch, context, { ...richOptions, selectable: true, selectionTarget: true });
    break;
  }

  if (objectOrGroup) {
    objectOrGroup.name = key;
    objectOrGroup.branchId = id;
    childrenOptions.parent = objectOrGroup;

    if ([
      'floor',
      'environment'
    ].includes(type) && cacheExistingChildren) {
      objectOrGroup.children = objectOrGroup.children.concat(cacheExistingChildren.filter(({ name }) => name !== '_ca-ground-grass'));
    }

    if (reselectObject && context.effects && context.resyncSelectionMesh) {
      context.effects.drag.attach(objectOrGroup);
      context.resyncSelectionMesh(objectOrGroup);
    }
  }

  if (branch.content && branch.content instanceof Array) {
    return branch.content.map((subbranch, index) => parseSchema(subbranch, context, childrenOptions));
  }
};
