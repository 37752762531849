import React from 'react';
import styled from 'styled-components';

export const CAPanel = styled.div`
  position: absolute;
  ${p => typeof p.top !== 'undefined' && `top: ${p.top}px;`}
  ${p => typeof p.bottom !== 'undefined' && `bottom: ${p.bottom}px;`}
  ${p => typeof p.left !== 'undefined' && `left: ${p.left}px;`}
  ${p => typeof p.right !== 'undefined' && `right: ${p.right}px;`}
  pointer-events: all;
`;

export const CAPanelBody = styled.div`
  display: ${p => p.display ? p.display : 'block'};
  ${p => p.display === 'flex' && (
    `
      justify-content: space-between;
      align-items: stretch;
      align-content: stretch;
    `
  )}
  margin: ${p => p.margin || '10px'};
  font-size: 12px;
  letter-spacing: 1px;
  color: #333;
  overflow: hidden;
`;

export const CAPanelH1 = styled.span`
  font-family: Helvetica, Arial, sans-serif;
  font-size: 15px;
  color: #000;
  cursor: ${p => p.onClick ? 'pointer' : 'default'};
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;

  ${p => p.onClick && `
    &:hover {
      color: #ff6f03;
    }
  `}
`;
