import React from 'react';
import { VariableInput } from '../variable-input';
import { Row, Col } from 'antd';
import { imageFileTypes, _3dModelFileTypes } from '../../data/file-types.data';
import { WtlTooltip } from '../layout/wtl-tooltip';

export const render3dModelProps = function () {
  const { tools } = this.state;
  const { selection } = tools;

  return (
    <>
      <Col span={8}>
        <Row gutter={4}>
          <VariableInput
            inputType="file"
            filterTypes={_3dModelFileTypes}
            span={12}
            name={<>
              3d model{' '}
              <WtlTooltip
                placement="top"
                title="Only .obj models compatible."
              >
                <i className="fas fa-fw fa-question-circle" />
              </WtlTooltip>
            </>}
            value={selection.branch.modelUrl}
            onChange={(value, params) => {
              this.updateSelectionParam('modelUrl', { target: { value }}, params);
              this.updateSelectionParam('_3dKey', { target: { value: Date.now() }}, params);
            }}
          />
          <VariableInput
            inputType="file"
            filterTypes={imageFileTypes}
            span={12}
            name="texture"
            value={selection.branch.textureUrl}
            onChange={(value, params) => {
              this.updateSelectionParam('textureUrl', { target: { value }}, params);
              this.updateSelectionParam('_3dKey', { target: { value: Date.now() }}, params);
            }}
          />
          <VariableInput
            inputType="file"
            filterTypes={imageFileTypes}
            span={12}
            name="reflections image"
            value={selection.branch.envMapUrl}
            onChange={(value, params) => {
              this.updateSelectionParam('envMapUrl', { target: { value }}, params);
              this.updateSelectionParam('_3dKey', { target: { value: Date.now() }}, params);
            }}
          />
          <VariableInput
            inputType="file"
            filterTypes={imageFileTypes}
            span={12}
            name="material mapping"
            value={selection.branch.materialMapUrl}
            onChange={(value, params) => {
              this.updateSelectionParam('materialMapUrl', { target: { value }}, params);
              this.updateSelectionParam('_3dKey', { target: { value: Date.now() }}, params);
            }}
          />
        </Row>
        {!selection.branch.materialMapUrl && <Row gutter={4}>
          <VariableInput
              nonVariable
              inputType="range"
              span={24}
              name="roughness"
              inputProps={{
                min: 1,
                max: 100,
                defaultValue: 100
              }}
              value={selection.branch.roughness}
              onChange={(value, params) => {
                this.updateSelectionParam('roughness', { target: { value }}, params);
                this.updateSelectionParam('_3dKey', { target: { value: Date.now() }}, params);
              }}
            />
            <VariableInput
              nonVariable
              inputType="range"
              span={24}
              name="metalness"
              inputProps={{
                min: 1,
                max: 100,
                defaultValue: 100
              }}
              value={selection.branch.metalness}
              onChange={(value, params) => {
                this.updateSelectionParam('metalness', { target: { value }}, params);
                this.updateSelectionParam('_3dKey', { target: { value: Date.now() }}, params);
              }}
            />
          </Row>}
      </Col>
      <Col span={8}>
        <VariableInput
          nonVariable
          inputType="switch"
          span={12}
          name="smooth edges"
          value={selection.branch.enableAA !== false}
          onChange={(value, params) => {
            this.updateSelectionParam('enableAA', { target: { value }}, params);
            this.updateSelectionParam('_3dKey', { target: { value: Date.now() }}, params);
          }}
        />
        <VariableInput
          nonVariable
          inputType="switch"
          span={12}
          name="high-quality rendering"
          value={selection.branch.enableSSAO}
          onChange={(value, params) => {
            this.updateSelectionParam('enableSSAO', { target: { value }}, params);
            this.updateSelectionParam('_3dKey', { target: { value: Date.now() }}, params);
          }}
        />
        <VariableInput
          nonVariable
          inputType="range"
          span={24}
          name={<>
            shade detail{' '}
            <WtlTooltip
              placement="top"
              title="SSAO kernel size. Low for small models, high for large models."
            >
              <i className="fas fa-fw fa-question-circle" />
            </WtlTooltip>
          </>}
          inputProps={{
            min: 1,
            max: 1000,
            defaultValue: 100,
            disabled: !selection.branch.enableSSAO
          }}
          value={selection.branch.SSAODetail}
          onChange={(value, params) => {
            this.updateSelectionParam('SSAODetail', { target: { value }}, params);
            this.updateSelectionParam('_3dKey', { target: { value: Date.now() }}, params);
          }}
        />
      </Col>
    </>
  );
}
