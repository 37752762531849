import React from 'react';
import { VariableInput } from '../variable-input';
import { Row, Col } from 'antd';

export const renderInputProps = function () {
  const { tools } = this.state;
  const { selection } = tools;

  return (
    <Col span={8}>
      <Row gutter={4}>
        <VariableInput
          span={12}
          name="input type"
          value={selection.branch.inputType}
          onChange={(value, params) => {
            this.updateSelectionParam('inputType', { target: { value }}, params);

            const validationPattern = ({
              'text': '.*',
              'textarea': '.*',
              'number': '\d*',
              'file': '',
              'checkbox': '',
              'password': '',
              'phone': '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$',
              'date': '',
              'time': ''
            })[value];

            this.updateSelectionParam('validation', { target: { value: validationPattern }}, params);
          }}
          inputType="select"
        >
          <option value="text">
            text
          </option>
          <option value="textarea">
            multiline text
          </option>
          <option value="number">
            number
          </option>
          <option value="file">
            file
          </option>
          <option value="checkbox">
            checkbox
          </option>
          <option value="password">
            password
          </option>
          <option value="tel">
            phone
          </option>
          <option value="date">
            date
          </option>
          <option value="time">
            time
          </option>
        </VariableInput>
        <VariableInput
          span={12}
          name="input pattern"
          value={selection.branch.validation}
          onChange={(value, params) => {
            this.updateSelectionParam('validation', { target: { value }}, params);
          }}
        />
      </Row>
      <Row gutter={4}>
        <VariableInput
          span={12}
          name="placeholder"
          value={selection.branch.placeholder}
          onChange={(value, params) => {
            this.updateSelectionParam('placeholder', { target: { value }}, params);
          }}
        />
      </Row>
    </Col>
  );
}