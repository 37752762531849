import React from 'react';
import styled from 'styled-components';
import {
  Layout,
  Menu,
  message,
  Tag,
  Progress
} from 'antd';
import { navigate, navigateTo, Link } from 'gatsby';
import StateService from '../services/state.service';
import EditorService, { authTokenKey } from '../services/editor.service';

const publishTimeKey = 'pt_';

const { Item } = Menu;
const {
  Header,
} = Layout;

const WtlLink = styled.a`
  color: unset;
  transition: unset;
`;

const WtlHeader = styled.div`
  display: flex;
  box-sizing: border-box;
  background-color: #444;
  border-bottom: solid 1px #263346;
  color: #fff;
  justify-content: space-between;
  font-size: 10px;
`;

const HeaderLeft = styled.div`
  display: inline-flex;
`;

const HeaderRight = styled.div`
  display: inline-flex;
`;

const HeaderItem = styled.div`
  padding: 10px;
  cursor: pointer;
  user-select: none;

  &[disabled] {
    cursor: default;
  }
`;

const ButtonItem = styled(HeaderItem)`
  transition: background-color .1s ease, color .1s ease;

  span {
    font-size: 10px;
  }

  &[disabled] {
    opacity: .5;
  }

  &:hover:not([disabled]) {
    background-color: ${p => p.color || '#fff'};
    color: #000;
  }

  &:active {
    filter: brightness(75%);
  }
`;

const LoadableItem = styled(HeaderItem)`
  @keyframes loadingPulse {
    0% {
      background: #fff;
      width: 0%;
      left: 0;
      box-shadow: 0 0 10px #fff;
    }

    40% {
      background: #1890ff;
      width: 100%;
      left: 0;
      box-shadow: 0 0 10px #1890ff;
    }

    100% {
      left: 100%;
      width: 0%;
      box-shadow: 0 0 10px #fff;
    }
  }

  position: relative;
  user-select: none;

  ${p => p.loading ? (`
    background-color: transparent !important;
    cursor: default !important;

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      content: '';
      background: #1890ff;
      animation: loadingPulse 1s ease-in-out both infinite;
    }
  `) : ''}

  ${p => p.highlight && `
    background-color: #1890ff;
  `}

  ${p => p.underline && `
    cursor: default;

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      content: '';
      background: #355dab;
    }
  `}

  ${p => p.disabled && `
    cursor: default;
  `}
`;

const onLogout = () => {
  sessionStorage.clear();

  window.location.href = '/login';
};

export class HeaderNav extends React.Component {
  updateInterval = null;

  constructor(props) {
    super(props);

    this.state = {
      target: '',
      logoutIcon: 'fa-door-closed'
    };
  }

  getPublishValue() {
    const value = Math.min(100, 100 - (sessionStorage[publishTimeKey] - Date.now()) / (2 * 60000) * 100);

    if (value >= 100 && this.updateInterval) {
      clearInterval(this.updateInterval);

      this.updateInterval = null;

      delete sessionStorage[publishTimeKey];
    }

    return value;
  }

  async onSave() {
    if (EditorService.isDemo()) {
      message.info('Sorry, cannot save the demo project.');
      return;
    }

    message.success('Saving project...');

    try {
      await new Promise((done) => {
        EditorService.refreshAuth(async () => {
          await StateService.saveAll();
          done();
        });
      });
    } catch (error) {
      console.error(error);
      message.error('Failed to save project, try again.');
      
      return;
    }

    message.success('Project saved.');
  }

  async onSaveAndPublish() {
    if (EditorService.isDemo()) {
      message.info('Sorry, cannot publish the demo project.');
      return;
    }

    const publishPercent = this.getPublishValue();

    if (publishPercent) {
      return;
    }

    message.success('Saving project...');
  
    try {
      await new Promise((done) => {
        EditorService.refreshAuth(async () => {
          await StateService.saveAll();
          done();
        });
      });
    } catch (error) {
      console.error(error);
      message.error('Failed to save project, try again.');
      
      return;
    }
  
    message.success('Publishing project...');
  
    try {
      const { requested, queued } = await StateService.publish();
  
      if (requested) {
        sessionStorage[publishTimeKey] = Date.now() + 2 * 60000;

        this.updateInterval = setInterval(() => {
          if (!this || typeof this.forceUpdate !== 'function' || this.getPublishValue() >= 100) {
            clearInterval(this.updateInterval);

            this.updateInterval = null;

            delete sessionStorage[publishTimeKey];

            return;
          }

          this.forceUpdate();
        }, 100);
  
        message.success('Site is being published. You can safely close the browser.');
      } else if (queued) {
        message.success('Site publishing in process. Wait until finished.');
      }
    } catch (error) {
      console.error(error);
      message.warn('Project back-up in progress, please try again in a moment.');
      
      return;
    }
  
    message.success('Project saved.');
  }

  getStoredPublishTime() {
    if (typeof window === 'undefined') {
      return '0';
    }
    
    return sessionStorage[publishTimeKey];
  }

  goTo(target) {
    if (target === window.location.pathname) {
      return;
    }

    this.setState({ ...this.state, target });

    navigateTo(target);
  }

  render() {
    const { service } = this.props;
    const { target, logoutIcon } = this.state;

    const publishPercent = this.getPublishValue();
    const location = typeof window !== 'undefined' ? window.location.pathname : '/';
    const subpage = StateService.getEditedElement('schemaPage');
    const isDemo = EditorService.isDemo();

    return (
      <WtlHeader>
        <HeaderLeft>
          <LoadableItem
            onClick={() => this.goTo('/')} loading={target === '/'}
            underline={location === '/'}
            disabled={location === '/'}
          >
            <i className="fas fa-fw fa-cookie-bite" /> editor
          </LoadableItem>
          {StateService.getFeatureToggle('feature_advanced_csseditor') &&
            <LoadableItem
              onClick={() => this.goTo('/library')}
              loading={target === '/library'}
              underline={location === '/library'}
            >
              <i className="fas fa-fw fa-palette" /> styles
            </LoadableItem>
          }
          <LoadableItem
            onClick={() => this.goTo('/events')}
            loading={target === '/events'}
            underline={location === '/events'}
          >
            <i className="fas fa-fw fa-keyboard" /> interactions
          </LoadableItem>
          <LoadableItem
            onClick={() => this.goTo('/settings')}
            loading={target === '/settings'}
            underline={location === '/settings'}
          >
            <i className="fas fa-fw fa-tools" /> settings
          </LoadableItem>
        </HeaderLeft>
        <HeaderRight>
          <ButtonItem disabled={isDemo} onClick={() => !isDemo && this.onSave()} key="save" color="#8fffc9">
            <i className="fas fa-fw fa-save"></i> <span>Save</span>
          </ButtonItem>
          <WtlLink href={`/preview#${subpage || 'index'}`} target="_blank">
            <ButtonItem key="preview" color="#c4d7ff">
              <i className="fas fa-fw fa-eye"></i> <span>Preview</span>
            </ButtonItem>
          </WtlLink>
          <ButtonItem disabled={publishPercent || isDemo} onClick={() => !isDemo && this.onSaveAndPublish()} key="publish" color="#ff7dc2">
            <i className="fas fa-fw fa-upload"></i>{' '}
            {
              publishPercent ?
              <span>Publishing {publishPercent.toFixed(0)}%</span> :
              <span>Publish</span>
            }
          </ButtonItem>
          <ButtonItem
            onClick={() => onLogout()}
            onMouseOver={() => this.setState({ ...this.state, logoutIcon: 'fa-door-open' })}
            onMouseLeave={() => this.setState({ ...this.state, logoutIcon: 'fa-door-closed' })}
            key="logout"
            color="#fff"
          >
            <i className={`fas fa-fw ${logoutIcon}`}></i><span>Logout</span>
          </ButtonItem>
        </HeaderRight>
      </WtlHeader>
    );
  }
}