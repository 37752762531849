import React from 'react';
import styled from 'styled-components';
import { CAPanel, CAPanelBody, CAPanelH1 } from './ca-panel';
import { Row, Col } from 'antd';
import { models } from '../../data/common/models';
import { ifWindow } from '../../utils/if-window';
import { CAButton } from './ca-button';

import LivingRoomIconSvg from '../../images/common-app/icons/ICONS_COMMON_SVG-01.svg';
import DecorIconSvg from '../../images/common-app/icons/ICONS_COMMON_SVG-02.svg';
import KitchenIconSvg from '../../images/common-app/icons/ICONS_COMMON_SVG-06.svg';
import BedroomIconSvg from '../../images/common-app/icons/ICONS_COMMON_SVG-11.svg';
import ToiletIconSvg from '../../images/common-app/icons/ICONS_COMMON_SVG-16.svg';
import { InvertedImage } from '../../utils/inverted-image';
import { WtlGlobalKeyboardListener } from '../layout/wtl-global-keyboard-listener';

const ModelPreview = styled.img`
  cursor: pointer;
  transition: all .1s ease;
  filter: drop-shadow(0px 1px 1px #cccccc);

  &:hover {
    transform: scale(1.2);
  }

  ${p => p.dimmed && 'opacity: .3;'}
`;

export class CALibrary extends React.Component {
  state = {
    selectedModel: ''
  };

  onModelClick(modelId) {
    const { selectedModel } = this.state;
    const { onElementSelected } = this.props;

    if (modelId === selectedModel) {
      this.state.selectedModel = '';

      onElementSelected();

      this.forceUpdate();
      return;
    }

    this.state.selectedModel = modelId;
    onElementSelected(modelId);

    this.forceUpdate();
  }

  renderFilteredModels() {
    const { selectedModel } = this.state;
    const {
      pushUiState,
      uiState
    } = this.props;

    return (
      <>
        <Row>
          {Object.keys(models).map((id) => {
            const model = models[id];
            
            return (
              <Col span={8}>
                <ModelPreview
                  src={model.previewUrl}
                  width="100%"
                  dimmed={selectedModel && selectedModel !== id}
                  onClick={() => {
                    this.onModelClick(id);
                  }}
                />
              </Col>
            ) ;
          })}
        </Row>
        <Row>
          <Col span={8}>
            <CAPanelH1 onClick={() => {
              if (uiState.libraryCustomModel) {
                return;
              }

              pushUiState(
                'left',
                365,
                -(ifWindow(window.innerHeight / 2, 0)) + 195,
                true,
                {
                  libraryCustomModel: true
                }
              ); 
            }}>
              <i className="fas fa-fw fa-plus-circle" style={{ fontSize: 62 }} />
            </CAPanelH1>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const {
      pushUiState,
      popUiState,
      uiState
    } = this.props;

    return (
      <CAPanelBody margin="20px 25px" style={{ overflow: 'visible' }}>
        <WtlGlobalKeyboardListener
          listeners={[
            {
              keys: ['escape'],
              off: () => {
                this.onModelClick(this.state.selectedModel);
              }
            }
          ]}
        />
        <Row>
          <Col span={12}>
            <CAPanelH1>
              Library
            </CAPanelH1>
          </Col>
          <Col span={12}>
            <CAButton
              onClick={() => {
                if (uiState.libraryFilters) {
                  popUiState('left');
                  return;
                }

                pushUiState(
                  'left',
                  597,
                  -(ifWindow(window.innerHeight / 2, 0)) + 195,
                  true,
                  {
                    libraryModal: true,
                    libraryFilters: true
                  }
                ); 
              }}
              style={{ textAlign: 'center' }}
              active={uiState.libraryFilters}
            >
              Search filters
            </CAButton>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'center' }}>
            <CAPanelBody display="flex" margin="20px 0" style={{ overflow: 'visible' }}>
              {[
                { icon: LivingRoomIconSvg, active: false, onClick: () => {} },
                { icon: KitchenIconSvg, active: false,  onClick: () => {} },
                { icon: ToiletIconSvg, active: true,  onClick: () => {} },
                { icon: BedroomIconSvg, active: false,  onClick: () => {} },
                { icon: DecorIconSvg, active: false, onClick: () => {} },
              ].map(({ icon, onClick, active }) => (
                <div
                  style={{ width: '100%' }}
                  onClick={onClick}
                >
                  <InvertedImage
                    src={icon}
                    width="50"
                    height="50"
                    style={{ filter: [active ? '' : 'invert(100%)', 'drop-shadow(0px 1px 2px #dddddd)'].join(' ') }}  
                  />
                </div>
              ))}
            </CAPanelBody>
          </Col>
          <Col span={24} style={{ textAlign: 'center' }}>
            <CAPanelBody margin="0 0 20px 0" style={{ overflow: 'visible' }}>
              <CAButton dimmed>
                Search ...
              </CAButton>
            </CAPanelBody>
          </Col>
        </Row>
        {this.renderFilteredModels()}
      </CAPanelBody>
    );
  }
}