import Rete from 'rete';
import { EventSourceSocket, StringSocket } from '../sockets';
import { BaseLayout } from '../layouts/base.layout';
import { StringControl } from '../controls/string.control';

export class NavigateToNode extends Rete.Component {
  constructor() {
    super('Go To Page');
    this.data.component = BaseLayout;
  }

  builder(node) {
    const inp1 = new Rete.Input('page-url', 'Page URL', StringSocket, false);
    const inp2 = new Rete.Input('event-source-in', '', EventSourceSocket, false);
    
    inp1.addControl(new StringControl(this.editor, 'page-url', node));

    return node
      .addInput(inp1)
      .addInput(inp2);
  }

  worker(node, inputs, outputs) {
    node.data['page-url'] = inputs['page-url'].length ? inputs['page-url'][0] : node.data['page-url'];
  }
}