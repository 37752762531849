import React from 'react';

export class RemoteCanvas extends React.Component {
  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();
  }

  initCanvas() {
    const { width = 512, height = 512 } = this.props;
    const ctx = this.canvasRef.current.getContext('2d');

    ctx.fillStyle = '0xffffff';
    ctx.fillRect(0, 0, width, height);
  }

  componentDidMount() {
    const { onCreate } = this.props;

    this.initCanvas();

    if (onCreate) {
      onCreate(this.canvasRef.current);      
    }
  }

  componentDidUpdate() {
    const { onUpdate } = this.props;

    if (onUpdate) {
      onUpdate(this.canvasRef.current);      
    }
  }

  render() {
    const { width = 512, height = 512 } = this.props;

    return (
      <canvas
        key={1}
        ref={this.canvasRef}
        width={width}
        height={height}
        style={{
          display: 'none'
        }}
      />
    );
  }
}
