export const extractPresetStyles = (preset) => {
  const styles = [];

  preset.forEach(block => {
    if (block.customWrapperElement) {
      styles.push(block.customWrapperElement);
    }

    if (block.content instanceof Array) {
      let childrenStyles = extractPresetStyles(block.content);

      styles.push(...childrenStyles);
    }
  });

  return styles;
};

export const presetStyles = {
  'wtl-preset-responsive-image-left': {
    id: 'wtl-preset-responsive-image-left',
    dom: 'div',
    styling: `& {
  overflow: hidden;

  img {
    width: 100%;
    min-height: 150px;
    height: auto;
    float: right;
  }
}`
  },
  'wtl-preset-responsive-image-right': {
    id: 'wtl-preset-responsive-image-right',
    dom: 'div',
    styling: `& {
  overflow: hidden;

  img {
    width: 100%;
    min-height: 150px;
    height: auto;
    float: left;
  }
}`
  },
  'wtl-preset-half-page-size': {
    id: 'wtl-preset-half-page-size',
    dom: 'div',
    styling: `& {
  max-width: calc(1200px / 2);
}`
  },
  'wtl-preset-page-size': {
    id: 'wtl-preset-half-page-size',
    dom: 'div',
    styling: `& {
  max-width: 1200px;
}`
  },
  'wtl-preset-background-image': {
    id: 'wtl-preset-background-image',
    dom: 'div',
    styling: `& {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: brightness(20%);
}`
  },
  'wtl-preset-page-width-960': {
    id: 'wtl-preset-page-width-960',
    dom: 'div',
    styling: `& {
  width: 100%;
  max-width: 960px;
  min-height: 100vh;
  height: 100%;
}`
  },
  'wtl-preset-page-width-1280': {
    id: 'wtl-preset-page-width-1280',
    dom: 'div',
    styling: `& {
  width: 100%;
  max-width: 1280px;
  min-height: 100vh;
  height: 100%;
}`
  },
  'wtl-preset-page-width-1440': {
    id: 'wtl-preset-page-width-1440',
    dom: 'div',
    styling: `& {
  width: 100%;
  max-width: 1440px;
  min-height: 100vh;
  height: 100%;
}`
  }
};
