import React from 'react';
import styled from 'styled-components';
import * as Three from 'three';
import { Col } from 'antd';
import { CAButtonBorderRadius, CAModalBorderRadius } from './shared';
import { isVar } from '../variable-input';
import { CAButton } from './ca-button';
import { CAMenuBlob } from './ca-menu-blob';
import { CAColorPicker } from './ca-color-picker';

const InputWrapper = styled(Col)`
  box-sizing: border-box;
  padding: 15px;
  background-color: #fff;
  min-height: 50px;
  border-radius: ${CAModalBorderRadius};
  cursor: default;

  input,
  textarea,
  select {
    width: 100%;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 11px;
    letter-spacing: 1px;
    color: #828282;
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    box-sizing: border-box;
    line-height: 1em;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    outline: none;
    resize: none;
  }
`;

const ExpandBoxLabel = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 50%;
  left: 50%;
  width: 84%;
  transform: translateX(-50%) translateY(-50%);
  white-space: nowrap;
  line-height: 0;
`;

export class CAVariableInput extends React.Component {
  state = {
    customVar1: null,
    customVar2: null
  };

  render() {
    const {
      name,
      value,
      onChange,
      onClick,
      span,
      inputType,
      inputProps = {},
      children,
      wrapperProps = {},
    } = this.props;
    const { customVar1, customVar2 } = this.state;

    if (inputType === 'expand-box') {
      return (
        <Col span={span || 24} {...wrapperProps}>
          <CAMenuBlob
            autoClose={false}
            customLabel={(
              <ExpandBoxLabel>
                <div>{name}</div>
                <div>
                  <i className={`fal fa-fw fa-chevron-${customVar1 ? 'up' : 'down'}`} />
                </div>
              </ExpandBoxLabel>
            )}
            expandedContent={(
              <div>
                {children}
              </div>
            )}
            onClick={(expanded) => {
              this.state.customVar1 = expanded;
              this.forceUpdate();
            }}
            style={{
              width: '100%'
            }}
          />
        </Col>
      );
    } else if (inputType === 'select') {

    } else if (inputType === 'color') {
      return (
        <Col span={span || 24} {...wrapperProps}>
          <CAColorPicker
            color={(isVar(value) ? value.substr(3) : (value ? decodeURIComponent(value) : ''))}
            onChangeComplete={(value) => {
              onChange(isVar(value) ? '' : value, { variable: false });
            }}
            {...inputProps}
          />
        </Col>
      );
    } else if (inputType === 'button') {
      return (
        <Col span={span || 24} {...wrapperProps}>
          <CAButton
            onClick={(e) => onClick(e)}
            {...inputProps}
          >
            {children || name}
          </CAButton>
        </Col>
      );
    } else if (inputType === 'textarea') {
      return (
        <InputWrapper span={span || 24} {...wrapperProps}>
          <textarea
            rows={4}
            defaultValue=""
            value={(isVar(value) ? value.substr(3) : (value ? decodeURIComponent(value) : ''))}
            onChange={(e) => onChange(isVar(value) ? '' : e.target.value, { variable: false })}
            {...inputProps}
          />
        </InputWrapper>
      );
    } else if (inputType === 'angle') {
      let degrees = Three.MathUtils.radToDeg(isVar(value) ? value.substr(3) : (value ? decodeURIComponent(value) : ''));

      degrees = degrees % 360;

      if (degrees < 0) {
        degrees += 360;
      }

      return (
        <InputWrapper span={span || 24} {...wrapperProps}>
          <i className="fal fa-fw fa-minus-circle" />
          <input
            style={{
              position: 'relative',
              left: 20,
              width: 'calc(100% - 40px)',
              textAlign: 'right'
            }}
            type="text"
            defaultValue=""
            value={customVar1 ? customVar2 : `${degrees.toFixed(1)}°`}
            onFocus={(e) => {
              this.setState({ customVar1: true, customVar2: parseInt(degrees) });
            }}
            onChange={(e) => {
              this.setState({ customVar2: e.target.value });
            }}
            onKeyUp={(e) => {
              if (customVar1) {
                const { key } = e;

                if (key === 'Enter') {
                  const rad = Three.MathUtils.degToRad(customVar2);
                  onChange(isVar(value) ? '' : rad, { variable: false });
                }
              }
            }}
            onBlur={(e) => {
              this.setState({ customVar1: false });

              const rad = Three.MathUtils.degToRad(e.target.value);
              onChange(isVar(value) ? '' : rad, { variable: false });
            }}
            {...inputProps}
          />
        </InputWrapper>
      );
    } else {
      return (
        <InputWrapper span={span || 24} {...wrapperProps}>
          <input
            type="text"
            defaultValue=""
            value={(isVar(value) ? value.substr(3) : (value ? decodeURIComponent(value) : ''))}
            onChange={(e) => onChange(isVar(value) ? '' : e.target.value, { variable: false })}
            {...inputProps}
          />
        </InputWrapper>
      );
    }

    return null;
  }
};
