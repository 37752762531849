import React, { createRef } from 'react';
import styled from 'styled-components';
import {
  Layout,
  Row,
  Col,
  message,
  Spin,
  Tooltip,
} from 'antd';
import { AntWrapper } from '../components/ant-wrapper';
import StateService from '../services/state.service';
import { getBranchHTML } from '../../wtl-schema/core/get-branch-html';
import { EditorOverlay, Selection, addCssValues } from '../components/editor';
import { v4 } from 'uuid';
import { HeaderNav } from '../components/header-nav';
import { SimpleButton, SimpleInput } from '../styles/styled';
import { Link, navigate } from 'gatsby';
import { VariableInput } from '../components/variable-input';
import EditorService, { SAFE_UNDEFINED, authTokenKey, serviceKey } from '../services/editor.service';
import { callApi } from '../../config';
import { renderVimeoProps } from '../components/advanced-elements/render-vimeo-props';
import { renderCarouselProps } from '../components/advanced-elements/render-carousel-props';
import VariablesService from '../../wtl-schema/services/variable.service';
import { renderTrustPilotProps } from '../components/advanced-elements/render-trust-pilot-props';
import { renderContactFormProps } from '../components/advanced-elements/render-contact-form-props';
import { RichTextEditor } from '../components/rich-text-editor';
import { BlockSelector } from '../components/block-selector';
import { extractPresetStyles, presetStyles } from '../components/preset-styles';
import { WtlPanel, WtlPanelNote, WtlPanelVertical, WtlPanelHorizontal } from '../components/layout/wtl-panel';
import { BlockStyleEditor } from '../components/block-style-editor';
import { renderPagePreviewProps } from '../components/advanced-elements/render-page-preview-props';
import { renderTimerProps } from '../components/advanced-elements/render-timer-props';
import { renderYouTubeProps } from '../components/advanced-elements/render-youtube-props';
import { renderRootProps } from '../components/advanced-elements/render-root-props';
import { renderLinkProps } from '../components/advanced-elements/render-link-props';
import { renderVideoProps } from '../components/advanced-elements/render-video-props';
import { renderImageProps } from '../components/advanced-elements/render-image-props';
import { renderTextProps } from '../components/advanced-elements/render-text-props';
import { renderInputProps } from '../components/advanced-elements/render-input-props';
import { renderIconProps } from '../components/advanced-elements/render-icon-props';
import { renderAnimationProps } from '../components/advanced-elements/render-animation-props';
import { WtlFilesBar } from '../components/layout/wtl-files-bar';
import { WtlSchemaTree } from '../components/layout/wtl-schema-tree';
import { renderShapeProps } from '../components/advanced-elements/render-shape-props';
import { snapTo } from '../helpers/math/snap-to';
import { renderAnimationBlockProps } from '../components/advanced-elements/render-animation-block-props';
import { groupBlocks, nestedBlocks } from '../data/block-types.data';
import { uNaN } from '../utils/u-nan';
import { globalMouseHelper } from '../utils/global-mouse';
import { WtlGlobalKeyboardListener } from '../components/layout/wtl-global-keyboard-listener';
import { parseUndefString } from '../utils/parse-undef-string';
import { renderScrollBlockProps } from '../components/advanced-elements/render-scroll-block-props';
import { WtlRulers } from '../components/layout/wtl-rulers';
import { WtlContextMenuKeyword } from '../components/layout/wtl-context-menu';
import { render3dModelProps } from '../components/advanced-elements/render-3d-model-props';
import { renderStripeProps } from '../components/advanced-elements/render-stripe-props';
import { renderAdvancedInputProps } from '../components/advanced-elements/render-advanced-input-props';
import { WtlSubpagesModal } from '../components/layout/wtl-subpages-modal';

if (typeof window !== 'undefined') {
  window.removeEventListener('mousemove', globalMouseHelper);
  window.addEventListener('mousemove', globalMouseHelper);
}

const {
  Content
} = Layout;

const PagePreview = styled.div`
  position: relative;
  width: 100%;
  min-width: 320px;
  cursor: default;
  overflow: visible;
  overflow-x: hidden;
`;

const PageWrapper = styled.div`
  overflow: visible;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 680px;
  width: ${p => p.previewSize || '320px'};
  background-color: #ccc;
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, .2); */
  ${p => !p.empty && `min-height: 980px;`}

  * {
    box-sizing: border-box;
    overflow: visible;
    user-select: none;
  }

  & > * {
    /* box-shadow: 0px 0px 30px rgba(0, 0, 0, .5); */
    overflow: visible;
  }

  .t-element {
    ${p => p.showFrames ? 'border: solid .5px rgba(0, 0, 0, .2);' : ''}
  }

  & * {
    color: inherit;
    text-decoration: inherit; 

    &:hover, &:active, &:focus {
      color: inherit;
      text-decoration: inherit; 
    }
  }
`;

const PageWrapperEmpty = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  font-size: 24px;
  transform: translateX(-50%) translateY(-50%);

  b {
    font-size: 32px;
  }
`;

const DebugHelperOption = styled.div`
  display: inline-block;
  margin: 0 5px;
  padding: 0 5px;
  padding-right: 0;
  border-left: solid 1px #ccc;
  cursor: default;
  text-align: center;
  user-select: none;
  width: ${p => p.width ? `${p.width}px` : 'auto'};

  &[disabled] {
    opacity: .3;
  }

  i {
    font-size: 10px;
    opacity: ${p => p.inactive ? '1' : '.5'};
  }

  &:not([disabled]):hover {
    i {
      opacity: 1;
    }
  }
`;

const SelectionDragState = {
  inactive: 0,
  click: 1,
  move: 2,
  finished: 3
};

export default class Index extends React.Component {
  autoSavingInterval = null;

  constructor(props) {
    super(props);

    this.state = {
      previewMode: 'desktop',
      page: StateService.getEditedElement('schemaPage'),
      tools: {
        tempParam: null,
        selection: {
          element: null,
          branch: null,
          refs: null
        },
        hover: {
          element: null,
          branch: null
        },
        rightClicked: null,
        dragState: SelectionDragState.inactive,
        dragStart: null,
        showFreeTransform: false,
        animationKeyframe: 0
      },
      panels: {
        panelBottomHorizontal: true,
        panelBottomIndex: null,
        showSavingSchema: false,
        showBackgroundModal: false,
        showRichTextEditor: false,
        showSubpagesModal: false
      },
      debug: {
        showFrames: true,
        showGrid: true,
        simulateBehaviours: false,
        showImages: true,
        previewSize: 'auto',
        showRulers: true,
        guides: [],
        snapToGuides: true
      }
    };

    this.pagePreview = createRef();
    this.pageWrapper = createRef();
  }

  preventLinkTransition(event) {
    event.preventDefault();

    message.info('Link clicked. Page change prevented.');
  }

  componentWillMount() {
    this.initAutoSave();
  }

  componentDidUpdate() {
    if (!this.pagePreview.current) {
      return;
    }

    
    this.pagePreview.current.querySelectorAll('a[href]').forEach(link => {
      link.removeEventListener('click', this.preventLinkTransition);
      link.addEventListener('click', this.preventLinkTransition);
    });
    
    this.initAutoSave();
    
    const { selection } = this.state.tools;

    if (selection && selection.branch && selection.branch._locked) {
      this.clearSelection();
    }
  }

  initAutoSave() {
    if (!this.autoSavingInterval) {
      if (this.autoSavingInterval) {
        clearInterval(this.autoSavingInterval);
      }

      this.autoSavingInterval = setInterval(() => {
        EditorService.refreshAuth(() => {
          this.saveSchema();
        });
      }, 60 * 1000);
    }
  }

  onToolClick(_id = ':', preset, unwrappedPreset) {
    let [ id, variant ] = `${_id}`.split(':').concat(null);

    const { selection } = this.state.tools;
    const uid = `${id}-${v4()}`;

    let newElement;

    if (preset) {
      if (unwrappedPreset) {
        newElement = this.remapSelectionIds(preset);
        newElement.id = uid;
      } else {
        newElement = this.remapSelectionIds({
          id: uid,
          type: 'block',
          variant: variant,
          size: 24,
          align: 'left',
          content: [
            ...preset
          ]
        });

        const extractedStyles = extractPresetStyles(preset);
        const library = StateService.getLibrary();

        extractedStyles.forEach(style => {
          const styleExists = library.some(component => component.id === style);

          if (!styleExists) {
            const stylePreset = presetStyles[style];
            const newStyle = StateService.createNewLibraryElement(stylePreset.id);

            newStyle.id = style;
            newStyle.dom = stylePreset.dom;
            newStyle.styling = stylePreset.styling;
          }
        });
      }

      StateService.saveLibrary();
    } else {
      newElement = {
        id: uid,
        type: id,
        variant: variant,
        align: ({
          'image': 'center'
        })[id] || 'left',
        content: ({
          'text': 'Double-click to add text...'
        })[id],
        url: ({
          'image': encodeURIComponent('https://cdn.wtlstudio.com/sample.wtlstudio.com/478ef8a8-f00f-4593-b84a-7051192fc7a3.png')
        })[id],
        cssBorderStyle: ({
          'shape': 'solid'
        })[id],
        cssBorderColor: ({
          'shape': '#000000'
        })[id],
        cssBorderThickness: ({
          'shape': '2px'
        })[id]
      };

      if (['block', 'advanced-background-block'].includes(id)) {
        newElement.size = 24;
      } else {
        newElement.size = 24;
        newElement.cssWidth = '160px';
        newElement.cssPositionSnap = 'absolute';
        newElement.cssPositionAlign = encodeURIComponent('center center');
        newElement.cssPositionOffsetX = encodeURIComponent('50%');
        newElement.cssPositionOffsetY = encodeURIComponent(
          selection.branch && selection.branch.cssHeight ? (
            `${(parseFloat(decodeURIComponent(selection.branch.cssHeight)) / 2)}px`
          ) : '50px'
        );
      }

      newElement.cssHeight = '120px';
      newElement.cssOpacity = 100;
    }

    if (selection.branch) {
      if (selection.branch._refSrc) {
        newElement._refSrc = newElement.id;
      }

      if (!selection.branch.content || selection.branch.content instanceof Array) {
        selection.branch.content = [
          ...selection.branch.content,
          newElement
        ].filter(Boolean);
      } else {
        selection.branch.content = [
          selection.branch.content,
          newElement
        ].filter(Boolean);
      }

      if (selection.refs) {
        this.syncSelectionRefs('content', selection.branch);
      }

      this.forceUpdate();

      this.forceSelection(uid, newElement);

      return this.saveSchema();
    }

    const schema = StateService.getSchema(this.state.page);

    schema.push(newElement);

    this.forceUpdate();

    this.saveSchema();

    this.forceSelection(uid, newElement);
  }

  saveSchema() {
    StateService.saveSchema();

    this.setState({
      ...this.state,
      panels: {
        ...this.state.panels,
        showSavingSchema: true
      }
    });

    setTimeout(() => {
      this.setState({
        ...this.state,
        panels: {
          ...this.state.panels,
          showSavingSchema: false
        }
      });
    }, 500);
  }

  onHover(state, { element, branch }) {
    if (state === false) {
      if (!this.state.tools.hover.element) {
        return;
      }

      return this.setState({
        tools: {
          ...this.state.tools,
          hover: {}
        }
      });
    }

    if (this.state.tools.hover.element === element) {
      return;
    }

    this.setState({
      tools: {
        ...this.state.tools,
        hover: {
          element,
          branch
        }
      }
    });
  }

  onSelection({ element, branch }, { doubleClick } = {}) {
    if (!element || !branch || branch._locked) {
      this.clearSelection();

      return;
    }

    if (this.state.tools.selection.element === element) {
      if (doubleClick) {
        if (branch.type === 'text') {
          this.state.panels.showRichTextEditor = true;
          this.forceUpdate();
        } else if (branch.type !== 'root' && branch.type !== 'block') {
          this.state.panels.panelBottomIndex = 1;
          this.forceUpdate();
        }
      }

      return;
    }

    if (doubleClick) {
      if (branch.type === 'text') {
        this.state.panels.showRichTextEditor = true;
      } else if (branch.type !== 'root' && branch.type !== 'block') {
        this.state.panels.panelBottomIndex = 1;
      }
    }

    this.setState({
      ...this.state,
      tools: {
        ...this.state.tools,
        selection: {
          element,
          branch,
          refs: this.findSelectionRefs({ branch })
        }
      }
    });
  }

  clearSelection() {
    this.setState({
      ...this.state,
      tools: {
        ...this.state.tools,
        selection: {
          element: null,
          branch: null,
          refs: null
        }
      }
    });

    setTimeout(() => {
      this.forceUpdate();
    }, 10);
  }

  changePage(page) {
    this.clearSelection();

    this.setState({
      page,
      selection: {
        element: null,
        branch: null,
        refs: null
      }
    });

    StateService.rememberEditedElement('schemaPage', page);
  }

  getSelectionKey() {
    const {
      selection
    } = this.state.tools;

    if (selection && selection.branch) {
      return selection.branch.id;
    }

    return null;
  }

  parseSelectionParam(value, { parseValue, parseImage, variable, files }) {
    let parsedValue = value;

    if (value && parseImage) {
      if (files && files.length && typeof window !== 'undefined') {
        parsedValue = window.URL.createObjectURL(files[0]);
      } else {
        parsedValue = '';
      }
    }

    if (value && parseValue) {
      parsedValue = JSON.parse(value);
    }

    if (value === SAFE_UNDEFINED) {
      parsedValue = undefined;
    }

    if (typeof parsedValue === 'string' && variable !== true) {
      parsedValue = encodeURIComponent(parsedValue);
    }

    return parsedValue;
  }

  updateSelectionParam(prop, event, { parseValue, parseImage, variable, propIndex } = {}) {
    if (event.preventDefault) {
      event.preventDefault();
    }

    const { target } = event;
    const { value } = target;
    const currentSelection = this.state.tools.selection.branch;

    if (currentSelection._locked) {
      this.clearSelection();
      return;
    }

    const parsedValue = this.parseSelectionParam(value, { parseValue, parseImage, variable, files: target.files });

    if (typeof propIndex === 'undefined') {
      currentSelection[prop] = parsedValue;
    } else {
      if (!(currentSelection[prop] instanceof Array)) {
        currentSelection[prop] = [];
      }

      currentSelection[prop][propIndex] = parsedValue;
    }

    if (this.state.tools.selection.refs) {
      this.syncSelectionRefs(prop, parsedValue, { propIndex });
    }

    this.saveSchema();
    this.forceUpdate();
  }

  syncSelectionRefs(prop, value, { propIndex } = {}, branch) {
    let refs;

    if (branch) {
      refs = this.findSelectionRefs({ branch });
    } else {
      refs = this.state.tools.selection.refs;
    }
    const excludedProps = [
      'cssPositionOffsetX',
      'cssPositionOffsetY'
    ];

    if (!refs) {
      return;
    }

    if (excludedProps.includes(prop)) {
      const { branch: parent } = this.getSelectionParent();

      if (!parent || !parent._refSrc) {
        return;
      }
    }

    if (prop === 'content' && typeof value === 'object') {
      refs.forEach(element => {
        const duplicatedSelectionBranch = JSON.parse(JSON.stringify(value));
        duplicatedSelectionBranch.id = `${value.type}-${v4()}`;

        element.content = [ ...this.remapSelectionIds(JSON.parse(JSON.stringify(duplicatedSelectionBranch))).content ];
      });

      return;
    }

    refs.forEach(element => {
      if (typeof propIndex === 'undefined') {
        element[prop] = value;
      } else {
        if (!(element[prop] instanceof Array)) {
          element[prop] = [];
        }
  
        element[prop][propIndex] = value;
      }
    });
  }

  updateTempParam(event, { parseValue } = {}) {
    if (event.preventDefault) {
      event.preventDefault();
    }

    const { target } = event;
    const { value } = target;

    this.setState({
      tools: {
        ...this.state.tools,
        tempParam: value && parseValue ? JSON.parse(value) : value
      }
    });
  }

  clearTempParam() {
    this.setState({
      tools: {
        ...this.state.tools,
        tempParam: null
      }
    });
  }

  scrollToSelection(element = null) {
    if (typeof window === 'undefined') {
      return;
    }

    return;

    let target;

    if (!element) {
      const {
        selection
      } = this.state.tools;

      if (!selection || !selection.element) {
        return;
      }

      target = selection.element;
    } else {
      target = element;
    }

    setTimeout(() => {
      if (!target) {
        return;
      }

      const parent = this.pagePreview.current.parentElement;
      const reduceOffset = (total = 0, current, prev = 0) => {
        if (current !== parent) {
          return reduceOffset(
            total + (current.offsetTop - prev), current.parentElement,
            0
          );
        }

        return total;
      };
      const offsetY = reduceOffset(0, target);

      parent.scrollTop = offsetY - parent.offsetTop - parent.offsetHeight * .25;
    }, 200);
  }

  forceSelection(id, branch) {
    if (typeof window === 'undefined') {
      return;
    }

    if (branch._locked) {
      this.clearSelection();
      return;
    }

    setTimeout(() => {
      if (id.match(/^[0-9].*/)) {
        return;
      }

      const element = document.querySelector(`#${id}`);

      if (!element) {
        return;
      }

      const {
        selection
      } = this.state.tools;
  
      if (selection && selection.branch && selection.branch !== branch) {
        this.scrollToSelection(element);
      }

      this.onSelection({
        element,
        branch
      });
    }, 1);
  }

  getSelectionParent() {
    const {
      selection
    } = this.state.tools;

    if (!selection) {
      return;
    }

    const { element } = selection;
    let parentElement = element.parentElement;

    if (!parentElement) {
      return;
    }

    if (selection.branch && nestedBlocks.includes(selection.branch.type)) {
      parentElement = parentElement.parentElement;
      
      if (!parentElement) {
        return;
      }
    }

    let parentBranch = StateService.findElementById(this.state.page, parentElement.id);

    if (!parentBranch) {
      return;
    }

    return {
      element: parentElement,
      branch: parentBranch
    };
  }

  selectSelectionParent() {
    const { element, branch } = this.getSelectionParent();

    if (!element || !branch) {
      return;
    }

    this.onSelection({
      element,
      branch
    });

    this.forceUpdate();
  }

  moveSelection(direction = 0) {
    const {
      selection
    } = this.state.tools;

    if (!selection) {
      return;
    }

    const branchId = selection.branch.id;
    const { element } = selection;

    const { element: parentElement, branch: parentBranch } = this.getSelectionParent();

    if (!parentElement || !parentBranch || !(parentBranch.content instanceof Array)) {
      return;
    }

    const selectionIndexInParent = parentBranch.content.findIndex(element => element.id === branchId);

    if (selectionIndexInParent + direction < 0) {
      message.warn(`Can't move up.`);
      return;
    }

    if (selectionIndexInParent + direction >= parentBranch.content.length) {
      message.warn(`Can't move down.`);
      return;
    }

    parentBranch.content.splice(
      selectionIndexInParent + direction,
      0,
      parentBranch.content.splice(selectionIndexInParent, 1)[0]
    );

    if (parentBranch._refSrc) {
      this.syncSelectionRefs('content', parentBranch, {}, parentBranch);
    }

    this.saveSchema();

    this.forceSelection(
      branchId,
      parentBranch.content[selectionIndexInParent + direction]
    );

    this.forceUpdate();
  }

  onRemoveSelection(requireConfirm = true) {
    const {
      selection
    } = this.state.tools;
    const removeId = selection.branch.id;

    if (typeof window === 'undefined' || !selection.branch) {
      return;
    }

    if (requireConfirm) {
      const confirm = window.confirm(`Remove selected ${groupBlocks.includes(selection.branch.type) ? 'group' : 'element'}?`);

      if (!confirm) {
        return;
      }
    }

    const { element: parentElement, branch: parent } = this.getSelectionParent();

    if (!parentElement) {
      return;
    }

    this.selectSelectionParent();

    parent.content = parent.content.filter(item => item.id !== removeId);

    if (parent._refSrc) {
      this.syncSelectionRefs('content', parent, {}, parent);
    }

    this.saveSchema();
  }

  onDebugStructure() {
    const {
      selection
    } = this.state.tools;

    console.info('debug', selection);
  }

  onClearChildren(requireConfirm = true) {
    const {
      selection
    } = this.state.tools;

    if (typeof window === 'undefined') {
      return;
    }

    if (requireConfirm) {
      const confirm = window.confirm('Remove all group elements?');

      if (!confirm) {
        return;
      }
    }

    selection.branch.content = [];

    if (selection.branch._refSrc) {
      this.syncSelectionRefs('content', selection.branch);
    }
  }

  remapSelectionIds(branch) {
    if (!branch.content || !(branch.content instanceof Array)) {
      return branch;
    }

    const _branch = {...JSON.parse(JSON.stringify(branch))};

    return {
      ..._branch,
      content: _branch.content.map(child => ({
        ...this.remapSelectionIds(JSON.parse(JSON.stringify(child))),
        id: `${child.type}-${v4()}`
      })),
      id: `${_branch.type}-${v4()}`
    };
  }

  onDuplicateSelection() {
    const {
      selection
    } = this.state.tools;

    if (typeof window === 'undefined') {
      return;
    }

    const { element: parentElement, branch: parent } = this.getSelectionParent();

    if (!parentElement || !parent) {
      return;
    }

    const duplicatedSelectionBranch = JSON.parse(JSON.stringify(selection.branch));
    duplicatedSelectionBranch.id = `${selection.branch.type}-${v4()}`;

    const newElement = this.remapSelectionIds(JSON.parse(JSON.stringify(duplicatedSelectionBranch)));

    if (parent._refSrc) {
      newElement._refSrc = newElement.id;
    }

    parent.content = [
      ...parent.content
    ];

    parent.content.splice(
      parent.content.findIndex(item => item.id === selection.branch.id) + 1,
      0,
      newElement
    );

    if (parent._refSrc) {
      this.syncSelectionRefs('content', parent, {}, parent);
    }

    this.forceUpdate();
    this.saveSchema();
    this.forceSelection(newElement.id, newElement);
  }

  createOrUpdatePage({
    url,
    originUrl,
    title,
    tags,
    hidden,
    publishDate,
    duplicateOf,
    compat_align_v1
  }) {
    if (typeof window === 'undefined') {
      return;
    }

    const pages = ['index', ...StateService.getSchemaPages()];
    let mode;
    let validatedUrl = (new RegExp(/^\W*(.*?)\W*?$/gm)).exec(url);

    if (validatedUrl[1]) {
      validatedUrl = validatedUrl[1];
    } else {
      validatedUrl = url;
    }

    if (!pages.includes(validatedUrl) && !pages.includes(originUrl)) {
      mode = 'create';
    } else {
      mode = 'update';
    }

    if (mode === 'update' && validatedUrl === 'index') {
      return message.error('Cannot change the index page.');
    }

    this.clearSelection();

    StateService.updateConfig(`title_${validatedUrl}`, title);
    StateService.updateConfig(`tags_${validatedUrl}`, tags);
    StateService.updateConfig(`compat_align_v1_${validatedUrl}`, compat_align_v1);

    if (publishDate) {
      StateService.updateConfig(`publish_${validatedUrl}`, publishDate);
    } else {
      StateService.updateConfig(`publish_${validatedUrl}`, 0);
    }

    if (hidden) {
      StateService.updateConfig(`publish_${validatedUrl}`, -1);
    }

    if (mode === 'update' && originUrl && originUrl !== validatedUrl) {
      StateService.renamePage(originUrl, validatedUrl);
  
      this.saveSchema();
      this.forceUpdate();
  
      this.setState({
        page: validatedUrl
      });
    }
    
    if (mode === 'create') {
      const schema = duplicateOf ? StateService.getSchema(duplicateOf) : [
        {
          id: 'root',
          type: 'root',
          cssBackgroundType: 'color',
          cssBackgroundColor: '#ffffff',
          content: []
        }
      ];

      StateService.createNewPage(validatedUrl, schema);
  
      this.saveSchema();
      this.forceUpdate();
  
      this.setState({
        page: validatedUrl
      });
    }

    StateService.saveConfig();
  }

  deletePage(page) {
    if (typeof window === 'undefined') {
      return;
    }

    if (page === 'index') {
      return message.error('Cannot remove the index page.');
    }

    StateService.removePage(page);
    if (this.state.page === page) {
      this.state.page = 'index';

      this.clearSelection();  
    }

    this.saveSchema();
    this.forceUpdate();
  }

  getClipboard() {
    if (typeof window === 'undefined') {
      return;
    }

    const clipboard = sessionStorage.__componentClipboard__;

    return clipboard;
  }

  onPasteChildrenToSelection(requireConfirm = true) {
    const {
      selection
    } = this.state.tools;

    if (typeof window === 'undefined') {
      return;
    }

    const clipboard = this.getClipboard();

    if (!selection || !selection.branch) {
      return message.error('Select element to paste into.');
    }

    if (!clipboard) {
      return message.error('Clipboard is empty.');
    }

    if (requireConfirm) {
      let confirm;

      if (!selection.branch.content) {
        confirm = true;
      } else if (selection.branch.content) {
        confirm = window.confirm('Override current selection?');
      }

      if (!confirm) {
        return;
      }
    }

    const newContent = this.remapSelectionIds({ content: JSON.parse(clipboard) }).content;

    selection.branch.content = [].concat(newContent).filter(Boolean);

    this.clearSelection();
    this.forceUpdate();

    this.saveSchema();
  }

  onAppendChildrenToSelection() {
    const {
      selection
    } = this.state.tools;

    if (typeof window === 'undefined') {
      return;
    }

    const clipboard = this.getClipboard();

    if (!selection || !selection.branch) {
      return message.error('Select element to paste into.');
    }

    if (!clipboard) {
      return message.error('Clipboard is empty.');
    }

    const newContent = this.remapSelectionIds({ content: JSON.parse(clipboard) }).content;

    selection.branch.content = [].concat(selection.branch.content).concat(newContent).filter(Boolean);

    this.clearSelection();
    this.forceUpdate();

    this.saveSchema();
  }

  onCopyChildrenFromSelection() {
    const {
      selection
    } = this.state.tools;

    if (typeof window === 'undefined') {
      return;
    }

    if (!selection || !selection.branch || !selection.branch.content) {
      return message.error('Cannot copy empty selection.');
    }

    sessionStorage.__componentClipboard__ = JSON.stringify(selection.branch.content)
      .replace(/\"_refSrc\"\s?\:\s?\".*?\",?/gm, '');

    message.success(`${selection.branch.content.length} element${selection.branch.content.length > 1 ? 's' : ''} copied to clipboard.`);
  }

  onCopyElementsFromSelection() {
    const {
      selection
    } = this.state.tools;

    if (typeof window === 'undefined') {
      return;
    }

    if (!selection || !selection.branch) {
      return message.error('Cannot copy empty selection.');
    }
    
    sessionStorage.__componentClipboard__ = JSON.stringify([selection.branch])

    if (selection.branch._refSrc) {
      const { branch: parent } = this.getSelectionParent();

      if (parent._refSrc) {
        sessionStorage.__componentClipboard__ = sessionStorage.__componentClipboard__.replace(/\"_refSrc\"\s?\:\s?\".*?\",?/gm, '')
      }
    }

    message.success(`1 element copied to clipboard.`);
  }

  onMakeSelectionReusable() {
    const {
      selection
    } = this.state.tools;

    if (!selection || !selection.branch) {
      return;
    }

    selection.branch._refSrc = selection.branch.id;
    selection.refs = this.findSelectionRefs();

    this.forceUpdate();
  }

  onReleaseSelectionReusable({ iterateChildren } = {}) {
    const {
      selection
    } = this.state.tools;

    if (!selection || !selection.branch) {
      return;
    }

    selection.branch._refSrc = undefined;
    selection.refs = undefined;

    if (iterateChildren && selection.branch.content instanceof Array) {
      const unlink = (node) => {
        if (node._refSrc) {
          node._refSrc = undefined;
        }

        if (groupBlocks.includes(node.type) && node.content instanceof Array) {
          node.content.forEach(unlink);
        }
      };

      selection.branch.content.forEach(unlink);
    }

    this.forceUpdate();
  }

  findSelectionRefs(next) {
    let selection;

    if (next) {
      selection = next;
    } else {
      const {
        selection: _selection
      } = this.state.tools;

      if (!_selection || !_selection.branch) {
        return;
      }

      selection = _selection;
    }

    if (!selection.branch._refSrc) {
      return null;
    }

    const allSchema = StateService.getEntireSchema();
    let refs = [];

    const traverseSchema = (node) => {
      if (node !== selection.branch && node._refSrc === selection.branch._refSrc) {
        refs.push(node);
      }

      if (groupBlocks.includes(node.type) && node.content instanceof Array) {
        node.content.forEach(traverseSchema);
      }
    };

    Object.keys(allSchema).forEach(page => {
      allSchema[page].forEach(_root => {
        traverseSchema(_root);
      });
    });

    return refs;
  }

  getImageAsBase64(image) {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
        message.success('Uploading file...');
      };
      reader.onerror = () => {
        message.error('Invalid file, try again.');
      };

      reader.readAsDataURL(image);
    });
  }

  async handleImageUpload(event) {
    const { target } = event;
    const { files } = target;

    if (!files) {
      return;
    }

    const file = files[0];
    const base64 = await this.getImageAsBase64(file);
    let upload;

    try {
      upload = await callApi(
        'project/upload',
        'POST',
        {
          base64: base64,
          filename: file.name
        },
        {
          authorization: `Bearer ${sessionStorage[authTokenKey]}`
        }
      );
    } catch {
      message.error('Upload failed, try again.');
      return;
    }

    message.success('File uploaded.');

    target.value = '';

    return upload.url || '';
  }

  onToggleDebug(option, state = undefined) {
    if (!option) {
      return;
    }

    this.clearSelection();

    let debugState;

    if (typeof state === 'undefined') {
      debugState = this.state.debug;

      debugState[option] = !debugState[option];
    } else {
      debugState = this.state.debug;

      debugState[option] = state;
    }

    this.setState({
      ...this.state,
      debug: debugState
    });
  }

  componentDidMount() {
    EditorService.refreshAuth();

    this.addResizeListener();

    const config = StateService.getConfig();

    if (config && config.initialVariables) {
      Object.keys(config.initialVariables).forEach(key => {
        VariablesService.setVar(key, config.initialVariables[key]);
      });
    }

    this.awaitFonts();
  }

  awaitFonts() {
    if (typeof document !== 'undefined' && document.onreadystatechange) {
      document.onreadystatechange = () => {
        if (document.readyState === 'complete') {
          this.forceUpdate();
        }
      }
    }
  }

  onWindowResize() {
    this.forceUpdate();
  }

  addResizeListener() {
    if (typeof window === 'undefined') {
      return;
    }

    window.removeEventListener('resize', this.onWindowResize.bind(this));
    window.addEventListener('resize', this.onWindowResize.bind(this));
  }

  isSelectionType(type = '') {
    const { tools } = this.state;
    const { selection } = tools;

    if (!selection.branch) {
      return false;
    }

    if (typeof type === 'object') {
      return type.indexOf(selection.branch.type) !== -1;
    }

    return selection.branch.type === type;
  }

  isSelectionResponsive() {
    const { tools } = this.state;
    const { selection } = tools;

    if (!selection.branch) {
      return false;
    }

    return !selection.branch.cssWidth;
  }

  addSelectionGuides(orientation = 'h') {
    const { debug } = this.state;
    const { selection } = this.state.tools;
    const pagePreview = this.pageWrapper.current;

    if (!selection.element || !pagePreview) {
      return;
    }

    const pageRoot = pagePreview.querySelector('#root');

    if (!pageRoot) {
      return null;
    }

    const selectionSize = selection.element.getBoundingClientRect();
    const rootSize = pageRoot.getBoundingClientRect();

    if (orientation === 'h') {
      const offsetY = parseInt(selectionSize.y - rootSize.y, 10);

      debug.guides.push({ y: offsetY }, { y: offsetY + selectionSize.height });
    } else {
      const offsetX = parseInt(selectionSize.x - rootSize.x, 10);

      debug.guides.push({ x: offsetX }, { x: offsetX + selectionSize.width });
    }

    this.forceUpdate();
  }

  globalToLocal(context, { x, y }) {
    if (!context || !context.getBoundingClientRect || !this.pageWrapper || !this.pageWrapper.current) {
      return { x, y };
    }

    const contextSize = context.getBoundingClientRect();
    const editorContext = this.pageWrapper.current;
    const editorRoot = editorContext.querySelector('#root');

    if (!editorRoot) {
      return { x, y };
    }

    const rootSize = editorRoot.getBoundingClientRect();

    return {
      x: x - (contextSize.x - rootSize.x),
      y: y - (contextSize.y - rootSize.y)
    };
  }

  renderCustomFonts() {
    const config = StateService.getConfig();

    return (<>
      {(config.fonts || []).map((font, index) => {
        if (font.url.match(/https?\:\/\/fonts.googleapis.com\//)) {
          return (
            <link
              href={font.url}
              rel="stylesheet"
            />
          );
        } else {
          return (
            <style dangerouslySetInnerHTML={{ __html: `
              @font-face {
                font-family: ${font.name ? font.name : `Project Font ${index + 1}`};
                src: url(${font.url}) format(${({
                  'ttf': '"truetype"',
                  'otf': '"opentype"',
                  'woff': '"woff"',
                  'woff2': '"woff2"'
                })[font.url.split('.').splice(-1, 1)[0]]});
              }
            ` }}></style>
          );
        }
      })}
    </>);
  }

  getDebugPreviewName() {
    const { debug } = this.state;

    return ({
      '375px': 'mobile',
      '768px': 'tablet'
    })[debug.previewSize] || 'desktop';
  }

  render() {
    if (!EditorService.isLoggedIn()) {
      EditorService.refreshAuth(async () => {
        this.forceUpdate();

        await StateService.fetchProject();

        this.forceUpdate();
      });

      return null;
    }

    if (sessionStorage[serviceKey] === 'common') {
      navigate('/common-3d');

      return null;
    }

    const schema = StateService.getSchema(this.state.page);
    const { tools, panels, debug } = this.state;
    const { selection } = tools;
    const {
      showRichTextEditor,
      panelBottomIndex,
      showSubpagesModal
    } = panels;
    const library = StateService.getLibrary();
    const pages = StateService.getSchemaPages();
    const behaviours = StateService.getBehaviours();
    const config = StateService.getConfig();

    const isSelectionType = this.isSelectionType.bind(this);
    const isSelectionResponsive = this.isSelectionResponsive.bind(this);

    const renderCompatAlignV1Mode = typeof config[`compat_align_v1_${this.state.page}`] !== 'undefined' ? config[`compat_align_v1_${this.state.page}`] : config.compat_align_v1;

    return (
      <AntWrapper style={{ minWidth: 1200 }}>
        <WtlGlobalKeyboardListener
          listeners={[
            {
              keys: ['control+c', 'meta+c'],
              callback: (event) => {
                if (selection && selection.branch) {
                  event.preventDefault();
                  this.onCopyElementsFromSelection();
                }
              }
            },
            {
              keys: ['control+v', 'meta+v'],
              callback: (event) => {
                if (selection && selection.branch) {
                  event.preventDefault();
                  this.onAppendChildrenToSelection();
                  message.success('Element pasted.');
                }
              }
            },
            {
              keys: ['control+d', 'meta+d'],
              callback: (event) => {
                if (selection && selection.branch) {
                  event.preventDefault();
                  this.onDuplicateSelection();
                  message.success('Element duplicated.');
                }
              }
            },
            {
              keys: ['control+backspace', 'meta+backspace'],
              callback: (event) => {
                if (selection && selection.branch) {
                  event.preventDefault();
                  this.onRemoveSelection(false);
                }
              }
            }
          ]}
        />
        {
          showSubpagesModal && (
            <WtlSubpagesModal
              onClose={() => {
                this.setState({ ...this.state, panels: { ...this.state.panels, showSubpagesModal: false }});
              }}
              onOpenPage={(page) => {
                this.setState({ ...this.state, panels: { ...this.state.panels, showSubpagesModal: false }});

                setTimeout(() => {
                  this.changePage(page);
                }, 1);
              }}
              onDeletePage={(page) => {
                this.deletePage(page);
              }}
              onCreateOrUpdatePage={(page) => {
                this.createOrUpdatePage(page);
              }}
            />
          )
        }
        {selection && showRichTextEditor && (
          <RichTextEditor
            selection={selection}
            onChange={(value) => {
              this.updateSelectionParam(
                'content',
                { target: { value: `_$rtejson${encodeURIComponent(JSON.stringify(value))}` }},
                { variable: true }
              );

              this.setState({
                ...this.state,
                panels: {
                  ...this.state.panels,
                  showRichTextEditor: false
                }
              });
            }}
            onClose={() => {
              this.setState({
                ...this.state,
                panels: {
                  ...this.state.panels,
                  showRichTextEditor: false
                }
              });
            }}
          />
        )}
        {this.renderCustomFonts()}
        <Spin
          tip="Loading project..."
          style={{ height: '100vh' }}
          spinning={StateService.state.fetchingProject}
        >
        <Layout style={{ height: '100vh', boxSizing: 'border-box' }}>
          <HeaderNav key={1} service={StateService} refresh={() => this.forceUpdate()} />
          <WtlFilesBar
            files={['index', ...StateService.getSchemaPages()].map(page => ({
              name: page,
              onClick: () => {
                this.changePage(page);
              }
            }))}
            selectedFile={this.state.page}
            onSelectionClosed={(newSelection) => {
              this.changePage(newSelection);
            }}
            onOpenModal={() => {
              this.setState({
                ...this.state,
                panels: {
                  ...this.state.panels,
                  showSubpagesModal: true
                }
              });
            }}
          />
            <Layout>
              <WtlPanel layout={WtlPanelVertical}>
                <div style={{ padding: 10, minHeight: 275 }}>
                  {
                    schema && schema.length !== 0 && !isSelectionType(groupBlocks) ? (
                      <WtlPanelNote
                        text="Cannot add element here :("
                      />
                    ) : (
                      <BlockSelector
                        onClick={(block, preset, unwrappedPreset) => this.onToolClick(block, preset, unwrappedPreset)}
                      />
                    )
                  }
                </div>
                <div style={{ borderTop: 'solid 1px #eee' }}>
                  <WtlSchemaTree
                    schema={schema}
                    selection={selection}
                    onNodeClick={(id, branch) => {
                      this.forceSelection(id, branch);
                    }}
                    onNodesUpdated={() => {
                      this.syncSelectionRefs('_groupName', selection.branch._groupName);
                      this.saveSchema();
                      this.forceUpdate();
                    }}
                    onSelectionAffected={() => {
                      if (selection._locked) {
                        this.selectSelectionParent();
                      }
                    }}
                  />
                </div>
              </WtlPanel>
              <Layout style={{ overflow: 'hidden' }}>
                <Content
                  style={{
                    position: 'relative',
                    background: '#ccc',
                    margin: 0,
                    minHeight: 280,
                    overflow: 'scroll'
                  }}
                  onClick={() => {
                    this.clearSelection();
                  }}
                >
                  <PagePreview
                    ref={this.pagePreview}
                    previewMode={this.state.previewMode}
                    style={{ pointerEvents: 'all' }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onContextMenu={(e) => {
                      e.preventDefault();
                    }}
                  >
                    {!showRichTextEditor &&
                      <EditorOverlay>
                        {
                          selection.element &&
                          (
                            <Selection
                              key={`${this.getSelectionKey()}-${this.state.tools.lastClick}`}
                              selection={selection}
                              context={this.getSelectionParent()}
                              pagePreview={this.pagePreview.current}
                              color="#001529"
                              invertFontColor={true}
                              active={true}
                              useFreeTransform={this.state.tools.showFreeTransform}
                              rightClicked={this.state.tools.rightClicked}
                              onSelectionChanged={(changedProps) => {
                                if (changedProps) {
                                  changedProps.forEach(prop => {
                                    this.syncSelectionRefs(prop, this.state.tools.selection.branch[prop]);
                                  });
                                }

                                this.forceUpdate();
                              }}
                              onExitFreeTransform={() => {
                                this.state.tools.showFreeTransform = false;
                                this.forceUpdate();
                              }}
                              showGrid={debug.showGrid}
                              contextMenu={[
                                isSelectionType('text') && ({
                                  name: 'Edit Content',
                                  children: [
                                    {
                                      name: 'Open Text Editor',
                                      onClick: () => {
                                        this.setState({
                                          ...this.state,
                                          panels: {
                                            ...this.state.panels,
                                            showRichTextEditor: true
                                          }
                                        });
                                      }
                                    },
                                    {
                                      name: 'Open Content Panel',
                                      onClick: () => {
                                        this.state.panels.panelBottomIndex = 1;
                                        this.forceUpdate();
                                      }
                                    }
                                  ]
                                }),
                                !isSelectionType(['text', 'block', 'root', 'advanced-background-block']) && ({
                                  name: 'Edit Content',
                                  onClick: () => {
                                    this.state.panels.panelBottomIndex = 1;
                                    this.forceUpdate();
                                    this.scrollToSelection();
                                  }
                                }),
                                {
                                  name: 'Edit Appearance',
                                  onClick: () => {
                                    this.state.panels.panelBottomIndex = 2;
                                    this.forceUpdate();
                                    this.scrollToSelection();
                                  }
                                },
                                {
                                  name: 'Guides',
                                  children: [
                                    {
                                      name: 'Add horizontal guides',
                                      onClick: () => {
                                        this.addSelectionGuides('h');
                                      }
                                    },
                                    {
                                      name: 'Add vertical guides',
                                      onClick: () => {
                                        this.addSelectionGuides('v');
                                      }
                                    }
                                  ]
                                },
                                !isSelectionType('root') && {
                                  name: 'Copy',
                                  children: [
                                    {
                                      name: 'Duplicate',
                                      onClick: () => {
                                        this.onDuplicateSelection();
                                      }
                                    },
                                    {
                                      name: `Copy ${isSelectionType(groupBlocks) ? 'Group' : 'Element'}`,
                                      onClick: () => {
                                        this.onCopyElementsFromSelection();
                                      }
                                    },
                                    isSelectionType(groupBlocks) && {
                                      name: 'Copy Group Elements',
                                      onClick: () => {
                                        this.onCopyChildrenFromSelection();
                                      }
                                    },
                                    !(selection.branch && selection.branch._refSrc) && {
                                      name: <>Convert to <WtlContextMenuKeyword>Reusable Block</WtlContextMenuKeyword></>,
                                      onClick: () => {
                                        this.onMakeSelectionReusable();
                                      }
                                    },
                                    !(selection.branch && !selection.branch._refSrc) && {
                                      name: <>Release <WtlContextMenuKeyword>Reusable Block</WtlContextMenuKeyword></>,
                                      onClick: () => {
                                        this.onReleaseSelectionReusable();
                                      }
                                    },
                                    !(selection.branch && !selection.branch._refSrc) && isSelectionType(groupBlocks) && {
                                      name: <>Release all <WtlContextMenuKeyword>Reusable Blocks</WtlContextMenuKeyword> in group</>,
                                      onClick: () => {
                                        this.onReleaseSelectionReusable({ iterateChildren: true });
                                      }
                                    }
                                  ]
                                },
                                isSelectionType(groupBlocks) && this.getClipboard() && {
                                  name: 'Paste',
                                  children: [
                                    {
                                      name: 'Add to Group',
                                      onClick: () => {
                                        this.onAppendChildrenToSelection();
                                      }
                                    },
                                    !isSelectionType('root') && {
                                      name: 'Replace',
                                      children: [
                                        {
                                          name: <>Confirm <b>Replace</b></>,
                                          type: 'warn',
                                          onClick: () => {
                                            this.onPasteChildrenToSelection(false);
                                          }
                                        }
                                      ]
                                    }
                                  ]
                                },
                                isSelectionType(groupBlocks) && !isSelectionType(['root']) && {
                                  name: 'Delete',
                                  children: [
                                    {
                                      name: 'Delete Group',
                                      children: [
                                        {
                                          name: <>Confirm <b>Remove Group</b></>,
                                          type: 'warn',
                                          onClick: () => {
                                            this.onRemoveSelection(false);
                                          }
                                        }
                                      ]
                                    },
                                    {
                                      name: 'Delete Group Elements',
                                      children: [
                                        {
                                          name: <>Confirm <b>Delete Group Elements</b></>,
                                          type: 'warn',
                                          onClick: () => {
                                            this.onClearChildren(false);
                                          }
                                        }
                                      ]
                                    }
                                  ]
                                },
                                !isSelectionType(groupBlocks) && {
                                  name: 'Delete Element',
                                  children: [
                                    {
                                      name: <>Confirm <b>Delete Element</b></>,
                                      type: 'warn',
                                      onClick: () => {
                                        this.onRemoveSelection(false);
                                      }
                                    }
                                  ]
                                },
                                !isSelectionType('root') && {
                                  name: 'Select Parent Group',
                                  onClick: () => {
                                    this.selectSelectionParent();
                                  }
                                },
                                StateService.getFeatureToggle('feature_debugtools') && {
                                  name: <><i className="fa fa-fw fa-bug"/> Debug</>,
                                  onClick: () => {
                                    this.onDebugStructure();
                                  }
                                }
                              ]}
                              contextActions={[
                                isSelectionResponsive() && {
                                  icon: 'arrow-up',
                                  onClick: () => {
                                    this.moveSelection(-1);
                                  }
                                },
                                isSelectionResponsive() && {
                                  icon: 'arrow-down',
                                  onClick: () => {
                                    this.moveSelection(1);
                                  }
                                }
                              ]}
                              debugPreview={this.getDebugPreviewName()}
                            ></Selection>
                          )
                        }
                        {debug.showRulers && (
                          <WtlRulers
                            context={this.pagePreview && this.pagePreview.current}
                            pagePreview={this.pageWrapper && this.pageWrapper.current}
                            guides={debug.guides}
                            onAddGuide={(guide) => {
                              debug.guides.push(guide);
                              this.forceUpdate();
                            }}
                            onRemoveGuide={({ x: tx, y: ty }) => {
                              debug.guides = debug.guides.filter(({ x, y }) => {
                                return !(x === tx && y === ty);
                              });
                              this.forceUpdate();
                            }}
                          />
                        )}
                      </EditorOverlay>
                    }
                    {(!schema || !schema.length) && (
                      <PageWrapperEmpty>
                        <div>
                          <b>
                            This page is empty
                          </b>
                        </div>
                        <div>
                          <i className="far fa-plus-circle" /> Add some elements
                        </div>
                      </PageWrapperEmpty>
                    )}
                    <PageWrapper
                      ref={this.pageWrapper}
                      previewMode={this.state.previewMode}
                      previewSize={debug.previewSize}
                      showFrames={debug.showFrames}
                      style={{ pointerEvents: 'all' }}
                      empty={!schema || !schema.length}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      {schema && library && behaviours && getBranchHTML(
                        schema,
                        0,
                        {
                          hover: (event, payload) => {
                            event.preventDefault();
                            event.stopPropagation();
                            this.onHover(true, payload);
                          },
                          leave: (event, payload) => {
                            event.preventDefault();
                            event.stopPropagation();

                            this.onHover(false, payload);
                          },
                          select: (event, payload, { rightClick, doubleClick } = {}) => {
                            event.preventDefault();
                            event.stopPropagation();

                            this.state.tools.rightClicked = rightClick || false;
                            this.state.tools.lastClick = Date.now();

                            if (this.state.tools.dragState === SelectionDragState.finished) {
                              this.state.tools.dragState = SelectionDragState.inactive;
                            } else if (this.state.tools.dragState !== SelectionDragState.drag) {
                              this.onSelection(payload, { doubleClick });
                            }

                            this.forceUpdate();
                          },
                          mouseUp: (event, payload) => {
                            const { dragState } = this.state.tools;

                            if ([SelectionDragState.drag, SelectionDragState.click].includes(dragState)) {
                              event.stopPropagation();
                              event.preventDefault();
                            }

                            if (dragState === SelectionDragState.drag) {
                              this.state.tools.dragState = SelectionDragState.finished;
                              this.state.tools.dragStart = null;

                              this.forceUpdate();
                            } else if (dragState === SelectionDragState.click) {
                              this.state.tools.dragState = SelectionDragState.inactive;
                              this.state.tools.dragStart = null;

                              this.forceUpdate();
                            }
                          },
                          mouseDown: (event, payload) => {
                            if (!selection || !selection.branch || event.button !== 0) {
                              return;
                            }

                            let position = {
                              x: 0,
                              y: 0
                            };
                            const blockType = selection.branch.type;

                            if (blockType === 'advanced-animation-block') {
                              const currentFrame = selection.branch.stopFrame || 0;

                              position = {
                                x: parseUndefString(decodeURIComponent(selection.branch.cssPositionOffsetX)),
                                y: parseUndefString(decodeURIComponent(selection.branch.cssPositionOffsetY))
                              };

                              if (selection.branch.keyframes instanceof Array && selection.branch.keyframes[currentFrame]) {
                                const framePosition = {
                                  x: parseUndefString(decodeURIComponent(selection.branch.keyframes[currentFrame].cssPositionOffsetX)),
                                  y: parseUndefString(decodeURIComponent(selection.branch.keyframes[currentFrame].cssPositionOffsetY))
                                };

                                framePosition.x === undefined && delete framePosition.x;
                                framePosition.y === undefined && delete framePosition.y;

                                position = {
                                  ...position,
                                  ...framePosition
                                };
                              }
                            } else {
                              position = {
                                x: parseUndefString(decodeURIComponent(selection.branch.cssPositionOffsetX)),
                                y: parseUndefString(decodeURIComponent(selection.branch.cssPositionOffsetY))
                              };
                            }

                            this.state.tools.dragState = SelectionDragState.click;
                            this.state.tools.dragStart = {
                              x: event.screenX,
                              y: event.screenY,
                              originX: position.x,
                              originY: position.y
                            };

                            this.forceUpdate();
                          },
                          mouseMove: (event, payload) => {
                            if (!selection || !selection.branch || !selection.element) {
                              return;
                            }

                            if (![SelectionDragState.inactive, SelectionDragState.finished].includes(this.state.tools.dragState)) {
                              const endX = event.screenX;
                              const endY = event.screenY;
                              const { x, y, originX: oX, originY: oY } = this.state.tools.dragStart;
                              const dx = endX - x;
                              const dy = endY - y;

                              if (Math.abs(dx) < 2 && Math.abs(dy) < 2) {
                                return;
                              }

                              this.state.tools.dragState = SelectionDragState.drag;

                              const parent = this.getSelectionParent();

                              if (!parent || !parent.element) {
                                this.state.tools.dragState = SelectionDragState.finished;
                                this.state.tools.dragStart = null;

                                this.forceUpdate();

                                return;
                              }

                              const contextSize = parent.element.getBoundingClientRect();
                              const guides = [];

                              if (debug.guides) {
                                debug.guides.forEach(({ x, y }) => {
                                  if (typeof x !== 'undefined') {
                                    const { x: _x } = this.globalToLocal(parent.element, { x, y: 0 });
                                    guides.push(_x);
                                  } else {
                                    const { y: _y } = this.globalToLocal(parent.element, { y, x: 0 });
                                    guides.push(_y);
                                  }
                                });
                              }

                              const offsetX = addCssValues(oX, `${dx}px`, contextSize.width, debug.snapToGuides, [ contextSize.width, contextSize.width * .5, ...guides ]);
                              const offsetY = addCssValues(oY, `${dy}px`, contextSize.height, debug.snapToGuides, [ contextSize.height, contextSize.height * .5, ...guides ]);

                              const blockType = selection.branch.type;

                              if (blockType === 'advanced-animation-block') {
                                const currentFrame = selection.branch.stopFrame || 0;

                                if (!(selection.branch.keyframes instanceof Array)) {
                                  selection.branch.keyframes = [];
                                }

                                selection.branch.keyframes[currentFrame] = {
                                  ...(selection.branch.keyframes[currentFrame] || {}),
                                  cssPositionOffsetX: encodeURIComponent(offsetX),
                                  cssPositionOffsetY: encodeURIComponent(offsetY)
                                };

                                if (currentFrame !== 0) {
                                  this.syncSelectionRefs('keyframes', selection.branch.keyframes[currentFrame], { propIndex: currentFrame });
                                  this.forceUpdate();
                                  return;
                                }
                              }

                              selection.branch.cssPositionOffsetX = encodeURIComponent(offsetX);
                              selection.branch.cssPositionOffsetY = encodeURIComponent(offsetY);

                              this.syncSelectionRefs('cssPositionOffsetX', selection.branch.cssPositionOffsetX);
                              this.syncSelectionRefs('cssPositionOffsetY', selection.branch.cssPositionOffsetY);
                              this.forceUpdate();
                            }
                          },
                          simulateBehaviours: debug.simulateBehaviours,
                          renderHdImages: debug.showImages,
                          debugPreview: this.getDebugPreviewName(),
                          schemaRef: schema,
                          ...config,
                          compat_align_v1: renderCompatAlignV1Mode
                        },
                        library,
                        behaviours
                      )}
                    </PageWrapper>
                  </PagePreview>
                </Content>
                <WtlPanel
                  resizable
                  layout={WtlPanelHorizontal}
                  defaultCollapsed
                  openSubpanel={panelBottomIndex}
                  onCollapse={(collapsed) => {
                    if (collapsed) {
                      this.state.panels.panelBottomIndex = null;
                      this.forceUpdate();
                    }
                  }}
                  subpanels={[
                    {
                      id: 'content',
                      height: isSelectionType('advanced-animation-block') ? 300 : undefined,
                      panel: (
                        <Row gutter={24} key={this.getSelectionKey()}>
                          {
                            !selection.branch ? (
                              <Col span={24} style={{ textAlign: 'center', top: 70, color: '#ccc' }}>
                                <i className="far fa-hand-pointer"></i> Select element to edit content
                              </Col>
                            ) : (
                              isSelectionType(['block', 'advanced-background-block']) ? (
                                <Col span={24} style={{ textAlign: 'center', top: 70, color: '#ccc' }}>
                                  <i className="far fa-clipboard-check"></i> Add content by adding elements!
                                </Col>
                              ) : (
                                <Col span={24} style={{ overflow: 'visible' }}>
                                  <Row gutter={4}>
                                    {isSelectionType('advanced-vimeo') && renderVimeoProps.bind(this)()}
                                    {isSelectionType('advanced-carousel') && renderCarouselProps.bind(this)()}
                                    {isSelectionType('advanced-trust-pilot') && renderTrustPilotProps.bind(this)()}
                                    {isSelectionType('advanced-contact-form') && renderContactFormProps.bind(this)()}
                                    {isSelectionType('advanced-page-preview') && renderPagePreviewProps.bind(this)()}
                                    {isSelectionType('advanced-timer') && renderTimerProps.bind(this)()}
                                    {isSelectionType('advanced-youtube') && renderYouTubeProps.bind(this)()}
                                    {isSelectionType('advanced-animation-block') && renderAnimationBlockProps.bind(this)()}
                                    {isSelectionType('advanced-scroll-block') && renderScrollBlockProps.bind(this)()}
                                    {isSelectionType('advanced-3d-model') && render3dModelProps.bind(this)()}
                                    {isSelectionType('advanced-stripe') && renderStripeProps.bind(this)()}
                                    {isSelectionType('advanced-input') && renderAdvancedInputProps.bind(this)()}
                                    {isSelectionType('root') && renderRootProps.bind(this)()}
                                    {isSelectionType('link') && renderLinkProps.bind(this)()}
                                    {isSelectionType('video') && renderVideoProps.bind(this)()}
                                    {isSelectionType('image') && renderImageProps.bind(this)()}
                                    {isSelectionType(['text', 'icon']) && renderTextProps.bind(this)()}
                                    {isSelectionType('input') && renderInputProps.bind(this)()}
                                    {isSelectionType('icon') && renderIconProps.bind(this)()}
                                    {isSelectionType('shape') && renderShapeProps.bind(this)()}
                                  </Row>
                                </Col>
                              )
                            )
                          }
                        </Row>
                      )
                    },
                    {
                      id: 'appearance',
                      height: 400,
                      panel: (
                        <Row gutter={24} key={this.getSelectionKey()}>
                          {
                            !selection.branch ? (
                              <Col span={24} style={{ textAlign: 'center', top: 70, color: '#ccc' }}>
                                <i className="far fa-palette"></i> Select element to edit styles
                              </Col>
                            ) : (
                              <BlockStyleEditor
                                selection={selection}
                                updateSelectionParam={(...args) => {
                                  if (args[0].match(/^cssContentAlign.*/)) {
                                    const id = selection.branch.id;
                                    const branch = selection.branch;

                                    setTimeout(() => {
                                      this.forceSelection(id, branch);
                                    }, 150);
                                  }

                                  this.updateSelectionParam(...args);
                                }}
                                debugPreview={this.getDebugPreviewName()}
                              />
                            )
                          }
                        </Row>
                      )
                    },
                    StateService.getFeatureToggle('feature_advanced_behavioureditor') && {
                      id: 'interactions',
                      panel: (
                        <Row gutter={24} key={this.getSelectionKey()}>
                          {
                            !selection.branch ? (
                              <Col span={24} style={{ textAlign: 'center', top: 70, color: '#ccc' }}>
                                <i className="far fa-keyboard"></i> Select element to edit interactions
                              </Col>
                            ) : (
                              <>
                                <Col span={8} style={{ overflow: 'visible' }}>
                                  <Row gutter={4} style={{ marginBottom: 10 }}>
                                    <SimpleInput span={24}>
                                      <span>
                                        interaction
                                      </span>
                                      <select
                                        value={selection.branch.behaviour || SAFE_UNDEFINED}
                                        onChange={(e) => {
                                          this.updateSelectionParam('behaviour', e);
                                          this.forceUpdate();
                                        }}
                                      >
                                        <option value={SAFE_UNDEFINED}>(none)</option>
                                        {
                                          behaviours && behaviours.map((item) => (
                                            <option value={item.id}>
                                              {item.name}  
                                            </option>  
                                          ))
                                        }
                                      </select>
                                    </SimpleInput>
                                    <VariableInput
                                      span={12}
                                      name="parameter (1)"
                                      value={selection.branch.behaviourParam0 || selection.branch.behaviourParam}
                                      onChange={(value, params) => {
                                        this.updateSelectionParam('behaviourParam0', { target: { value }}, params);
                                      }}
                                    />
                                    <VariableInput
                                      span={12}
                                      name="parameter (2)"
                                      value={selection.branch.behaviourParam1}
                                      onChange={(value, params) => {
                                        this.updateSelectionParam('behaviourParam1', { target: { value }}, params);
                                      }}
                                    />
                                    <VariableInput
                                      span={12}
                                      name="parameter (3)"
                                      value={selection.branch.behaviourParam2}
                                      onChange={(value, params) => {
                                        this.updateSelectionParam('behaviourParam2', { target: { value }}, params);
                                      }}
                                    />
                                    <VariableInput
                                      span={12}
                                      name="parameter (4)"
                                      value={selection.branch.behaviourParam3}
                                      onChange={(value, params) => {
                                        this.updateSelectionParam('behaviourParam3', { target: { value }}, params);
                                      }}
                                    />
                                    <VariableInput
                                      span={12}
                                      name="parameter (5)"
                                      value={selection.branch.behaviourParam4}
                                      onChange={(value, params) => {
                                        this.updateSelectionParam('behaviourParam4', { target: { value }}, params);
                                      }}
                                    />
                                    {
                                      selection.branch.type === 'input' && (
                                        <Col span={24}>
                                          <SimpleInput>
                                            <span>
                                              input interaction
                                            </span>
                                            <select
                                              value={selection.branch.inputBehaviour || SAFE_UNDEFINED}
                                              onChange={(e) => {
                                                this.updateSelectionParam('inputBehaviour', e);
                                                this.forceUpdate();
                                              }}
                                            >
                                              <option value={SAFE_UNDEFINED}>(none)</option>
                                              {
                                                behaviours && behaviours.map((item) => (
                                                  <option value={item.id}>
                                                    {item.name}  
                                                  </option>  
                                                ))
                                              }
                                            </select>
                                          </SimpleInput>
                                        </Col>
                                      )
                                    }
                                    <Col span={24}>
                                      <Link to="/events">
                                        <SimpleButton>
                                          Open Editor
                                        </SimpleButton>
                                      </Link>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col span={8}>
                                  <Row gutter={4}>
                                    <VariableInput
                                      value={selection.branch.id}
                                      nonVariable
                                      inputProps={{
                                        readonly: true
                                      }}
                                      name="element id"
                                      onChange={() => {}}
                                    />
                                  </Row>
                                </Col>
                              </>
                            )
                          }
                        </Row>
                      )
                    }
                  ]}
                ></WtlPanel>
                <Content
                  style={{
                    position: 'relative',
                    background: '#fff',
                    borderTop: 'solid 1px #333',
                    minHeight: 24,
                    maxHeight: 24,
                    textAlign: 'right',
                    padding: '0 5px'
                  }}
                >
                  <Row>
                    <Col span={24}>
                      {panels.showSavingSchema && <DebugHelperOption inactive={true}>
                        <i className="far fa-sync fa-spin"/> saving...
                      </DebugHelperOption>}
                      {renderCompatAlignV1Mode && (
                        <DebugHelperOption inactive={true}>
                          <Tooltip
                            placement="top"
                            title={
                              <>
                                Legacy WTL compatibility mode is enabled for this subpage. Some new features may not work as expected.
                              </>
                            }
                          >
                            <i className="far fa-alicorn"/> compat
                          </Tooltip>
                        </DebugHelperOption>
                      )}
                      <DebugHelperOption
                        width={75}
                        onClick={() => {
                        this.onToggleDebug('previewSize', ({
                          '375px': 'auto',
                          '768px': '375px',
                          'auto': '768px'
                        })[debug.previewSize] || 'auto');
                      }}>
                        <Tooltip
                          placement="top"
                          title={
                            <>
                              Change preview mode
                            </>
                          }
                        >
                          <i className={`far fa-${({
                            '375px': 'mobile',
                            '768px': 'tablet',
                            'auto': 'phone-laptop'
                          })[debug.previewSize] || 'desktop'}`}/>{' '}
                          {({
                            '375px': 'mobile',
                            '768px': 'tablet',
                            'auto': 'desktop'
                          })[debug.previewSize] || 'desktop'}
                        </Tooltip>
                      </DebugHelperOption>
                      <DebugHelperOption onClick={() => this.onToggleDebug('showFrames')}>
                        <Tooltip
                          placement="topRight"
                          title={
                            <>
                              Show editor frames
                            </>
                          }
                        >
                          <i className={`far fa-toggle-${debug.showFrames ? 'on' : 'off'}`}/> frames
                        </Tooltip>
                      </DebugHelperOption>
                      <DebugHelperOption onClick={() => this.onToggleDebug('showGrid')}>
                        <Tooltip
                          placement="topRight"
                          title={
                            <>
                              Show alignment grid
                            </>
                          }
                        >
                          <i className={`far fa-toggle-${debug.showGrid ? 'on' : 'off'}`}/> grid
                        </Tooltip>
                      </DebugHelperOption>
                      <DebugHelperOption onClick={() => this.onToggleDebug('showRulers')}>
                        <Tooltip
                          placement="topRight"
                          title={
                            <>
                              Show rulers
                            </>
                          }
                        >
                          <i className={`far fa-toggle-${debug.showRulers ? 'on' : 'off'}`}/> rulers
                        </Tooltip>
                      </DebugHelperOption>
                      <DebugHelperOption onClick={() => this.onToggleDebug('snapToGuides')}>
                        <Tooltip
                          placement="topRight"
                          title={
                            <>
                              Snap to guides
                            </>
                          }
                        >
                          <i className={`far fa-toggle-${debug.snapToGuides ? 'on' : 'off'}`}/> snap
                        </Tooltip>
                      </DebugHelperOption>
                    </Col>
                  </Row>
                </Content>
              </Layout>
            </Layout>
          </Layout>
        </Spin>
      </AntWrapper>
    );
  }
}
