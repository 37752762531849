import Rete from 'rete';
import { EventSourceSocket } from '../sockets';
import { BaseLayout } from '../layouts/base.layout';

export class RefreshPageNode extends Rete.Component {
  constructor() {
    super('Update View');
    this.data.component = BaseLayout;
  }

  builder(node) {
    const inp1 = new Rete.Input('event-source-in', '', EventSourceSocket, false);
    const out1 = new Rete.Output('then', '', EventSourceSocket, false);

    return node.addInput(inp1).addOutput(out1);
  }

  worker(node, inputs, outputs) {

  }
}