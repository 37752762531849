import React from 'react';
import styled from 'styled-components';
import { Modal, Row, Col, Tooltip } from 'antd';
import { Editor as SlateEditor } from 'slate-react';
import { Value as SlateValue } from 'slate';
import { SimpleButton, SimpleInput } from '../styles/styled';
import { extractVariable } from '../../wtl-schema/helpers/extract-variable';
import { SAFE_UNDEFINED } from '../services/editor.service';
import stateService from '../services/state.service';

export const defaultFonts = [
  `Arial, Helvetica, sans-serif`,
  `"Arial Black", Gadget, sans-serif`,
  `"Comic Sans MS", cursive, sans-serif`,
  `Impact, Charcoal, sans-serif`,
  `"Lucida Sans Unicode", "Lucida Grande", sans-serif`,
  `Tahoma, Geneva, sans-serif`,
  `"Trebuchet MS", Helvetica, sans-serif`,
  `Verdana, Geneva, sans-serif`,
  `Georgia, serif`,
  `"Palatino Linotype", "Book Antiqua", Palatino, serif`,
  `"Times New Roman", Times, serif`,
  `"Courier New", Courier, monospace`,
  `"Lucida Console", Monaco, monospace`
];

const EditorToolNote = styled.span`
  opacity: .5;
`;

const EditorWrapper = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  border: solid 1px #ccc;
  padding: 4px;
  background-color: #fff;
  transition: border-color .1s ease;
  box-shadow: inset 2px 2px 4px #eee;
  line-height: 1.5;

  *::selection {
    background: #c7f3ff;
    color: #333;
  }

  & > div {
    height: 100%;
  }
`;

const ToolsWrapper = styled(Row)`
  position: absolute;
  bottom: 10px;
  left: 20px;
  background-color: #263346;
  border-radius: 2px;
  padding: 2px 0px 2px 4px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, .2);
  z-index: 2;
`;

const ToolsCol = styled(Col)`
  font-size: 10px;

  & > * {
    display: inline-block;
    width: 30px;
    height: 30px;
    outline: none;
  }
`;

const RTESelect = styled.select`
  appearance: none;
  -webkit-appearance: none;
  border: solid 1px #ccc;
  border-radius: 0;
  padding: 8px;
  margin: 2.5px 0;
  margin-right: 5px;
  line-height: 1em;
  box-sizing: border-box;
  width: 150px;

  ${p => p.toggled && `
    border-color: #1890ff;
    background-color: #e8f4ff;
  `}
`;

export class RichTextEditor extends React.Component {
  constructor(props) {
    super(props);

    const { content } = props.selection ? props.selection.branch : {};
    const extractedContent = content ? extractVariable(content, { richTextAsJson: true }) : '';

    this.editor = React.createRef();

    this.state = {
      value: SlateValue.fromJSON(typeof extractedContent === 'string' ? {
        object: 'value',
        document: {
          object: 'document',
          nodes: [
            {
              object: 'block',
              type: 'paragraph',
              nodes: [
                {
                  object: 'text',
                  text: extractedContent
                }
              ]
            }
          ]
        }
      } : extractedContent)
    };
  }

  hasMark(mark) {
    const { value } = this.editor;

    if (!this.editor || !value) {
      return;
    }

    return value.activeMarks.some(selection => selection.type === mark);
  }

  isLink() {
    const { value } = this.state;

    return value.inlines.some(inline => inline.type === 'a');
  }

  hasData(type) {
    const { value } = this.state;

    return value.inlines.some(inline => inline.data && inline.data.get(type));
  }

  getData(type) {
    const { value } = this.state;

    const inline = value.inlines.find(inline => inline.data && inline.data.get(type));

    if (!inline || !inline.data) {
      return SAFE_UNDEFINED;
    }

    return inline.data.get(type);
  }

  onChangeFont(font) {
    const { value } = this.editor;

    if (!value || !value.selection.isExpanded) {
      return;
    }

    const hasFontFace = this.hasData('fontFace');

    if (font === SAFE_UNDEFINED && hasFontFace) {
      this.editor.command(() => {
        this.editor.unwrapInline('span');
      });
    } else {
      this.editor.command(() => {
        this.editor.wrapInline({
          type: 'span',
          data: { fontFace: font }
        });

        this.editor.moveToEnd();
      });
    }
  }

  onMakeLink() {
    const { value } = this.editor;

    if (!value) {
      return;
    }

    const isLink = this.isLink();

    if (isLink) {
      this.editor.command(() => {
        this.editor.unwrapInline('a');
      });
    } else if (value.selection.isExpanded) {
      const href = window.prompt('Linked website:');

      if (!href) {
        return;
      }

      this.editor.command(() => {
        this.editor.wrapInline({
          type: 'a',
          data: { href, target: '_blank' }
        });

        this.editor.moveToEnd();
      });
    } else {
      const href = window.prompt('Linked website:');
      const text = window.prompt('Link text:');

      if (!href || !text) {
        return;
      }

      this.editor.insertText(text)
        .moveFocusBackward(text.length)
        .command(() => {
          this.editor.wrapInline({
            type: 'a',
            data: { href, target: '_blank' }
          });
  
          this.editor.moveToEnd();
        });
    }
  }

  renderTool(icon, hint, mark, dataKey, options) {
    const isActive = this.hasMark(mark);
    const isLink = this.isLink();
    let button;

    if (options) {
      const hasData = this.hasData(dataKey);

      button = (
        <RTESelect
          value={this.getData(dataKey)}
          onChange={(e) => {
            e.preventDefault();

            const value = e.target.value;

            if (dataKey === 'fontFace') {
              this.onChangeFont(value);
            }
          }}
          toggled={hasData}
        >
          <option value={SAFE_UNDEFINED}>(default)</option>
          {options}
        </RTESelect>
      );
    } else {
      button = (
        <SimpleButton
          onClick={(e) => {
            e.preventDefault();
  
            if (mark === 'a') {
              this.onMakeLink();
            } else {
              this.editor.toggleMark(mark);
            }
          }}
          toggled={mark === 'a' ? isLink : isActive}
        >
          <i className={`far fa-${icon}`}></i>
        </SimpleButton>
      );
  
    }

    if (hint) {
      return (
        <Tooltip title={hint} placement="bottom">
          {button}
        </Tooltip>
      )
    }
    
    return button;
  }

  renderMark(props, editor, next) {
    const { children, mark, attributes } = props;

    switch (mark.type) {
      case 'b':
        return <b {...attributes}>{children}</b>;
      case 'i':
        return <i {...attributes}>{children}</i>;
      case 'h1':
        return <h1 {...attributes}>{children}</h1>;
      case 'h2':
        return <h2 {...attributes}>{children}</h2>;
      case 'h3':
        return <h3 {...attributes}>{children}</h3>;
      case 'h4':
        return <h4 {...attributes}>{children}</h4>;
      default:
        return next();
    }
  }

  renderInline(props, editor, next) {
    const { attributes, children, node } = props;

    switch (node.type) {
      case 'a':
        return (
          <Tooltip
            placement="bottom"
            title={<>
              <EditorToolNote>
                link
              </EditorToolNote>
              {` ${node.data.get('href') || ''}`}
            </>}
          >
            <a {...attributes} href="#">{children}</a>
          </Tooltip>
        );
      case 'span':
        return (
          <span
            {...attributes}
            style={{
              fontFamily: `${node.data.get('fontFace')}` || ''
            }}
          >
            {children}
          </span>
        )
      default:
        return next();
    }
  }

  render() {
    const config = stateService.getConfig();
    const { selection, onChange, onClose } = this.props;

    if (!selection || !selection.branch) {
      return null;
    }

    const { content } = selection.branch;
    const extractedContent = extractVariable(content, { richTextAsJson: true });
    const projectFonts = config.fonts || [];

    return (
      <Modal
        title={
          <>
            <i className="far fa-text-size"></i> Edit Text
          </>
        }
        onOk={() => {
          onChange(this.state.value.toJSON());
        }}
        onCancel={() => {
          onClose();
        }}
        visible={true}
        closable={true}
        width={768}
        bodyStyle={{
          position: 'relative',
          height: 320
        }}
        okText="Save"
      >
        <ToolsWrapper gutter={0}>
          <ToolsCol span={24}>
            {this.renderTool('bold', 'Bold', 'b')}
            {this.renderTool('italic', 'Italic', 'i')}
            {this.renderTool('link', 'Link', 'a')}
            {this.renderTool('h1', <>Title <EditorToolNote>H1</EditorToolNote></>, 'h1')}
            {this.renderTool('h2', <>Subtitle <EditorToolNote>H2</EditorToolNote></>, 'h2')}
            {this.renderTool('h3', <>Section <EditorToolNote>H3</EditorToolNote></>, 'h3')}
            {this.renderTool('h4', <>Subsection <EditorToolNote>H4</EditorToolNote></>, 'h4')}
            {this.renderTool('font-case', undefined, 'h4', 'fontFace',
              <>
                {
                  projectFonts && (
                    <option disabled>
                      --- Project Fonts --- 
                    </option>
                  )
                }
                {
                  projectFonts.map(font => (
                    <option
                      value={font.name}
                      style={{
                        fontFamily: `"${font.name}"`
                      }}
                    >
                      {font.name.match(/([^(,\")]+)/)[0]}
                    </option>
                  ))
                }
                {
                  projectFonts && (
                    <option disabled>
                      --- Web Fonts --- 
                    </option>
                  )
                }
                {defaultFonts.map(font => (
                  <option
                    value={font}
                    style={{
                      fontFamily: font
                    }}
                  >
                    {font.match(/([^(,\")]+)/)[0]}
                  </option>
                ))}
              </>
            )}
          </ToolsCol>
        </ToolsWrapper>
        <Row gutter={4} style={{ height: '100%' }}>
          <Col span={24} style={{ height: '100%' }}>
            <SlateEditor
              spellCheck={false}
              ref={editor => this.editor = editor}
              placeholder="Click here to add text..."
              value={this.state.value}
              onChange={({ value }) => this.setState({ value })}
              renderEditor={(props, editor, next) => (
                <EditorWrapper>
                  {next()}
                </EditorWrapper>
              )}
              renderMark={this.renderMark}
              renderInline={this.renderInline}
            />
          </Col>
        </Row>
      </Modal>
    );
  }
}