export const materialPresets = {
  'glass': {
    refractive: true,
    roughness: .1,
    metalness: .9,
    opacity: .75,
    color: 0x333333,
    clearcoat: .9,
    clearcoatRoughness: .25
  },
  'metal': {
    roughness: 0.1,
    metalness: 0.85,
    textureUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/b1dfadbe-6c0a-455d-b9a3-a84d6e07dd02.png'
  },
  'tile-basic': {
    textureUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/bb1fb00f-6cfc-4783-9ef6-cf926eb6a797.png',
    pbrUrl: null,
    clearcoatUrl: null,
    normalsUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/fb6d255c-5e11-4106-87f8-13e1c2774fb7.png',
    displacementUrl: null,
    displacementScale: null,
    refractive: false,
    reflective: false,
    translucent: false,
    lightSrc: null,
    lightIntensity: 1.0,
    lightUrl: null,
  },
  'tile-wooden-floor': {
    textureUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/cf9721c5-5e29-4979-bf1e-118c604547dd.png',
    pbrUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/8984591d-fdb0-4782-85da-36684c5041a8.jpg',
    clearcoatUrl: null,
    normalsUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/819de472-6348-4446-9468-c5446eb7515d.png',
    displacementUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/677bc03a-219c-4af6-9056-7347a68ba224.png',
    displacementScale: 0.3,
    refractive: false,
    reflective: false,
    translucent: false,
    lightSrc: null,
    lightIntensity: 1.0,
    lightUrl: null,
  },
  'wall-wallpaper-basic': {
    textureUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/b1dfadbe-6c0a-455d-b9a3-a84d6e07dd02.png',
    pbrUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/e4244c51-027d-4676-8a2e-cbd4cad02798.jpg',
    clearcoatUrl: null,
    normalsUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/8741b965-f524-427c-8880-97a7646ee28e.png',
    displacementUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/1a4f9cad-c9f3-47bb-ace2-903df32ecea7.png',
    displacementScale: 0.3,
    refractive: false,
    reflective: false,
    translucent: false,
    lightSrc: null,
    lightIntensity: 1.0,
    lightUrl: null,
  },
  'furniture-basic-1': {
    previewUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/1fb475fc-1675-49ad-be6b-db3445b1f25b.png',
    textureUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/cf9721c5-5e29-4979-bf1e-118c604547dd.png',
    pbrUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/8984591d-fdb0-4782-85da-36684c5041a8.jpg',
    clearcoatUrl: null,
    normalsUrl: null,
    displacementUrl: null,
    displacementScale: null,
    refractive: false,
    reflective: false,
    translucent: false,
    lightSrc: null,
    lightIntensity: 1.0,
    lightUrl: null,
  },
  'space-mat': {
    previewUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/b6b6bb51-095b-4838-9723-e447ce0259d5.png',
    textureUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/b1dfadbe-6c0a-455d-b9a3-a84d6e07dd02.png',
    pbrUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/6c54043e-02b6-48c8-95c7-653fea90dc7e.jpg',
    clearcoatUrl: '',
    normalsUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/fb6d255c-5e11-4106-87f8-13e1c2774fb7.png',
    refractive: false,
    lightUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/f58af08a-7a27-42b0-bc33-6c0f1ef070cc.jpg',
    lightSrc: 'rgba(253, 255, 0, 1)',
    lightIntensity: 10,
    opacity: 1,
    clearcoat: '',
    reflective: false
  },
  // 'wood-matte': {
  //   previewUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/1fb475fc-1675-49ad-be6b-db3445b1f25b.png',
  //   textureUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/f3b6b1f9-90b5-446e-af89-d833e6cd2431.png',
  //   pbrUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/8984591d-fdb0-4782-85da-36684c5041a8.jpg',
  //   clearcoatUrl: 'undefined',
  //   normalsUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/d8668539-a50a-4e73-9674-ea2c92ef9912.png',
  //   refractive: false,
  //   lightUrl: '',
  //   lightSrc: '',
  //   lightIntensity: '1',
  //   opacity: '1',
  //   clearcoat: '',
  //   reflective: false
  // },
  'wood-glossy': {
    previewUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/0f9fdda0-3c26-416c-8848-7f78d7bc87f6.png',
    textureUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/f3b6b1f9-90b5-446e-af89-d833e6cd2431.png',
    pbrUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/6c54043e-02b6-48c8-95c7-653fea90dc7e.jpg',
    clearcoatUrl: 'undefined',
    normalsUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/d8668539-a50a-4e73-9674-ea2c92ef9912.png',
    refractive: false,
    lightUrl: '',
    lightSrc: '',
    lightIntensity: 1,
    opacity: 1,
    clearcoat: '',
    reflective: false
  },
  'marble': {
    previewUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/8f735a71-70df-4ab6-9aa0-4bc2f4d17405.png',
    textureUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/bb1fb00f-6cfc-4783-9ef6-cf926eb6a797.png',
    pbrUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/5d4f9925-1772-4d98-b83d-c1034296d490.jpg',
    clearcoatUrl: '',
    normalsUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/fb6d255c-5e11-4106-87f8-13e1c2774fb7.png',
    refractive: false,
    lightUrl: '',
    lightSrc: '',
    lightIntensity: 1,
    opacity: 1,
    clearcoat: '',
    reflective: false
  },
  'red-paint': {
    previewUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/f28a76af-3a9a-4c8b-a0b4-655a7559caca.png',
    textureUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/6c54043e-02b6-48c8-95c7-653fea90dc7e.jpg',
    pbrUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/5d4f9925-1772-4d98-b83d-c1034296d490.jpg',
    clearcoatUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/6c54043e-02b6-48c8-95c7-653fea90dc7e.jpg',
    normalsUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/819de472-6348-4446-9468-c5446eb7515d.png',
    refractive: false,
    lightUrl: '',
    lightSrc: '',
    lightIntensity: 1,
    opacity: 1,
    clearcoat: '',
    reflective: false
  },
  'blue-paint': {
    previewUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/3d0f6e16-ea36-4c77-9be8-fdfd7bc25e0d.png',
    textureUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/b1dfadbe-6c0a-455d-b9a3-a84d6e07dd02.png',
    pbrUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/5d4f9925-1772-4d98-b83d-c1034296d490.jpg',
    clearcoatUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/6c54043e-02b6-48c8-95c7-653fea90dc7e.jpg',
    normalsUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/819de472-6348-4446-9468-c5446eb7515d.png',
    refractive: false,
    lightUrl: '',
    lightSrc: '',
    lightIntensity: 1,
    opacity: 1,
    clearcoat: '',
    reflective: false
  },
  'yellow-paint': {
    previewUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/3217711d-406c-4602-8718-9cf15d00ee71.png',
    textureUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/e4244c51-027d-4676-8a2e-cbd4cad02798.jpg',
    pbrUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/5d4f9925-1772-4d98-b83d-c1034296d490.jpg',
    clearcoatUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/6c54043e-02b6-48c8-95c7-653fea90dc7e.jpg',
    normalsUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/819de472-6348-4446-9468-c5446eb7515d.png',
    refractive: false,
    lightUrl: '',
    lightSrc: '',
    lightIntensity: 1,
    opacity: 1,
    clearcoat: '',
    reflective: false
  },
  'wood-and-black-metal': {
    previewUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/f4c54543-312f-4680-ba96-17cf624b44ff.png',
    textureUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/b77a7a1f-2939-4246-8b9d-b0bcec0a5bac.png',
    pbrUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/8984591d-fdb0-4782-85da-36684c5041a8.jpg',
    clearcoatUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/6c54043e-02b6-48c8-95c7-653fea90dc7e.jpg',
    normalsUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/d8668539-a50a-4e73-9674-ea2c92ef9912.png',
    refractive: false,
    lightUrl: '',
    lightSrc: '',
    lightIntensity: 1,
    opacity: 1,
    clearcoat: '',
    reflective: false
  },
  'castle-wall': {
    previewUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/f6b49a79-9480-44ea-9c7b-bfddd2385e10.png',
    textureUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/bdf2b71e-fcd3-4db4-83ed-1eaea29c813d.png',
    pbrUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/8984591d-fdb0-4782-85da-36684c5041a8.jpg',
    clearcoatUrl: '',
    normalsUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/608b8dc2-77ec-4cf3-be8e-585649f6ea02.jpg',
    refractive: false,
    lightUrl: '',
    lightSrc: '',
    lightIntensity: 1,
    opacity: 1,
    clearcoat: 0,
    reflective: false
  },
  'castle-wall-simplified': {
    previewUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/459da623-f868-436a-8804-70b1851738f0.png',
    textureUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/5097dba5-f1e9-449e-b73f-ac7dba0c8bb6.png',
    sharpenTexture: true,
    pbrUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/8984591d-fdb0-4782-85da-36684c5041a8.jpg',
    clearcoatUrl: '',
    normalsUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/608b8dc2-77ec-4cf3-be8e-585649f6ea02.jpg',
    refractive: false,
    lightUrl: '',
    lightSrc: '',
    lightIntensity: 1,
    opacity: 1,
    clearcoat: 0,
    reflective: false
  },
  'castle-floor': {
    previewUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/ed0c2a22-e502-48f3-8f48-6ced5f154817.png',
    textureUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/285b01c3-f6a5-4d04-a996-7cec2991c7f8.jpg',
    sharpenTexture: false,
    pbrUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/8984591d-fdb0-4782-85da-36684c5041a8.jpg',
    clearcoatUrl: '',
    normalsUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/02f82029-8de2-4245-856f-689c7bbf774a.jpg',
    refractive: false,
    lightUrl: '',
    lightSrc: '',
    lightIntensity: 1,
    opacity: 1,
    clearcoat: 0,
    reflective: false
  },
  'grass': {
    previewUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/b326bbcb-264b-4a1b-b068-35d05b346523.png',
    textureUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/15041ec1-fb6f-41be-ba4c-d154ebb19d3c.png',
    sharpenTexture: true,
    pbrUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/8984591d-fdb0-4782-85da-36684c5041a8.jpg',
    clearcoatUrl: null,
    normalsUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/ecdfe840-ca35-469e-94cb-28f26a984c8a.png',
    refractive: false,
    lightUrl: '',
    lightSrc: '',
    lightIntensity: 1,
    opacity: 1,
    clearcoat: 0,
    reflective: false
  },
  'clear': {
    previewUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/c1cd253e-465e-4325-b9e9-e07db63044b3.png',
    textureUrl: null,
    sharpenTexture: false,
    pbrUrl: null,
    clearcoatUrl: null,
    normalsUrl: null,
    refractive: false,
    lightUrl: '',
    lightSrc: '',
    lightIntensity: 1,
    opacity: 1,
    clearcoat: 0,
    reflective: false,
    roughness: .7,
    metalness: .5
  },
  'unpainted-wall': {
    previewUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/ab9b870e-7294-4a43-ac40-55d194880a8b.png',
    textureUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/f25bfdf1-43ac-4bd4-ad94-b3f039ad3038.png',
    sharpenTexture: false,
    pbrUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/8984591d-fdb0-4782-85da-36684c5041a8.jpg',
    clearcoatUrl: null,
    normalsUrl: null,
    refractive: false,
    lightUrl: '',
    lightSrc: '',
    lightIntensity: 1,
    opacity: 1,
    clearcoat: 0,
    reflective: false
  },
  'unpainted-floor': {
    previewUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/2d169fc1-139a-458a-b2d3-1dd23bfca7d2.png',
    textureUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/89231692-288a-4403-b948-2e68b230b58a.png',
    sharpenTexture: false,
    pbrUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/8984591d-fdb0-4782-85da-36684c5041a8.jpg',
    clearcoatUrl: '',
    normalsUrl: '',
    refractive: false,
    lightUrl: '',
    lightSrc: '',
    lightIntensity: 1,
    opacity: 1,
    clearcoat: 0,
    reflective: false
  },
};
