import React from 'react';
import styled from 'styled-components';
import Rough from 'roughjs/bin/rough';
import { models } from '../../data/common/models';

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #eee;
`;

export class CABlueprint extends React.Component {
  canvasRef = React.createRef();
  paintOrder = {
    'tile': 0,
    'object': 1,
    'wall': 2,
  };

  constructor(props) {
    super(props);

    this.state = {
      dragging: false,
      mx: 0,
      mz: 0,
      sx: 0,
      sy: 0
    };
  }

  getEventPosition(event) {
    const { clientX, clientY, target } = event;
    const { left, top } = target.getBoundingClientRect();

    return {
      x: clientX - left,
      y: clientY - top
    };
  }

  onMouseDown(event) {
    const { x, y } = this.getEventPosition(event);

    this.setState({
      ...this.state,
      dragging: true,
      sx: x,
      sy: y
    });
  }

  onMouseUp(event) {
    this.setState({ ...this.state, dragging: false, x: 0, y: 0 });
  }

  onMouseMove(event) {
    const { sx, sy, mx, mz, dragging } = this.state;
    
    if (!dragging) {
      return;
    }

    event.preventDefault();

    const { x, y } = this.getEventPosition(event);
    const dx = (sx - x);
    const dy = (sy - y);

    this.setState({
      dragging: true,
      sx: x,
      sy: y,
      mx: mx + dx,
      mz: mz + dy,
    });
  }

  paintSchema(branch, repaint = false) {
    const { camera, floor } = this.props;
    const { mx, mz } = this.state;

    if (branch instanceof Array) {
      return branch
        .sort((branchA, branchB) => {
          return this.paintOrder[branchA.type] - this.paintOrder[branchB.type];
        })
        .map((subbranch, index) => this.paintSchema(subbranch, repaint));
    }

    const canvas = this.canvasRef.current;

    if (!canvas) {
      return;
    }

    const ctx = canvas.getContext('2d');
    const rough = Rough.canvas(canvas);
    const zoom = 5;
    const cameraX = window.innerWidth / 2 - mx;
    const cameraZ = window.innerHeight / 2 - mz;

    if (repaint) {
      ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
    }

    ctx.lineWidth = 1;

    if (branch.type === 'object') {
      const { size = [] } = branch;

      const w = (size[0] * 10) || 5;
      const h = (size[1] * 10) || 5;
      const x = (branch.positionX - w / 2) * zoom + cameraX;
      const y = (branch.positionZ - h / 2) * zoom + cameraZ;

      ctx.beginPath();
      ctx.rect(
        x,
        y,
        w * zoom,
        h * zoom
      );
      ctx.stroke();
      
      ctx.beginPath();
      ctx.strokeStyle = '#cccccc';
      ctx.moveTo(x, y);
      ctx.lineTo(x + w * zoom, y + h * zoom);
      ctx.moveTo(x + w * zoom, y);
      ctx.lineTo(x, y + h * zoom);
      ctx.stroke();

      const model = models[branch.modelId];

      if (model && model.details && model.details.name) {
        let labelSize = w < h ? h * zoom : w * zoom;
        ctx.save();
        ctx.fillStyle = '#000000';
        ctx.font = '100 14px Arial, sans-serif';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';

        ctx.translate(x + (w * zoom / 2), y + (h * zoom / 2));
        if (w < h) {
          ctx.rotate(-Math.PI / 2);
        }
        ctx.fillText(model.details.name, 0, 0, labelSize - 4);
        ctx.restore();
      }
    } else if (branch.type === 'tile') {
      ctx.beginPath();
      ctx.rect(
        (branch.positionX - 5) * zoom + cameraX,
        (branch.positionZ - 5) * zoom + cameraZ,
        10 * zoom, 
        10 * zoom
      );
      ctx.fillStyle = '#ffffff';
      ctx.fill();
    } else if (branch.type === 'wall') {
      ctx.lineWidth = 3;

      const x = branch.positionX * zoom + cameraX;
      const z = branch.positionZ * zoom + cameraZ;
      
      ctx.save();
      ctx.beginPath();
      
      ctx.translate(x, z);
      ctx.rotate(Math.PI * 2 - branch.angle);
      ctx.translate(-1 * zoom, -1 * zoom);
      ctx.rect(
        0,
        0,
        12 * zoom, 
        2 * zoom
      );
      ctx.fillStyle = '#ffffff';
      ctx.fill();
      rough.rectangle(
        0,
        0,
        12 * zoom,
        2 * zoom,
        {
          fill: '#777777',
          roughness: 0.5,
          hachureGap: 2
        }
      );

      ctx.restore();
    }

    if (branch.content && branch.content instanceof Array) {
      return branch.content
        .sort((branchA, branchB) => {
          return this.paintOrder[branchA.type] - this.paintOrder[branchB.type];
        })
        .map((subbranch, index) => this.paintSchema(subbranch, false));
    }
  }

  componentDidMount() {
    const { schema } = this.props;

    this.paintSchema(schema, true);
  }

  componentDidUpdate() {
    const { schema } = this.props;

    this.paintSchema(schema, true);
  }

  render() {
    const { schema } = this.props;

    this.paintSchema(schema, true);

    return (
      <Container>
        <Canvas
          ref={this.canvasRef}
          width={window.innerWidth}  
          height={window.innerHeight}
          onMouseDown={(e) => this.onMouseDown(e)}
          onMouseUp={(e) => this.onMouseUp(e)}
          onMouseLeave={(e) => this.onMouseUp(e)}
          onMouseMove={(e) => this.onMouseMove(e)}
        />
      </Container>
    );
  }
}