import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { CAPanelBody, CAPanelH1 } from './ca-panel';
import { Row, Col } from 'antd';
import { models } from '../../data/common/models';
import { CAVariableInput } from './ca-variable-input';
import StateService from '../../services/state.service';

const commentsSlideHeight = 300;

const ModelPreview = styled.img`
  filter: drop-shadow(0px 1px 2px #cccccc);
  margin: 10px 0 20px 0;
`;

const ObjectName = styled(CAPanelH1)`
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const CommentsSlide = styled.div`
  max-height: ${commentsSlideHeight}px;
  overflow: hidden;
  overflow-y: scroll;
`;

const CommentsOverflowShadow = styled.div`
  width: 100%;
  height: 1px;

  ${p => p.shiftY && `
    filter: drop-shadow(0px ${p.shiftY}px 2px #aaaaaa);
  `}
`;

const CommentTimestamp = styled(Col)`
  margin: 10px 0;
  text-align: center;
  color: #999;
  letter-spacing: 1px;

  span {
    letter-spacing: -.1px;
  }
`;

const CommentWrapper = styled.div`
  position: relative;

  .timestamp {
    opacity: 0.0;
    transform: translateX(-50%) translateY(-60%);
  }

  &:hover {
    .timestamp {
      opacity: 1.0;
      transform: translateX(-50%) translateY(-50%);
      transition: all .1s ease .5s;
    }
  }
`;

const FoldableTimestamp = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  display: inline-block;
  background-color: #ffffff;
  border-radius: 25px;
  text-align: center;
  color: #999;
  letter-spacing: 1px;
  padding: 6px 10px;
  transform: translateX(-50%) translateY(-50%);
  filter: drop-shadow(0px 1px 2px #cccccc);
  z-index: 2;
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all .1s ease 0s;

  span {
    letter-spacing: -.1px;
  }
`;

const CommentAuthor = styled(Col)`
  margin: 5px 0;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: -0.1px;
`;

const CommentText = styled(Col)`
  margin-bottom: 10px;
  color: #777;
  line-height: 1.3;
  hyphens: auto;
  letter-spacing: 0px;
`;

const CommentBlock = ({
  date = '',
  comments = []
}) => (
  <div className="comments">
    {comments.map(({ time, author, comment }, index) => {
      const selfMoment = moment(time);
      const prevMoment = moment((index > 0 ? comments[index - 1].time : time));

      const timeFromLast = selfMoment.diff(prevMoment, 'hours');
      const daysDifference = index === 0 || selfMoment.format('D') !== prevMoment.format('D');

      let showTime = true;

      if (timeFromLast <= 6 && !daysDifference) {
        showTime = false;
      }

      let showDay;

      if (daysDifference) {
        showDay = selfMoment.calendar(null, {
          lastDay: '[Yesterday]',
          sameDay: '[Today]',
          nextDay: 'DD/MM/YYYY',
          lastWeek: 'DD/MM/YYYY',
          nextWeek: 'DD/MM/YYYY',
          sameElse: 'DD/MM/YYYY'
        });
      }

      return (
        <CommentWrapper>
          {(showTime || daysDifference) && <Row>
            <CommentTimestamp span={24}>
              {daysDifference && `${showDay}, `}<span>{selfMoment.format('h:mm a')}</span>
            </CommentTimestamp>
          </Row>}
          {!showTime && !daysDifference && (
            <FoldableTimestamp className="timestamp">
              <span>{selfMoment.format('DD/MM/YYYY h:mm a')}</span>
            </FoldableTimestamp>
          )}
          <Row>
            <CommentAuthor span={24}>
              @{author}
            </CommentAuthor>
            <CommentText span={24} lang="en">
              {comment}
            </CommentText>
          </Row>
        </CommentWrapper>
      );
    })}
  </div>
);

export class CAComments extends React.Component {
  commentsBlockRef = React.createRef();

  state = {
    resetMessageScroll: false,
    showTopShadow: false,
    showBottomShadow: false,
    newComment: ''
  };

  componentDidMount() {
    this.fitCommentsSlide();
  }

  componentDidUpdate() {
    this.fitCommentsSlide();
  }

  onMouseScroll() {
    this.fitCommentsSlide();
  }

  fitCommentsSlide() {
    if (!this.commentsBlockRef.current) {
      return;
    }

    const slide = this.commentsBlockRef.current;
    const comments = slide.querySelector('.comments');

    const hasOverflow = comments.scrollHeight >= slide.clientHeight;

    if (hasOverflow) {
      const {
        showTopShadow,
        showBottomShadow,
        resetMessageScroll
      } = this.state;
      const yPosition = slide.scrollTop;

      if (resetMessageScroll === false) {
        slide.scrollTop = slide.clientHeight;
        this.state.resetMessageScroll = true;
      }
      
      if (yPosition <= 0) {
        if (showTopShadow !== false) {
          this.setState({
            showTopShadow: false,
            showBottomShadow: true
          });
        }
      } else if (yPosition >= comments.scrollHeight - slide.clientHeight) {
        if (showBottomShadow !== false) {
          this.setState({
            showTopShadow: true,
            showBottomShadow: false
          });
        }
      } else if (showBottomShadow === false || showTopShadow === false) {
        this.setState({
          showTopShadow: true,
          showBottomShadow: true
        });
      }
    } else if (this.state.showBottomShadow || this.state.showTopShadow) {
      this.setState({
        showTopShadow: false,
        showBottomShadow: false
      });
    }
  }

  postComment() {
    const {
      newComment
    } = this.state;
    const { object } = this.props;

    const comment = `${newComment}`.trim();
    const config = StateService.getConfig();

    if (!object || !comment) {
      return;
    }

    if (!object.comments) {
      object.comments = [];
    }

    object.comments.push({
      time: moment().utc().toISOString(),
      author: `${config.title.split('.wtlstudio.com').join('')}`,
      comment: comment
    });
    StateService.saveAll();

    this.setState({
      ...this.state,
      newComment: '',
      resetMessageScroll: false
    });

    setTimeout(() => {
      const slide = this.commentsBlockRef.current;

      if (!slide) {
        return;
      }

      slide.scrollTo(0, slide.clientHeight);
    }, 1000);
  }

  render() {
    const { object } = this.props;

    if (!object) {
      return null;
    }

    const model = models[object.modelId];

    if (!model) {
      return null;
    }
    
    const details = model.details;
    
    if (!details) {
      return null;
    }

    const {
      name
    } = details;
    const {
      showTopShadow,
      showBottomShadow,
      newComment
    } = this.state;

    return (
      <CAPanelBody
        style={{ overflow: 'visible', width: 210 }}
        margin="20px 25px"
      >
        <Row>
          <Col span={6}>
          
          </Col>
          <Col span={12} style={{ textAlign: 'center' }}>
            <ObjectName>
              {name}
            </ObjectName>
          </Col>
          <Col span={6}>
          
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'center', textTransform: 'uppercase' }}>
            <ModelPreview src={object.previewUrl} width="70" />
          </Col>
        </Row>
        {showTopShadow && <CommentsOverflowShadow shiftY={1} />}
        <CommentsSlide
          ref={this.commentsBlockRef}
          onScroll={this.onMouseScroll.bind(this)}
        >
          <CommentBlock
            comments={object.comments || []}
          />
        </CommentsSlide>
        {showBottomShadow && <CommentsOverflowShadow shiftY={-1} />}
        <Row>
          <CAVariableInput
            span={24}
            wrapperProps={{
              style: { marginTop: 10 }
            }}
            inputType="textarea"
            inputProps={{
              placeholder: 'Type here...'
            }}
            value={newComment}
            onChange={(value => {
              this.state.newComment = value;
              this.forceUpdate();
            })}
          />
          <Col span={6} />
          <CAVariableInput
            span={12}
            wrapperProps={{
              style: { marginTop: 10 }
            }}
            inputType="button"
            inputProps={{
              active: true,
              style: {
                textAlign: 'center'
              }
            }}
            onClick={() => {
              this.postComment();
            }}
          >
            Submit
          </CAVariableInput>
          <Col span={6} />
        </Row>
      </CAPanelBody>
    );
  }
}