import Rete from 'rete';
import { EventSourceSocket, StringSocket } from '../sockets';
import { BaseLayout } from '../layouts/base.layout';

export class SendEmailNode extends Rete.Component {
  constructor() {
    super('Email');
    this.data.component = BaseLayout;
  }

  builder(node) {
    const inp1 = new Rete.Input('reply-to', 'Reply-To', StringSocket, false);
    const inp2 = new Rete.Input('fields', 'Fields', StringSocket, true);
    const inp3 = new Rete.Input('event-source-in', '', EventSourceSocket, false);
    const out1 = new Rete.Output('then', 'Success', EventSourceSocket, false);
    const out2 = new Rete.Output('catch', 'Failure', EventSourceSocket, false);

    return node
      .addInput(inp1)
      .addInput(inp2)
      .addInput(inp3)
      .addOutput(out1)
      .addOutput(out2);
  }

  worker(node, inputs, outputs) {
    node.data['reply-to'] = inputs['reply-to'].length && inputs['reply-to'][0];
    node.data['fields'] = inputs['fields'] || [];
  }
}
