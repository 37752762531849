import React from 'react';
import styled, { keyframes } from 'styled-components';
import { AntWrapper } from '../components/ant-wrapper';
import {
  Row,
  Col,
  message,
} from 'antd';
import { BeautifulButton, SimpleInput } from '../styles/styled';
import { callApi, config } from '../../config';
import EditorService, { authTokenKey } from '../services/editor.service';
import { navigate } from 'gatsby';
import StateService from '../services/state.service';
import splashBackground from '../images/splash-background.jpg';
import splashBoxTexture from '../images/splash-box-texture.png';
import splashBoxNormals from '../images/splash-box-normals.jpg';
import splashBoxPbr from '../images/splash-box-pbr.jpg';
import { Wtl3DScene } from '../utils/three/wtl-3d-scene';
import * as Three from 'three';

const { demoAccounts } = config;

const AppearAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(-40%) translateX(-50%);
  }

  to {
    opacity: 1;
    transform: translateY(-50%) translateX(-50%);
  }
`;

const LoginWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 1;
`;

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background-color: rgba(0, 0, 0, .25);
  backdrop-filter: blur(5px) saturate(90%);
  color: #eee;
  text-align: center;
  font-size: 10px;

  a {
    color: #eee;
    text-decoration: none;
    transition: all .1s ease;

    &:hover {
      color: #ff7dc2;
    }
  }
`;

const LoginDialog = styled.div`
  position: absolute;
  display: inline-flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  width: 350px;
  min-height: 300px;
  transform: translateY(-50%) translateX(-50%);
  background-color: #fff;
  border: solid 1px #000;
  border-radius: 2px;
  box-sizing: border-box;
  z-index: 2;
  box-shadow: -48px 48px 80px rgba(0, 0, 0, .5),
    48px 48px 80px rgba(0, 0, 0, .5);
  opacity: 0;
  animation: ${AppearAnimation} 1s ease 1s both;

  .title {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0 20px;
    padding-top: 16px;
    padding-bottom: 8px;
    font-size: 32px;
    font-weight: 600;
    background-color: #fff;
    z-index: 1;
  }

  .body {
    flex-grow: 2;
    flex-shrink: 0;
    padding: 0 20px;
    padding-bottom: 16px;
    color: #000;
    background-color: #fff;
    z-index: 1;
  }

  .footer {
    font-size: 12px;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 16px 20px;
    background-color: #263346;
    color: #fff;
    z-index: 1;

    a {
      text-decoration: underline;
    }

    &:not(:last-child) {
      border-bottom: solid 1px #495c75;
    }
  }
`;

const IntroWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  color: #fff;
  z-index: 1;
  user-select: none;
  cursor: default;

  h1 {
    display: inline-block;
    font-size: 42px;
    line-height: 60px;
    font-weight: bold;
    background-color: #000;
    padding: 20px;
    margin: 5px 0;
    border-radius: 0 20px 20px 0;
  }

  h2 {
    display: inline-block;
    font-size: 42px;
    line-height: 60px;
    font-weight: bold;
    color: #fff;
    background-color: #000;
    padding: 20px;
    margin: 5px 0;
    border-radius: 0 20px 0 0;
  }

  a {
    display: inline-block;
    font-size: 18px;
    line-height: 38px;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    background-color: #000;
    padding: 20px;
    margin: 5px 0;
    border-radius: 0 0 20px 0;
    transition: all .02s ease;

    &:hover {
      color: #000;
      background-color: #ff7dc2;
    }
  }
`;

export default class Login extends React.Component {
  enableAccount = null;

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      isLogging: false
    };

    StateService.clearAll();
  }

  componentDidMount() {
    if (typeof window === 'undefined') {
      return;
    }

    const querystring = window.location.search.split(/[?&]/gm);
    const queryValues = querystring.map(t => {
      const [ key, value ] = decodeURIComponent(t).split('=');

      if (key === 'a') {
        this.enableAccount = value;
        this.setState({ email: value });
      }
    });

    if (this.enableAccount && demoAccounts.includes(this.enableAccount)) {
      setTimeout(() => {
        this.handleLogin();
      }, 1000);
    }
  }

  handleLogin() {
    if (typeof window === 'undefined') {
      return null;
    }

    const { email, password } = this.state;
    const isDemo = demoAccounts.includes(email);

    if (!email || (!isDemo && !password)) {
      return message.info('Invalid email or password');
    }

    this.setState({
      ...this.state,
      isLogging: true
    });

    EditorService.rememberDemo(false);

    EditorService.login(email, password)
    .then(async () => {
      message.success('Logged in.');
      message.info('Loading project...');

      if (isDemo) {
        EditorService.rememberDemo(true);
      }

      await StateService.fetchProject();

      navigate('/');
    })
    .catch((e) => {
      message.error('Invalid email or password. Try again.');

      this.setState({
        isLogging: false
      });
    });
  }

  loginBoxes = [];

  render() {
    const { isLogging } = this.state;

    return (
      <AntWrapper style={{ minWidth: 1200 }}>
        <Wtl3DScene
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 0
          }}
          onSceneReady={({
            renderer,
            scene,
            camera,
            root,
            canvas,
          }) => {
            camera.position.x = -5.0;
            camera.position.y = 5.0;
            scene.background = new Three.Color(0xeeeeee);

            (new Three.TextureLoader()).load(splashBackground, (envMap) => {
                const pmremGenerator = new Three.PMREMGenerator(renderer);
                pmremGenerator.compileEquirectangularShader();

                const cubeMap = pmremGenerator.fromEquirectangular(envMap);
                scene.environment = cubeMap.texture;

                envMap.dispose();
                pmremGenerator.dispose();
              }
            );

            const box = new Three.BoxBufferGeometry(10., 10.0, 10.0);

            let boxTexture = null;
            let normals = null;
            let pbr = null;

            box.attributes.uv = new Three.Float32BufferAttribute([
              0, .33, .33, .33,
              0, 0, .33, 0,
              .33, .66, .66, .66,
              .33, .33, .66, .33,
              .66, 1, 1, 1,
              .66, .66, 1, .66,
              0, .66, .33, .66,
              0, .33, .33, .33,
              0, 1, .33, 1,
              0, .66, .33, .66,
              .66, .33, 1, .33,
              .66, 0, 1, 0
            ], 2, true);

            const boxAngles = [
              0, Math.PI / 2, Math.PI, -Math.PI / 2
            ];

            const flipBox = (mesh, dirZ, rotAmount, rotAxis) => {
              if (mesh.userData.flipping) {
                flipBox(
                  this.loginBoxes[Math.floor(Math.random() * this.loginBoxes.length)],
                  1,
                  Math.PI / 2,
                  ['y', 'x', 'z'][Math.floor(Math.random() * 3)]
                );

                return;
              }

              setTimeout(() => {
                let flipping = 0;
                const originalPosition = mesh.position.clone();
                const originalRotation = mesh.rotation.clone();

                mesh.userData.flipping = true;

                const flipStep = () => {
                  if (flipping === 0) {
                    if (mesh.position.z < 10.0) {
                      mesh.position.z += .3;
                    } else {
                      flipping = 1;
                    }
                  } else if (flipping === 1) {
                    if (mesh.rotation[rotAxis] < originalRotation[rotAxis] + rotAmount) {
                      mesh.rotation[rotAxis] += .1;
                    } else {
                      mesh.rotation[rotAxis] = originalRotation[rotAxis] + rotAmount;
                      flipping = 2;
                    }
                  } else if (flipping === 2) {
                    if (mesh.position.z > originalPosition.z) {
                      mesh.position.z -= .3;
                    } else {
                      mesh.position.z = originalPosition.z;
                      flipping = -1;
                    }
                  }
                  
                  if (flipping !== -1) {
                    requestAnimationFrame(() => flipStep());
                  } else {
                    mesh.userData.flipping = false;
                    flipBox(
                      this.loginBoxes[Math.floor(Math.random() * this.loginBoxes.length)],
                      1,
                      Math.PI / 2,
                      ['y', 'x', 'z'][Math.floor(Math.random() * 3)]
                    );
                  }
                };

                requestAnimationFrame(() => flipStep());

              }, Math.random() * 3000 + 500);
            };


            Promise.all([
              new Promise(done => (new Three.TextureLoader()).load(splashBoxTexture, texture => {
                boxTexture = texture;
                done();
              })),
              new Promise(done => (new Three.TextureLoader()).load(splashBoxNormals, texture => {
                normals = texture;
                done();
              })),
              new Promise(done => (new Three.TextureLoader()).load(splashBoxPbr, texture => {
                pbr = texture;
                done();
              }))
            ]).then(() => {
              for (let y = -10; y < 10; y++) {
                for (let x = -10; x < 10; x++) {
                  const mat = new Three.MeshStandardMaterial({
                    map: boxTexture,
                    normalMap: normals,
                    aoMap: pbr,
                    metalnessMap: pbr,
                    roughnessMap: pbr
                  });
                  const dz = (Math.abs(x) + Math.abs(y)) * .5;
                  const mesh = new Three.Mesh(box, mat);
                  mesh.position.set(x * 14.5, y * 14.5, Math.random() * 2 * dz - dz);
                  mesh.rotation.y = boxAngles[Math.floor(Math.random() * boxAngles.length)];
                  mesh.rotation.y += Math.random() * 0.1 - 0.05;
                  mesh.castShadow = true;
                  mesh.receiveShadow = true;

                  this.loginBoxes.push(mesh);

                  root.add(mesh);
                }
              }

              for (let i = 0; i < 10; i++) {
                flipBox(
                  this.loginBoxes[Math.floor(Math.random() * this.loginBoxes.length)],
                  1,
                  Math.PI / 2,
                  ['y', 'x', 'z'][Math.floor(Math.random() * 3)]
                );
              }
            });
          }}
          onAnimationStep={({
            renderer,
            scene,
            camera,
            root,
            canvas,
          }) => {
            
          }}
        />
        <LoginWrapper>
          <IntroWrapper>
            {/* <Row>
              <Col span={24}>
                <h2>2020 Update</h2>
              </Col>
              <Col span={24}>
                <h1>WTL Studio Website Builder</h1>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <a href="https://demo.wtlstudio.com/">
                  See what's new!
                </a>
              </Col>
            </Row> */}
          </IntroWrapper>
          <LoginDialog>
            <div className="title">
              Login
            </div>
            <div className="body">
              <Row>
                <Col span={24}>
                  <SimpleInput>
                    <span>
                      email
                    </span>
                    <input
                      name="user"
                      type="email"
                      defaultValue={this.state.email}
                      onChange={(e) => this.setState({
                        email: e.target.value
                      })}
                    />
                  </SimpleInput>
                </Col>
                <Col span={24}>
                  <SimpleInput>
                    <span>
                      password
                    </span>
                    <input  
                      disabled={demoAccounts.includes(this.state.email)}
                      name="password"
                      type="password"
                      defaultValue={this.state.password}
                      onChange={(e) => this.setState({
                        password: e.target.value
                      })}
                    />
                  </SimpleInput>
                </Col>
                <Col span={24} style={{ textAlign: 'right' }}>
                  <BeautifulButton
                    style={{ minWidth: '100px' }}
                    onClick={() => this.handleLogin()}
                  >
                    {
                      !isLogging ?
                      <>
                        Log In
                      </> :
                      <>
                        <i className="fas fa-spinner-third fa-spin"></i>
                      </>
                    }
                  </BeautifulButton>
                </Col>
              </Row>
            </div>
            <div className="footer">
              No account? <a href="https://access.wtlstudio.nl">Create one here.</a>
            </div>
            <div className="footer">
              Can't access account? <a href="mailto:support@wtlstudio.com">Contact us.</a>
            </div>
          </LoginDialog>
          <Footer>
            <Row>
              <Col span={24}>
                © 2016-2020 <a href="https://wtlstudio.com" target="_blank">WTL Studio Amsterdam</a>
              </Col>
              <Col span={24}>
                April 2020
              </Col>
            </Row>
          </Footer>
        </LoginWrapper>
      </AntWrapper>
    )
  }
};