import Rete from 'rete';
import { EventSourceSocket } from '../sockets';
import { BaseLayout } from '../layouts/base.layout';
import { EventSourceControl } from '../controls/event-source.control';
import { StringControl } from '../controls/string.control';

export class ApiNode extends Rete.Component {
  constructor() {
    super('Call API');
    this.data.component = BaseLayout;
  }

  builder(node) {
    const inp1 = new Rete.Input('event-source-in', '', EventSourceSocket, false);
    const out1 = new Rete.Output('then', '', EventSourceSocket, false);
    const ctrl1 = new StringControl(this.editor, 'API Endpoint', node);
    const ctrl2 = new StringControl(this.editor, 'Method', node);
    const ctrl3 = new StringControl(this.editor, 'Payload', node);

    return node
      .addControl(ctrl1)
      .addControl(ctrl2)
      .addControl(ctrl3)
      .addInput(inp1)
      .addOutput(out1);
  }

  worker(node, inputs, outputs) {

  }
}