import Rete from 'rete';
import { EventSourceSocket, StringSocket } from '../sockets';
import { BaseLayout } from '../layouts/base.layout';
import { StringControl } from '../controls/string.control';

export class ElementSourceNode extends Rete.Component {
  constructor() {
    super('Element');
    this.data.component = BaseLayout;
  }

  builder(node) {
    const out1 = new Rete.Output('element-source', 'Element', StringSocket, false);
    const out2 = new Rete.Output('element-value', 'Value', StringSocket, false);
    
    const ctrl = new StringControl(this.editor, 'Element Id', node);

    return node
      .addControl(ctrl)
      .addOutput(out1)
      .addOutput(out2);
  }

  worker(node, inputs, outputs) {
    outputs['element-source'] = `_$el${node.data['Element Id']}`;
    outputs['element-value'] = `_$inp${node.data['Element Id']}`;
  }
}
