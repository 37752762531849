import React from 'react';
import { VariableInput } from '../variable-input';
import { Row, Col } from 'antd';

export const renderAdvancedInputProps = function () {
  const { tools } = this.state;
  const { selection } = tools;

  return (
    <Col span={8}>
      <Row>
        <VariableInput
          span={24}
          inputType="select"
          name="field type"
          value={selection.branch.variant || 'text'}
          onChange={(value, params) => {
            this.updateSelectionParam('variant', { target: { value }}, params);
          }}
        >
          <option value="text">short text</option>
          <option value="number">number</option>
          <option value="password">password</option>
          <option value="textarea">long text</option>
          <option value="date">date</option>
          <option value="week">week</option>
          <option value="time">time</option>
          <option value="select">select</option>
        </VariableInput>
        <VariableInput
          span={24}
          name="placeholder"
          value={selection.branch.placeholder}
          onChange={(value, params) => {
            this.updateSelectionParam('placeholder', { target: { value }}, params);
          }}
        />
      </Row>
      <Row gutter={4}>
        {
          selection.branch.variant === 'select' && (
            <VariableInput
              span={24}
              inputType="table"
              inputProps={[ 'name' ]}
              name="options"
              value={selection.branch.inputOptions}
              onChange={(value, params) => {
                this.updateSelectionParam('inputOptions', { target: { value }}, params);
              }}
            />
          )
        }
      </Row>
    </Col>
  );
}
