import React from 'react';
import { VariableInput } from '../variable-input';
import { Row, Col } from 'antd';
import { imageFileTypes } from '../../data/file-types.data';

export const renderImageProps = function () {
  const { tools } = this.state;
  const { selection } = tools;

  return (
    <>
      <Col span={8}>
        <Row gutter={4}>
          <VariableInput
            inputType="file"
            filterTypes={imageFileTypes}
            span={24}
            name="image"
            value={selection.branch.url}
            onChange={(value, params) => {
              this.updateSelectionParam('url', { target: { value }}, params);
            }}
          />
        </Row>
      </Col>
      <Col span={8}>
        <Row gutter={4}>
          {
            selection.branch.url && (
              <>
                <VariableInput
                  span={24}
                  name={
                    <>
                      description <i className="fa fa-h1"></i>
                    </>
                  }
                  value={selection.branch.imageTitle}
                  onChange={(value, params) => {
                    this.updateSelectionParam('imageTitle', { target: { value }}, params);
                  }}
                />
              </>
            )
          }
        </Row>
        <Row gutter={4}>
          <VariableInput
            span={12}
            value={selection.branch.cssWidth}
            onChange={(value, params) => this.updateSelectionParam('cssWidth', { target: { value }}, params)}
            inputType="css-prop"
            type="css-prop"
            name="width"
          />
          <VariableInput
            span={12}
            value={selection.branch.cssWidth}
            onChange={(value, params) => this.updateSelectionParam('cssWidth', { target: { value }}, params)}
            inputType="css-prop"
            name="height"
          />
          <VariableInput
            span={12}
            value={selection.branch.scaleToSize}
            onChange={(value, params) => this.updateSelectionParam('scaleToSize', { target: { value }}, params)}
            inputType="switch"
            name="scale to size"
          />
          <VariableInput
            span={12}
            inputProps={{
              disabled: !selection.branch.scaleToSize
            }}
            value={selection.branch.scaleToSize && selection.branch.preserveRatio}
            onChange={(value, params) => this.updateSelectionParam('preserveRatio', { target: { value }}, params)}
            inputType="switch"
            name="preserve ratio"
          />
          <VariableInput
            span={12}
            inputProps={{
              disabled: !selection.branch.scaleToSize
            }}
            value={selection.branch.scaleToSize && selection.branch.sharpen}
            onChange={(value, params) => this.updateSelectionParam('sharpen', { target: { value }}, params)}
            inputType="switch"
            name="sharpen"
          />
        </Row>
      </Col>
    </>
  );
}