import React from 'react';
import styled from 'styled-components';
import * as Three from 'three';
import {
  Layout,
  Spin,
  Row
} from 'antd';
import { AntWrapper } from '../components/ant-wrapper';
import StateService from '../services/state.service';
import EditorService, { SAFE_UNDEFINED } from '../services/editor.service';
import VariablesService from '../../wtl-schema/services/variable.service';
import ThreeStats from 'three/examples/jsm/libs/stats.module';
import { OrbitControls } from '../utils/common/orbit-controls';
import { TransformControls } from '../utils/common/transform-controls';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { SAOPass } from 'three/examples/jsm/postprocessing/SAOPass';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader';
import { RemoteCanvas } from '../components/common-app/remote-canvas';
import { parseSchema } from '../../common-schema/core/parse-schema';
import { models } from '../data/common/models';
import { ifWindow } from '../utils/if-window';

import { VertexShaderPCSS } from '../../common-schema/shaders/pcss/vertex';
import { FragmentShaderPCSS } from '../../common-schema/shaders/pcss/fragment';
import { _3dModelFileTypes } from '../data/file-types.data';
import { parseCursor } from '../../common-schema/elements/cursor';
import { parseWall } from '../../common-schema/elements/wall';
import { parseTile } from '../../common-schema/elements/tile';

import { CAMenuBlob } from '../components/common-app/ca-menu-blob';
import { CAKnob } from '../components/common-app/ca-knob';
import { CAPanel, CAPanelBody } from '../components/common-app/ca-panel';
import { CALayout } from '../components/common-app/ca-layout';
import { CAButtonDebug } from '../components/common-app/ca-button';
import { CAModalBackground, CAModalBorderRadius } from '../components/common-app/shared';
import { CALibrary } from '../components/common-app/ca-library';
import { CALibraryCustomModel } from '../components/common-app/ca-library-custom-model';
import { CAFilters } from '../components/common-app/ca-filters';
import { tweenTo, tweenFunctions } from '../utils/common/tween-to';
import { CABlueprint } from '../components/common-app/ca-blueprint';
import { applyToAll } from '../../common-schema/utils/apply-to-all';
import { loadTexture, modelLoader } from '../../common-schema/utils/loaders';

import CALogo from '../images/common-app/common-logo.png';
import CursorBuildWallSVG from '../images/common-app/icons/ICONS_COMMON_SVG-23.svg';
import CursorBuildTileSVG from '../images/common-app/icons/ICONS_COMMON_SVG-22.svg';
import { CASmartToggle } from '../components/common-app/ca-smart-toggle';
import { CALabelRange } from '../components/common-app/ca-label-range';
import { CAObjectDetails } from '../components/common-app/ca-object-details';
import { CAComments } from '../components/common-app/ca-comments';
import { CAVariableInput } from '../components/common-app/ca-variable-input';
import { CAEnvironment } from '../components/common-app/ca-environment';
import { CAToolbox } from '../components/common-app/ca-toolbox';
import CursorBuildWallShiftSVG from '../images/common-app/icons/ICONS_COMMON_SVG-21.svg';
import CursorBuildTileShiftSVG from '../images/common-app/icons/ICONS_COMMON_SVG-24.svg';
import CursorBuildAltSVG from '../images/common-app/icons/ICONS_COMMON_SVG-20.svg';
import { WtlGlobalKeyboardListener } from '../components/layout/wtl-global-keyboard-listener';
import LibraryIconSVG from '../images/common-app/icons/ICONS_COMMON_SVG-25.svg';
import EnvironmentIconSVG from '../images/common-app/icons/ICONS_COMMON_SVG-26.svg';
import ToolsIconSVG from '../images/common-app/icons/ICONS_COMMON_SVG-27.svg';
import { InvertedImage } from '../utils/inverted-image';

const CanvasContainer = styled.div`
  &, canvas {
    outline: none;
  }
`;

export const ContextTools = {
  select: 0,
  place: 1,
  place_env: 2,
  paint_env: 3
};

const ViewMode = {
  view3d: 0,
  blueprint: 1,
  render: 2
};

export default class Common3d extends React.Component {
  constructor(props) {
    super(props);

    this.sceneRef = React.createRef();
    this.cursorRef = React.createRef();
    this.remoteCanvasRef = null;

    this.active = 0;
    this.renderQuality = 1;
    this.renderer = null;
    this.composer = null;
    this.scene = null;
    this.sceneRoot = null;
    this.camera = null;
    this.controls = null;
    this.stats = null;
    this.raycaster = null;
    this.effects = {};
    this.cursor = null;
    this.floor = null;

    this.state = {
      project: StateService.getEditedElement('schemaPage'),
      tools: {
        tempParam: null,
        selection: {
          branch: null
        },
        hover: {
          branch: null
        },
        contextTool: {
          id: ContextTools.select
        }
      },
      debug: {
        pauseRender: false,
        showBlueprints: false,
        viewMode: ViewMode.view3d,
        viewModeTransition: false,
        pauseSelection: 0,
        renderWalls: 2,
        floorCount: 2,
        currentFloorIndex: 0
      },
      uiLeft: [],
      uiRight: []
    };
  }

  saveSchema() {
    StateService.saveSchema();
  }

  onSelection({ mesh, branch }, { doubleClick } = {}) {
    if (!mesh || !branch) {
      this.clearSelection();

      return;
    }

    if (doubleClick) {
      // NOTE Do something contextual if necessary
      // if (branch.type === 'text') {
      //   this.state.panels.showRichTextEditor = true;
      //   this.forceUpdate();
      // } else if (branch.type !== 'root' && branch.type !== 'block') {
      //   this.state.panels.panelBottomIndex = 1;
      //   this.forceUpdate();
      // }
    }

    if (this.state.tools.selection.mesh === mesh) {
      return;
    }

    const { selection } = this.state.tools;

    applyToAll(selection.mesh, (mesh) => {
      if (mesh.material && mesh.material.emissive) {
        mesh.material.emissive.set(0x000000);
      }
    });

    if (mesh && branch) {
      this.effects.drag.detach();

      this.state.tools.selection = {
        mesh: mesh,
        branch: branch
      };

      if (!branch._locked) {
        this.effects.drag.attach(mesh);
      }

      applyToAll(mesh, (mesh) => {
        if (mesh.material && mesh.material.emissive) {
          mesh.material.emissive.set(0xaaaaaa);
        }
      });

      this.resetUiState('right');
      this.pushUiState(
        'right',
        225,
        -100,
        true,
        {
          selectionMenu: true
        }
      );
    }

    this.forceUpdate();
  }

  clearSelection() {
    const { selection } = this.state.tools;
    
    if (!selection.branch) {
      return;
    }
    
    this.effects.drag.detach();

    applyToAll(selection.mesh, (mesh) => {
      if (mesh.material && mesh.material.emissive) {
        mesh.material.emissive.set(0x000000);
      }
    });

    this.state.tools.selection = {};
    this.resetUiState('right');

    this.forceUpdate();
  }

  getSelectionKey() {
    const {
      selection
    } = this.state.tools;

    if (selection && selection.branch) {
      return selection.branch.id;
    }

    return null;
  }

  parseSelectionParam(value, { parseValue, parseImage, variable, files }) {
    let parsedValue = value;

    if (value && parseImage) {
      if (files && files.length && typeof window !== 'undefined') {
        parsedValue = window.URL.createObjectURL(files[0]);
      } else {
        parsedValue = '';
      }
    }

    if (value && parseValue) {
      parsedValue = JSON.parse(value);
    }

    if (value === SAFE_UNDEFINED) {
      parsedValue = undefined;
    }

    if (typeof parsedValue === 'string' && variable !== true) {
      parsedValue = encodeURIComponent(parsedValue);
    }

    return parsedValue;
  }

  updateSelectionParam(prop, event, { parseValue, parseImage, variable, propIndex, skipUpdate } = {}) {
    if (event.preventDefault) {
      event.preventDefault();
    }

    const { target } = event;
    const { value } = target;
    const { branch, mesh } = this.state.tools.selection;

    if (!branch || !mesh) {
      return;
    }

    const parsedValue = this.parseSelectionParam(value, { parseValue, parseImage, variable, files: target.files });

    if (typeof propIndex === 'undefined') {
      branch[prop] = parsedValue;
    } else {
      if (!(branch[prop] instanceof Array)) {
        branch[prop] = [];
      }

      branch[prop][propIndex] = parsedValue;
    }

    if (!skipUpdate) {
      branch._dirty = 2;

      this.saveSchema();
      this.forceUpdate();
    }
  }

  syncSelectionMesh() {
    const { selection } = this.state.tools;

    if (!selection.branch || !selection.mesh) {
      return;
    }

    selection.branch.positionX = selection.mesh.position.x;
    selection.branch.positionY = selection.mesh.position.y;
    selection.branch.positionZ = selection.mesh.position.z;

    selection.branch.rotationX = selection.mesh.rotation.x;
    selection.branch.rotationY = selection.mesh.rotation.y;
    selection.branch.rotationZ = selection.mesh.rotation.z;

    selection.mesh.updateMatrix();

    selection.branch._dirty = 1;

    this.saveSchema();
    this.forceUpdate();
  }

  onRemoveSelection(requireConfirm = true) {
    const {
      selection
    } = this.state.tools;

    if (typeof window === 'undefined' || !selection.branch) {
      return;
    }

    if (requireConfirm) {
      const confirm = window.confirm(`Remove selected object?`);

      if (!confirm) {
        return;
      }
    }

    const { branch, mesh } = selection;
    const parent = this.getSelectionParent();

    if (!parent) {
      return;
    }

    parent.content = parent.content.filter(item => item.id !== branch.id);
    mesh.parent.remove(mesh);
    
    this.saveSchema();
    this.clearSelection();
  }

  onToggleSelectionLock() {
    const {
      selection
    } = this.state.tools;

    if (typeof window === 'undefined' || !selection.branch) {
      return;
    }

    selection.branch._locked = !selection.branch._locked;

    this.effects.drag.detach();

    this.forceUpdate();
    this.saveSchema();
  }

  getSelectionParent() {
    const {
      selection
    } = this.state.tools;
    const schema = StateService.getSchema(this.state.project);

    if (!selection.branch || !schema) {
      return;
    }

    const { branch: target } = selection;

    let parent;

    const searchContent = (branch) => {
      if (parent) {
        return;
      }

      if (branch.content && branch.content instanceof Array) {
        if (branch.content.includes(target)) {
          parent = branch;
          return true;
        } else {
          return branch.content.some(searchContent);
        }
      }
      return;
    };

    schema.some(searchContent);

    return parent;
  }

  onDuplicateSelection() {
    const {
      selection
    } = this.state.tools;

    if (typeof window === 'undefined' || !selection || !selection.branch) {
      return;
    }

    const { branch } = selection;

    const objectProps = models[branch.modelId || 'debug-sphere'];

    this.floor.branch.content.push({
      id: `model-${Math.random() * Date.now()}`,
      modelId: branch.modelId,
      type: 'object',
      positionX: branch.positionX + branch.size[0] * 10,
      positionY: branch.positionY,
      positionZ: branch.positionZ,
      size: [ ...branch.size ],
      measurements: [ ...branch.measurements ],
      ...objectProps,
    });

    this.forceUpdate();
    this.clearSelection();
  }

  awaitFonts() {
    if (typeof document !== 'undefined' && document.onreadystatechange) {
      document.onreadystatechange = () => {
        if (document.readyState === 'complete') {
          this.forceUpdate();
        }
      }
    }
  }

  initScene() {
    if (typeof window === 'undefined' || !this.sceneRef.current) {
      return;
    }

    const schema = StateService.getSchema(this.state.project);

    this.active = 1;

    this.createScene();

    parseSchema(schema, this.getSchemaContext());
  }

  changeRenderQuality(quality = 1) {
    this.active = 0;
    this.renderQuality = quality;

    this.composer = null;

    // if (this.controls) {
    //   this.controls.dispose();
    //   this.effects.drag.dispose();
    // }
    // this.controls = null;

    // if (this.camera && this.camera.parent) {
    //   this.camera.parent.remove(this.camera);
    // }
    // this.camera = null;

    // this.raycaster = null;

    // if (this.renderer) {
    //   this.renderer.dispose();
    // }
    // this.renderer = null;

    if (this.floor && this.floor.parent) {
      this.floor.parent.remove(this.floor);
    }
    this.floor = null;

    if (this.cursor && this.cursor.parent) {
      this.cursor.parent.remove(this.cursor);
    }
    this.cursor = null;

    if (this.sceneRoot) {
      this.sceneRoot.parent.remove(this.sceneRoot);
    }
    this.sceneRoot = null;

    if (this.scene) {
      this.scene.dispose();
    }
    this.scene = null;

    this.initScene();
  }

  getSchemaContext() {
    return {
      renderer: this.renderer,
      scene: this.scene,
      camera: this.camera,
      controls: this.controls,
      root: this.sceneRoot,
      effects: this.effects,
      renderQuality: this.renderQuality,
      renderWalls: this.state.debug.renderWalls,
      resyncSelectionMesh: (newMesh) => {
        this.state.tools.selection.mesh = newMesh;  
      }
    };
  }

  createScene() {
    if (!this.sceneRef.current || this.active !== 1) {
      return;
    }
    
    if (!this.scene) {
      this.scene = new Three.Scene();

      if (this.renderQuality >= 2) {
        let shadowShader = Three.ShaderChunk.shadowmap_pars_fragment;
        shadowShader = shadowShader.replace('#ifdef USE_SHADOWMAP', '#ifdef USE_SHADOWMAP' + VertexShaderPCSS);
        shadowShader = shadowShader.replace('#if defined( SHADOWMAP_TYPE_PCF )', FragmentShaderPCSS + '#if defined( SHADOWMAP_TYPE_PCF )');
        Three.ShaderChunk.shadowmap_pars_fragment = shadowShader;
      }

      this.sceneRoot = new Three.Group();
      this.scene.add(this.sceneRoot);
    }

    if (!this.renderer) {
      this.renderer = new Three.WebGLRenderer({
        antialias: true
      });
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.renderer.toneMapping = Three.ACESFilmicToneMapping;
      this.renderer.toneMappingExposure = 1;
      this.renderer.outputEncoding = Three.sRGBEncoding;
      this.renderer.shadowMap.enabled = true;

      this.stats = new ThreeStats();
      this.sceneRef.current.appendChild(this.stats.dom);

      requestAnimationFrame(() => this.onAnimationStep());
    }

    if (!this.raycaster) {
      this.raycaster = new Three.Raycaster();
    }

    if (!this.camera) {
      this.camera = new Three.PerspectiveCamera(
        40,
        window.innerWidth / window.innerHeight,
        1,
        2000
      );
      this.camera.position.set(0, 40, 140);

      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.enablePan = true;
      this.controls.enableDamping = true;
      this.controls.enableRotate = true;
      this.controls.dampingFactor = 0.2;
      this.controls.minDistance = 2.0;
      this.controls.maxDistance = 200.0;
      this.controls.enableKeys = true;
      this.controls.keyPanSpeed = 100.0;
      this.controls.panSpeed = 1.0;

      const dragControls = new TransformControls(this.camera, this.renderer.domElement);
      dragControls.setMode('translate');
      dragControls.showY = false;
      dragControls.addEventListener('dragging-changed', (event) => {
        this.controls.enabled = !event.value;
      });
      dragControls.addEventListener('mouseUp', (event) => {
        this.syncSelectionMesh();
      });
      this.scene.add(dragControls);

      this.effects.drag = dragControls;
    }

    if (!this.composer) {
      this.composer = new EffectComposer(this.renderer);

      const renderPass = new RenderPass(this.scene, this.camera);
      this.composer.addPass(renderPass);

      if (this.renderQuality > 1) {
        const saoPass = new SAOPass(this.scene, this.camera, false, true);
        saoPass.params.saoBias = 1.0;
        saoPass.params.saoIntensity = 1;
        saoPass.params.saoScale = 1000.0;
        saoPass.params.saoKernelRadius = 20.0;
        saoPass.params.saoMinResolution = 0.0;
        saoPass.params.saoBlur = true;
        saoPass.params.saoBlurRadius = 5.0;
        saoPass.params.saoBlurStdDev = 4.0;
        saoPass.params.saoBlurDepthCutoff = 0.01;
        this.composer.addPass(saoPass);
      }

      if (this.renderQuality >= 2) {
        const fxaaEffect = new ShaderPass(FXAAShader);
        fxaaEffect.uniforms.resolution.value.set(1 / window.innerWidth, 1 / window.innerHeight);
        this.composer.addPass(fxaaEffect);
        this.effects.fxaa = fxaaEffect;
      }

      this.sceneRef.current.innerHTML = '';
      this.sceneRef.current.appendChild(this.renderer.domElement);
    }

    if (!this.cursor) {
      const cursor = parseCursor(this.getSchemaContext());
      this.scene.add(cursor);
      this.cursor = cursor;
    }

    this.active = 2;
  }

  onWindowResize() {
    if (this.active === 2) {
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();

      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.composer.setSize(window.innerWidth, window.innerHeight);

      if (this.effects.fxaa) {
        this.effects.fxaa.uniforms.resolution.value.set(1 / window.innerWidth, 1 / window.innerHeight);
      }
    }

    this.forceUpdate();
  }

  onAnimationStep() {
    if (this.active !== 2) {
      return;
    }

    this.stats.begin();

    this.controls.update();

    if (this.composer) {
      this.composer.render();
    } else {
      this.renderer.render(this.scene, this.camera);
    }

    this.stats.end();

    if (this.active !== 2 || this.state.debug.pauseRender) {
      return;
    }

    requestAnimationFrame(() => this.onAnimationStep());
  }

  findMeshParent(mesh, prop, value) {
    if (mesh === this.sceneRoot) {
      return;
    }

    if (mesh[prop] === value) {
      return mesh;
    }

    if (mesh.parent) {
      return this.findMeshParent(mesh.parent, prop, value);
    }
  }

  onHover(mesh, selectionTargetOnly = true) {
    const { hover, contextTool } = this.state.tools;
    
    if (!mesh) {
      return;
    }
    
    const selectionTarget = this.findMeshParent(mesh, 'selectionTarget', true);
    
    if (!selectionTarget ) {
      return;
    }
    
    const { name, parent } = selectionTarget;
    let escapedName = parent ? name.substr(parent.name.length + 1) : name;
    const branch = StateService.findElementById(this.state.project, escapedName);
    
    if (!branch) {
      return;
    }
    
    const unhoverIsSelected = this.state.tools.selection.mesh === hover.mesh ||
      (contextTool.id === ContextTools.paint_env && contextTool.active);

    applyToAll(hover.mesh, (mesh) => {
      if (mesh.material && mesh.material.emissive) {
        mesh.material.emissive.set(unhoverIsSelected ? 0xaaaaaa : 0x000000);
      }
    });
    
    this.state.tools.hover = {
      branch: branch,
      mesh: selectionTargetOnly ? selectionTarget : mesh
    };

    applyToAll(selectionTargetOnly ? selectionTarget : mesh, (mesh) => {
      if (mesh.material && mesh.material.emissive) {
        mesh.material.emissive.set(0x333333);
      }
    });

    if (this.sceneRef.current) {
      this.sceneRef.current.style.cursor = 'pointer';
    }
  }

  clearHover() {
    const { hover } = this.state.tools;
    const { contextTool } = this.state.tools;
    
    if (!hover.branch) {
      return;
    }

    const unhoverIsSelected = this.state.tools.selection.mesh === hover.mesh ||
      (contextTool.id === ContextTools.paint_env && contextTool.active);

    applyToAll(hover.mesh, (mesh) => {
      if (mesh.material && mesh.material.emissive) {
        mesh.material.emissive.set(unhoverIsSelected ? 0xaaaaaa : 0x000000);
      }
    });

    this.state.tools.hover = {};

    if (this.sceneRef.current) {
      this.sceneRef.current.style.cursor = 'default';
    }

    this.forceUpdate();
  }

  updateCursorPosition() {
    if (!this.floor) {
      const floorBranch = StateService.findElementByParam(this.state.project, 'type', 'floor');

      if (floorBranch && this.sceneRoot) {
        const floorMesh = this.sceneRoot.getObjectByProperty('branchId', floorBranch.id);

        if (floorMesh) {
          this.floor = {
            branch: floorBranch,
            mesh: floorMesh
          };
        }
      }
    }
    
    if (this.floor) {
      if (this.floor && this.cursor) {
        const { contextTool } = this.state.tools;
        const floorPosition = this.raycaster.intersectObject(this.floor.mesh.getObjectByName('_ca-ground-mesh'), false);

        if (floorPosition && floorPosition[0]) {
          const floorX = floorPosition[0].point.x;
          const floorZ = floorPosition[0].point.z;
          const gridSnapFn = contextTool.gridSnapFn ? Math[contextTool.gridSnapFn] : Math.round

          if (contextTool.gridSnap === 1) {
            this.cursor.position.set(floorX, 0, floorZ);
          } else {
            this.cursor.position.set(gridSnapFn(floorX / contextTool.gridSnap) * contextTool.gridSnap, 0, gridSnapFn(floorZ / contextTool.gridSnap) * contextTool.gridSnap);
          }

          this.cursor.position.add(new Three.Vector3(contextTool.gridShiftX, 0, contextTool.gridShiftZ));
        }
      }
    }
  }

  onPaintElement() {
    const { hover, contextTool } = this.state.tools;

    if (hover && hover.mesh) {
      const { branch, mesh } = hover;
      const { paintMaterial } = contextTool;

      if (branch.type === 'wall') {
        if (mesh.name === 'sideA' && branch.materialA !== paintMaterial) {
          branch.materialA = paintMaterial;
          branch._dirty = 2;

          applyToAll(mesh, (mesh) => {
            if (mesh.material && mesh.material.emissive) {
              mesh.material.emissive.set(0x333333);
            }
          });
        } else if (mesh.name === 'sideB' && branch.materialB !== paintMaterial) {
          branch.materialB = paintMaterial;
          branch._dirty = 2;

          applyToAll(mesh, (mesh) => {
            if (mesh.material && mesh.material.emissive) {
              mesh.material.emissive.set(0x333333);
            }
          });
        }
      } else if (branch.type === 'tile' && branch.material !== paintMaterial) {
        branch.material = paintMaterial;
        branch._dirty = 2;

        applyToAll(mesh, (mesh) => {
          if (mesh.material && mesh.material.emissive) {
            mesh.material.emissive.set(0x333333);
          }
        });
      }
    }
  }

  onMouseMove(event) {
    if (this.active !== 2) {
      return;
    }
    
    const { contextTool } = this.state.tools;

    let x, y;

    if (event.changedTouches) {
      x = event.changedTouches[0].pageX;
      y = event.changedTouches[0].pageY;
    } else {
      x = event.clientX;
      y = event.clientY;
    }

    x = (x / window.innerWidth) * 2 - 1;
    y = -(y / window.innerHeight) * 2 + 1;

    this.raycaster.setFromCamera(new Three.Vector2(x, y), this.camera);

    this.updateCursorPosition();

    if (this.state.debug.pauseSelection === 1) {
      this.state.debug.pauseSelection = 2;
    }

    if (contextTool.id === ContextTools.select && this.state.debug.pauseSelection !== 2) {
      const intersects = this.raycaster.intersectObjects([ this.scene ], true);

      if (intersects.length > 0) {
        const firstSelectable = intersects.find(({ object }) => object.selectable);

        if (firstSelectable) {
          this.onHover(firstSelectable.object, true);
        } else {
          this.clearHover();
        }
      } else {
        this.clearHover();
      }
    } else if (contextTool.id === ContextTools.paint_env && this.state.debug.pauseSelection !== 2) {
      const intersects = this.raycaster.intersectObjects([ this.scene ], true);

      if (intersects.length > 0) {
        const firstSelectable = intersects.find(({ object }) => object.selectable && (object.name === 'sideA' || object.name === 'sideB'));

        if (firstSelectable) {
          this.onHover(firstSelectable.object, false);

          if (contextTool.active) {
            this.onPaintElement();
          }
        } else {
          this.clearHover();
        }
      } else {
        this.clearHover();
      }
    } else if (contextTool.id === ContextTools.place_env) {
      const previewColor = new Three.Color(0xffffff);

      if (contextTool.lastPosition && contextTool.active) {
        const { x: startX, z: startZ } = this.cursor.position;
        const { x: endX, z: endZ } = contextTool.lastPosition;
        const { mode } = contextTool;
        const parseFn = ({
          'wall': parseWall,
          'tile': parseTile
        })[contextTool.envType];

        if (startX !== endX || startZ !== endZ) {
          const previewGroupMesh = this.floor.mesh.getObjectByProperty('branchId', contextTool.group.id);

          if (!mode || mode === 'remove') {
            const lastPlacedWall = contextTool.group.content[contextTool.group.content.length - 1];

            // if (lastPlacedWall && startX === lastPlacedWall.positionX && startZ === lastPlacedWall.positionZ) {
            //   const removedWall = contextTool.group.content.splice(contextTool.group.content.length - 1, 1)[0];

            //   previewGroupMesh.remove(previewGroupMesh.getObjectByName(removedWall.id));
            // } else {
              if (!this.floor.branch.content) {
                this.floor.branch.content = [];
              }

              const dx = endX - startX;
              const dz = endZ - startZ;
              const angle = Math.atan2(dz, -dx);
              const wallId = `${contextTool.envType}-${contextTool.group.content.length}`;

              const wallSchema = {
                id: wallId,
                type: contextTool.envType,
                positionX: endX,
                positionZ: endZ,
                endX: startX,
                endZ: startZ,
                color: previewColor,
                angle: angle,
                previewOnly: true,
                archX1: contextTool.archX1,
                archY1: contextTool.archY1,
                archX2: contextTool.archX2,
                archY2: contextTool.archY2
              };
    
              const previewWall = parseFn(wallSchema, {
                ...this.getSchemaContext(),
                renderQuality: 0
              }, {
                parent: previewGroupMesh,
                _selfKey: wallId
              });

              if (previewWall) {
                previewWall.name = wallId;
                contextTool.group.content.push(wallSchema);
              }
            // }

            contextTool.lastPosition = this.cursor.position.clone();
          } else if (mode === 'bulk') {
            if (!this.floor.branch.content) {
              this.floor.branch.content = [];
            }

            previewGroupMesh.children.forEach(child => previewGroupMesh.remove(child));
            contextTool.group.content = [];

            if (contextTool.fillBulk) {
              const sideMinZ = Math.min(startZ, endZ);
              const sideMaxZ = Math.max(startZ, endZ);
              const sideMinX = Math.min(startX, endX);
              const sideMaxX = Math.max(startX, endX);

              for (let sideZ = sideMinZ; sideZ <= sideMaxZ; sideZ += 10) {
                for (let sideX = sideMinX; sideX <= sideMaxX; sideX += 10) {
                  const wallId = `${contextTool.envType}-${contextTool.group.content.length}`;
                  const wallSchema = {
                    id: wallId,
                    type: contextTool.envType,
                    positionX: sideX,
                    positionZ: sideZ,
                    endX: sideX + 10,
                    endZ: sideZ + 10,
                    color: previewColor,
                    angle: -Math.PI / 2,
                    previewOnly: true
                  };
        
                  const previewWall = parseFn(wallSchema, {
                    ...this.getSchemaContext(),
                    renderQuality: 0
                  }, {
                    parent: previewGroupMesh,
                    _selfKey: wallId
                  });
                  previewWall.name = wallId;
                  contextTool.group.content.push(wallSchema);
                }
              }
            } else {
              let sideMin = Math.min(startZ, endZ);
              let sideMax = Math.max(startZ, endZ);

              for (let sideZ = sideMin; sideZ < sideMax; sideZ += 10) {
                [startX, endX].forEach((sideX, index) => {
                  if (endX === startX) {
                    return;
                  }

                  const wallId = `${contextTool.envType}-${contextTool.group.content.length}`;
                  const shift = index ? 10 : 0;

                  const wallSchema = {
                    id: wallId,
                    type: contextTool.envType,
                    positionX: sideX,
                    positionZ: sideZ + shift,
                    endX: sideX,
                    endZ: sideZ + 10 + shift,
                    color: previewColor,
                    angle: index ? Math.PI / 2 : -Math.PI / 2,
                    previewOnly: true
                  };
        
                  const previewWall = parseFn(wallSchema, {
                    ...this.getSchemaContext(),
                    renderQuality: 0
                  }, {
                    parent: previewGroupMesh,
                    _selfKey: wallId
                  });
                  previewWall.name = wallId;
                  contextTool.group.content.push(wallSchema);
                });
              }
  
              sideMin = Math.min(startX, endX);
              sideMax = Math.max(startX, endX);
  
              for (let sideX = sideMin; sideX < sideMax; sideX += 10) {
                [startZ, endZ].forEach((sideZ, index) => {
                  if (endZ === startZ) {
                    return;
                  }

                  const wallId = `${contextTool.envType}-${contextTool.group.content.length}`;
                  const shift = index ? 0 : 10;
  
                  const wallSchema = {
                    id: wallId,
                    type: contextTool.envType,
                    positionX: sideX + shift,
                    positionZ: sideZ,
                    endX: sideX + 10 + shift,
                    endZ: sideZ,
                    color: previewColor,
                    angle: index ? 0 : -Math.PI,
                    previewOnly: true
                  };
        
                  const previewWall = parseFn(wallSchema, {
                    ...this.getSchemaContext(),
                    renderQuality: 0
                  }, {
                    parent: previewGroupMesh,
                    _selfKey: wallId
                  });
                  previewWall.name = wallId;
                  contextTool.group.content.push(wallSchema);
                });
              }
            }
          }
        }
      }
    }
  }

  onMouseDown(event) {
    if (this.active !== 2) {
      return;
    }

    const { contextTool } = this.state.tools;
    const { shiftKey, ctrlKey, altKey, metaKey } = event;
    const specAction = shiftKey || ctrlKey || metaKey;
    const altAction = altKey;

    this.state.debug.pauseSelection = 1;

    if (contextTool.id === ContextTools.place_env) {
      contextTool.lastPosition = this.cursor.position.clone();
      contextTool.active = true;
      contextTool.group = {
        id: `env-place--preview-group`,
        type: 'group',
        positionX: 0,
        positionY: 0,
        positionZ: 0,
        content: []
      };

      if (specAction) {
        contextTool.mode = 'bulk';
      } else if (altAction) {
        contextTool.mode = 'remove';
      } else {
        contextTool.mode = null;
      }

      this.floor.branch.content.push(contextTool.group);
      this.forceUpdate();
    } else if (contextTool.id === ContextTools.paint_env) {
      contextTool.active = true;
      this.state.debug.pauseSelection = 0;

      this.forceUpdate();
    }
  }

  onMouseUp(event) {
    if (this.active !== 2) {
      return;
    }

    const { contextTool } = this.state.tools;

    setTimeout(() => {
      this.state.debug.pauseSelection = 0;
    }, 1);

    if (contextTool.id === ContextTools.place_env) {
      contextTool.lastPosition = null;
      contextTool.active = false;

      if (contextTool.mode !== 'remove') {
        this.floor.branch.content.push(...(contextTool.group.content).map(branch => ({
          ...branch,
          previewOnly: false,
          id: `${contextTool.envType}-${Math.random() * Date.now()}`
        })));
        this.floor.branch.content = this.floor.branch.content.filter(object => object.id !== contextTool.group.id);
        this.floor.mesh.remove(this.floor.mesh.getObjectByProperty('branchId', contextTool.group.id));
        contextTool.group = null;

        if (contextTool.addTrailingEnv) {
          if (!contextTool.mode) {
            this.floor.branch.content.push({
              id: `${contextTool.envType}-${Math.random() * Date.now()}`,
              type: contextTool.envType,
              positionX: this.cursor.position.x,
              positionZ: this.cursor.position.z,
              color: new Three.Color(0xffffff),
              angle: 0
            });
          }
        }

        this.forceUpdate();
      } else {
        if (contextTool.addTrailingEnv) {
          contextTool.group.content.push({
            positionX: this.cursor.position.x,
            positionZ: this.cursor.position.z
          });
        }

        (contextTool.group.content).forEach((branch) => {
          const branchFitIndex = this.floor.branch.content
            .findIndex(testBranch =>
              ((testBranch.positionX === branch.positionX && testBranch.positionZ === branch.positionZ) ||
              (testBranch.endX === branch.positionX && testBranch.endZ === branch.positionZ)) &&
              testBranch.type === contextTool.envType);

          if (branchFitIndex !== -1) {
            const branchFit = this.floor.branch.content.splice(branchFitIndex, 1)[0];

            this.floor.mesh.remove(this.floor.mesh.getObjectByProperty('branchId', branchFit.id));
          }
        });
        this.floor.branch.content = this.floor.branch.content.filter(object => object.id !== contextTool.group.id);
        this.floor.mesh.remove(this.floor.mesh.getObjectByProperty('branchId', contextTool.group.id));
        contextTool.group = null;

        this.forceUpdate();
      }
    } else if (contextTool.id === ContextTools.paint_env) {
      contextTool.active = false;

      this.forceUpdate();
    }
  }

  onClick(event) {
    if (this.active !== 2) {
      return;
    }

    const { hover, contextTool } = this.state.tools;

    if (this.state.debug.pauseSelection === 2) {
      return;
    }

    if (contextTool.id === ContextTools.select) {
      if (hover && hover.mesh) {
        this.onSelection(hover);

        this.state.tools.hover = {};
      } else {
        this.clearSelection();
      }
    } else if (contextTool.id === ContextTools.paint_env) {
      this.onPaintElement();
    } else if (contextTool.id === ContextTools.place) {
      if (!this.floor.branch.content) {
        this.floor.branch.content = [];
      }

      const objectProps = models[contextTool.modelId || 'debug-sphere'];
      const objectDetails = objectProps.details;

      this.floor.branch.content.push({
        id: `model-${Math.random() * Date.now()}`,
        modelId: contextTool.modelId,
        type: 'object',
        positionX: this.cursor.position.x,
        positionY: 2.5,
        positionZ: this.cursor.position.z,
        size: objectDetails.sizeOptions[objectProps.sizeId || 0],
        measurements: objectDetails.measurementOptions[objectProps.sizeId || 0],
        ...objectProps,
      });
      this.forceUpdate();
    }
  }

  addResizeListener() {
    if (typeof window === 'undefined') {
      return;
    }

    window.removeEventListener('resize', this.onWindowResize.bind(this));
    window.addEventListener('resize', this.onWindowResize.bind(this));
  }

  componentDidMount() {
    EditorService.refreshAuth();

    this.addResizeListener();

    const config = StateService.getConfig();

    if (config && config.initialVariables) {
      Object.keys(config.initialVariables).forEach(key => {
        VariablesService.setVar(key, config.initialVariables[key]);
      });
    }

    this.awaitFonts();
    this.initScene();
  }

  componentDidUpdate() {
    const { selection } = this.state.tools;

    if (selection && selection.branch && selection.branch._locked) {
      this.effects.drag.detach();
    } else if (selection.mesh) {
      this.effects.drag.attach(selection.mesh);
    }

    this.initScene();
  }

  componentWillUnmount() {
    this.active = 0;
  }

  renderCustomFonts() {
    const config = StateService.getConfig();

    return (<>
      {(config.fonts || []).map((font, index) => {
        if (font.url.match(/https?\:\/\/fonts.googleapis.com\//)) {
          return (
            <link
              href={font.url}
              rel="stylesheet"
            />
          );
        } else {
          return (
            <style dangerouslySetInnerHTML={{ __html: `
              @font-face {
                font-family: ${font.name ? font.name : `Project Font ${index + 1}`};
                src: url(${font.url}) format(${({
                  'ttf': '"truetype"',
                  'otf': '"opentype"',
                  'woff': '"woff"',
                  'woff2': '"woff2"'
                })[font.url.split('.').splice(-1, 1)[0]]});
              }
            ` }}></style>
          );
        }
      })}
    </>);
  }

  onMouseEnter() {
    requestAnimationFrame(() => this.onAnimationStep());

    this.setState({
      ...this.state,
      debug: {
        ...this.state.debug,
        pauseRender: false
      }
    });
  }

  onMouseLeave() {
    this.setState({
      ...this.state,
      debug: {
        ...this.state.debug,
        pauseRender: true
      }
    });
  }

  changeCursor(cursorOrNone = '', imageOrSvg = 'image', iconUrl, iconShortname = '') {
    this.cursor.children.forEach(mesh => mesh.visible = false);
    this.sceneRef.current.style.cursor = '';

    if (cursorOrNone) {
      const defaultCursor = this.cursor.getObjectByName(`_ca-cursor-default`);
      defaultCursor.visible = true;
      const targetCursor = this.cursor.getObjectByName(`_ca-cursor-${cursorOrNone}`);
      targetCursor.visible = true;

      const targetCursorIcon = defaultCursor.getObjectByName('icon');
      const targetCursorBackground = defaultCursor.getObjectByName('background');

      if (targetCursorIcon) {
        const originalMaterial = targetCursorIcon.material;
        const iconsContainer = defaultCursor.getObjectByName('context-icons');

        iconsContainer.children.forEach(mesh => {
          mesh.visible = false;
        });

        if (imageOrSvg === 'image') {
          originalMaterial.dispose();

          targetCursorBackground.visible = true;
          targetCursorIcon.visible = true;
          targetCursorIcon.material = new Three.MeshBasicMaterial({
            map: loadTexture(iconUrl),
            transparent: true,
            color: 0xffffff,
            toneMapped: false
          });
        } else if (imageOrSvg === 'svg') {
          originalMaterial.dispose();
          targetCursorIcon.visible = false;

          const existingSvgIcon = iconsContainer.getObjectByName(`context-icon-${iconShortname}`);

          if (existingSvgIcon) {
            existingSvgIcon.visible = true;
          } else {
            modelLoader.svg().load(iconUrl, (model) => {
              model.name = `context-icon-${iconShortname}`;
              model.scale.set(.05, .05, 0);
              model.position.set(-2.5, -1.0, .1);

              iconsContainer.add(model);
            });
          }
        }
      }
    }

    requestAnimationFrame(() => this.onAnimationStep());
  }

  onContextToolChanged(toolId, settings) {
    const defaultSettings = {
      gridSnap: 1,
      gridShiftX: 0,
      gridShiftY: 0,
      gridShiftZ: 0,
      lockPan: false,
      lockRotate: false,
      showCursor: false,
      addTrailingEnv: false,
      fillBulk: false
    };

    this.state.tools.contextTool = {
      ...defaultSettings,
      ...settings,
      id: toolId
    };

    this.controls.enablePan = !this.state.tools.contextTool.lockPan;
    this.controls.enableRotate = !this.state.tools.contextTool.lockRotate;

    if (settings.modelId) {
      this.changeCursor(
        this.state.tools.contextTool.showCursor,
        'image',
        models[settings.modelId].previewUrl,
        settings.modelId
      );
    } else if (settings.envType) {
      this.changeCursor(
        this.state.tools.contextTool.showCursor,
        'svg',
        ({
          'wall': CursorBuildWallSVG,
          'arch': CursorBuildWallSVG,
          'tile': CursorBuildTileSVG
        })[settings.envType],
        settings.envType
      );
    } else {
      this.changeCursor(this.state.tools.contextTool.showCursor);
    }

    this.clearSelection();
    this.forceUpdate();
  }

  isSelectionType(type) {
    const { selection } = this.state.tools;

    if (!selection.branch) {
      return false;
    }

    return selection.branch.type === type;
  }

  getUiState(side) {
    const { uiLeft, uiRight } = this.state;

    if (side === 'left') {
      return uiLeft[uiLeft.length - 1] || {};
    } else {
      return uiRight[uiRight.length - 1] || {};
    }
  }

  resetUiState(side) {
    if (side === 'left') {
      this.state.uiLeft = [];
    } else {
      this.state.uiRight = [];
    }

    this.forceUpdate();
  }

  popUiState(side) {
    const { uiLeft, uiRight } = this.state;

    if (side === 'left') {
      if (uiLeft.length < 0) {
        return;
      }

      uiLeft.splice(uiLeft.length - 1, 1);
    } else {
      if (uiRight.length < 0) {
        return;
      }

      uiRight.splice(uiRight.length - 1, 1);
    }

    this.forceUpdate();
  }

  pushUiState(side, offsetX = 0, offsetY = 0, active = true, otherUiProps = {}) {
    const { uiLeft, uiRight } = this.state;

    if (side === 'left') {
      this.setState({ ...this.state, uiLeft: [
        ...uiLeft,
        {
          knobX: offsetX,
          knobY: offsetY,
          knobActive: active,
          ...otherUiProps
        }
      ]});
    } else {
      this.setState({ ...this.state, uiRight: [
        ...uiRight,
        {
          knobX: offsetX,
          knobY: offsetY,
          knobActive: active,
          ...otherUiProps
        }
      ]});
    }
  }

  toggleViewMode(mode) {
    this.state.debug.pauseRender = false;
    this.state.debug.viewMode = mode;
    this.state.debug.viewModeTransition = true;
    this.onAnimationStep();

    if (mode === ViewMode.blueprint) {
      this.controls.enabled = false;
      this.forceUpdate();

      tweenTo(this.camera.position.y, 300.0, 1500, tweenFunctions.easeInQuad, (value, done) => {
        this.camera.position.y = value;
        this.controls.update();

        if (done) {
          this.state.debug.pauseRender = true;
          this.state.debug.showBlueprints = true;
          this.state.debug.viewModeTransition = false;
          this.forceUpdate();
        }
      });
    } else if (mode === ViewMode.view3d || mode === ViewMode.render) {
      this.controls.enabled = false;
      this.state.debug.showBlueprints = false;
      this.forceUpdate();

      tweenTo(this.camera.position.y, 40, 1500, tweenFunctions.easeInQuad, (value, done) => {
        this.camera.position.y = value;
        this.camera.position.z += 10.0;
        this.controls.update();

        if (done) {
          this.controls.enabled = true; 
          this.state.debug.pauseRender = false;
          this.state.debug.viewModeTransition = false;
          this.forceUpdate();
        }
      });
    }
  }

  render() {
    if (!EditorService.isLoggedIn()) {
      EditorService.refreshAuth(async () => {
        this.forceUpdate();

        await StateService.fetchProject();

        this.forceUpdate();
      });

      return null;
    }

    const { tools, panels, debug, uiLeft, uiRight, project } = this.state;
    const schema = StateService.getSchema(project);
    const { selection, contextTool } = tools;
    const projects = StateService.getSchemaPages();
    const config = StateService.getConfig();
    const uiStateLeft = this.getUiState('left');
    const uiStateRight = this.getUiState('right');

    const isSelectionType = this.isSelectionType.bind(this);

    if (this.renderer) {
      this.renderer.renderLists.dispose();
    }

    if (this.effects.drag) {
      if (uiStateRight.editRotation) {
        this.effects.drag.setMode('rotate');
        this.effects.drag.showY = true;
        this.effects.drag.showZ = false;
        this.effects.drag.showX = false;
      } else {
        this.effects.drag.setMode('translate');
        this.effects.drag.showY = false;
        this.effects.drag.showZ = true;
        this.effects.drag.showX = true;
      }
    }

    return (
      <AntWrapper style={{ minWidth: 1200 }}>
        <WtlGlobalKeyboardListener
          listeners={[
            {
              keys: ['shift'],
              on: () => {
                const { contextTool } = this.state.tools;
                
                if (contextTool && [ContextTools.place_env].includes(contextTool.id)) {
                  contextTool.mode = 'bulk';
                  this.changeCursor(
                    contextTool.showCursor,
                    'svg',
                    ({
                      'wall': CursorBuildWallShiftSVG,
                      'tile': CursorBuildTileShiftSVG
                    })[contextTool.envType],
                    `bulk-${contextTool.envType}`
                  );
                  this.forceUpdate();
                }
              },
              off: () => {
                const { contextTool } = this.state.tools;
                if (contextTool) {
                  contextTool.mode = '';
                  this.onContextToolChanged(contextTool.id, contextTool);
                  this.forceUpdate();
                }
              }
            },
            {
              keys: ['alt'],
              on: () => {
                const { contextTool } = this.state.tools;
                
                if (contextTool && [ContextTools.place_env].includes(contextTool.id)) {
                  contextTool.mode = 'remove';
                  this.changeCursor(
                    contextTool.showCursor,
                    'svg',
                    CursorBuildAltSVG,
                    'remove'
                  );
                  this.forceUpdate();
                }
              },
              off: () => {
                const { contextTool } = this.state.tools;

                if (contextTool) {
                  contextTool.mode = '';
                  this.onContextToolChanged(contextTool.id, contextTool);
                  this.forceUpdate();
                }
              }
            },
            {
              keys: ['escape'],
              requireFocus: false,
              off: () => {
                const { contextTool } = this.state.tools;

                if (contextTool.id !== ContextTools.select) {
                  this.onContextToolChanged(ContextTools.select, {
                    gridSnap: 1,
                    showCursor: false
                  });
                  this.forceUpdate();
                } else {
                  this.popUiState('left');
                  this.popUiState('right');
                  this.forceUpdate();
                }
              }
            }
          ]}
          focusElement={this.sceneRef.current ? this.sceneRef.current.querySelector('canvas') : null}
        />
        {this.renderCustomFonts()}
        <Spin
          tip="Loading project..."
          style={{ height: '100vh' }}
          spinning={StateService.state.fetchingProject}
        >
          <Layout style={{ height: '100vh', boxSizing: 'border-box' }}>
            <CanvasContainer
              ref={this.sceneRef}
              key={2}
              style={{
                position: 'relative',
                maxWidth: '100%',
                maxHeight: '100%',
                width: '100%',
                height: '100%',
                pointerEvents: 'all',
                outline: 'none',
                backgroundColor: '#ff00ff',
                overflow: 'hidden'
              }}
              onMouseDown={(event) => {
                this.onMouseDown(event);
              }}
              onMouseUp={(event) => {
                this.onMouseUp(event);
              }}
              onMouseMove={(event) => {
                this.onMouseMove(event);
              }}
              onClick={(event) => {
                this.onClick(event);
              }}
              onMouseEnter={() => {
                this.onMouseEnter();
              }}
              onMouseLeave={() => {
                this.onMouseLeave();
              }}
            >
            </CanvasContainer>
            {debug.showBlueprints && (
              <CABlueprint
                schema={schema}
                camera={this.camera}
                floor={this.floor}
              />
            )}
          </Layout>
          <CALayout smoothEdges={false}>
            <CAPanel
              name="context-knob"
              top={ifWindow(window.innerHeight / 2 - 50 * 3, 100) + (uiStateLeft.knobY || 0) + 55}
              left={uiStateLeft.knobX || 65}
            >
              <CAKnob
                scale={0.75}
                icon="chevron-left"
                iconActive="chevron-right"
                toggled={!uiStateLeft.knobActive}
                onClick={() => {
                  if (uiLeft.length > 0) {
                    this.popUiState('left');
                  } else {
                    this.pushUiState(
                      'left',
                      215,
                      0,
                      true,
                      {
                        leftExpanded: true
                      }
                    );
                  }
                }}
              />
            </CAPanel>
            <CAPanel
              name="left-menu"
              top={ifWindow(window.innerHeight / 2 - 50 * 3, 100)}
              left={(uiLeft.length < 1 || uiStateLeft.leftExpanded) ? 15 : -200}
            >
              <div>
                {
                  uiStateLeft.leftExpanded ?
                  <CAMenuBlob onClick={() => {
                    this.pushUiState(
                      'left',
                      365,
                      -(ifWindow(window.innerHeight / 2, 0)) + 195,
                      true,
                      {
                        libraryModal: true
                      }
                    );
                  }}>
                    Library
                  </CAMenuBlob> :
                  <InvertedImage
                    onClick={() => {
                      this.pushUiState(
                        'left',
                        365,
                        -(ifWindow(window.innerHeight / 2, 0)) + 195,
                        true,
                        {
                          libraryModal: true
                        }
                      );
                    }}
                    src={LibraryIconSVG}
                    width="50"
                    height="50"
                    style={{ filter: 'invert(100%) drop-shadow(0px 1px 2px rgba(0, 0, 0, .1))', marginBottom: 5, cursor: 'pointer' }}  
                  />
                }
              </div>
              <div style={{ display: 'inline-flex' }}>
                {
                  uiStateLeft.leftExpanded ?
                  <CAMenuBlob onClick={() => {
                    this.pushUiState(
                      'left',
                      315,
                      0,
                      true,
                      {
                        envModal: true
                      }
                    );
                  }}>
                    Environment
                  </CAMenuBlob> :
                  <InvertedImage
                    onClick={() => {
                      this.pushUiState(
                        'left',
                        315,
                        0,
                        true,
                        {
                          envModal: true
                        }
                      );
                    }}
                    src={EnvironmentIconSVG}
                    width="50"
                    height="50"
                    style={{ filter: 'invert(100%) drop-shadow(0px 1px 2px rgba(0, 0, 0, .1))', marginBottom: 5, cursor: 'pointer' }}
                  />
                }
              </div>
              <div>
                {
                  uiStateLeft.leftExpanded ?
                  <CAMenuBlob onClick={() => {
                    this.pushUiState(
                      'left',
                      365,
                      0,
                      true,
                      {
                        toolsExpanded: true
                      }
                    );
                  }}>
                    Tools
                  </CAMenuBlob> :
                  <InvertedImage
                    onClick={() => {
                      this.pushUiState(
                        'left',
                        365,
                        0,
                        true,
                        {
                          toolsExpanded: true
                        }
                      );
                    }}
                    src={ToolsIconSVG}
                    width="50"
                    height="50"
                    style={{ filter: 'invert(100%) drop-shadow(0px 1px 2px rgba(0, 0, 0, .1))', marginBottom: 5, cursor: 'pointer' }}  
                  />
                }
              </div>
            </CAPanel>
            <CAPanel
              name="bottom-panel"
              bottom={0}
              left={0}
              style={{
                width: '100%',
                height: 65,
                backgroundColor: CAModalBackground
              }}
            >
              <CAPanelBody display="flex">
                <div>
                  {' '}
                </div>
                <div>
                  <CASmartToggle
                    label="Measurements"
                    options={[
                      { label: 'OFF', selected: debug.viewMode === ViewMode.view3d, onClick: () => {
                        
                      } },
                      { label: 'ON', selected: debug.viewMode === ViewMode.blueprint, onClick: () => {
                        
                      } }
                    ]}
                  />
                </div>
                <div style={{ display: 'flex' }}>
                  <CALabelRange
                    max={2}
                    min={0}
                    value={debug.renderWalls}
                    label="Walls"
                    onChange={value => {
                      this.state.debug.renderWalls = value;

                      StateService.traverse(project, (branch) => {
                        if (branch.type === 'wall') {
                          branch._dirty = 2;
                        }
                      });

                      this.forceUpdate();

                      setTimeout(() => {
                        this.onAnimationStep();
                      }, 1);
                    }}
                  />
                  <CASmartToggle
                    disabled={debug.viewModeTransition}
                    options={[
                      { label: '2D', selected: debug.viewMode === ViewMode.blueprint, onClick: () => {
                        this.toggleViewMode(ViewMode.blueprint);
                      } },
                      { label: '3D', selected: debug.viewMode === ViewMode.view3d, onClick: () => {
                        this.toggleViewMode(ViewMode.view3d);
                        this.changeRenderQuality(1);
                      } },
                      { label: 'HD', selected: debug.viewMode === ViewMode.view3d, onClick: () => {
                        this.toggleViewMode(ViewMode.view3d);
                        this.changeRenderQuality(2);
                      } },
                    ]}
                  />
                  <CALabelRange
                    max={debug.floorCount}
                    min={0}
                    value={debug.current}
                    label="Floor"
                    onChange={value => {
                      // TODO Change floor
                      {/* this.state.debug.renderWalls = value;
                      this.forceUpdate(); */}
                    }}
                  />
                </div>
                <div>
                  <CASmartToggle
                    label="Snapping"
                    options={[
                      { label: 'OFF', selected: debug.viewMode === ViewMode.view3d, onClick: () => {
                        
                      } },
                      { label: 'ON', selected: debug.viewMode === ViewMode.blueprint, onClick: () => {
                        
                      } }
                    ]}
                  />
                </div>
                <img
                  src={CALogo}
                  width={100}  
                />
              </CAPanelBody>
            </CAPanel>
            <CAPanel
              name="top-panel"
              top={15}
              left={15}
            >
              <CAMenuBlob
                options={[
                  {
                    label: 'Measurements & Options'
                  },
                  {
                    label: 'Participants'
                  }
                ]}
              >
                Project Properties
              </CAMenuBlob>
            </CAPanel>
            <CAPanel
              top={15}
              left={235}
            >
              <CAMenuBlob
                options={[
                  {
                    label: 'Invite'
                  },
                  {
                    label: 'Export to 3D'
                  },
                  {
                    label: 'Export to Image'
                  },
                  {
                    label: 'Social Media'
                  }
                ]}  
              >
                Share
              </CAMenuBlob>
            </CAPanel>
            <CAPanel
              top={15}
              left={440}
            >
              <CAMenuBlob style={{ marginLeft: 15 }}>
                Calculate
              </CAMenuBlob>
            </CAPanel>
            <CAPanel
              top={15}
              left={660}
            >
              <CAMenuBlob style={{ marginLeft: 15 }}>
                Save
              </CAMenuBlob>
            </CAPanel>
            <CAPanel
              name="right-knob"
              top={ifWindow(window.innerHeight / 2 - 50 * 3, 100) + (uiStateRight.knobY || 0) + 55}
              right={uiRight.length ? uiStateRight.knobX || 0 : -100}
            >
              <CAKnob
                scale={0.75}
                icon="chevron-right"
                iconActive="chevron-left"
                toggled={!uiStateRight.knobActive}
                onClick={() => {
                  if (uiRight.length > 1) {
                    this.popUiState('right');
                  } else {
                    this.clearSelection();
                  }
                }}
              />
            </CAPanel>
            <CAPanel
              name="selection-details-panel"
              top={ifWindow(window.innerHeight / 2 - ifWindow(window.innerHeight - 200, 300) / 2, 100)}
              right={uiStateRight.selectionDetails ? 15 : -500}
              style={{
                backgroundColor: CAModalBackground,
                borderRadius: CAModalBorderRadius,
                maxHeight: window.innerHeight - 200,
                overflowY: 'scroll',
                overflowX: 'hidden'
              }}
            >
              <CAObjectDetails
                object={selection.branch}
                updateSelectionParam={this.updateSelectionParam.bind(this)}
              />
            </CAPanel>
            <CAPanel
              name="selection-comments-panel"
              top={ifWindow(window.innerHeight / 2 - ifWindow(window.innerHeight - 200, 300) / 2, 100)}
              right={uiStateRight.selectionComments ? 15 : -500}
              style={{
                backgroundColor: CAModalBackground,
                borderRadius: CAModalBorderRadius
              }}
            >
              <CAComments
                object={selection.branch}
              />
            </CAPanel>
            <CAPanel
              name="selection-properties-panel"
              top={ifWindow(window.innerHeight / 2 - ifWindow(window.innerHeight - 200, 300) / 2, 100)}
              right={uiStateRight.selectionMenu ? 15 : -500}
              style={{
                backgroundColor: CAModalBackground,
                borderRadius: CAModalBorderRadius
              }}
            >
              <CAPanelBody style={{ overflow: 'visible' }} margin="4px 4px 0px 4px">
                {selection.branch && selection.branch.type === 'object' && (<div>
                  <CAMenuBlob onClick={() => {
                    this.pushUiState(
                      'right',
                      320,
                      -100,
                      true,
                      {
                        selectionDetails: true,
                        selectionMenu: false
                      }
                    );
                  }}>
                    Properties
                  </CAMenuBlob>
                </div>)}
                <div>
                  <CAMenuBlob onClick={() => this.onDuplicateSelection()}>
                    Duplicate
                  </CAMenuBlob>
                </div>
                <div>
                  <CAMenuBlob
                    autoClose={false}
                    expandedContent={(
                      <Row>
                        <CAVariableInput
                          wrapperProps={{
                            style: {
                              marginBottom: 10
                            }
                          }}
                          span={24}
                          inputType="angle"
                          value={selection.mesh && selection.mesh.rotation.y}
                          onChange={(value) => {
                            selection.mesh.rotation.y = value;
                            this.syncSelectionMesh();
                            this.onAnimationStep();
                          }}
                        />
                      </Row>
                    )}
                    onClick={(expanded) => {
                      if (expanded) {
                        uiStateRight.editRotation = true;
                        this.forceUpdate();
                        
                        setTimeout(() => {
                          this.onAnimationStep();
                        }, 1);
                      } else {
                        uiStateRight.editRotation = false;
                        this.forceUpdate();

                        setTimeout(() => {
                          this.onAnimationStep();
                        }, 1);
                      }
                    }}
                  >
                    Rotate
                  </CAMenuBlob>
                </div>
                <div>
                  <CAMenuBlob
                    onClick={() => {
                      this.onToggleSelectionLock();  
                    }}
                  >
                    {(selection.branch && selection.branch._locked) ? 'Unlock' : 'Lock'}
                  </CAMenuBlob>
                </div>
                <div>
                  <CAMenuBlob dangerous onClick={() => {
                    this.onRemoveSelection(false);
                  }}>
                    Delete
                  </CAMenuBlob>
                </div>
                <div>
                  <CAMenuBlob onClick={() => {
                    this.pushUiState(
                      'right',
                      280,
                      -100,
                      true,
                      {
                        selectionComments: true,
                        selectionMenu: false
                      }
                    );
                  }}>
                    Comment {selection.branch && selection.branch.comments && selection.branch.comments.length ? `(${selection.branch.comments.length})` : ''}
                  </CAMenuBlob>
                </div>
              </CAPanelBody>
            </CAPanel>
            <CAPanel
              name="env-nodal"
              top={ifWindow(window.innerHeight / 2 - 250, 100)}
              left={uiStateLeft.envModal ? 15 : -500}
              style={{
                width: 300,
                height: 450,
                backgroundColor: CAModalBackground,
                borderRadius: CAModalBorderRadius,
                overflowY: 'scroll',
                overflowX: 'hidden'
              }}
            >
              <CAEnvironment
                project={project}
                schema={schema}
                renderer={this.renderer}
                onEnvironmentUpdated={() => {
                  this.forceUpdate();
                  this.onAnimationStep();
                }}
              />
            </CAPanel>
            <CAPanel
              name="tools-nodal"
              top={ifWindow(window.innerHeight / 2 - 250, 100)}
              left={uiStateLeft.toolsExpanded ? 15 : -500}
              style={{
                width: 350,
                height: 450,
                backgroundColor: CAModalBackground,
                borderRadius: CAModalBorderRadius,
                overflowY: 'scroll',
                overflowX: 'hidden'
              }}
            >
              <CAToolbox
                project={project}
                schema={schema}
                renderer={this.renderer}
                contextTool={contextTool}
                onUpdated={(contextTool, options) => {
                  this.onContextToolChanged(contextTool, options);
                }}
              />
            </CAPanel>
            <CAPanel
              name="library-nodal"
              top={ifWindow(window.innerHeight / 2 - ifWindow(window.innerHeight - 200, 300) / 2, 100)}
              left={uiStateLeft.libraryModal ? 15 : -500}
              style={{
                width: 350,
                height: ifWindow(window.innerHeight - 200, 300),
                backgroundColor: CAModalBackground,
                borderRadius: CAModalBorderRadius
              }}
            >
              <CALibrary
                uiState={uiStateLeft}
                pushUiState={this.pushUiState.bind(this)}
                popUiState={this.popUiState.bind(this)}
                onElementSelected={(modelId) => {
                  if (!modelId) {
                    this.onContextToolChanged(ContextTools.select, {
                      gridSnap: 1,
                      showCursor: false
                    });

                    return;
                  }

                  this.onContextToolChanged(ContextTools.place, {
                    showCursor: 'default',
                    modelId: modelId,
                    gridSnap: 1
                  });
                }}
              />
            </CAPanel>
            <CAPanel
              name="library-custom-model-modal"
              top={ifWindow(window.innerHeight / 2 - ifWindow(window.innerHeight - 200, 300) / 2, 100)}
              left={uiStateLeft.libraryCustomModel ? 15 : -500}
              style={{
                width: 350,
                height: ifWindow(window.innerHeight - 200, 300),
                backgroundColor: CAModalBackground,
                borderRadius: CAModalBorderRadius,
                overflow: 'scroll'
              }}
            >
              <CALibraryCustomModel
                uiState={uiStateLeft}
                pushUiState={this.pushUiState.bind(this)}
              />
            </CAPanel>
            {uiStateLeft.libraryFilters && <CAPanel
              name="library-filters-nodal"
              top={ifWindow(window.innerHeight / 2 - ifWindow(window.innerHeight - 200, 300) / 2, 100)}
              left={370}
              style={{
                width: 230,
                height: ifWindow(window.innerHeight - 200, 300),
                backgroundColor: CAModalBackground,
                borderRadius: CAModalBorderRadius
              }}
            >
              <CAFilters />
            </CAPanel>}
          </CALayout>
        </Spin>
        <RemoteCanvas
          onCreate={(canvas) => {
            this.remoteCanvasRef = canvas;
          }}
          onUpdate={(canvas) => {
            this.remoteCanvasRef = canvas;
          }}
        />
        <div style={{ display: 'none' }}>
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
            <defs>
              <filter id="ca-smooth-panels">
                <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="smooth-panels" />
                <feComposite in="SourceGraphic" in2="smooth-panels" operator="atop"/>
              </filter>
            </defs>
          </svg>
        </div>
      </AntWrapper>
    );
  }
}
