export const snapTo = (number, points = [], threshold = 2) => {
  if (isNaN(number)) {
    return number;
  }

  const snapPoint = points
    .filter(v => !isNaN(v))
    .find(point => number >= point - threshold && number <= point + threshold);

  return typeof snapPoint !== 'undefined' ? snapPoint : number
};

export const snapToRounded = (number, interval = 10, threshold = 2) => {
  if (isNaN(number)) {
    return number;
  }

  if (threshold === 1 || interval <= threshold) {
    return number;
  }

  const top = Math.ceil(number / interval) * interval;
  const bottom = Math.floor(number / interval) * interval;

  if (Math.abs(top - number) <= threshold) {
    return top;
  } else if (Math.abs(number - bottom) <= threshold) {
    return bottom;
  }

  return number;
};
