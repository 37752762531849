import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  width: ${p => (p.scale || 1.0) * 50}px;
  height: ${p => (p.scale || 1.0) * 50}px;
  margin: ${p => (1 - (p.scale || 1.0)) * 25}px;
  line-height: 0;
  color: #000;
  font-size: 14px;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;

  &:hover {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, .1);
  }

  ${p => p.inverted && `
    color: #fff;
    background-color: #000;
  `}

  i {
    position: absolute;
    display: inline-block;
    line-height: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`;

export class CAKnob extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { style, scale, icon, iconActive, toggled, onClick } = this.props;
    
    return (
      <Wrapper style={style} scale={scale} inverted={toggled} onClick={onClick}>
        <i className={`fal fa-fw fa-${toggled ? (iconActive || icon) : icon}`} />
      </Wrapper>
    );
  }
}