import * as Three from 'three';
import { modelLoader, loadTexture } from '../utils/loaders';
import CursorBackgroundOvalSVG from '../images/cursor/cursor-background-oval.svg';

const createContextCursor = ({ camera }) => {
  const contextCursor = new Three.Object3D();
  contextCursor.name = '_ca-cursor-default';

  const cursorGeometry = new Three.BoxBufferGeometry(.25, 18, .25, 1, 1, 1);
  const cursorMaterial = new Three.MeshBasicMaterial({
    color: 0xffffff
  });
  const cursorA = new Three.Mesh(cursorGeometry, cursorMaterial);
  cursorA.position.set(-0.125, 10.0, 0);
  cursorA.name = 'pole';
  contextCursor.add(cursorA);
  contextCursor.visible = false;

  modelLoader.svg().load(CursorBackgroundOvalSVG, (model) => {
    const group = new Three.Object3D();
    group.position.set(-0.125, 24, .1);
    group.scale.set(1, -1, 1);

    model.name = 'background';
    model.scale.set(.05, .05, 0);
    model.position.set(-2.5, -2.5, 0);

    group.add(model);

    const iconGeometry = new Three.PlaneBufferGeometry(3, 3);
    const iconMaterial = new Three.MeshBasicMaterial({
      opacity: 0,
      transparent: true,
      color: 0xffffff,
      toneMapped: false
    });
    const iconMesh = new Three.Mesh(iconGeometry, iconMaterial);
    iconMesh.name = 'icon';
    iconMesh.position.z += 0.1;
    iconMesh.scale.set(1, -1, 1);
    group.add(iconMesh);

    const update = () => {
      if (contextCursor.visible) {
        group.lookAt(camera.position);
      }

      requestAnimationFrame(update);
    };
    requestAnimationFrame(update);

    group.name = 'context-icons';
    contextCursor.add(group);
  });

  return contextCursor;
};

const createTileCursor = () => {
  const tileCursor = new Three.Object3D();
  tileCursor.name = '_ca-cursor-tile';

  const tileCursorGeometry = new Three.BoxBufferGeometry(10, 2, 10, 1, 1, 1);
  const tileCursorMaterial = new Three.MeshBasicMaterial({
    color: 0xffffff,
    opacity: 0.5,
    transparent: true
  });
  const cursorD = new Three.Mesh(tileCursorGeometry, tileCursorMaterial);
  cursorD.position.set(0, 2.1, 0);
  tileCursor.add(cursorD);
  tileCursor.visible = false;

  return tileCursor;
};

const createWallCursor = () => {
  const wallCursor = new Three.Object3D();
  wallCursor.name = '_ca-cursor-walls';

  const wallCursorGeometry = new Three.BoxBufferGeometry(.25, 20, .25, 1, 1, 1);
  const wallCursorMaterial = new Three.MeshBasicMaterial({
    color: 0xffffff
  });
  const cursorC = new Three.Mesh(wallCursorGeometry, wallCursorMaterial);
  cursorC.position.set(-0.125, 10.0, -0.125);
  wallCursor.add(cursorC);
  wallCursor.visible = false;

  return wallCursor;
};

export const parseCursor = ({ camera }) => {
  const cursor = new Three.Group();

  cursor.add(createContextCursor({ camera }));
  cursor.add(createWallCursor());
  cursor.add(createTileCursor());

  return cursor;
};
