import React from 'react';
import styled from 'styled-components';

export const CALayout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  ${p => p.smoothEdges && `filter: url('#ca-smooth-panels');`}
`;