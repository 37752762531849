import Rete from 'rete';
import { EventSourceSocket, StringSocket } from '../sockets';
import { BaseLayout } from '../layouts/base.layout';

export class DebugNode extends Rete.Component {
  constructor() {
    super('Test Value');
    this.data.component = BaseLayout;
  }

  builder(node) {
    const inp2 = new Rete.Input('value', 'Value', StringSocket, false);
    const inp1 = new Rete.Input('event-source-in', '', EventSourceSocket, false);
    const out1 = new Rete.Output('then', '', EventSourceSocket, false);

    return node
      .addInput(inp2)
      .addInput(inp1)
      .addOutput(out1);
  }

  worker(node, inputs, outputs) {
    node.data['value'] = (inputs['value'] && inputs['value'][0]) || 'Empty value.';
  }
}
