import React from 'react';
import styled from 'styled-components';
import { CAPanelBody } from './ca-panel';
import { materialPresets } from '../../data/common/materials';
import { Row, Col } from 'antd';
import { CAButton } from './ca-button';

export const MaterialPreviewWrapper = styled.div`
  position: relative;
  cursor: pointer;
  filter: drop-shadow(0px 1px 2px #cccccc);

  img {
    transition: all .1s ease;
    min-width: 50px;
    min-height: 50px;
  }

  &:hover {
    img {
      transform: scale(1.2);
    }
  }

  ${p => p.selected && `
    &:after {
      position: absolute;
      top: 10%;
      right: 10%;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #000;
      content: '';
      z-index: 1;
    }
  `}
`;

export const MaterialPreview = (props) => {
  return (
    <MaterialPreviewWrapper selected={props.selected}>
      <img {...props} />
    </MaterialPreviewWrapper>
  );
};

export class CAFilters extends React.Component {
  render() {
    return (
      <CAPanelBody margin="20px 25px">
        <Row>
          <CAButton
            style={{ textAlign: 'center' }}
            active={true}
          >
            Material
          </CAButton>
        </Row>
        <Row>
          {Object.keys(materialPresets)
            .filter(id => !!materialPresets[id].previewUrl)
            .map(id => {
              const material = materialPresets[id];

              return (
                <Col span={8}>
                  <MaterialPreview src={material.previewUrl} width="100%" />
                </Col>
              );
            }
          )}
        </Row>
      </CAPanelBody>
    );
  }
}