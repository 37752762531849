import React from 'react';
import styled from 'styled-components';
import * as Three from 'three';
import { VertexShaderPCSS } from '../../../common-schema/shaders/pcss/vertex';
import { FragmentShaderPCSS } from '../../../common-schema/shaders/pcss/fragment';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { SAOPass } from 'three/examples/jsm/postprocessing/SAOPass';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader';

const CanvasContainer = styled.div`
  &, canvas {
    outline: none;
    cursor: default;
  }
`;

export class Wtl3DScene extends React.Component {
  constructor(props) {
    super(props);

    this.sceneRef = React.createRef();

    this.active = 0;
    this.renderer = null;
    this.composer = null;
    this.scene = null;
    this.camera = null;
    this.controls = null;
  }

  componentDidMount() {
    this.initScene();
    this.addResizeListener();
  }

  componentDidUpdate() {
    if (this.active === 0) {
      this.initScene();
    }
  }

  componentWillUnmount() {
    this.active = -1;
  }

  addResizeListener() {
    if (typeof window === 'undefined') {
      return;
    }

    window.removeEventListener('resize', this.onWindowResize.bind(this));
    window.addEventListener('resize', this.onWindowResize.bind(this));
  }

  initScene() {
    if (typeof window === 'undefined' || this.active !== 0 || !this.sceneRef.current) {
      return;
    }

    const { width, height, onSceneReady, renderQuality, rendererOptions } = this.props;

    this.active = 1;

    if (!this.scene) {
      this.scene = new Three.Scene();

      const ambientLight = new Three.AmbientLight(0x264b73);
      ambientLight.name = 'ambient';
      this.scene.add(ambientLight);

      const sunLight = new Three.DirectionalLight(0xffffff, 1);
      sunLight.name = 'sun';
      sunLight.position.set(100., 100., 100.);
      sunLight.castShadow = true;
      sunLight.shadow.mapSize.width = 2048;
      sunLight.shadow.mapSize.height = 2048;
      sunLight.shadow.camera.near = 1.;
      sunLight.shadow.camera.far = 15.;
      sunLight.shadow.bias = 0.00001;
      sunLight.shadow.camera.scale.set(20, 20, 20);

      sunLight.castShadow = true;
      sunLight.shadow.mapSize.width = [256, 512, 2048][renderQuality || 0];
      sunLight.shadow.mapSize.height = [256, 512, 2048][renderQuality || 0];

      if (this.renderQuality === 2) {
        let shadowShader = Three.ShaderChunk.shadowmap_pars_fragment;
        shadowShader = shadowShader.replace('#ifdef USE_SHADOWMAP', '#ifdef USE_SHADOWMAP' + VertexShaderPCSS);
        shadowShader = shadowShader.replace('#if defined( SHADOWMAP_TYPE_PCF )', FragmentShaderPCSS + '#if defined( SHADOWMAP_TYPE_PCF )');
        Three.ShaderChunk.shadowmap_pars_fragment = shadowShader;
      }

      this.scene.add(sunLight);

      this.sceneRoot = new Three.Group();
      this.scene.add(this.sceneRoot);
    }

    if (!this.renderer) {
      this.renderer = new Three.WebGLRenderer({
        antialias: true,
        ...(rendererOptions || {})
      });
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(width || window.innerWidth, height || window.innerHeight);
      this.renderer.toneMapping = Three.ACESFilmicToneMapping;
      this.renderer.toneMappingExposure = 1;
      this.renderer.outputEncoding = Three.sRGBEncoding;
      this.renderer.shadowMap.enabled = true;

      requestAnimationFrame(() => this.onAnimationStep());
    }

    if (!this.camera) {
      this.camera = new Three.PerspectiveCamera(
        40,
        (width || window.innerWidth) / (height || window.innerHeight),
        1,
        2000
      );
      this.camera.position.set(0, 40, 140);
    }

    this.sceneRef.current.innerHTML = '';
    this.sceneRef.current.appendChild(this.renderer.domElement);

    if (!this.composer && renderQuality === 2) {
      this.composer = new EffectComposer(this.renderer);

      const renderPass = new RenderPass(this.scene, this.camera);
      this.composer.addPass(renderPass);

      const saoPass = new SAOPass(this.scene, this.camera, false, true);
      saoPass.params.saoBias = 1.0;
      saoPass.params.saoIntensity = 1;
      saoPass.params.saoScale = 1000.0;
      saoPass.params.saoKernelRadius = 20.0;
      saoPass.params.saoMinResolution = 0.0;
      saoPass.params.saoBlur = true;
      saoPass.params.saoBlurRadius = 5.0;
      saoPass.params.saoBlurStdDev = 4.0;
      saoPass.params.saoBlurDepthCutoff = 0.01;
      this.composer.addPass(saoPass);

      const fxaaEffect = new ShaderPass(FXAAShader);
      fxaaEffect.uniforms.resolution.value.set(1 / window.innerWidth, 1 / window.innerHeight);
      this.composer.addPass(fxaaEffect);
    }

    if (onSceneReady) {
      onSceneReady({
        renderer: this.renderer,
        scene: this.scene,
        camera: this.camera,
        root: this.sceneRoot,
        canvas: this.renderer.domElement
      });
    }

    this.active = 2;
  }

  onAnimationStep() {
    if (this.active !== 2) {
      return;
    }

    const { onAnimationStep } = this.props;

    if (onAnimationStep) {
      onAnimationStep({
        renderer: this.renderer,
        scene: this.scene,
        camera: this.camera,
        root: this.sceneRoot,
        canvas: this.renderer.domElement
      });
    }

    if (this.composer) {
      this.composer.render();
    } else {
      this.renderer.render(this.scene, this.camera);
    }

    if (this.active !== 2) {
      return;
    }

    requestAnimationFrame(() => this.onAnimationStep());
  }

  onWindowResize() {
    const { width, height } = this.props;

    if (width || height) {
      return;
    }

    if (this.active === 2) {
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();

      this.renderer.setSize(window.innerWidth, window.innerHeight);

      if (this.composer) {
        this.composer.setSize(window.innerWidth, window.innerHeight);
      }
    }

    this.forceUpdate();
  }

  render() {
    const { style } = this.props;

    return (
      <CanvasContainer
        ref={this.sceneRef}
        key={1}
        style={{
          position: 'relative',
          maxWidth: '100%',
          maxHeight: '100%',
          width: '100%',
          height: '100%',
          outline: 'none',
          overflow: 'hidden',
          ...(style || {})
        }}
      />
    );
  }
}