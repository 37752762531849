import * as Three from 'three';

const generateLightNoise = () => {
  if (typeof window === 'undefined') {
    return;
  }

  const canvas = document.createElement('canvas');
  canvas.width = 512;
  canvas.height = 512;
  const ctx = canvas.getContext('2d');

  for (let i = 0; i < 20000; i++) {
    ctx.fillStyle = 'hsl(0,0%,' + ( Math.random() * 50 + 50 ) + '%)';
    ctx.beginPath();
    ctx.arc( Math.random() * canvas.width, Math.random() * canvas.height, Math.random() + 0.15, 0, Math.PI * 2, true);
    ctx.fill();
  }

  ctx.globalAlpha = 0.075;
  ctx.globalCompositeOperation = 'lighter';

  return canvas;
}

export const parseFloor = (branch, context, options) => {
  const { _selfKey, parent } = options;
  const {
    yOffset,
    showGrid,
    gridColor = 0xffffff,
    tintColor,
    materialId = 'glass'
  } = branch;
  const { renderQuality } = context;
  let instance = parent.getObjectByName(_selfKey);
  const floorTintColor = new Three.Color(tintColor).getHSL();

  if (!instance) {
    instance = new Three.Object3D();

    if (renderQuality < 2) {
      const grid = new Three.GridHelper(100, 10, gridColor, gridColor);
      instance.add(grid);
      grid.position.y = 1.0;
    }

    const groundGeometry = new Three.BoxBufferGeometry(100, 1, 100, 1, 1, 1);
    let ground;

    if (materialId === 'grass') {
      const groundMaterial = new Three.MeshPhysicalMaterial({
        color: new Three.Color().setHSL(tintColor ? floorTintColor.h : 0.3, 0.3, 0.05),
        clearcoat: 0.0,
        clearcoatRoughness: 1.0,
        metalness: 0.0,
        roughness: 1.0
      });
      ground = new Three.Mesh(groundGeometry, groundMaterial);

      const grassGroup = new Three.Group();
      grassGroup.name = '_ca-ground-grass';
      const grassGeometry = new Three.PlaneBufferGeometry(100, 100, 1, 1);
      const grassTexture = new Three.CanvasTexture(generateLightNoise());
      grassTexture.repeat.set(2, 2);
      grassTexture.wrapS = Three.MirroredRepeatWrapping;
      grassTexture.wrapT = Three.MirroredRepeatWrapping;
      const grassTilt = -Math.PI / 2;

      for (let i = 0; i < 15; i++) {
        const grassMaterial = new Three.MeshBasicMaterial({
          color: new Three.Color().setHSL(tintColor ? floorTintColor.h : 0.3, 0.3, 0.1 + 0.4 * (i / 15)),
          map: grassTexture,
          depthWrite: false,
          transparent: true,
          side: Three.DoubleSide
        });
        const grass = new Three.Mesh(grassGeometry, grassMaterial);
        grass.receiveShadow = true;
        grass.castShadow = false;
        grass.rotation.x = grassTilt;
        grass.position.y = i * 0.15;
        grass.position.x += Math.random() * 0.1;
        grass.position.z += Math.random() * 0.1;
        grassGroup.add(grass);
      }

      instance.add(grassGroup);
    } else {
      const groundMaterial = new Three.MeshPhysicalMaterial({
        color: new Three.Color(tintColor),
        clearcoat: 0.0,
        clearcoatRoughness: 1.0,
        metalness: 0.0,
        roughness: 1.0
      });
      ground = new Three.Mesh(groundGeometry, groundMaterial);
    }

    ground.receiveShadow = true;
    ground.castShadow = true;
    ground.name = '_ca-ground-mesh';
    instance.add(ground);

    parent.add(instance);
  }

  instance.position.y = yOffset;

  return instance;
};
