import * as Three from 'three';
import { Lensflare, LensflareElement } from 'three/examples/jsm/objects/Lensflare';
import LensflareImage from '../images/lensflare.png';

const sunOffset = 15.;

export const parseEnvironment = (branch, context, options) => {
  const {
    scene,
    renderer,
    root,
    renderQuality
  } = context;
  const {
    backgroundType,
    backgroundColor,
    backgroundColorB,
    backgroundImage,
    sunColor,
    daytimeOffset = 180
  } = branch;

  if (!scene || typeof window === 'undefined') {
    return;
  }

  let sunInstance = scene.getObjectByName('sun');
  let sunModelInstance = scene.getObjectByName('sun-model');
  let ambientInstance = scene.getObjectByName('ambient');

  if (!sunInstance) {
    const sunLight = new Three.DirectionalLight(new Three.Color(sunColor || '#ffffff'), 1);
    sunLight.name = 'sun';
    sunLight.position.set(sunOffset, sunOffset, 0);
    sunLight.castShadow = true;
    sunLight.shadow.mapSize.width = [256, 512, 2048][renderQuality || 0];
    sunLight.shadow.mapSize.height = [256, 512, 2048][renderQuality || 0];
    
    if (renderQuality >= 1) {
      sunLight.shadow.camera.near = 1.;
      sunLight.shadow.camera.far = 15.;
      sunLight.shadow.bias = 0.00001;
      sunLight.shadow.camera.scale.set(20, 20, 20);
    }

    const sunModel = new Three.Mesh(
      new Three.SphereBufferGeometry(10., 4., 4.),
      new Three.MeshPhongMaterial({ color: 0xffffff, emissive: 0xffffff })
    );
    sunModel.name = 'sun-model';
    sunModel.position.copy(sunLight.position.multiplyScalar(15));

    if (renderQuality >= 1) {
      const textureLoader = new Three.TextureLoader();
      const lensFlareTexture = textureLoader.load(LensflareImage);

      const lensflare = new Lensflare();
      lensflare.addElement(new LensflareElement(lensFlareTexture, 60, 0.6));
      lensflare.addElement(new LensflareElement(lensFlareTexture, 70, 0.7));
      lensflare.addElement(new LensflareElement(lensFlareTexture, 120, 0.9));
      lensflare.addElement(new LensflareElement(lensFlareTexture, 70, 1.0));
      sunModel.add(lensflare);
    }

    const ambientLight = new Three.HemisphereLight(sunLight.color.clone(), 0x202c40, 0.6);
    ambientLight.name = 'ambient';
      
    scene.add(sunModel);
    scene.add(sunLight);
    scene.add(ambientLight);

    sunInstance = sunLight;
    sunModelInstance = sunModel;
    ambientInstance = ambientLight;
  }

  if (sunInstance && sunModelInstance && ambientInstance) {
    if (daytimeOffset >= 90 && daytimeOffset <= 270) {
      const offset = (daytimeOffset - 90) / 180;
      const invOffset = 1.0 - offset;

      if (offset < .25) {
        sunInstance.color = new Three.Color(`hsl(${parseInt(60 * 4 * offset, 10)},100%,${20 + parseInt(80 * 4 * offset, 10)}%)`);
        sunInstance.intensity = offset * 4 *  2;
      } else if (offset >= .75) {
        sunInstance.color = new Three.Color(`hsl(${parseInt(60 * 4 * invOffset, 10)},100%,${20 + parseInt(80 * 4 * invOffset, 10)}%)`);
        sunInstance.intensity = invOffset * 4 * 2;
      } else {
        sunInstance.color = new Three.Color('hsl(60,100%,100%)');
        sunInstance.intensity = 2.0;
      }

      sunInstance.position.set(
        Math.cos((daytimeOffset - 90) * Math.PI / 180) * -sunOffset,
        Math.sin((daytimeOffset - 90) * Math.PI / 180) * sunOffset,
        0.
      );
      sunModelInstance.position.copy(sunInstance.position.multiplyScalar(15));
      sunModelInstance.material.emissive.copy(sunInstance.color);
      ambientInstance.color.copy(sunInstance.color);
      ambientInstance.groundColor.set(new Three.Color(0x202c40));
    } else {
      let offset = (daytimeOffset - 270) / 180;

      if (offset < 0) {
        offset += 2;
      }

      const invOffset = 1.0 - offset;

      if (offset < .25) {
        sunInstance.color = new Three.Color(`hsl(${180 + parseInt(20 * 4 * offset, 10)},10%,${parseInt(80 * 4 * offset, 10)}%)`);
        sunInstance.intensity = offset * 4 * .5;
      } else if (offset >= .75) {
        sunInstance.color = new Three.Color(`hsl(${180 + parseInt(20 * 4 * invOffset, 10)},10%,${parseInt(80 * 4 * invOffset, 10)}%)`);
        sunInstance.intensity = invOffset * 4 * .5;
      } else {
        sunInstance.color = new Three.Color('hsl(200,10%,80%)');
        sunInstance.intensity = .5;
      }

      sunInstance.position.set(
        Math.cos((daytimeOffset - 270) * Math.PI / 180) * -sunOffset,
        Math.sin((daytimeOffset - 270) * Math.PI / 180) * sunOffset,
        0.
      );
      sunModelInstance.position.copy(sunInstance.position.multiplyScalar(15));
      sunModelInstance.material.emissive.copy(sunInstance.color);
      ambientInstance.color.copy(sunInstance.color);
      ambientInstance.groundColor.set(new Three.Color(0x000000));
    }
  }

  const canvas = document.createElement('canvas');
  canvas.width = 512;
  canvas.height = 512;
  const ctx = canvas.getContext('2d');
  ctx.clearRect(0, 0, 512, 512);

  if (backgroundType === 'simulated' && ambientInstance) {
    const gradient = ctx.createLinearGradient(0, 0, 0, 512);
    const offset = (daytimeOffset - 90) / 360;

    let start = ambientInstance.color.clone();
    let end = ambientInstance.groundColor.clone();

    start = start.offsetHSL(0, -0.5, offset >= 0 && offset <= .5 ? 0 :  -0.99);
    end = end.offsetHSL(0, -0.5, offset >= 0 && offset <= .5 ? 0 :  .1);

    gradient.addColorStop(0, `#${start.getHexString()}` || '#000000');
    gradient.addColorStop(1, `#${end.getHexString()}` || '#000000');

    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, 512, 512);
  } else if (backgroundType === 'gradient') {
    const gradient = ctx.createLinearGradient(0, 0, 0, 512);
    gradient.addColorStop(0, backgroundColor || '#000000');
    gradient.addColorStop(1, backgroundColorB || '#000000');

    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, 512, 512);
  } else if (backgroundType === 'image') {
    ctx.drawImage(backgroundImage, 0, 0);
  } else {
    ctx.fillStyle = backgroundColor || '#000000';
    ctx.fillRect(0, 0, 512, 512);
  }

  const envBackground = new Three.CanvasTexture(canvas);
  const pmremGenerator = new Three.PMREMGenerator(renderer);
  pmremGenerator.compileEquirectangularShader();

  const cubeRenderTarget = pmremGenerator.fromEquirectangular(envBackground);
  
  scene.background = envBackground;
  scene.environment = cubeRenderTarget.texture;

  pmremGenerator.dispose();

  return root;
};
