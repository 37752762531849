import React from 'react';
import { VariableInput } from '../variable-input';
import { Row, Col } from 'antd';

export const renderContactFormProps = function () {
  const { tools } = this.state;
  const { selection } = tools;

  return (
    <Col span={8}>
      <Row gutter={4}>
        <VariableInput
          span={24}
          inputType="table"
          inputProps={[
            'field name',
            [ 'required', 'email', 'optional' ],
            [ 'text', 'textarea' ]
          ]}
          name="form fields"
          value={selection.branch.fields}
          onChange={(value, params) => {
            this.updateSelectionParam('fields', { target: { value }}, params);
          }}
        />
      </Row>
      <Row>
        <VariableInput
          span={24}
          name="submit button"
          value={selection.branch.submitLabel}
          onChange={(value, params) => {
            this.updateSelectionParam('submitLabel', { target: { value }}, params);
          }}
        />
        <VariableInput
          span={24}
          name="success message"
          value={selection.branch.messageSuccess}
          onChange={(value, params) => {
            this.updateSelectionParam('messageSuccess', { target: { value }}, params);
          }}
        />
        <VariableInput
          span={24}
          name="error message"
          value={selection.branch.messageError}
          onChange={(value, params) => {
            this.updateSelectionParam('messageError', { target: { value }}, params);
          }}
        />
        <VariableInput
          span={24}
          name="footer disclaimer"
          value={selection.branch.footerNote}
          onChange={(value, params) => {
            this.updateSelectionParam('footerNote', { target: { value }}, params);
          }}
        />
        <VariableInput
          span={24}
          inputType="select"
          name="email language"
          value={selection.branch.emailLanguage}
          onChange={(value, params) => {
            this.updateSelectionParam('emailLanguage', { target: { value }}, params);
          }}
        >
          <option value="en">english (default)</option>
          <option value="nl">dutch</option>
          <option value="pl">polish</option>
        </VariableInput>
        <VariableInput
          span={12}
          inputType="select"
          name="show loading"
          value={selection.branch.showSpinner}
          onChange={(value, params) => {
            this.updateSelectionParam('showSpinner', { target: { value }}, params);
          }}
        >
          <option value="true">yes</option>
          <option value="false">no</option>
        </VariableInput>
        <VariableInput
          span={12}
          inputType="select"
          name="field label type"
          value={selection.branch.useNameAsPlaceholder}
          onChange={(value, params) => {
            this.updateSelectionParam('useNameAsPlaceholder', { target: { value }}, params);
          }}
        >
          <option value="false">name tag</option>
          <option value="true">placeholder</option>
        </VariableInput>
      </Row>
    </Col>
  );
}
