import React from 'react';
import styled from 'styled-components';
import {
  Layout,
  Menu,
  Icon,
  Row,
  Col,
  message,
} from 'antd';
import { AntWrapper } from '../components/ant-wrapper';
import { HeaderNav } from '../components/header-nav';
import { SimpleButton, SimpleInput } from '../styles/styled';
import { createBehaviourEditor } from '../components/rete/behaviour-editor/behaviour-editor';
import StateService from '../services/state.service';
import EditorService from '../services/editor.service';

const { SubMenu } = Menu;
const {
  Sider,
  Content
} = Layout;

const BehaviourEditorWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 500px;

  .connection .main-path {
    stroke: #1890ff;
    stroke-width: 2;
  }
`;

export default class Events extends React.Component {
  addEditorNode = null;

  constructor(props) {
    super(props);

    this.state = {
      editor: null,
      behaviours: []
    };

    this.behaviourEditor = React.createRef();

    StateService.fetchProject().then(() => {
      this.forceUpdate();
    });
  }

  createNewBehaviour() {
    const behaviour = StateService.createNewBehaviour();

    this.setState({
      editor: behaviour
    });

    StateService.saveBehaviours();
  }

  removeBehaviour() {
    if (typeof window === 'undefined') {
      return;
    }

    const confirm = window.confirm('Remove this interaction?');

    if (!confirm) {
      return;
    }

    const id = this.state.editor.id;

    this.setState({
      editor: null
    });

    StateService.removeBehaviour(id);
    StateService.saveBehaviours();
  }

  duplicateBehaviour() {
    if (typeof window === 'undefined') {
      return;
    }

    const confirm = window.confirm('Duplicate this interaction?');

    if (!confirm) {
      return;
    }

    const base = {
      ...this.state.editor,
      name: `${this.state.editor.name} (2)`
    };
    delete base.id;

    const newBehaviour = StateService.createNewBehaviour(base);

    this.setState({
      editor: newBehaviour
    });

    StateService.saveBehaviours();
  }

  findBehaviourById(key) {
    const behaviours = StateService.getBehaviours();

    return behaviours.find(({ id }) => id === key);
  }

  selectBehaviour({ key }) {
    const behaviour = this.findBehaviourById(key);

    if (!behaviour) {
      return;
    }

    this.setState({
      editor: behaviour
    });

    StateService.rememberEditedElement('behaviour', key);
  }

  updateParam(prop, event, { parseValue, updateView } = {}) {
    if (event.preventDefault) {
      event.preventDefault();
    }

    const { target } = event;
    const { value } = target;
    const behaviour = this.state.editor;

    let parsedValue = value;

    if (value && parseValue) {
      parsedValue = JSON.parse(value);
    }

    const eventsBehaviour = this.findBehaviourById(behaviour.id);

    if (!eventsBehaviour) {
      return;
    }

    eventsBehaviour[prop] = parsedValue;
    behaviour[prop] = parsedValue;

    StateService.saveBehaviours();

    if (updateView !== false) {
      this.forceUpdate();
    }
  }

  buildSequenceUntilDone(sequence, node, map, forceTrigger) {
    sequence.push({
      id: node.id,
      triggerNode: forceTrigger ? forceTrigger : (node.inputs ?
        (node.inputs['event-source-in'] && 
        node.inputs['event-source-in'].connections &&
        node.inputs['event-source-in'].connections[0].node) : undefined),
      action: node.name,
      params: node.data || {}
    });

    const outputEvent = node.outputs['then'];
    const conditionOutput = node.outputs['then-true'] || node.outputs['then-false'];
    
    if (conditionOutput) {
      const positiveEvent = node.outputs['then-true'];
      const negativeEvent = node.outputs['then-false'];

      const positiveNode = positiveEvent && positiveEvent.connections.length ? map.nodes[positiveEvent.connections[0].node] : null;
      const negativeNode = negativeEvent && negativeEvent.connections.length ? map.nodes[negativeEvent.connections[0].node] : null;

      if (!positiveNode && !negativeNode) {
        return sequence;
      }

      let nextSequence = [];

      if (positiveNode) {
        nextSequence = [
          ...nextSequence,
          ...this.buildSequenceUntilDone([], positiveNode, map, `${node.id}-true`)
        ];
      }

      if (negativeNode) {
        nextSequence = [
          ...nextSequence,
          ...this.buildSequenceUntilDone([], negativeNode, map, `${node.id}-false`)
        ];
      }

      return [
        ...sequence,
        ...nextSequence
      ];
    } else if (!outputEvent || outputEvent.connections.length === 0) {
      return sequence;
    } else {
      const outputNode = map.nodes[outputEvent.connections[0].node];

      if (!outputNode) {
        return sequence;
      }

      return this.buildSequenceUntilDone(sequence, outputNode, map);
    }
  }

  mapEditorToSequence(map) {
    return Object.keys(map.nodes)
      .map(key => map.nodes[key])
      .filter(node => node.name === 'Event')
      .map(node => this.buildSequenceUntilDone([], node, map))
      .filter(sequence => sequence.length > 1);
  }

  addBehaviourEditorBlock(id) {
    if (!this.addEditorNode) {
      return;
    }

    this.addEditorNode(id);
  }

  renderEditor() {
    const { editor } = this.state;

    return (
      <Content
        style={{
          position: 'relative',
          background: '#fff',
          padding: 24,
          margin: 0
        }}
      >
        <Row gutter={4}>
          <Col span={24}>
            <BehaviourEditorWrapper
              className="behaviour-editor-root"
              ref={async (ref) => {
                if (!ref) {
                  return;
                }

                const { addNode } = await createBehaviourEditor(
                  ref,
                  editor ? editor.map : undefined,
                  (map) => {
                    this.updateParam('sequence',
                      {
                        target: {
                          value: this.mapEditorToSequence(map)
                        }
                      },
                      {
                        updateView: false
                      }
                    );
                    this.updateParam('map',
                      {
                        target: {
                          value: JSON.stringify(map)
                        }
                      },
                      {
                        updateView: false
                      }
                    );
                  }
                );

                this.addEditorNode = addNode;
              }}
            ></BehaviourEditorWrapper>
          </Col>
        </Row>
      </Content>
    );
  }

  componentDidMount() {
    EditorService.refreshAuth();
  }

  render() {
    if (!EditorService.isLoggedIn()) {
      EditorService.refreshAuth(async () => {
        this.forceUpdate();

        await StateService.fetchProject();

        this.forceUpdate();
      });

      return null;
    }

    try {
      const { editor } = this.state;
      const behaviours = StateService.getBehaviours();

      return (
        <AntWrapper style={{ minWidth: 1200 }}>
          <Layout style={{ width: '100vw', height: '100vh', boxSizing: 'border-box' }}>
            <HeaderNav service={StateService} />
            <Layout>
              <Sider width={250} style={{ background: '#fff' }}>
                <Menu
                  mode="inline"
                  defaultOpenKeys={[
                    'behaviours'
                  ]}
                  style={{ height: '100%', borderRight: 0 }}
                  selectedKeys={editor ? [editor.id] : []}
                  onSelect={(e) => this.selectBehaviour(e)}
                >
                  <SubMenu
                    key="behaviours"
                    title={
                      <span>
                        <Icon type="branches" />
                        All Interactions
                      </span>
                    }
                    disabled={!behaviours}
                  >
                    {behaviours.map((item, index) => (
                      <Menu.Item
                        key={item.id}
                      >
                        {item.name || 'Unnamed'}
                      </Menu.Item>
                    ))}
                  </SubMenu>
                </Menu>
              </Sider>
              <Layout style={{ padding: 24 }}>
              <Row gutter={24} style={{ marginBottom: 10 }}>
                  <Col span={24}>
                    <Content
                      style={{
                        position: 'relative',
                        background: '#fff',
                        padding: 12,
                        margin: 0
                      }}
                    >
                      <i className="fa fa-fw fa-info-circle" /> Interactions panel allows you to add complex scripts to the website.
                    </Content>
                  </Col>
                </Row>
                <Row gutter={24} style={{ marginBottom: 10 }}>
                  <Col span={24}>
                    <Content
                      style={{
                        position: 'relative',
                        background: '#fff',
                        padding: 12,
                        margin: 0
                      }}
                    >
                      <SimpleButton onClick={() => this.createNewBehaviour()}>
                        <i className="fal fa-shapes"></i> New Interaction
                      </SimpleButton>
                      <SimpleButton onClick={() => this.duplicateBehaviour()}>
                        <i className="fal fa-copy"></i> Duplicate
                      </SimpleButton>
                      <SimpleButton onClick={() => this.removeBehaviour()}>
                        <i className="fal fa-trash-alt"></i> Remove
                      </SimpleButton>
                    </Content>
                  </Col>
                </Row>
                {
                  editor &&
                  (
                    <Row gutter={24} style={{ marginBottom: 10 }}>
                      <Col span={24}>
                        <Content
                          style={{
                            position: 'relative',
                            background: '#fff',
                            padding: 12,
                            margin: 0
                          }}
                        >
                          <Row gutter={4}>
                            <SimpleInput span={6}>
                              <span>behaviour name</span>
                              <input
                                placeholder="New Behaviour"
                                value={editor.name}
                                onChange={(e) => this.updateParam('name', e)}
                              />
                            </SimpleInput>
                            <Col span={12}></Col>
                            <SimpleInput span={6}>
                              <span>new block</span>
                              <select
                                onChange={(e) => {
                                  this.addBehaviourEditorBlock(e.target.value);
                                }}
                                value="reset"
                                defaultValue="reset"
                              >
                                <option disabled value="reset">Select block to add...</option>
                                <option disabled>--- Start ---</option>
                                <option value={0}>Event</option>
                                <option value={6}>Element</option>
                                <option value={7}>Behaviour Parameter</option>
                                <option value={10}>Color</option>
                                <option value={11}>Text</option>
                                <option value={12}>Appearance Property</option>
                                <option disabled>--- Reaction ---</option>
                                <option value={2}>Update Variable</option>
                                <option value={9}>Update Appearance</option>
                                <option value={5}>Email Inbox</option>
                                <option value={8}>Test Value</option>
                                <option disabled>--- Flow ---</option>
                                <option value={13}>If / Else</option>
                                <option disabled>--- End ---</option>
                                <option value={1}>Update View</option>
                                <option value={3}>Go To Page</option>
                                <option disabled>--- Misc ---</option>
                                <option value={14}>Comment</option>
                              </select>
                            </SimpleInput>
                          </Row>
                        </Content>
                      </Col>
                    </Row>
                  )
                }
                <Row gutter={24}>
                  <Col span={24}>
                    {editor && this.renderEditor()}
                  </Col>
                </Row>
              </Layout>
            </Layout>
          </Layout>
        </AntWrapper>
      );
    } catch (error) {
      if (typeof window === 'undefined') {
        return null;
      }

      message.error('Error, refresh page.', error);

      return null;
    }
  }
}
