import React from 'react';
import { VariableInput } from '../variable-input';
import { Row, Col } from 'antd';

export const renderVimeoProps = function () {
  const { tools } = this.state;
  const { selection } = tools;

  return (
    <Col span={8}>
      <Row gutter={4}>
        <VariableInput
          span={24}
          name="vimeo url"
          value={selection.branch.url}
          onChange={(value, params) => {
            this.updateSelectionParam('url', { target: { value }}, params);
          }}
        />
      </Row>
    </Col>
  );
}
