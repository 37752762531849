import React from 'react';
import { VariableInput } from '../variable-input';
import { Row, Col } from 'antd';

const shapeTypes = [
  'ellipse',
  'rectangle',
  'line',
  'wave'
];

export const renderShapeProps = function () {
  const { tools } = this.state;
  const { selection } = tools;

  return (
    <Col span={8}>
      <Row gutter={4}>
        <VariableInput
          span={24}
          name="shape type"
          inputType="select"
          value={selection.branch.variant || 'ellipse'}
          onChange={(value, params) => {
            this.updateSelectionParam('variant', { target: { value }}, params);
          }}
        >
          {shapeTypes.map(type => <option value={type}>{type}</option>)}
        </VariableInput>
        {selection.branch.variant === 'wave' && (
          <VariableInput
            span={24}
            name="wave frequency"
            inputType="range"
            value={selection.branch.waveFrequency}
            onChange={(value, params) => {
              this.updateSelectionParam('waveFrequency', { target: { value }}, params);
            }}
            inputProps={{
              min: 1,
              max: 64
            }}
          />
        )}
      </Row>
    </Col>
  );
}
