import * as Three from 'three';
import { generateLocalEnvMap } from '../utils/generate-local-env-map';
import { modelLoader, resourceCache, loadTexture } from '../utils/loaders';
import { extractVariable } from '../../wtl-schema/helpers/extract-variable';
import { materialPresets } from '../../src/data/common/materials';

const generateMaterial = (branch) => {
  let texture = null;
  let sharpenTexture = false;
  let pbrMap = null;
  let clearcoatMap = null;
  let normalMap = null;
  let refractionEnabled = false;
  let lightMap = null;
  let lightSrc = null;
  let lightIntensity = null;
  let color = null;
  let roughness = null;
  let metalness = null;
  let clearcoat = null;
  let clearcoatRoughness = null;
  let opacity = null;

  if (branch.materialId === 'custom') {
    texture = loadTexture(branch.textureUrl);
    sharpenTexture = branch.sharpenTexture || false;
    pbrMap = loadTexture(branch.pbrUrl);
    clearcoatMap = loadTexture(branch.clearcoatUrl);
    normalMap = loadTexture(branch.normalsUrl);
    refractionEnabled = branch.refractive;
    lightMap = loadTexture(branch.lightUrl);
    lightSrc = branch.lightSrc;
    lightIntensity = branch.lightIntensity;
    color = branch.color;
    roughness = branch.roughness;
    metalness = branch.metalness;
    opacity = typeof branch.opacity !== 'undefined' ? branch.opacity : (branch.opacity || 1.0);
    clearcoat = branch.clearcoat;
    clearcoatRoughness = branch.clearcoatRoughness;
  } else if (branch.materialId) {
    const preset = materialPresets[branch.materialId];

    texture = loadTexture(preset.textureUrl);
    sharpenTexture = preset.sharpenTexture || false;
    pbrMap = loadTexture(preset.pbrUrl);
    clearcoatMap = loadTexture(preset.clearcoatUrl);
    normalMap = loadTexture(preset.normalsUrl);
    refractionEnabled = preset.refractive;
    lightMap = loadTexture(preset.lightUrl);
    lightSrc = preset.lightSrc;
    lightIntensity = preset.lightIntensity;
    color = preset.color;
    roughness = preset.roughness;
    metalness = preset.metalness;
    opacity = typeof preset.opacity !== 'undefined' ? preset.opacity : (branch.opacity || 1.0);
    clearcoat = preset.clearcoat;
    clearcoatRoughness = preset.clearcoatRoughness;
  }

  if (sharpenTexture && texture) {
    texture.minFilter = Three.NearestFilter;
    texture.maxFilter = Three.NearestFilter;
  }

  const materialType = (clearcoatMap || clearcoat || clearcoatRoughness) ? 'MeshPhysicalMaterial' : 'MeshStandardMaterial';

  return new Three[materialType]({
    aoMap: pbrMap,
    emissive: lightSrc ? new Three.Color(lightSrc) : new Three.Color(0x000000),
    emissiveMap: lightMap,
    emissiveIntensity: lightIntensity || 1.0,
    metalnessMap: pbrMap,
    normalMap: normalMap,
    roughnessMap: pbrMap,
    clearcoatMap: clearcoatMap,
    clearcoatRoughnessMap: clearcoatMap,
    opacity: opacity,
    transparent: opacity < 1,
    map: texture,
    color: texture ? undefined : new Three.Color(color || 0x777777),
    roughness: pbrMap ? undefined : roughness || .5,
    metalness: pbrMap ? undefined : metalness || .5,
    refractionRatio: refractionEnabled ? 0.975 : 1.0,
    clearcoat: clearcoatMap ? 1.0 : clearcoat,
    clearcoatRoughness: clearcoatMap ? undefined : clearcoatRoughness,
  });
}

const createCube = (branch) => {
  const { sizeX, sizeY, sizeZ } = branch;
  const geometry = new Three.BoxBufferGeometry(sizeX, sizeY, sizeZ);
  const material = generateMaterial(branch);

  return new Three.Mesh(geometry, material);
}

const createPlane = (branch) => {
  const { sizeX, sizeZ } = branch;
  const geometry = new Three.BoxBufferGeometry(sizeX, 1.0, sizeZ);
  const material = generateMaterial(branch);
  return new Three.Mesh(geometry, material);
}

const createSphere = (branch) => {
  const { radius } = branch;
  const geometry = new Three.SphereBufferGeometry(radius || 1.0, 32, 32);
  const material = generateMaterial(branch);
  return new Three.Mesh(geometry, material);
}

const createImage = ({ sprite, imageSrc, sizeX, sizeZ }) => {
  const loader = new Three.TextureLoader();
  const image = loader.load(imageSrc);

  if (sprite) {
    const material = new Three.SpriteMaterial({
      map: image,
      color: 0xffffff
    });
    const sprite = new Three.Sprite(material);

    sprite.center.set(0.0, 1.0);
    sprite.scale.set(sizeX, sizeZ, 1.0);

    return sprite;
  } else {
    const geometry = new Three.PlaneBufferGeometry(sizeX, sizeZ);
    const material = new Three.MeshBasicMaterial({
      map: image,
      transparent: true,
      color: 0xffffff,
      side: Three.DoubleSide
    });

    return new Three.Mesh(geometry, material);
  }
}

const createArch = (branch, context) => {
  const { renderQuality } = context;
  const { sizeX, sizeY, sizeZ, archX1, archY1, archX2, archY2 } = branch;
  const shape = new Three.Shape();

  shape.moveTo(0, 0);
  shape.lineTo(0, 1);
  shape.lineTo(1, 1);
  shape.lineTo(1, 1 - archY1);
  shape.lineTo(archX1, 1 - archY1);
  shape.quadraticCurveTo(
    archX2, 1 - archY1,
    archX2, 1 - archY2
  );
  shape.lineTo(archX2, 0);
  shape.lineTo(0, 0);
  const geometry = new Three.ExtrudeBufferGeometry(shape, {
    steps: 1,
    depth: sizeZ,
    bevelEnabled: false,
    curveSegments: [2, 12, 12][renderQuality || 0]
  });
  geometry.scale(sizeX, sizeY, 1);
  geometry.translate(-sizeX / 2, -sizeY / 2, -sizeZ / 2);
  const material = generateMaterial(branch);

  return new Three.Mesh(geometry, material);
}

export const parseObject = (branch, context, options) => {
  const { _selfKey, parent } = options;
  const {
    positionX = 0,
    positionY = 0,
    positionZ = 0,
    rotationX = 0,
    rotationY = 0,
    rotationZ = 0,
    color = 0x333333,
    opacity = 1.0,
    primitive,
    modelUrl,
    modelScale = 1.0,
    materialId,
    details,
    _dirty
  } = branch;
  const { renderQuality } = context;

  if (!parent) {
    return;
  }

  let instance = parent.getObjectByName(_selfKey);

  if (!instance) {
    let richBranch = {
      ...branch,
      materialId: (details && details.materialOptions) ? details.materialOptions[branch.materialId || 0] : branch.materialId
    };

    if (modelUrl) {
      instance = new Three.Group();

      const escapedModelUrl = extractVariable(modelUrl);
      let promised = resourceCache[escapedModelUrl];

      if (!promised) {
        promised = resourceCache[escapedModelUrl] = new Promise((done) => {
          const extension = escapedModelUrl.split('.').splice(-1);

          (modelLoader[extension || 'obj'])().load(
            escapedModelUrl,
            (model) => {
              resourceCache[escapedModelUrl] = new Promise(done => done(model));

              done(model);
            },
            undefined,
            () => console.warn(`failed to load model: ${escapedModelUrl}`)
          );
        });
      }

      promised.then((model) => {
        instance.add(model.clone());

        const material = generateMaterial(richBranch);

        const applyToAll = (model) => {
          model.children.forEach((mesh) => {
            mesh.selectable = options.selectable;

            if (!richBranch.lightSrc) {
              mesh.castShadow = true;
              mesh.receiveShadow = true;
            }

            if (mesh.name && mesh.name.match(/^glass.*/gm)) {
              mesh.material = generateMaterial({ materialId: 'glass' });
              generateLocalEnvMap(richBranch, mesh, context, { top: instance });
              
              mesh.castShadow = false;
              mesh.receiveShadow = false;
            } else if (mesh.name && mesh.name.match(/^context.*/gm)) {
              
              if (richBranch.contextType === 'painting') {
                const paintingMaterial = generateMaterial({
                  materialId: 'custom',
                  textureUrl: [
                    'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/398fec89-22f1-49ed-aefd-cd9b9d4d4c38.jpg',
                    'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/dec75857-2442-43c3-90b4-56880467f2a1.jpg',
                    'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/fee10c71-3182-4bd0-8e64-74dd71b7fb64.png',
                    'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/2eee0d23-3d2a-4133-beba-16b85568e0b4.jpg'
                  ][Math.floor(Math.random() * 4)],
                  pbrUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/8984591d-fdb0-4782-85da-36684c5041a8.jpg',
                  normalsUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/819de472-6348-4446-9468-c5446eb7515d.png'
                });
                mesh.material = paintingMaterial;
              } else {
                mesh.visible = false;

                mesh.castShadow = false;
                mesh.receiveShadow = false;
              }
            } else if (mesh.name && mesh.name.match(/^bulb.*/gm)) {              
              if (renderQuality >= 2) {
                const bulb = new Three.PointLight(0xffffff, 1.0, 100.);
                bulb.position.x = mesh.position.x;
                bulb.position.y = mesh.position.y;
                bulb.position.z = mesh.position.z;
              
                bulb.castShadow = true;
                bulb.shadow.mapSize.width = [256, 512, 2048][renderQuality || 0];
                bulb.shadow.mapSize.height = [256, 512, 2048][renderQuality || 0];
                bulb.shadow.camera.near = .2;
                bulb.shadow.camera.far = 100.;
                bulb.shadow.bias = 0.00001;
                bulb.shadow.camera.scale.set(20, 20, 20);

                instance.add(bulb);
              }
            } else {
              mesh.material = material;
              generateLocalEnvMap(richBranch, mesh, context, { top: instance });
            }

            if (mesh.children) {
              applyToAll(mesh);
            }
          });
        };
  
        applyToAll(instance);

        if (!richBranch.lightSrc) {
          generateLocalEnvMap(richBranch, instance, context);
        }
      });
    } else {
      switch (primitive) {
        case 'sphere':
          instance = createSphere(richBranch);
        break;
        case 'plane':
          instance = createPlane(richBranch);
        break;
        case 'cube':
          instance = createCube(richBranch);
        break;
        case 'image':
          instance = createImage(richBranch);
        break;
        case 'arch':
          instance = createArch(richBranch, context);
        break;
        default:
          instance = createSphere(richBranch);
        break;
      }
    }
    
    if (primitive !== 'image' && !branch.lightSrc) {
      instance.castShadow = true;
    }
    
    instance.selectable = options.selectable;
    instance.selectionTarget = options.selectionTarget;
    instance.receiveShadow = true;

    if (opacity >= 1 && !branch.lightSrc) {
      generateLocalEnvMap(branch, instance, context);
    }

    parent.add(instance);
  }

  instance.position.x = positionX;
  instance.position.y = positionY;
  instance.position.z = positionZ;

  instance.rotation.x = rotationX;
  instance.rotation.y = rotationY;
  instance.rotation.z = rotationZ;

  instance.scale.set(modelScale || 1.0, modelScale || 1.0, modelScale || 1.0);

  // if (_dirty === 1 && renderQuality >= 2 && opacity >= 1 && !branch.lightSrc) {
  // generateLocalEnvMap(branch, instance, context);
  // }

  return instance;
};
