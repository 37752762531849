import ReactGA from 'react-ga';
import { callApi } from '../../config';
import { navigate } from 'gatsby';
import { message } from 'antd';

export const SAFE_UNDEFINED = '__undefined__';
export const authTokenKey = 'sessionId';
export const livePreviewKey = 'liveUrl';
export const serviceKey = 'service';
export const demoKey = 'demoSession';

class EditorService {
  authValid = false;
  refreshingAuth = false;

  isLoggedIn() {
    return typeof window !== 'undefined' && this.authValid;
  }

  login(email, password) {
    return new Promise((resolve, reject) => {
      callApi(
        'project/auth',
        'POST',
        {
          email,
          password
        }
      ).then(({ token, live, service }) => {
        sessionStorage[authTokenKey] = token;
        sessionStorage[livePreviewKey] = live;
        sessionStorage[serviceKey] = service;

        ReactGA.event({
          category: 'Usage',
          action: 'Log In Success'
        });

        this.authValid = true;
        resolve();
      })
      .catch(() => {
        ReactGA.event({
          category: 'Usage',
          action: 'Log In Error'
        });

        this.authValid = false;
        reject();
      });
    });
  }

  rememberDemo(value) {
    if (typeof window === 'undefined') {
      return;
    }

    sessionStorage[demoKey] = value;
  }

  isDemo() {
    return JSON.parse(sessionStorage[demoKey]);
  }

  refreshAuth(done, error) {
    if (typeof window === 'undefined') {
      return this.failAuth();
    }
  
    const key = sessionStorage[authTokenKey];
  
    if (!key) {
      return this.failAuth();
    }

    if (!this.refreshingAuth) {
      this.refreshingAuth = true;

      callApi(
        'project/auth',
        'POST',
        undefined,
        {
          Authorization: `Bearer ${key}`
        }
      )
      .then(({ token, live }) => {
        sessionStorage[authTokenKey] = token;
        sessionStorage[livePreviewKey] = live;
        this.refreshingAuth = false;
        this.authValid = true;

        if (typeof done === 'function') {
          done();
        }
      })
      .catch(() => {
        this.refreshingAuth = false;

        if (typeof error === 'function') {
          error();
        } else {
          this.failAuth();
        }
      });
    }
  }

  failAuth() {
    this.authValid = false;
    this.goToAuthPage();
  }

  goToAuthPage() {
    if (typeof window === 'undefined') {
      return;
    }

    navigate('/login');
  }
}

export default new EditorService();
