import React from 'react';
import styled from 'styled-components';
import { Col, Row, Switch, Divider, Radio, message } from 'antd';
import { VariableInput } from './variable-input';
import { Link } from 'gatsby';
import StateService from '../services/state.service';
import { defaultFonts } from '../components/rich-text-editor';
import { WtlTooltip } from './layout/wtl-tooltip';
import { imageFileTypes } from '../data/file-types.data';
import { groupBlocks } from '../data/block-types.data';

export const WtlStyleCategoryWrapperStyle = styled.div`
  position: relative;
  margin: 2px;
  margin-top: 20px;
  padding: 8px;
  width: 100%;
  border: solid 1px #ddd;
  background-color: #fff;
  user-select: none;

  .title {
    position: absolute;
    display: inline-block;
    top: -12px;
    left: 2px;
    padding: 2px 4px;
    background-color: ${p => p.colorCode || '#fff'};
    border-radius: 3px;
    font-size: 9px;
    font-weight: bold;
    z-index: 1;
    user-select: none;
    cursor: pointer;
  }
`;

export const WtlStyleCategoryCollapsedWrapperStyle = styled.div`
  position: relative;
  margin: 2px;
  margin-top: 8px;
  padding: 8px;
  padding-left: 28px;
  width: 100%;
  background-color: #eee;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color .3s ease;
  font-size: 12px;
  text-transform: capitalize;
  overflow: hidden;
  user-select: none;

  &:hover {
    background-color: #f5f5f5;
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 100%;
    z-index: 1;
    content: '';
    background-color: ${p => p.colorCode || '#fff'}
  }
`;

const WtlBlockBackgroundTypes = [
  'color',
  'image',
  'gradient'
];

const WtlBlockBackgroundRepeat = [
  { key: 'don\'t repeat', value: 'no-repeat' },
  { key: 'repeat horizontally', value: 'repeat-x' },
  { key: 'repeat vertically', value: 'repeat-y' }
];

const WtlBlockBackgroundSize = [
  { key: 'original size', value: 'auto' },
  { key: 'scale to fit container', value: 'contain' },
  { key: 'scale to cover container', value: 'cover' },
  { key: 'scale to cover window', value: '100%' }
];

const WtlBlockBackgroundGradientDirection = [
  'horizontal',
  'vertical',
  'radial'
];

const WtlTextStyle = [
  'normal',
  'bold',
  'italic'
];

const WtlPositionSnapType = [
  { key: 'block', value: 'relative' },
  { key: 'page', value: 'absolute' }
];

const WtlBorderStyle = [
  'solid',
  'dashed'
];

const EditMode = {
  collapsed: 0,
  basic: 1,
  advanced: 2
};

const WtlStyledNote = ({text, icon} = {}) => {
  return (
    <div style={{ textAlign: 'center', fontSize: 10, opacity: 0.9, padding: 10, cursor: 'default' }}>
      {icon && <div className={`far fa-${icon}`} />} {text}
    </div>
  );
};

const WtlSmallDivider = () => (
  <Row>
    <div style={{ borderTop: 'solid 1px #eee', margin: '8px 0' }} />
  </Row>
);

class WtlStyleCategoryWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: props.defaultExpanded ? false : true
    };
  }

  render() {
    const { children, title, color } = this.props;
    const { collapsed } = this.state;

    if (collapsed) {
      return (
        <WtlStyleCategoryCollapsedWrapperStyle
          colorCode={color}
          onClick={() => collapsed ? this.setState({ collapsed: false }) : null}
        >
          {title}
        </WtlStyleCategoryCollapsedWrapperStyle>
      );
    }

    return (
      <WtlStyleCategoryWrapperStyle
        onClick={() => collapsed ? this.setState({ collapsed: false }) : null}
        colorCode={color}
      >
        <div
          className="title"
          onClick={() => this.setState({ collapsed: !collapsed })}
        >
          {title} {collapsed ? (
            <i className="fas fa-caret-down"></i>
          ) : (
            <i className="fas fa-caret-up"></i>
          )}
        </div>
        {!collapsed ? children : (
          <WtlStyledNote icon="paint-brush" text={`Click to edit ${title}`} />
        )}
      </WtlStyleCategoryWrapperStyle>
    );
  }
}

export class BlockStyleEditor extends React.Component {
  state = {
    selectionId: '',
    sizePropsMode: EditMode.basic,
    positionPropsMode: EditMode.basic,
    styleState: ''
  };

  constructor(props) {
    super(props);

    this.refreshControls();
  }

  componentDidMount() {
    this.refreshControls();
  }

  componentDidUpdate() {
    this.refreshControls();
  }

  refreshControls() {
    const { selectionId } = this.state;
    const { selection } = this.props;

    if (selectionId !== selection.branch.id) {
      this.state.selectionId = selection.branch.id;
      this.state.styleState = '';

      this.state.sizePropsMode = selection.branch.cssWidth ? EditMode.advanced : EditMode.basic;

      this.forceUpdate();
    }
  }

  getStateScoped(styleId) {
    const { styleState } = this.state;

    return `${styleId}${styleState ? styleState.substr(0, 1).toUpperCase() + styleState.substr(1) : ''}`;
  }

  clampSize(primary, secondary) {
    if (typeof primary !== 'undefined' && typeof primary === 'number') {
      return primary;
    }

    return secondary;
  }

  renderResponsiveProperties() {
    const { selection, updateSelectionParam, debugPreview } = this.props;
    const { sizePropsMode } = this.state;

    const _size = this.getStateScoped('size');
    const _sizeTablet = this.getStateScoped('sizeTablet');
    const _sizeMobile = this.getStateScoped('sizeMobile');
    const _cssWidth = this.getStateScoped('cssWidth');
    const _cssHeight = this.getStateScoped('cssHeight');
    const _cssPositionAlign = this.getStateScoped('cssPositionAlign');
    const _cssPositionOffsetX = this.getStateScoped('cssPositionOffsetX');
    const _cssPositionOffsetY = this.getStateScoped('cssPositionOffsetY');
    const cssAutoHeight = selection.branch.cssAutoHeight;

    const autoWidthSwitch = (
      <VariableInput
        nonVariable
        span={2}
        inputType="switch"
        inputProps={{
          disabled: ['advanced-background-block', 'root'].includes(selection.branch.type)
        }}
        value={sizePropsMode === EditMode.basic}
        onChange={value => {
          if (value) {
            this.state.sizePropsMode = EditMode.basic;

            message.info('Enabled: Responsive Width');
            updateSelectionParam(_size, { target: { value: '24' } }, { parseValue: true });
            updateSelectionParam(_sizeTablet, { target: { value: undefined } }, { parseValue: true });
            updateSelectionParam(_sizeMobile, { target: { value: undefined } }, { parseValue: true });
            updateSelectionParam(_cssWidth, { target: { value: undefined } }, { parseValue: false });
            updateSelectionParam(_cssPositionAlign, { target: { value: undefined } }, { parseValue: false });
            updateSelectionParam(_cssPositionOffsetX, { target: { value: undefined } }, { parseValue: false });
            updateSelectionParam(_cssPositionOffsetY, { target: { value: undefined } }, { parseValue: false });
          } else {
            this.state.sizePropsMode = EditMode.advanced;

            message.info('Disabled: Responsive Width');
            updateSelectionParam(_cssWidth, { target: { value: '100px' } }, { parseValue: false });
            updateSelectionParam(_cssPositionAlign, { target: { value: 'top left' } }, { parseValue: false });
            updateSelectionParam(_cssPositionOffsetX, { target: { value: '0%' } }, { parseValue: false });
            updateSelectionParam(_cssPositionOffsetY, { target: { value: '0px' } }, { parseValue: false });
          }

          this.forceUpdate();
        }}
        name="auto"
      />
    );

    const heightInput = (
      <>
        <VariableInput
          nonVariable
          span={2}
          inputType="switch"
          value={cssAutoHeight}
          onChange={value => {
            if (value) {
              message.info('Enabled: Responsive Height');
            } else {
              message.info('Disabled: Responsive Height');
            }

            updateSelectionParam('cssAutoHeight', { target: { value } }, { parseValue: false });
            this.forceUpdate();
          }}
          name="auto"
        />
        <VariableInput
          span={22}
          value={selection.branch[_cssHeight]}
          onChange={(value, params) => {
            updateSelectionParam(_cssHeight, { target: { value }}, params);
          }}
          inputType="css-prop"
          name="height"
        />
      </>
    );
    let sizeInput = null;
    let visibilityInput = null;

    if (['advanced-background-block', 'root'].includes(selection.branch.type)) {
      sizeInput = null;
    } else if (sizePropsMode === EditMode.basic) {
      const sizeArrow = (
        <i
          style={{
            color: '#0077e3'
          }}
          className="fas fa-fw fa-caret-left"
        />
      );

      sizeInput = (
        <Col span={24}>
          <Row>
            {autoWidthSwitch}
          </Row>
          <VariableInput
            nonVariable
            span={21}
            onChange={(value, params) => {
              updateSelectionParam(_size, { target: { value } }, { parseValue: true });
            }}
            inputType="range"
            inputProps={{
              min: 1,
              max: 24,
              disabled: selection.branch[_size] === -1
            }}
            value={selection.branch[_size] === -1 ? 1 : selection.branch[_size]}
            name={
              <>
                <div className="far fa-phone-laptop" /> desktop {debugPreview === 'desktop' && sizeArrow}
              </>
            }
          ></VariableInput>
          <VariableInput
            nonVariable
            span={3}
            inputType="switch"
            value={selection.branch[_size] === -1}
            onChange={(value, params) => {
              if (value) {
                updateSelectionParam(_size, { target: { value: '-1' } }, { parseValue: true });
              } else {
                updateSelectionParam(_size, { target: { value: '24' } }, { parseValue: true });
              }
            }}
            name="hidden"
          ></VariableInput>
          <VariableInput
            nonVariable
            span={21}
            onChange={(value, params) => {
              updateSelectionParam(_sizeTablet, { target: { value } }, { parseValue: true });
            }}
            inputType="range"
            inputProps={{
              min: 1,
              max: 24,
              disabled: selection.branch[_sizeTablet] === -1
            }}
            value={selection.branch[_sizeTablet] === -1 ? 1 : this.clampSize(selection.branch[_sizeTablet], selection.branch[_size])}
            name={
              <>
                <div className="far fa-tablet" /> tablet {debugPreview === 'tablet' && sizeArrow}
              </>
            }
          ></VariableInput>
          <VariableInput
            nonVariable
            span={3}
            inputType="switch"
            value={selection.branch[_sizeTablet] === -1}
            onChange={(value, params) => {
              if (value) {
                updateSelectionParam(_sizeTablet, { target: { value: '-1' } }, { parseValue: true });
              } else {
                updateSelectionParam(_sizeTablet, { target: { value: '24' } }, { parseValue: true });
              }
            }}
            name="hidden"
          ></VariableInput>
          <VariableInput
            nonVariable
            span={21}
            onChange={(value, params) => {
              updateSelectionParam(_sizeMobile, { target: { value } }, { parseValue: true });
            }}
            inputType="range"
            inputProps={{
              min: 1,
              max: 24,
              disabled: selection.branch[_sizeMobile] === -1,
              defaultValue: 24
            }}
            value={selection.branch[_sizeMobile] === -1 ? 1 : this.clampSize(selection.branch[_sizeMobile], selection.branch[_size])}
            name={
              <>
                <div className="far fa-mobile" /> mobile {debugPreview === 'mobile' && sizeArrow}
              </>
            }
          ></VariableInput>
          <VariableInput
            nonVariable
            span={3}
            inputType="switch"
            value={selection.branch[_sizeMobile] === -1}
            onChange={(value, params) => {
              if (value) {
                updateSelectionParam(_sizeMobile, { target: { value: '-1' } }, { parseValue: true });
              } else {
                updateSelectionParam(_sizeMobile, { target: { value: '24' } }, { parseValue: true });
              }
            }}
            name="hidden"
          ></VariableInput>
        </Col>
      );
    } else if (sizePropsMode === EditMode.advanced) {
      sizeInput = (
        <>
          {autoWidthSwitch}
          <VariableInput
            span={22}
            value={selection.branch[_cssWidth]}
            onChange={(value, params) => {
              updateSelectionParam(_cssWidth, { target: { value }}, params);
            }}
            inputType="css-prop"
            name="width"
          />
        </>
      );

      visibilityInput = (
        <Col span={24}>
          <WtlSmallDivider />
          <Row>
            <VariableInput
              nonVariable
              span={8}
              inputType="switch"
              value={selection.branch[_size] === -1}
              onChange={(value, params) => {
                if (value) {
                  updateSelectionParam(_size, { target: { value: '-1' } }, { parseValue: true });
                } else {
                  updateSelectionParam(_size, { target: { value: undefined } }, { parseValue: true });
                }
              }}
              name={<><div className="far fa-phone-laptop" /> hidden on desktop</>}
            ></VariableInput>
            <VariableInput
              nonVariable
              span={8}
              inputType="switch"
              value={selection.branch[_sizeTablet] === -1}
              onChange={(value, params) => {
                if (value) {
                  updateSelectionParam(_sizeTablet, { target: { value: '-1' } }, { parseValue: true });
                } else {
                  updateSelectionParam(_sizeTablet, { target: { value: undefined } }, { parseValue: true });
                }
              }}
              name={<><div className="far fa-tablet" /> hidden on tablet</>}
            ></VariableInput>
            <VariableInput
              nonVariable
              span={8}
              inputType="switch"
              value={selection.branch[_sizeMobile] === -1}
              onChange={(value, params) => {
                if (value) {
                  updateSelectionParam(_sizeMobile, { target: { value: '-1' } }, { parseValue: true });
                } else {
                  updateSelectionParam(_sizeMobile, { target: { value: undefined } }, { parseValue: true });
                }
              }}
              name={<><div className="far fa-mobile" /> hidden on mobile</>}
            ></VariableInput>
          </Row>
        </Col>
      );
    }

    if (!sizeInput && !heightInput) {
      return (
        <WtlStyledNote
          text="Cannot adjust size"
        />
      );
    }
    
    return (
      <Row gutter={4}>
        {sizeInput}
        {heightInput}
        {visibilityInput}
      </Row>
    );
  }

  renderPositionProperties() {
    const { selection, updateSelectionParam } = this.props;

    const _cssPositionAlign = this.getStateScoped('cssPositionAlign');
    const _cssPositionSnap = this.getStateScoped('cssPositionSnap');
    const _cssPositionOffsetX = this.getStateScoped('cssPositionOffsetX');
    const _cssPositionOffsetY = this.getStateScoped('cssPositionOffsetY');

    if (this.state.sizePropsMode === EditMode.basic) {
      return (
        <Row gutter={4}>
          <Col span={24}>
            <WtlStyledNote
              text={<>Disable responsive columns in <b>size</b> to adjust position</>}
            />
          </Col>
        </Row>
      );
    }

    return (
      <Row gutter={4}>
        <VariableInput
          nonVariable
          span={24}
          value={selection.branch[_cssPositionAlign]}
          onChange={(value, params) => {
            updateSelectionParam(_cssPositionAlign, { target: { value }}, params);
          }}
          inputType="position"
          name="align"
        ></VariableInput>
        <VariableInput
          span={12}
          value={selection.branch[_cssPositionOffsetX]}
          onChange={(value, params) => {
            updateSelectionParam(_cssPositionOffsetX, { target: { value }}, params);
          }}
          inputType="css-prop"
          name="horizontal"
        ></VariableInput>
        <VariableInput
          span={12}
          value={selection.branch[_cssPositionOffsetY]}
          onChange={(value, params) => {
            updateSelectionParam(_cssPositionOffsetY, { target: { value }}, params);
          }}
          inputType="css-prop"
          name="vertical"
        ></VariableInput>
      </Row>
    );
  }

  renderContentRenderingProperties() {
    const { selection, updateSelectionParam } = this.props;

    const _cssContentAlign = this.getStateScoped('cssContentAlign');
    const _cssContentOverflow = this.getStateScoped('cssContentOverflow');
    const _3dOffsetX = this.getStateScoped('_3dOffsetX');
    const _3dOffsetY = this.getStateScoped('_3dOffsetY');
    const _3dOffsetZ = this.getStateScoped('_3dOffsetZ');
    const _3dAngleX = this.getStateScoped('_3dAngleX');
    const _3dAngleY = this.getStateScoped('_3dAngleY');
    const _3dAngleZ = this.getStateScoped('_3dAngleZ');
    const _3dUserOrbit = this.getStateScoped('_3dUserOrbit');
    const _3dUserZoom = this.getStateScoped('_3dUserZoom');
    const _3dAngleSpeedX = this.getStateScoped('_3dAngleSpeedX');
    const _3dAngleSpeedY = this.getStateScoped('_3dAngleSpeedY');
    const _3dAngleSpeedZ = this.getStateScoped('_3dAngleSpeedZ');

    let alignInput, maskInput;

    if (groupBlocks.includes(selection.branch.type)) {
      alignInput = null;
    } else {
      alignInput = (
        <VariableInput
          nonVariable
          span={24}
          value={selection.branch[_cssContentAlign]}
          onChange={(value, params) => {
            updateSelectionParam(_cssContentAlign, { target: { value }}, params);
          }}
          inputType="position"
          name="align content"
        ></VariableInput>
      );
    }

    if (['advanced-background-block'].includes(selection.branch.type)) {
      maskInput = null;
    } else {
      maskInput = <VariableInput
        nonVariable
        span={12}
        value={selection.branch[_cssContentOverflow]}
        onChange={(value, params) => {
          updateSelectionParam(_cssContentOverflow, { target: { value }}, params);
        }}
        inputType="switch"
        name="mask content"
      />;
    }
    
    if (!alignInput && !maskInput) {
      return (
        <WtlStyledNote
          text="No content to adjust :("
        />
      );
    }

    if (['advanced-3d-model'].includes(selection.branch.type)) {
      return (
        <>
          <Row gutter={4}>
            <VariableInput
              span={8}
              value={selection.branch[_3dOffsetZ]}
              onChange={(value, params) => {
                updateSelectionParam(_3dOffsetZ, { target: { value }}, params);
                updateSelectionParam('_3dKey', { target: { value: Date.now() }}, params);
              }}
              name="zoom"
              inputType="css-prop"
            />
            <VariableInput
              span={8}
              value={selection.branch[_3dOffsetX]}
              onChange={(value, params) => {
                updateSelectionParam(_3dOffsetX, { target: { value }}, params);
                updateSelectionParam('_3dKey', { target: { value: Date.now() }}, params);
              }}
              name="offset x"
              inputType="css-prop"
            />
            <VariableInput
              span={8}
              value={selection.branch[_3dOffsetY]}
              onChange={(value, params) => {
                updateSelectionParam(_3dOffsetY, { target: { value }}, params);
                updateSelectionParam('_3dKey', { target: { value: Date.now() }}, params);
              }}
              name="offset y"
              inputType="css-prop"
            />
            <VariableInput
              span={8}
              value={selection.branch[_3dAngleX]}
              onChange={(value, params) => {
                updateSelectionParam(_3dAngleX, { target: { value }}, params);
                updateSelectionParam('_3dKey', { target: { value: Date.now() }}, params);
              }}
              name="pitch (x)"
              inputType="css-prop"
            />
            <VariableInput
              span={8}
              value={selection.branch[_3dAngleY]}
              onChange={(value, params) => {
                updateSelectionParam(_3dAngleY, { target: { value }}, params);
                updateSelectionParam('_3dKey', { target: { value: Date.now() }}, params);
              }}
              name="yaw (y)"
              inputType="css-prop"
            />
            <VariableInput
              span={8}
              value={selection.branch[_3dAngleZ]}
              onChange={(value, params) => {
                updateSelectionParam(_3dAngleZ, { target: { value }}, params);
                updateSelectionParam('_3dKey', { target: { value: Date.now() }}, params);
              }}
              name="roll (z)"
              inputType="css-prop"
            />
          </Row>
          <WtlSmallDivider />
          <Row gutter={4}>
            <VariableInput
              nonVariable
              span={12}
              value={selection.branch[_3dUserOrbit]}
              onChange={(value, params) => {
                updateSelectionParam(_3dUserOrbit, { target: { value }}, params);
                updateSelectionParam('_3dKey', { target: { value: Date.now() }}, params);
              }}
              name="allow rotating"
              inputType="switch"
            />
            <VariableInput
              nonVariable
              span={12}
              inputProps={{
                disabled: !selection.branch[_3dUserOrbit]
              }}
              value={selection.branch[_3dUserOrbit] && selection.branch[_3dUserZoom]}
              onChange={(value, params) => {
                updateSelectionParam(_3dUserZoom, { target: { value }}, params);
                updateSelectionParam('_3dKey', { target: { value: Date.now() }}, params);
              }}
              name="allow zooming"
              inputType="switch"
            />
          </Row>
          <WtlSmallDivider />
          <Row gutter={4}>
            <VariableInput
              span={8}
              value={selection.branch[_3dAngleSpeedX]}
              onChange={(value, params) => {
                updateSelectionParam(_3dAngleSpeedX, { target: { value }}, params);
                updateSelectionParam('_3dKey', { target: { value: Date.now() }}, params);
              }}
              name="pitch (x) velocity"
              inputType="css-prop"
            />
            <VariableInput
              span={8}
              value={selection.branch[_3dAngleSpeedY]}
              onChange={(value, params) => {
                updateSelectionParam(_3dAngleSpeedY, { target: { value }}, params);
                updateSelectionParam('_3dKey', { target: { value: Date.now() }}, params);
              }}
              name="yaw (y) velocity"
              inputType="css-prop"
            />
            <VariableInput
              span={8}
              value={selection.branch[_3dAngleSpeedZ]}
              onChange={(value, params) => {
                updateSelectionParam(_3dAngleSpeedZ, { target: { value }}, params);
                updateSelectionParam('_3dKey', { target: { value: Date.now() }}, params);
              }}
              name="roll (z) velocity"
              inputType="css-prop"
            />
          </Row>
        </>
      );
    }

    return (
      <Row gutter={4}>
        {alignInput}
        {maskInput}
      </Row>
    );
  }

  renderInnerPaddingProperties() {
    const { selection, updateSelectionParam } = this.props;

    const _cssPaddingTop = this.getStateScoped('cssPaddingTop');
    const _cssPaddingBottom = this.getStateScoped('cssPaddingBottom');
    const _cssPaddingLeft = this.getStateScoped('cssPaddingLeft');
    const _cssPaddingRight = this.getStateScoped('cssPaddingRight');

    return (
      <Row gutter={4}>
        <VariableInput
          span={12}
          value={selection.branch[_cssPaddingTop]}
          onChange={(value, params) => {
            updateSelectionParam(_cssPaddingTop, { target: { value }}, params);
          }}
          inputType="css-prop"
          name="top"
        ></VariableInput>
        <VariableInput
          span={12}
          value={selection.branch[_cssPaddingBottom]}
          onChange={(value, params) => {
            updateSelectionParam(_cssPaddingBottom, { target: { value }}, params);
          }}
          inputType="css-prop"
          name="bottom"
        ></VariableInput>
        <VariableInput
          span={12}
          value={selection.branch[_cssPaddingLeft]}
          onChange={(value, params) => {
            updateSelectionParam(_cssPaddingLeft, { target: { value }}, params);
          }}
          inputType="css-prop"
          name="left"
        ></VariableInput>
        <VariableInput
          span={12}
          value={selection.branch[_cssPaddingRight]}
          onChange={(value, params) => {
            updateSelectionParam(_cssPaddingRight, { target: { value }}, params);
          }}
          inputType="css-prop"
          name="right"
        ></VariableInput>
      </Row>
    )
  }

  renderOuterPaddingProperties() {
    const { selection, updateSelectionParam } = this.props;

    const _cssMarginTop = this.getStateScoped('cssMarginTop');
    const _cssMarginBottom = this.getStateScoped('cssMarginBottom');
    const _cssMarginLeft = this.getStateScoped('cssMarginLeft');
    const _cssMarginRight = this.getStateScoped('cssMarginRight');

    return (
      <Row gutter={4}>
        <VariableInput
          span={12}
          value={selection.branch[_cssMarginTop]}
          onChange={(value, params) => {
            updateSelectionParam(_cssMarginTop, { target: { value }}, params);
          }}
          inputType="css-prop"
          name="top"
        ></VariableInput>
        <VariableInput
          span={12}
          value={selection.branch[_cssMarginBottom]}
          onChange={(value, params) => {
            updateSelectionParam(_cssMarginBottom, { target: { value }}, params);
          }}
          inputType="css-prop"
          name="bottom"
        ></VariableInput>
        <VariableInput
          span={12}
          value={selection.branch[_cssMarginLeft]}
          onChange={(value, params) => {
            updateSelectionParam(_cssMarginLeft, { target: { value }}, params);
          }}
          inputType="css-prop"
          name="left"
        ></VariableInput>
        <VariableInput
          span={12}
          value={selection.branch[_cssMarginRight]}
          onChange={(value, params) => {
            updateSelectionParam(_cssMarginRight, { target: { value }}, params);
          }}
          inputType="css-prop"
          name="right"
        ></VariableInput>
      </Row>
    )
  }

  renderPresetSelector() {
    const { selection, updateSelectionParam } = this.props;
    const library = StateService.getLibrary();
    
    return (
      <Row gutter={4}>
        <VariableInput
          span={8}
          name={
            <>
              shared style
              {' '}
              {StateService.getFeatureToggle('advanced_csseditor') && <Link
                to="#"
                disabled={!selection.branch || !selection.branch.type}
                onClick={(event) => {
                  event.preventDefault();

                  const id = selection.branch && selection.branch.type;

                  if (!id) {
                    return;
                  }

                  const styleName = typeof window !== 'undefined' && window.prompt('New style name:', `${id}-custom-style`);

                  if (!styleName) {
                    return;
                  }

                  const libraryElement = StateService.createNewLibraryElement(styleName);
                  StateService.rememberEditedElement('library', libraryElement.id);
                  StateService.saveLibrary();

                  updateSelectionParam('customWrapperElement', { target: { value: libraryElement.id }});

                  this.forceUpdate();
                }}
              >
                (add new style)
              </Link>}
            </>
          }
          value={selection.branch.customWrapperElement}
          onChange={(value, params) => {
            updateSelectionParam('customWrapperElement', { target: { value }}, params);
          }}
          inputType="select"
        >
          {
            library && library.map((item) => (
              <option value={item.id}>
                {item.name}  
              </option>  
            ))
          }
        </VariableInput>
      </Row>
    );
  }

  renderBackgroundColorProperties() {
    const { selection, updateSelectionParam } = this.props;

    const _cssBackgroundColor = this.getStateScoped('cssBackgroundColor');

    return (
      <>
        <VariableInput
          span={12}
          value={selection.branch[_cssBackgroundColor]}
          onChange={(value, params) => {
            updateSelectionParam(_cssBackgroundColor, { target: { value }}, params);
          }}
          inputType="color"
          name="color"
        >
        </VariableInput>
      </>
    );
  }

  renderBackgroundGradientProperties() {
    const { selection, updateSelectionParam } = this.props;

    const _cssBackgroundGradientDirection = this.getStateScoped('cssBackgroundGradientDirection');
    const _cssBackgroundColor = this.getStateScoped('cssBackgroundColor');
    const _cssBackgroundColorB = this.getStateScoped('cssBackgroundColorB');

    return (
      <>
        <VariableInput
          span={24}
          value={0}
          value={selection.branch[_cssBackgroundGradientDirection]}
          onChange={(value, params) => {
            updateSelectionParam(_cssBackgroundGradientDirection, { target: { value }}, params);
          }}
          inputType="select"
          name="direction"
        >
          {WtlBlockBackgroundGradientDirection.map(type => (
            <option value={type}>{type}</option>
          ))}
        </VariableInput>
        <VariableInput
          span={12}
          value={selection.branch[_cssBackgroundColor]}
          onChange={(value, params) => {
            updateSelectionParam(_cssBackgroundColor, { target: { value }}, params);
          }}
          inputType="color"
          name="start color"
        ></VariableInput>
        <VariableInput
          span={12}
          value={selection.branch[_cssBackgroundColorB]}
          onChange={(value, params) => {
            updateSelectionParam(_cssBackgroundColorB, { target: { value }}, params);
          }}
          inputType="color"
          name="end color"
        ></VariableInput>
      </>
    );
  }

  renderBackgroundImageProperties() {
    const { selection, updateSelectionParam } = this.props;

    const _cssBackgroundImage = this.getStateScoped('cssBackgroundImage');
    const _cssBackgroundPosition = this.getStateScoped('cssBackgroundPosition');
    const _cssBackgroundRepeat = this.getStateScoped('cssBackgroundRepeat');
    const _cssBackgroundFit = this.getStateScoped('cssBackgroundFit');
    const _cssBackgroundSharpen = this.getStateScoped('cssBackgroundSharpen');

    return (
      <>
        <VariableInput
          span={24}
          value={selection.branch[_cssBackgroundImage]}
          onChange={(value, params) => {
            updateSelectionParam(_cssBackgroundImage, { target: { value }}, params);
          }}
          inputType="file"
          filterTypes={imageFileTypes}
          name="image"
        ></VariableInput>
        <Col span={12}>
          <VariableInput
            nonVariable
            span={24}
            value={selection.branch[_cssBackgroundPosition]}
            onChange={(value, params) => {
              updateSelectionParam(_cssBackgroundPosition, { target: { value }}, params);
            }}
            inputType="position"
            name="position"
          ></VariableInput>
        </Col>
        <Col span={12}>
          <VariableInput
            span={24}
            value={selection.branch[_cssBackgroundRepeat]}
            onChange={(value, params) => {
              updateSelectionParam(_cssBackgroundRepeat, { target: { value }}, params);
            }}
            inputType="select"
            name="repeat"
          >
            {WtlBlockBackgroundRepeat.map(({ key, value }) => (
              <option value={value}>{key}</option>
            ))}
          </VariableInput>
          <VariableInput
            span={24}
            value={selection.branch[_cssBackgroundFit]}
            onChange={(value, params) => {
              updateSelectionParam(_cssBackgroundFit, { target: { value }}, params);
            }}
            inputType="select"
            name="fit"
          >
            {WtlBlockBackgroundSize.map(({ key, value }) => (
              <option value={value}>{key}</option>
            ))}
          </VariableInput>
          <VariableInput
            nonVariable
            span={24}
            value={selection.branch[_cssBackgroundSharpen]}
            onChange={(value, params) => {
              updateSelectionParam(_cssBackgroundSharpen, { target: { value }}, params);
            }}
            inputType="switch"
            name="sharpen"
          />
        </Col>
      </>
    );
  }

  renderBorderProperties() {
    const { selection, updateSelectionParam } = this.props;

    const _cssBorderStyle = this.getStateScoped('cssBorderStyle');
    const _cssBorderColor = this.getStateScoped('cssBorderColor');
    const _cssBorderThickness = this.getStateScoped('cssBorderThickness');
    const _cssBorderRadius = this.getStateScoped('cssBorderRadius');

    return (
      <Row gutter={4}>
        <VariableInput
          span={12}
          value={selection.branch[_cssBorderStyle]}
          onChange={(value, params) => {
            updateSelectionParam(_cssBorderStyle, { target: { value }}, params);
          }}
          inputType="select"
          name="style"
        >
          {WtlBorderStyle.map(type => (
            <option value={type}>{type}</option>
          ))}
        </VariableInput>
        <VariableInput
          span={12}
          value={selection.branch[_cssBorderColor]}
          onChange={(value, params) => {
            updateSelectionParam(_cssBorderColor, { target: { value }}, params);
          }}
          inputType="color"
          name="color"
        >
        </VariableInput>
        <VariableInput
          span={12}
          value={selection.branch[_cssBorderThickness]}
          onChange={(value, params) => {
            updateSelectionParam(_cssBorderThickness, { target: { value }}, params);
          }}
          inputType="css-prop"
          name="thickness"
        >
        </VariableInput>
        <VariableInput
          span={12}
          value={selection.branch[_cssBorderRadius]}
          onChange={(value, params) => {
            updateSelectionParam(_cssBorderRadius, { target: { value }}, params);
          }}
          inputType="css-prop"
          name="edge angle"
        >
        </VariableInput>
      </Row>
    );
  }

  renderTypographyProperties() {
    const { selection, updateSelectionParam } = this.props;
    const config = StateService.getConfig();
    const projectFonts = config.fonts || [];

    const _fontFamily = this.getStateScoped('fontFamily');
    const _fontSize = this.getStateScoped('fontSize');
    const _cssFontColor = this.getStateScoped('cssFontColor');
    const _cssFontStyle = this.getStateScoped('cssFontStyle');
    const _cssFontWordWrap = this.getStateScoped('cssFontWordWrap');
    const _cssLetterSpacing = this.getStateScoped('cssLetterSpacing');
    const _cssLineHeight = this.getStateScoped('cssLineHeight');
    const _cssFontKerning = this.getStateScoped('cssFontKerning');
    const _cssTextIndent = this.getStateScoped('cssTextIndent');

    if ([ 'image', 'video', 'shape' ].includes(selection.branch.type)) {
      return (
        <WtlStyledNote
          text="No typography to adjust :("
        />
      );
    }

    return (
      <>
        <VariableInput
          span={16}
          value={selection.branch[_fontFamily]}
          onChange={(value, params) => {
            updateSelectionParam(_fontFamily, { target: { value }}, params);
          }}
          inputType="select"
          name={
            <>
              font
              {' '}
              <Link to="/settings#content-fonts">
                (add more fonts)
              </Link>
            </>
          }
        >
          {
            projectFonts && (
              <option disabled>
                --- Project Fonts --- 
              </option>
            )
          }
          {
            projectFonts.map(font => (
              <option
                value={font.name}
                style={{
                  fontFamily: `"${font.name}"`
                }}
              >
                {font.name.match(/([^(,\")]+)/)[0]}
              </option>
            ))
          }
          {
            projectFonts && (
              <option disabled>
                --- Web Fonts --- 
              </option>
            )
          }
          {defaultFonts.map(font => (
            <option
              value={font}
              style={{
                fontFamily: font
              }}
            >
              {font.match(/([^(,\")]+)/)[0]}
            </option>
          ))}
        </VariableInput>
        <VariableInput
          span={8}
          value={selection.branch[_cssFontStyle]}
          onChange={(value, params) => {
            updateSelectionParam(_cssFontStyle, { target: { value }}, params);
          }}
          inputType="select"
          name="style"
        >
          {WtlTextStyle.map(type => (
            <option value={type}>{type}</option>
          ))}
        </VariableInput>
        <VariableInput
          span={12}
          value={selection.branch[_fontSize]}
          onChange={(value, params) => {
            updateSelectionParam(_fontSize, { target: { value }}, params);
          }}
          inputType="css-prop"
          name="size"
        ></VariableInput>
        <VariableInput
          span={12}
          value={selection.branch[_cssFontColor]}
          onChange={(value, params) => {
            updateSelectionParam(_cssFontColor, { target: { value }}, params);
          }}
          inputType="color"
          name="color"
        ></VariableInput>
        <VariableInput
          span={8}
          value={selection.branch[_cssLetterSpacing]}
          onChange={(value, params) => {
            updateSelectionParam(_cssLetterSpacing, { target: { value }}, params);
          }}
          inputType="css-prop"
          name={
            <>
              <i className="fas fa-text-width" /> letter spacing
            </>
          }
        ></VariableInput>
        <VariableInput
          span={8}
          value={selection.branch[_cssLineHeight]}
          onChange={(value, params) => {
            updateSelectionParam(_cssLineHeight, { target: { value }}, params);
          }}
          inputType="css-prop"
          name={
            <>
              <i className="fas fa-text-height" /> line height
            </>
          }
        ></VariableInput>
        <VariableInput
          span={8}
          value={selection.branch[_cssTextIndent]}
          onChange={(value, params) => {
            updateSelectionParam(_cssTextIndent, { target: { value }}, params);
          }}
          inputType="css-prop"
          name="paragraph indent"
        ></VariableInput>
        <VariableInput
          nonVariable
          span={12}
          value={selection.branch[_cssFontKerning]}
          onChange={(value, params) => {
            updateSelectionParam(_cssFontKerning, { target: { value }}, params);
          }}
          inputType="switch"
          name="kerning"
        ></VariableInput>
      </>
    );
  }

  renderEffectsProperties() {
    const { selection, updateSelectionParam } = this.props;

    const _cssEffectBlur = this.getStateScoped('cssEffectBlur');
    const _cssEffectDropShadow = this.getStateScoped('cssEffectDropShadow');
    const _cssEffectDesaturate = this.getStateScoped('cssEffectDesaturate');
    const _cssEffectZoom = this.getStateScoped('cssEffectZoom');
    const _cssEffectRotate = this.getStateScoped('cssEffectRotate');
    const _cssEffectSkew = this.getStateScoped('cssEffectSkew');
    const _cssOpacity = this.getStateScoped('cssOpacity');

    return (
      <Row gutter={4}>
        <VariableInput
          span={24}
          name="opacity"
          value={selection.branch[_cssOpacity]}
          inputType="range"
          inputProps={{
            min: 0,
            max: 100
          }}
          onChange={(value, params) => {
            updateSelectionParam(_cssOpacity, { target: { value: `${value}` }}, params);
          }}
        />
        <VariableInput
          span={24}
          name="gaussian blur"
          value={selection.branch[_cssEffectBlur]}
          inputType="range"
          inputProps={{
            min: 0,
            max: 100
          }}
          onChange={(value, params) => {
            updateSelectionParam(_cssEffectBlur, { target: { value }}, params);
          }}
        />
        <VariableInput
          span={24}
          name="drop shadow"
          value={selection.branch[_cssEffectDropShadow]}
          inputType="range"
          inputProps={{
            min: 0,
            max: 100
          }}
          onChange={(value, params) => {
            updateSelectionParam(_cssEffectDropShadow, { target: { value }}, params);
          }}
        />
        <VariableInput
          span={24}
          name="desaturate"
          value={selection.branch[_cssEffectDesaturate]}
          inputType="range"
          inputProps={{
            min: 0,
            max: 100
          }}
          onChange={(value, params) => {
            updateSelectionParam(_cssEffectDesaturate, { target: { value }}, params);
          }}
        />
        <VariableInput
          span={24}
          name="zoom"
          value={selection.branch[_cssEffectZoom]}
          inputType="range"
          inputProps={{
            min: -100,
            max: 100
          }}
          onChange={(value, params) => {
            updateSelectionParam(_cssEffectZoom, { target: { value }}, params);
          }}
        />
        <VariableInput
          span={24}
          name="rotate"
          value={selection.branch[_cssEffectRotate]}
          inputType="range"
          inputProps={{
            min: 0,
            max: 359
          }}
          onChange={(value, params) => {
            updateSelectionParam(_cssEffectRotate, { target: { value }}, params);
          }}
        />
        <VariableInput
          span={24}
          name="skew"
          value={selection.branch[_cssEffectSkew]}
          inputType="range"
          inputProps={{
            min: -100,
            max: 100
          }}
          onChange={(value, params) => {
            updateSelectionParam(_cssEffectSkew, { target: { value }}, params);
          }}
        />
      </Row>
    );
  }

  requireCustomStylesheet(switches) {
    // NOTE Deprecated
    return switches;
  }

  render() {
    const {
      selection,
      updateSelectionParam,
      columns = 3,
      showStateTransitions = true,
      showSharedStyles = true
    } = this.props;
    const { styleState } = this.state;

    const _cssBackgroundType = this.getStateScoped('cssBackgroundType');
    const _cssTransitionAll = this.getStateScoped('cssTransitionAll');

    const backgroundType = selection.branch && selection.branch[_cssBackgroundType];

    const columnSize = 24 / columns;

    return (
      <>
        {showStateTransitions && <Col span={24} style={{ textAlign: 'right' }}>
          <VariableInput
            inputType="radio"
            value={styleState}
            span={0}
            onChange={(value) => {
              this.state.styleState = value;

              if (value === 'hover') {
                updateSelectionParam('cssStylesHover', { target: { value: true } }, { parseValue: true });
              }

              if (value === 'active') {
                updateSelectionParam('cssStylesActive', { target: { value: true } }, { parseValue: true });
              }

              this.forceUpdate();
            }}
          >
            <Radio.Button value="">
              <WtlTooltip
                title="Regular"
                placement="top"
              >
                <i className="far fa-fw fa-eye" />
              </WtlTooltip>
            </Radio.Button>
            <Radio.Button value="hover">
              <WtlTooltip
                title="Mouse Over"
                placement="top"
              >
                <i className="far fa-fw fa-mouse-pointer" />
              </WtlTooltip>
            </Radio.Button>
            <Radio.Button value="active">
              <WtlTooltip
                title="Clicked"
                placement="top"
              >
                <i className="fas fa-fw fa-mouse-pointer" />
              </WtlTooltip>
            </Radio.Button>
          </VariableInput>
          <VariableInput
            inputType="radio"
            inputProps={{
              style: {
                marginLeft: 4
              }
            }}
            value={selection.branch[_cssTransitionAll] || ''}
            span={0}
            onChange={(value) => {
              updateSelectionParam(_cssTransitionAll, { target: { value } });
            }}
          >
            <Radio.Button value=""> 
              <WtlTooltip
                title="Transition: Instant"
                placement="top"
              >
                <i className="fas fa-fw fa-fast-forward" />
              </WtlTooltip>
            </Radio.Button>
            <Radio.Button value="smooth">
              <WtlTooltip
                title="Transition: Smooth"
                placement="top"
              >
                <i className="far fa-fw fa-walking" />
              </WtlTooltip>
            </Radio.Button>
            <Radio.Button value="fast">
              <WtlTooltip
                title="Transition: Fast"
                placement="top"
              >
                <i className="far fa-fw fa-running" />
              </WtlTooltip>
            </Radio.Button>
          </VariableInput>
        </Col>}
        <Col span={columnSize} style={{ overflow: 'visible' }}>
          <WtlStyleCategoryWrapper title="background" color="#91bbff">
            {this.requireCustomStylesheet(
              <Row gutter={4}>
                <VariableInput
                  span={24}
                  value={selection.branch[_cssBackgroundType]}
                  onChange={(value, params) => {
                    updateSelectionParam(_cssBackgroundType, { target: { value }}, params);
                  }}
                  inputType="select"
                  name="type"
                >
                  {WtlBlockBackgroundTypes.map(type => (
                    <option value={type}>
                      {type}
                    </option>
                  ))}
                </VariableInput>
                {backgroundType === WtlBlockBackgroundTypes[0] && this.renderBackgroundColorProperties()}
                {backgroundType === WtlBlockBackgroundTypes[1] && this.renderBackgroundImageProperties()}
                {backgroundType === WtlBlockBackgroundTypes[2] && this.renderBackgroundGradientProperties()}
              </Row>
            )}
          </WtlStyleCategoryWrapper>
          <WtlStyleCategoryWrapper title="typography" color="#d78cff">
            {this.requireCustomStylesheet(
              <Row gutter={4}>
                {this.renderTypographyProperties()}
              </Row>
            )}
          </WtlStyleCategoryWrapper>
          <WtlStyleCategoryWrapper title="border & strokes" color="#8fffc9">
            {this.requireCustomStylesheet(this.renderBorderProperties())}
          </WtlStyleCategoryWrapper>
        </Col>
        <Col span={columnSize} style={{ overflow: 'visible' }}>
          <WtlStyleCategoryWrapper title="size" color="#ff7dc2">
            {this.renderResponsiveProperties()}
          </WtlStyleCategoryWrapper>
          <WtlStyleCategoryWrapper title="position" color="#ffd280">
            {
              this.requireCustomStylesheet(
                <>
                  {this.renderPositionProperties()}
                </>
              )
            }
          </WtlStyleCategoryWrapper>
          <WtlStyleCategoryWrapper title="effects" color="#6fa2b0">
            {
              this.requireCustomStylesheet(
                <>
                  {this.renderEffectsProperties()}
                </>
              )
            }
          </WtlStyleCategoryWrapper>
        </Col>
        <Col span={columnSize} style={{ overflow: 'visible' }}>
          <WtlStyleCategoryWrapper title={
            `content ${['advanced-3d-model'].includes(selection.branch.type) ? '3D' : ''}`
          } color="#ccd48c">
            {this.renderContentRenderingProperties()}
          </WtlStyleCategoryWrapper>
          <WtlStyleCategoryWrapper title="margins" color="#c9a795">
            {this.renderInnerPaddingProperties()}
          </WtlStyleCategoryWrapper>
        </Col>
        {showSharedStyles && <Col span={24}>
          <Divider />
        </Col>}
        {showSharedStyles && <Col span={24} style={{ overflow: 'visible' }}>
          {this.renderPresetSelector()}
        </Col>}
      </>
    );
  }
}
