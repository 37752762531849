import React from 'react';
import styled from 'styled-components';

const BarWrapper = styled.div`
  display: inline-flex;
  box-sizing: border-box;
  background-color: #333;
  border-bottom: solid 1px #263346;
  font-size: 10px;
  height: 20px;
  min-height: 20px;
  white-space: nowrap;
  user-select: none;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, .5);
`;

const Files = styled.div`
  display: inline-block;
  width: calc(100% - 18px);
  overflow: hidden;
`;

const File = styled.div`
  position: relative;
  display: inline-flex;
  padding: 2px 8px;
  background-color: #444;
  border-right: solid 1px #263346;
  color: #ccc;
  cursor: default;
  min-width: 100px;
  max-width: 200px;
  justify-content: space-between;
  align-items: center;

  &:hover {
    color: #fff;
    cursor: pointer;
    background-color : #355dab;

    & > i {
      color: #ccc;
    }
  }

  &:active {
    color: #fff;
    cursor: pointer;
    background-color: #000;

    & > i {
      color: #ccc;
    }
  }

  & > i {
    display: inline-block;
    position: absolute;
    top: 2px;
    right: 0;
    padding: 2px;
    background-color: inherit;
  }

  & >i:hover {
    color: #fff;
    background-color: #841d3f;
    cursor: pointer;
  }

  ${p => p.selected && `
    background-color: #354970;
    border-right: solid 1px #263346;
    color: #fff;
  `}

  span {
    width: calc(100% - 4px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    i {
      margin-right: 2px;
      font-size: 10px;
    }
  }
`;

const Menu = styled.div`
  position: relative;
  display: inline-flex;
  padding: 2px;
  background-color: #4d83eb;
  border-right: solid 1px #263346;
  cursor: default;
  justify-content: space-between;
  align-items: center;
  z-index: 990;
  cursor: pointer;

  &:hover {
    background-color: #8cb3ff;
  }

  & > i, & > span {
    color: #e8f4ff;
  }

  & > i:hover, & > span:hover {
    color: #fff;
    cursor: pointer;
  }

  & > span {
    margin-left: 2px;
    margin-right: 2px;
  }
`;

export class WtlFilesBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openFiles: []
    };
  }

  componentDidMount() {
    this.openFirstFile();
  }

  componentDidUpdate(prevProps) {
    this.openFirstFile();
    this.openCurrentFile();

    if (prevProps.selectedFile !== this.props.selectedFile) {
      this.forceUpdate();
    }
  }

  openFirstFile() {
    const { files } = this.props;
    const { openFiles } = this.state;

    if (files && files[0] && openFiles.length === 0) {
      this.setState({ openFiles: [ files[0] ] });
    }

    this.filterInvalidFiles();
  }

  openCurrentFile() {
    const { selectedFile, files } = this.props;
    const { openFiles } = this.state;

    if (openFiles.find(file => file.name === selectedFile)) {
      return;
    }

    const file = files.find(file => file.name === selectedFile);

    if (!file) {
      return;
    }

    this.state.openFiles.push(file);
    this.filterInvalidFiles();
    this.forceUpdate();
  }

  filterInvalidFiles() {
    const { files } = this.props;
    const { openFiles } = this.state;
    const oldLength = openFiles.length;

    if (openFiles.length === 0) {
      return;
    }

    this.state.openFiles = openFiles.filter(file => {
      return files.find(validFile => validFile.name === file.name);
    });

    if (this.state.openFiles.length !== oldLength) {
      this.forceUpdate();
    }
  }

  closeFile(index) {
    const { selectedFile, onSelectionClosed } = this.props;
    const { openFiles } = this.state;
    const removedFile = openFiles.splice(index, 1)[0];

    if (!removedFile) {
      return;
    }

    if (removedFile.name === selectedFile) {
      onSelectionClosed(openFiles[Math.max(0, index - 1)].name);
    }

    this.forceUpdate();
  }

  render() {
    const { files, selectedFile, onOpenModal } = this.props;
    const { openFiles } = this.state;

    if (!files || !openFiles.length) {
      return null;
    }

    const showCloseButton = openFiles.length > 1;

    return (
      <BarWrapper>
        <Menu
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            onOpenModal();
          }}  
        >
          <i
            className={`fa fa-fw fa-bars`}
          />
          <span>pages</span>
        </Menu>
        <Files>
          {(openFiles || []).map(({ name, onClick }, index) => (
            <File
              onClick={() => onClick()}
              selected={name === selectedFile}
            >
              <span>
                {name.split(/\//gm).map((step, index) => 
                  `${index > 0 ? ' » ' : ''}${step}`
                )}
              </span>
              {showCloseButton && <i className="fa fa-fw fa-times" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                this.closeFile(index);
              }} />}
            </File>
          ))}
        </Files>
      </BarWrapper>
    );
  }
}
