// TODO (1) Change SimpleInputs to Variable Inputs
import React from 'react';
import { Row, Col } from 'antd';
import { SimpleInput } from '../../styles/styled';
import { videoFileTypes } from '../../data/file-types.data';
import { VariableInput } from '../variable-input';

export const renderVideoProps = function () {
  const { tools } = this.state;
  const { selection } = tools;

  return (
    <Col span={8}>
      <Row gutter={4}>
        <VariableInput
          inputType="file"
          filterTypes={videoFileTypes}
          span={14}
          name="video file"
          value={selection.branch.url}
          onChange={(value, params) => {
            this.updateSelectionParam('url', { target: { value }}, params);
            this.forceUpdate();
          }}
        />
        <SimpleInput span={10}>
          <span>video format</span>
          <select
            value={decodeURIComponent(selection.branch.videoType) || 'video/webm'}
            onChange={(e) => this.updateSelectionParam('videoType', e)}
          >
            <option value="video/webm">
              WebM
            </option>
            <option value="video/ogg">
              Ogg Theora Vorbis
            </option>
            <option value="video/ogg; codecs=opus">
              Ogg Opus
            </option>
            <option value="video/mp4">
              MP4
            </option>
          </select>
        </SimpleInput>
      </Row>
      <Row gutter={4}>
        <SimpleInput span={12}>
          <span>width</span>
          <input
            type="number"
            defaultValue=""
            value={selection.branch.width}
            onChange={(e) => this.updateSelectionParam('width', e, { parseValue: true })}
          />
        </SimpleInput>
        <SimpleInput span={12}>
          <span>height</span>
          <input
            type="number"
            defaultValue=""
            value={selection.branch.height}
            onChange={(e) => this.updateSelectionParam('height', e, { parseValue: true })}
          />
        </SimpleInput>
        <Row gutter={4}>
          <SimpleInput span={12}>
            <span>autoplay</span>
            <select
              value={selection.branch.autoplay || 'true'}
              onChange={(e) => this.updateSelectionParam('autoplay', e)}
            >
              <option value="true">
                yes
              </option>
              <option value="false">
                no
              </option>
            </select>
          </SimpleInput>
          <SimpleInput span={12}>
            <span>loop playback</span>
            <select
              value={selection.branch.loop || 'true'}
              onChange={(e) => this.updateSelectionParam('loop', e)}
            >
              <option value="true">
                yes
              </option>
              <option value="false">
                no
              </option>
            </select>
          </SimpleInput>
          <SimpleInput span={12}>
            <span>show controls</span>
            <select
              value={selection.branch.controls || 'false'}
              onChange={(e) => this.updateSelectionParam('controls', e)}
            >
              <option value="false">
                no
              </option>
              <option value="true">
                yes
              </option>
            </select>
          </SimpleInput>
        </Row>
      </Row>
    </Col>
  );
}