import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  font-family: Helvetica, Arial, sans-serif;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  box-sizing: border-box;
  width: 100px;
  text-align: center;
  overflow: visible;
`;

const ArrowSwitch = styled.div`
  position: absolute;
  color: #000;
  font-size: 20px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 2;
  overflow: visible;

  ${p => p.top && `
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-20%);
  `}

  ${p => p.bottom && `
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(30%);
  `}

  ${p => p.disabled && `
    cursor: default;
    color: #fff;
  `}
`;

const Label = styled.div`
  color: #000;
  cursor: default;
  font-size: 10px;
  padding: 12px 0 10px 0;
  text-transform: uppercase;
  z-index: 1;
`;

export const CALabelRange = ({
  max = 2,
  min = 0,
  value = 0,
  label = '',
  onChange = () => {}
}) => {
  return (
    <Wrapper>
      <ArrowSwitch
        top
        disabled={value >= max}
        onClick={() => {
          if (value >= max) {
            return;
          }

          onChange(value + 1);  
        }}
      >
        <i className="fas fa-fw fa-sort-up" />
      </ArrowSwitch>
      <Label>
        {label}
      </Label>
      <ArrowSwitch
        bottom
        disabled={value <= min}
        onClick={() => {
          if (value <= min) {
            return;
          }

          onChange(value - 1);  
        }}
      >
        <i className="fas fa-fw fa-sort-down" />
      </ArrowSwitch>
    </Wrapper>
  );
}
