import React from 'react';
import styled from 'styled-components';
import { WtlContextMenu } from './layout/wtl-context-menu';
import { snapTo } from '../helpers/math/snap-to';
import { uNaN } from '../utils/u-nan';
import { nFloat } from '../utils/n-float';
import { parseUndefString } from '../utils/parse-undef-string';

export const EditorOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 901;
`;

export const SelectionWrapper = styled.div`
  position: absolute;
  top: ${p => p.selectionY}px;
  left: ${p => p.selectionX}px;
  width: ${p => p.selectionWidth}px;
  height: ${p => p.selectionHeight}px;
  color: #000;
  z-index: 900;

  svg {
    position: relative;
    width: ${p => p.selectionWidth}px;
    height: ${p => p.selectionHeight}px;
    ${p => !p.active && 'opacity: .5;'}

    .white {
      stroke: #eee;
    }

    .black {
      stroke: #000;
      stroke-dasharray: 4px;
      ${p => p.active && 'animation: stroke 1s linear infinite;'}
      stroke-dashoffset: 8px;
    }

    .white, .black {
      width: inherit;
      height: inherit;
      fill: none;
      stroke-width: 2px;
      vector-effect: non-scaling-stroke;
      shape-rendering: geometricPrecision;
    }

    @keyframes stroke {
      to {
        stroke-dashoffset: 0;
      }
    }
  }
`;

const ContextFrame = styled.div`
  opacity: 0.5;
`;

const ContextShadow = styled.div`
  position: absolute;
  ${p => p.top && `top: ${p.top}px;`}
  ${p => p.left && `left: ${p.left}px;`}
  ${p => p.bottom && `bottom: ${p.bottom}px;`}
  ${p => p.right && `right: ${p.right}px;`}
  width: ${p => p.width};
  height: ${p => p.height};
  background-color: #000;
  z-index: 1;
  pointer-events: none;
`;

const ContextVGuide = styled.div`
  position: absolute;
  top: 0px;
  ${p => p.left && `left: ${p.left}px;`}
  width: 1px;
  height: 100%;
  pointer-events: none;
  border-right: dashed 1px #fff;
  border-left: dashed 1px #000;
  opacity: ${p => p.primary ? 0.5 : 0.25};
  z-index: 4;
`;

const ContextHGuide = styled.div`
  position: absolute;
  ${p => p.top && `top: ${p.top}px;`}
  left: 0px;
  width: 100%;
  height: 1px;
  pointer-events: none;
  border-bottom: dashed 1px #fff;
  border-top: dashed 1px #000;
  opacity: ${p => p.primary ? 0.5 : 0.25};
  z-index: 4;
`;

const ContextInfo = styled.div`
  position: absolute;
  display: inline-block;
  top: ${p => p.top}px;
  left: ${p => p.left}px;
  color: #fff;
  font-size: 10px;
  padding: 4px;
  background-color: #000;
  transform: ${p => p.left > 10 ? 'translateX(-100%)' : 'translateX(1px)'} ${p => p.top > 10 ? 'translateY(-100%)' : 'translateY(1px)'};
  z-index: 3;
`;

const SelectionMock = styled.div`
  position: absolute;
  top: ${p => p.top}px;
  left: ${p => p.left}px;
  display: inline-block;
  width: ${p => p.width}px;
  height: ${p => p.height}px;
  /* background-color: rgba(102, 156, 255, .1); */
  border: solid 1px rgba(102, 156, 255, 1);
  z-index: 2;
  cursor: move;
`;

const ResizeHandle = styled.div`
  position: absolute;
  top: ${p => p.top}%;
  left: ${p => p.left}%;
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: rgba(1, 91, 197, .5);
  border: solid 1px #000;
  cursor: ${p => p.cursor || 'pointer'};
  transform: translateX(-50%) translateY(-50%);
  z-index: 2;
  pointer-events: all;
  opacity: 1;
  transition: opacity .3s ease;

  ${p => p.inactive && `
    opacity: 0;
    pointer-events: none;
  `}
`;

const RotateHandle = styled.div`
  position: absolute;
  top: calc(${p => p.top}% - 50px);
  left: ${p => p.left}%;
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: rgba(1, 197, 91, .5);
  border: solid 1px #000;
  border-radius: 50%;
  cursor: ${p => p.cursor || 'pointer'};
  transform: translateX(-50%) translateY(-50%);
  z-index: 2;
  pointer-events: all;
  opacity: 1;
  transition: opacity .3s ease;

  ${p => p.inactive && `
    opacity: 0;
    pointer-events: none;
  `}
`;

const MassCentre = styled(ResizeHandle)`
  width: 7px;
  height: 7px;
  background-color: rgba(1, 91, 197, .75);
  border: solid 1px #000;
  border-radius: 50%;
  cursor: default;
  pointer-events: none;
`;

const ResizeArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: green;
  opacity: 0;
  z-index: 10;
  pointer-events: all;

  ${p => p.inactive && `
    pointer-events: none;
  `}
`;

const SelectionDragState = {
  inactive: 0,
  click: 1,
  move: 2
};

const RotatePreview = styled(ResizeHandle)`
  pointer-events: none;
  width: 100px;
  height: 100px;
  border: 0;
  background-color: transparent;
  overflow: visible;
  z-index: -1 !important;

  svg {
    overflow: visible;

    circle {
      stroke: rgba(0, 0, 0, .5);
      stroke-width: 4px;
      fill: transparent;
      overflow: visible;
    }
  }
`;

export const getValueFormat = (value) => {
  return `${value}`.substr(-1) === '%' ? '%' : 'px';
}

export const addCssValues = (source, diff, converter, snap = false, relativeSnapPoints = []) => {
  const srcFormat = getValueFormat(source);
  const diffFormat = getValueFormat(diff);
  const snapPoints = snap ? [ 0, ...relativeSnapPoints ] : [];
  const snapThreshold = 10;

  if (typeof converter === 'undefined') {
    const sum = snapTo(Math.round(nFloat(source) + nFloat(diff)), snapPoints, snapThreshold);

    return `${sum}${srcFormat}`;
  }

  if (srcFormat !== diffFormat) {
    let convertedDiff, sum;

    if (srcFormat === '%') { // diff in px
      convertedDiff = (nFloat(diff) / converter * 100);
      const srcPx = (nFloat(source) * converter / 100);
      const sumPx = snapTo(Math.round(nFloat(srcPx) + nFloat(diff)), snapPoints, snapThreshold);

      if (snapPoints.includes(sumPx)) {
        sum = (nFloat(sumPx) / converter * 100);
      } else {
        sum = Math.round(nFloat(source) + nFloat(convertedDiff));
      }
    } else if (srcFormat === 'px') { // diff in %
      convertedDiff = (nFloat(diff) * converter / 100);
      sum = snapTo(Math.round(nFloat(source) + nFloat(convertedDiff)), snapPoints, snapThreshold);
    }

    return `${sum}${srcFormat}`;
  } else {
    const sum = snapTo(Math.round(nFloat(source) + nFloat(diff)), snapPoints, snapThreshold);

    return `${sum}${srcFormat}`;
  }
}

export class Selection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      resizeState: SelectionDragState.inactive,
      resizeDirection: null,
      rotateState: SelectionDragState.inactive,
      rotateDirection: null
    };
  }

  startResize(event, directionX, directionY) {
    if (this.state.resizeState !== SelectionDragState.inactive) {
      return;
    }

    const { selection } = this.props;

    if (!selection || !selection.branch || !selection.element) {
      return;
    }

    const { x, y, w, h } = this.getSelectionSize();

    this.state.resizeState = SelectionDragState.click;
    this.state.resizeDirection = {
      dirX: directionX,
      dirY: directionY,
      x: event.screenX,
      y: event.screenY,
      originX: x,
      originY: y,
      originW: w,
      originH: h,
      originBox: selection.element.getBoundingClientRect()
    };
    this.forceUpdate();
  }

  getContextSize() {
    const { context } = this.props;

    return (context && context.element) ? context.element.getBoundingClientRect() : null;
  }

  moveResize(event) {
    if (this.state.resizeState === SelectionDragState.inactive) {
      return;
    }

    const {
      selection,
      context,
      pagePreview,
      onSelectionChanged
    } = this.props;

    if (!selection || !selection.branch) {
      return;
    }

    this.state.resizeState = SelectionDragState.move;

    const contextSize = this.getContextSize();
    const endX = event.screenX;
    const endY = event.screenY;
    const { x, y, originBox, dirX: _dirX, dirY: _dirY } = this.state.resizeDirection;
    let dirX = _dirX;
    let dirY = _dirY;
    const dx = Math.round(dirX * (endX - x));
    const dy = Math.round(dirY * (endY - y));
    const align = selection.branch.cssPositionAlign || 'top left';

    if (Math.abs(dx) < 2 && Math.abs(dy) < 2) {
      return;
    }

    let addX = 0;
    let addY = 0;
    let addW = 0;
    let addH = 0;

    if (dirX > 0) {
      addW = dx;
    } else if (dirX < 0) {
      addW = dx;
    }

    if (align.match(/^.+left$/)) {
      addX = dirX > 0 ? 0 : -dx;
      addW = dx;
    }

    if (align.match(/^.+center$/)) {
      addX = dirX > 0 ? 0 : -dx;
      addW = dx;
    }

    if (align.match(/^.+right$/)) {
      addX = dirX > 0 ? dx : 0;
      addW = dx;
    }

    if (align.match(/^top.+$/)) {
      addY = dirY > 0 ? 0 : -dy;
      addH = dy;
    }

    if (align.match(/^center.+$/)) {
      addY = dirY > 0 ? 0 : -dy;
      addH = dy;
    }

    if (align.match(/^bottom.+$/)) {
      addY = dirY > 0 ? dy : 0;
      addH = dy;
    }

    if (typeof selection.branch.cssWidth !== 'undefined') {
      if (contextSize) {
        this.setSelectionSize(
          undefined,
          undefined,
          `${addX / contextSize.width * 100}%`
        );
      } else {
        this.setSelectionSize(
          undefined, 
          undefined,
          `${addX}px`
        );
      }

      this.setSelectionSize(
        `${addW}px`
      );
    } else {
      const previewSize = pagePreview.getBoundingClientRect();
      const contextWidth = contextSize ? contextSize.width : previewSize.width;
      const contextGridStep = contextWidth / 24;
      const stepsW = Math.min(24, Math.floor(Math.max(0, originBox.width + addW) / contextGridStep) + 1);

      this.setSelectionSize(
        undefined,
        undefined,
        undefined,
        undefined,
        parseInt(stepsW, 10)
      );
    }

    this.setSelectionSize(
      undefined,
      undefined,
      undefined,
      `${addY}px`
    );
    this.setSelectionSize(
      undefined,
      `${addH}px`
    );

    onSelectionChanged([ 'cssWidth', 'cssHeight' ]);
  }

  endResize() {
    if (this.state.resizeState === SelectionDragState.inactive) {
      return;
    }

    this.state.resizeState = SelectionDragState.inactive;
    this.state.resizeDirection = null;
    this.forceUpdate();
  }

  getSelectionSize() {
    const {
      selection
    } = this.props;

    if (!selection || !selection.branch) {
      return { w: 0, h: 0, x: 0, y: 0 };
    }

    const blockType = selection.branch.type;
    let size;

    if (blockType === 'advanced-animation-block') {
      const currentFrame = selection.branch.stopFrame || 0;

      size = {
        x: parseUndefString(decodeURIComponent(selection.branch.cssPositionOffsetX)),
        y: parseUndefString(decodeURIComponent(selection.branch.cssPositionOffsetY)),
        w: parseUndefString(decodeURIComponent(selection.branch.cssWidth)),
        h: parseUndefString(decodeURIComponent(selection.branch.cssHeight)),
      };

      if (selection.branch.keyframes instanceof Array && selection.branch.keyframes[currentFrame]) {
        const frameSize = {
          x: parseUndefString(decodeURIComponent(selection.branch.keyframes[currentFrame].cssPositionOffsetX)),
          y: parseUndefString(decodeURIComponent(selection.branch.keyframes[currentFrame].cssPositionOffsetY)),
          w: parseUndefString(decodeURIComponent(selection.branch.keyframes[currentFrame].cssWidth)),
          h: parseUndefString(decodeURIComponent(selection.branch.keyframes[currentFrame].cssHeight)),
        };

        frameSize.x === undefined && delete frameSize.x;
        frameSize.y === undefined && delete frameSize.y;
        frameSize.w === undefined && delete frameSize.w;
        frameSize.h === undefined && delete frameSize.h;

        size = {
          ...size,
          ...frameSize
        };
      }
    } else {
      size = {
        x: parseUndefString(decodeURIComponent(selection.branch.cssPositionOffsetX)),
        y: parseUndefString(decodeURIComponent(selection.branch.cssPositionOffsetY)),
        w: parseUndefString(decodeURIComponent(selection.branch.cssWidth)),
        h: parseUndefString(decodeURIComponent(selection.branch.cssHeight)),
      };
    }

    return size;
  }

  setSelectionSize(dw, dh, dx, dy, s) {
    const {
      selection,
      debugPreview
    } = this.props;

    if (!selection || !selection.branch) {
      return;
    }

    const blockType = selection.branch.type;
    const currentFrame = selection.branch.stopFrame || 0;
    const isStatic = blockType !== 'advanced-animation-block';
    const updateBaseFrame = isStatic || currentFrame === 0;
    const { originW, originH, originX, originY } = this.state.resizeDirection;
    const context = this.getContextSize() || {};

    const oW = originW || '0px';
    const oH = originH || '0px';
    const oX = originX || '0%';
    const oY = originY || '0px';

    if (!isStatic) {
      if (!(selection.branch.keyframes instanceof Array)) {
        selection.branch.keyframes = [];
      }

      if (!selection.branch.keyframes[currentFrame]) {
        selection.branch.keyframes[currentFrame] = {};
      }
    }

    if (typeof dw !== 'undefined') {
      const w = addCssValues(oW, dw, context.width);

      updateBaseFrame && (selection.branch.cssWidth = encodeURIComponent(w));
      !isStatic && (selection.branch.keyframes[currentFrame].cssWidth = encodeURIComponent(w));
    }

    if (typeof dh !== 'undefined') {
      const h = addCssValues(oH, dh, context.height);

      updateBaseFrame && (selection.branch.cssHeight = encodeURIComponent(h));
      !isStatic && (selection.branch.keyframes[currentFrame].cssHeight = encodeURIComponent(h));
    }

    if (typeof dy !== 'undefined') {
      const y = addCssValues(oY, dy, context.height);

      updateBaseFrame && (selection.branch.cssPositionOffsetY = encodeURIComponent(y));
      !isStatic && (selection.branch.keyframes[currentFrame].cssPositionOffsetY = encodeURIComponent(y));
    }

    if (typeof dx !== 'undefined') {
      const x = addCssValues(oX, dx, context.width);

      updateBaseFrame && (selection.branch.cssPositionOffsetX = encodeURIComponent(x));
      !isStatic && (selection.branch.keyframes[currentFrame].cssPositionOffsetX = encodeURIComponent(x));
    }

    if (typeof s !== 'undefined') {
      const primary = ({
        desktop: 'size',
        tablet: 'sizeTablet',
        mobile: 'sizeMobile'
      })[debugPreview || 'desktop'];
      const secondary = [
        'size',
        'sizeTablet',
        'sizeMobile'
      ].filter(prop => prop !== primary);

      updateBaseFrame && (selection.branch[primary] = s);
      !isStatic && (selection.branch.keyframes[currentFrame][primary] = s);

      secondary.forEach(prop => {
        updateBaseFrame && (selection.branch[prop] = typeof selection.branch[prop] === 'undefined' ? 24 : selection.branch[prop]);
        !isStatic && (selection.branch.keyframes[currentFrame][prop] = typeof selection.branch[prop] === 'undefined' ? 24 : selection.branch[prop]);
      });
    }
  }

  startRotate(event) {
    if (this.state.rotateState !== SelectionDragState.inactive) {
      return;
    }

    const { selection } = this.props;

    if (!selection || !selection.branch || !selection.element) {
      return;
    }

    const r = this.getSelectionRotation();

    this.state.rotateState = SelectionDragState.click;
    this.state.rotateDirection = {
      x: event.screenX,
      y: event.screenY,
      originR: r,
      angle: 0
    };
    this.forceUpdate();
  }

  moveRotate(event) {
    if (this.state.rotateState === SelectionDragState.inactive) {
      return;
    }

    const {
      selection,
      onSelectionChanged
    } = this.props;

    if (!selection || !selection.branch) {
      return;
    }

    this.state.rotateState = SelectionDragState.move;

    const endX = event.screenX;
    const endY = event.screenY;
    const { x, y, originR } = this.state.rotateDirection;

    const v1 = {
      x: 0,
      y: -50
    };
    const v2 = {
      x: endX - x,
      y: endY - y - 100
    };
    const dx = v2.x - v1.x;
    const dy = v2.y - v1.y;
    let dr = Math.atan2(
      dy,
      dx
    ) + Math.PI / 2;

    if (dr < 0) {
      dr += 2 * Math.PI;
    }

    const oR = isNaN(originR) ? 0 : originR;
    const angle = Math.round(dr * (180 / Math.PI));
    this.setSelectionRotation(snapTo(oR + angle, [ 0, 45, 90, 135, 180, 225, 270, 315, 360 ], 3));

    this.state.rotateDirection.angle = selection.branch.cssEffectRotate - oR;

    onSelectionChanged([ 'cssEffectRotate' ]);
    this.forceUpdate();
  }

  endRotate() {
    if (this.state.rotateState === SelectionDragState.inactive) {
      return;
    }
    
    this.state.rotateState = SelectionDragState.inactive;
    this.state.rotateDirection = null;
    this.forceUpdate();
  }

  getSelectionRotation() {
    const {
      selection
    } = this.props;

    if (!selection || !selection.branch) {
      return 0;
    }

    const blockType = selection.branch.type;
    let rotation;

    if (blockType === 'advanced-animation-block') {
      const currentFrame = selection.branch.stopFrame || 0;

      rotation = parseFloat(selection.branch.cssEffectRotate);

      if (selection.branch.keyframes instanceof Array && selection.branch.keyframes[currentFrame]) {
        const frameR = uNaN(parseFloat(selection.branch.keyframes[currentFrame].cssEffectRotate));

        if (frameR !== undefined) {
          rotation = frameR;
        }
      }
    } else {
      rotation = parseFloat(selection.branch.cssEffectRotate);
    }

    return rotation;
  }

  setSelectionRotation(r) {
    const {
      selection
    } = this.props;

    if (!selection || !selection.branch) {
      return;
    }

    const blockType = selection.branch.type;
    const currentFrame = selection.branch.stopFrame || 0;
    const isStatic = blockType !== 'advanced-animation-block';
    const updateBaseFrame = isStatic || currentFrame === 0;

    let _r = r;

    if (r < 0) {
      _r += 360;
    }

    if (r >= 360) {
      _r -= 360;
    }

    if (!isStatic) {
      if (!(selection.branch.keyframes instanceof Array)) {
        selection.branch.keyframes = [];
      }

      if (!selection.branch.keyframes[currentFrame]) {
        selection.branch.keyframes[currentFrame] = {};
      }
    }

    if (typeof _r !== 'undefined') {
      updateBaseFrame && (selection.branch.cssEffectRotate = encodeURIComponent(_r));
      !isStatic && (selection.branch.keyframes[currentFrame].cssEffectRotate = encodeURIComponent(_r));
    }
  }

  getCentreOffMass(selection) {
    if (!selection || !selection.branch) {
      return [0, 0];
    }

    const massAlign = decodeURIComponent(selection.branch.cssPositionAlign || 'top left').split(' ');

    return [
      {
        top: 0,
        center: 50,
        bottom: 100
      }[massAlign[0]],
      {
        left: 0,
        center: 50,
        right: 100
      }[massAlign[1]]
    ];
  }
  
  render() {
    const {
      selection,
      context,
      pagePreview,
      children,
      contextMenu,
      contextActions,
      rightClicked,
      useFreeTransform,
      onExitFreeTransform,
      showGrid
    } = this.props;
    const {
      resizeState,
      rotateState,
      rotateDirection
    } = this.state;

    const previewSize = pagePreview.getBoundingClientRect();
    const selectionSize = selection.element.getBoundingClientRect();
    const contextSize = context ? context.element.getBoundingClientRect() : null;
    const rootElement = pagePreview.querySelector('#root');

    const selectionX = selectionSize.x - previewSize.x;
    const selectionY = selectionSize.y + pagePreview.scrollTop - previewSize.y;
    const selectionWidth = selectionSize.width;
    const selectionHeight = selectionSize.height;

    const rootPreviewMargin = 0;
    const contextX = contextSize ? contextSize.x - previewSize.x : rootPreviewMargin;
    const contextY = contextSize ? contextSize.y + pagePreview.scrollTop - previewSize.y : rootPreviewMargin;
    const contextWidth = contextSize ? contextSize.width : previewSize.width - (rootPreviewMargin * 2);
    const contextHeight = contextSize ? contextSize.height : previewSize.height - (rootPreviewMargin * 2);
    const contextGridStep = contextWidth / 24;
    const contextGridVerticalStep = Math.round(contextHeight / 4);

    const array25 = Array(25).fill(0);

    const onLeftEdge = selectionX <= contextX + 2;
    const onTopEdge = selectionY <= contextY + 2;
    const onRightEdge = selectionX >= contextX + contextWidth - 2;
    const onBottomEdge = selectionY >= contextY + contextHeight - 2;
    
    const centreOfMass = this.getCentreOffMass(selection);

    const isBlock = selection.branch && selection.branch.type === 'block';
    const isRoot = selection.branch && selection.branch.type === 'root';

    let rotationPreview = {};

    if (rotateDirection) {
      rotationPreview.radius = 50;
      rotationPreview.circumfence = 2 * Math.PI * rotationPreview.radius;
      rotationPreview.strokeOffset = 0.25 * rotationPreview.circumfence;
      rotationPreview.strokeDasharray = (rotateDirection.angle / 360) * rotationPreview.circumfence;
    }

    return (
      <>
        <ResizeArea
          inactive={
            resizeState === SelectionDragState.inactive &&
            rotateState === SelectionDragState.inactive
          }
          onMouseMove={(e) => {
            this.moveResize(e);
            this.moveRotate(e);
          }}
          onMouseUp={() => {
            this.endResize();
            this.endRotate();
          }}
          onClick={() => {
            this.endResize();
            this.endRotate();
          }}
          onRightClick={() => {
            this.endResize();
            this.endRotate();
          }}
        />
        <SelectionMock
          top={selectionY}
          left={selectionX}
          width={selectionWidth}
          height={selectionHeight}
        >
          {!isRoot && <MassCentre
            top={centreOfMass[0]}
            left={centreOfMass[1]}
          />}
          {!isRoot && <RotateHandle
            top={centreOfMass[0]}
            left={centreOfMass[1]}
            cursor="ew-resize"
            onMouseDown={(e) => this.startRotate(e)}
          />}
          {rotateState !== SelectionDragState.inactive && (
            <RotatePreview
              top={centreOfMass[0]}
              left={centreOfMass[1]}
              angle={50}
            >
              <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                <circle
                  cx="50"
                  cy="50"
                  r={rotationPreview.radius}
                  strokeDasharray={[
                    rotationPreview.strokeDasharray,
                    rotationPreview.circumfence - rotationPreview.strokeDasharray
                  ].join(' ')}
                  strokeDashoffset={rotationPreview.strokeOffset}
                />
              </svg>
            </RotatePreview>
          )}
          {!isRoot && <ResizeHandle
            top={0}
            left={0}
            cursor="nwse-resize"
            onMouseDown={(e) => this.startResize(e, -1, -1)}
          />}
          {!isRoot && <ResizeHandle
            top={0}
            left={50}
            cursor="ns-resize"
            onMouseDown={(e) => this.startResize(e, 0, -1)}
          />}
          {!isRoot && <ResizeHandle
            top={0}
            left={100}
            cursor="nesw-resize"
            onMouseDown={(e) => this.startResize(e, 1, -1)}
          />}
          {!isRoot && <ResizeHandle
            top={100}
            left={100}
            cursor="nwse-resize"
            onMouseDown={(e) => this.startResize(e, 1, 1)}
          />}
          {!isRoot && <ResizeHandle
            top={50}
            left={0}
            cursor="ew-resize"
            onMouseDown={(e) => this.startResize(e, -1, 0)}
          />}
          {!isRoot && <ResizeHandle
            top={100}
            left={0}
            cursor="nesw-resize"
            onMouseDown={(e) => this.startResize(e, -1, 1)}
          />}
          {!isRoot && <ResizeHandle
            top={50}
            left={100}
            cursor="ew-resize"
            onMouseDown={(e) => this.startResize(e, 1, 0)}
          />}
          <ResizeHandle
            top={100}
            left={50}
            cursor="ns-resize"
            onMouseDown={(e) => this.startResize(e, 0, 1)}
          />
        </SelectionMock>
        <SelectionWrapper
          selectionX={selectionX}
          selectionY={selectionY}
          selectionWidth={selectionWidth}
          selectionHeight={selectionHeight}
          active
        >
          {children}
          <WtlContextMenu
            contextMenu={contextMenu}
            contextActions={contextActions}
            defaultExpanded={rightClicked}
            selection={selection}
            pagePreview={pagePreview}
          />
          {
            useFreeTransform && (
              <WtlContextMenu
                contextActions={[
                  {
                    icon: 'check',
                    onClick: () => {
                      onExitFreeTransform();
                    }
                  }
                ]}
                defaultExpanded={false}
                selection={selection}
                pagePreview={pagePreview}
              />
            )
          }
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewbox="0 0 40 40"
            preserveAspectRatio="none"
          >
            <rect className="white" width="40" height="40" />
            <rect className="black" width="40" height="40" />
          </svg>
        </SelectionWrapper>
        {showGrid && <ContextFrame>
          {
            contextSize && array25.map((_, index) => (
              <ContextVGuide
                left={`${contextX + index * contextGridStep}`}
                primary={index % 8 === 0}  
              />
            ))
          }
          {
            contextSize && Array(5).fill(0).map((_, index) => (
              <ContextHGuide
                top={`${contextY + index * contextGridVerticalStep}`}
                primary
              />
            ))
          }
        </ContextFrame>}
      </>
    );
  }
}