import React from 'react';
import { VariableInput } from '../variable-input';
import { Row, Col } from 'antd';

export const renderTrustPilotProps = function () {
  const { tools } = this.state;
  const { selection } = tools;

  return (
    <Col span={8}>
      <Row gutter={4}>
        <VariableInput
          span={24}
          name="business unit id"
          value={selection.branch.businessUnitId}
          onChange={(value, params) => {
            this.updateSelectionParam('businessUnitId', { target: { value }}, params);
          }}
        />
        <VariableInput
          span={24}
          inputType="select"
          name="template type"
          value={selection.branch.templateId}
          onChange={(value, params) => {
            this.updateSelectionParam('templateId', { target: { value }}, params);
          }}
        >
          <option value="5419b6a8b0d04a076446a9ad">Micro Review Count</option>
          <option value="5419b732fbfb950b10de65e5">Micro Star</option>
          <option value="5419b757fa0340045cd0c938">Micro Button</option>
          <option value="5419b6ffb0d04a076446a9af">Micro Combo</option>
          <option value="5419b6a8b0d04a076446a9ad">Micro Review Count</option>
          <option value="5419b637fa0340045cd0c936">Micro TrustScore</option>
          <option value="56278e9abfbbba0bdcd568bc">Review Collector</option>
          <option value="53aa8807dec7e10d38f59f32">Mini</option>
          <option value="539ad60defb9600b94d7df2c">List</option>
          <option value="5613c9cde69ddc09340c6beb">Starter</option>
        </VariableInput>
        <VariableInput
          span={24}
          inputType="select"
          name="template theme"
          value={selection.branch.theme}
          onChange={(value, params) => {
            this.updateSelectionParam('theme', { target: { value }}, params);
          }}
        >
          <option value="light">light</option>
          <option value="dark">dark</option>
        </VariableInput>
      </Row>
    </Col>
  );
}
