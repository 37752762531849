export const models = {
  'simple-table': {
    previewUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/c4b860b6-6787-41f3-b7be-34c7e337bea3.png',
    modelUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/806461a3-a415-4c02-bb91-f5d24eaa4098.gltf',
    modelScale: 4.0,
    materialId: 0,
    sizeId: 0,
    details: {
      name: 'Table',
      owner: 'Simple®',
      designer: '九安 句人吃开',
      priceBase: 14000,
      priceCurrency: 'eur',
      taxRate: 0,
      shippingFrom: 'Beijng, CN',
      measurementOptions: [
        [ 121, 76, 60 ],
        [ 142, 76, 60 ],
        [ 100, 76, 65 ]
      ],
      materialNames: [
        'Beech wood',
        'Carbon fiber',
        'Painted wood (red)',
        'Painted wood (blue)',
        'Painted wood (yellow)'
      ],
      sizeOptions: [
        [ 1, 2 ],
        [ 1, 2.1 ],
        [ 1, 1.75 ]
      ],
      materialOptions: [
        'furniture-basic-1',
        'space-mat',
        'red-paint',
        'blue-paint',
        'yellow-paint'
      ]
    }
  },
  'simple-carpet': {
    previewUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/7a84de5a-9279-4d51-8f50-72f9e5e39816.png',
    modelUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/9a71ecef-c73b-4f5c-8a09-c0af953cc428.obj',
    modelScale: 4.0,
    materialId: 0,
    sizeId: 0,
    details: {
      name: 'Carpet',
      owner: 'Simple®',
      designer: '九安 句人吃开',
      priceBase: 9900,
      priceCurrency: 'eur',
      taxRate: 0,
      shippingFrom: 'Beijng, CN',
      measurementOptions: [
        [ 100, 76, 65 ]
      ],
      materialNames: [
        'Beech wood'
      ],
      sizeOptions: [
        [ 1, 2 ],
      ],
      materialOptions: [
        'furniture-basic-1'
      ]
    }
  },
  'simple-shelf': {
    previewUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/a94f3226-6a11-4469-a2c4-595e8cf017f9.png',
    modelUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/94930927-8625-4ef9-a92c-e6237e892a15.glb',
    modelScale: 4.0,
    materialId: 0,
    sizeId: 0,
    details: {
      name: 'Shelf',
      owner: 'Simple®',
      designer: '九安 句人吃开',
      priceBase: 29900,
      priceCurrency: 'eur',
      taxRate: 0,
      shippingFrom: 'Beijng, CN',
      measurementOptions: [
        [ 121, 76, 60 ]
      ],
      materialNames: [
        'Beech wood',
        'Painted wood (red)',
        'Painted wood (blue)',
        'Painted wood (yellow)'
      ],
      sizeOptions: [
        [ 1, 2 ],
      ],
      materialOptions: [
        'furniture-basic-1',
        'red-paint',
        'blue-paint',
        'yellow-paint'
      ]
    }
  },
  'simple-chair': {
    previewUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/3ee86dbf-b893-4bc6-b629-f836afdae808.png',
    modelUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/65593bc8-3765-4c7e-ba7b-63e137668e52.dae',
    modelScale: 4.0,
    materialId: 0,
    sizeId: 0,
    details: {
      name: 'Chair',
      owner: 'Simple®',
      designer: '九安 句人吃开',
      priceBase: 999,
      priceCurrency: 'eur',
      taxRate: 0,
      shippingFrom: 'Beijng, CN',
      measurementOptions: [
        [ 50, 76, 50 ],
      ],
      materialNames: [
        'Beech wood',
        'Carbon fiber'
      ],
      sizeOptions: [
        [ 1, 1 ],
      ],
      materialOptions: [
        'furniture-basic-1',
        'space-mat',
      ]
    }
  },
  'simple-lampstand': {
    previewUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/25b64d36-63e1-4bb6-8fb9-4f1a444c4e96.png',
    modelUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/50067593-3a62-4695-8fa1-f93101a08b63.dae',
    modelScale: 4.0,
    materialId: 0,
    sizeId: 0,
    details: {
      name: 'Lämp',
      owner: 'Simple®',
      designer: '九安 句人吃开',
      priceBase: 129,
      priceCurrency: 'eur',
      taxRate: 0,
      shippingFrom: 'Beijng, CN',
      measurementOptions: [
        [ 50, 185, 50 ],
      ],
      materialNames: [
        'Matte black'
      ],
      sizeOptions: [
        [ 1, 1 ],
      ],
      materialOptions: [
        'wood-and-black-metal'
      ]
    }
  },
  'simple-painting': {
    previewUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/3838f302-ff6c-4bf7-878e-e6121dbd89af.png',
    modelUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/9516de4e-a1ed-4e52-ad23-d972efd11e75.dae',
    modelScale: 4.0,
    contextType: 'painting',
    sizeId: 0,
    details: {
      name: 'Painting Frame',
      owner: 'Simple®',
      designer: '九安 句人吃开',
      priceBase: 99999,
      priceCurrency: 'eur',
      taxRate: 0,
      shippingFrom: 'Beijng, CN',
      measurementOptions: [
        [ 5, 50, 62 ],
      ],
      materialNames: [
        'Wooden Frame'
      ],
      sizeOptions: [
        [ .5, 1 ],
      ],
      materialOptions: [
        'furniture-basic-1',
      ]
    }
  },
  'debug-sphere': {
    previewUrl: 'https://cdn.wtlstudio.com/common-ptr.wtlstudio.com/04bd023e-4a97-457a-bb7d-e008f3542086.png',
    primitive: 'sphere',
    materialId: 0,
    radius: 15.0,
    modelScale: 4.0,
    materialId: 0,
    sizeId: 0,
    details: {
      name: 'Debug Sphere',
      measurementOptions: [
        [ 1, 1 ],
      ],
      materialNames: [
        'Debug'
      ],
      sizeOptions: [
        [ 1, 1 ]
      ],
      materialOptions: [
        'metal'
      ]
    },
    comments: [
      {
        time: '2020-04-20T12:10:00+00:00',
        author: 'jasonstekham',
        comment: 'I think we should reconsider the sofas here. And maybe replace it with some- thing in brighter color?'
      },
      {
        time: '2020-04-20T22:21:00+00:00',
        author: 'mason',
        comment: 'I think we should reconsider the sofas here. And maybe replace it with some- thing in brighter color?'
      },
      {
        time: '2020-04-20T22:21:00+00:00',
        author: 'mason',
        comment: 'I think we should reconsider the sofas here. And maybe replace it with some- thing in brighter color?'
      },
      {
        time: '2020-04-21T11:40:00+00:00',
        author: 'deevasilevskaia',
        comment: 'Let’s see if we can find something else.'
      }
    ]
  }
};
