import Rete from 'rete';
import { EventSourceSocket, StringSocket } from '../sockets';
import { BaseLayout } from '../layouts/base.layout';
import { StringControl } from '../controls/string.control';

export class CommentNode extends Rete.Component {
  constructor() {
    super('Comment');
    this.data.component = BaseLayout;
  }

  builder(node) {
    const ctrl = new StringControl(this.editor, 'Text', node);

    return node.addControl(ctrl);
  }

  worker(node, inputs, outputs) {
    
  }
}
