import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: inline-flex;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 18px;
  border-radius: 25px;
  cursor: pointer;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  box-sizing: border-box;

  ${p => p.disabled && `pointer-events: none;`}
`;

const LabelWrapper = styled.div`
  position: relative;
  padding: 10px 14px;
  background-color: #ffffff;
  color: #000000;
  box-sizing: border-box;
  border-radius: 25px;
  z-index: 1;
`;

const OptionsWrapper = styled.div`
  position: relative;
  ${p => p.shifted && 'left: -5px;'}
  display: flex;
  background-color: #000000;
  color: #ffffff;
  padding: 4px;
  box-sizing: border-box;
  border-radius: 25px;
  z-index: 2;
  overflow: hidden;
`;

const Option = styled.div`
  position: relative;
  font-size: 12px;
  padding: 6px;
  text-transform: uppercase;
  box-sizing: border-box;
  border-radius: 25px;
  z-index: 2;

  ${p => p.selected && `
    color: #000000;
  `}

  ${p => p.background && `
    position: absolute;
    top: 4px;
    left: 4px;
    color: transparent;
    background: #ffffff;
    z-index: 1;
  `}

  ${p => p.location && `
    top: 4px;
    left: ${p.location}px;
    transition: left .2s ease, width .2s ease;
  `}
`;

export class CASmartToggle extends React.Component {
  optionRefs = [];
  state = {
    selectedOffset: null,
    moveToIndex: 0
  };

  setInitialOffset() {
    const { options } = this.props;
    let selectionIndex = options.findIndex(({ selected }) => selected);

    if (this.optionRefs[selectionIndex].current && this.state.selectedOffset === null) {
      this.state.moveToIndex = selectionIndex;
      this.state.selectedOffset = this.optionRefs[selectionIndex].current.offsetLeft;
      this.forceUpdate();
    }
  }

  onSelectOption(i) {
    if (!this.optionRefs[i].current) {
      return;
    }

    this.state.selectedOffset = this.optionRefs[i].current.offsetLeft;
    this.state.moveToIndex = i;
    this.forceUpdate();
  }

  componentDidMount() {
    const { options } = this.props;

    options.forEach((_, i) => this.optionRefs[i] = React.createRef());

    this.setInitialOffset();

    setTimeout(() => {
      this.forceUpdate();
    }, 100);
  }

  componentDidUpdate() {
    this.setInitialOffset();
  }
  
  render() {
    const { label, options, disabled } = this.props;
    const { moveToIndex } = this.state;
    let selectionIndex = options.findIndex(({ selected }) => selected);

    if (selectedOption === -1) {
      selectedOption = 0;
    }

    const selectedOption = options[selectionIndex];

    return (
      <Wrapper disabled={disabled}>
        {label && <LabelWrapper
          onClick={() => {
            if (disabled) {
              return;
            }

            const { moveToIndex } = this.state;
            const nextSelectedIndex = (moveToIndex + 1) % options.length;
            const { onClick } = options[nextSelectedIndex];

            this.onSelectOption(nextSelectedIndex);

            if (typeof onClick === 'function') {
              onClick();
            }
          }}
        >
          {label}
        </LabelWrapper>}
        <OptionsWrapper shifted={!!label}>
          {options.map(({ label, onClick }, i) => (
            <Option
              selected={this.state.moveToIndex === i}
              ref={this.optionRefs[i]}
              onClick={() => {
                if (disabled) {
                  return;
                }

                this.onSelectOption(i);

                if (typeof onClick === 'function') {
                  onClick();
                }
              }}
            >
              {label}
            </Option>
          ))}
          <Option
            background
            location={this.state.selectedOffset}
          >
            {options[moveToIndex].label}
          </Option>
        </OptionsWrapper>
      </Wrapper>
    );
  }
}
