import Rete from 'rete';
import { EventSourceControl } from '../controls/event-source.control';
import { EventSourceSocket } from '../sockets';
import { BaseLayout } from '../layouts/base.layout';

export class EventSourceNode extends Rete.Component {
  constructor() {
    super('Event');
    this.data.component = BaseLayout;
  }

  builder(node) {
    const out1 = new Rete.Output('then', '', EventSourceSocket, false);
    const ctrl = new EventSourceControl(this.editor, 'then', node);

    return node.addControl(ctrl).addOutput(out1);
  }

  worker(node, inputs, outputs) {
    outputs['then'] = node.data['then'];
  }
}
