import { v4 } from 'uuid';
import { callApi } from '../../config';
import { authTokenKey } from './editor.service';
import { message } from 'antd';

class StateService {
  schemaLocalKey = '__schema__';
  libraryLocalKey = '__lib__';
  behavioursLocalKey = '__behaviours__';
  clearBehavioursLocalKey = '__cBehaviours__';
  configLocalKey = '__config__';

  constructor() {
    this.state = {
      schema: null,
      schemaHistory: {
        steps: [],
        index: 0
      },
      library: null,
      behaviours: null,
      config: null,
      fetchingProject: true,
      projectDirty: false,
      editedElement: {
        schemaPage: 'index',
        library: null,
        behaviour: null
      },
      previewMode: false
    };
  }

  rememberEditedElement(type, id) {
    this.state.editedElement[type] = id;
  }

  getEditedElement(type) {
    return this.state.editedElement[type];
  }

  isFetchingProject() {
    return this.state.fetchingProject;
  }

  clearAll() {
    if (typeof window === 'undefined') {
      return;
    }

    sessionStorage.clear();
  }

  async fetchProject() {
    if (typeof window === 'undefined') {
      return;
    }

    this.state.fetchingProject = true;

    if (sessionStorage[this.schemaLocalKey] && sessionStorage[this.behavioursLocalKey] && sessionStorage[this.libraryLocalKey]) {
      this.state.schema = JSON.parse(sessionStorage.getItem(this.schemaLocalKey) || '{}');
      this.state.library = JSON.parse(sessionStorage.getItem(this.libraryLocalKey) || '[]');
      this.state.behaviours = JSON.parse(sessionStorage.getItem(this.behavioursLocalKey) || '[]');
      this.state.config = JSON.parse(sessionStorage.getItem(this.configLocalKey) || '{}');

      this.pushSchemaHistory();

      this.state.fetchingProject = false;

      return;
    }

    this.state.schema = {};
    this.state.library = [];
    this.state.behaviours = [];
    this.state.config = {};

    let response;
    
    try {
      response = await callApi(
        'project/schema',
        'GET',
        undefined,
        {
          authorization: `Bearer ${sessionStorage[authTokenKey]}`
        }
      );
    } catch {
      response = {};
    }

    if (response && response.schema && response.schema.schema) {
      this.state.schema = JSON.parse(response.schema.schema);
      this.pushSchemaHistory();
    }
    
    if (response && response.schema && response.schema.library) {
      this.state.library = JSON.parse(response.schema.library);
    }

    if (response && response.schema && response.schema.behaviours) {
      this.state.behaviours = JSON.parse(response.schema.behaviours);
    }

    if (response && response.schema && response.schema.config) {
      this.state.config = JSON.parse(response.schema.config);
    }

    this.state.fetchingProject = false;

    await this.saveAll({ local: true });
  }

  getSchemaCount() {
    return Object.keys(this.state.schema).length;
  }

  getEntireSchema() {
    return this.state.schema;
  }

  getSchemaPages() {
    if (!this.state.schema) {
      return [];
    }

    return Object.keys(this.state.schema)
      .filter(name => this.state.schema[name])
      .filter(name => name !== 'index') || [];
  }

  createNewPage(id, startingSchema = []) {
    this.state.schema[id] = JSON.parse(JSON.stringify(startingSchema));

    this.state.projectDirty = true;
  }

  renamePage(id, newId) {
    this.state.schema[newId] = this.state.schema[id];

    this.state.schema[id] = {};
    delete this.state.schema[id];

    this.state.projectDirty = true;
  }

  removePage(id) {
    delete this.state.schema[id];
  }

  clearPage(id){ 
    this.state.schema[id] = [];
  }

  getSchema(page) {
    if (!this.state.schema) {
      return;
    }

    if (this.state.schemaHistory.index > 0) {
      return JSON.parse(this.state.schemaHistory.steps[this.state.schemaHistory.index])[page];
    }

    const pageSchema = this.state.schema[page];

    if (!pageSchema) {
      this.state.schema[page] = [];

      this.state.projectDirty = true;
    }

    return this.state.schema[page];
  }

  pushSchemaHistory() {
    this.state.schemaHistory.steps.unshift(JSON.stringify(this.state.schema));
    this.state.schemaHistory.steps = [].concat(this.state.schemaHistory.steps.slice(0, 5));

    this.state.schemaHistory.index = 0;
  }

  getSchemaHistoryStepsCount() {
    return this.state.schemaHistory.steps.length;
  }

  canRedo() {
    return this.state.schemaHistory.index > 0;
  }

  undo() {
    if (this.state.schemaHistory.index >= this.state.schemaHistory.steps.length) {
      return;
    }

    this.state.schemaHistory.index += 1;
  }

  redo() {
    if (this.state.schemaHistory.index <= 0) {
      return;
    }

    this.state.schemaHistory.index -= 1;
  }

  async saveSchema() {
    if (typeof window === 'undefined') {
      return;
    }

    this.pushSchemaHistory();
    sessionStorage.setItem(this.schemaLocalKey, JSON.stringify(this.state.schema));

    this.state.projectDirty = true;
  }

  async saveLibrary() {
    if (typeof window === 'undefined') {
      return;
    }

    sessionStorage.setItem(this.libraryLocalKey, JSON.stringify(this.state.library));

    this.state.projectDirty = true;
    window.stylingCache = [];
  }

  async cleanLibrary() {
    if (typeof window === 'undefined') {
      return;
    }

    this.state.library = [];
    this.state.projectDirty = true;

    await this.saveLibrary();
  }

  findElementById(page, id, branch) {
    const { schema } = this.state || {};

    if (!schema) {
      return;
    }

    const schemaPage = schema[page];

    if (!schema || !schemaPage) {
      return;
    }

    let collection;

    if (!branch) {
      collection = schema[page];
    }

    if (branch && branch.content instanceof Array) {
      collection = branch.content;
    }

    if (!collection) {
      return;
    }

    const element = collection.find(item => item.id === id);

    if (element) {
      return element;
    }

    if (!element) {
      for (let nextBranch of collection) {
        const nested = this.findElementById(page, id, nextBranch);

        if (nested) {
          return nested;
        }
      }
    }
  }

  findElementByParam(page, param, value, branch) {
    const { schema } = this.state || {};

    if (!schema) {
      return;
    }

    const schemaPage = schema[page];

    if (!schema || !schemaPage) {
      return;
    }

    let collection;

    if (!branch) {
      collection = schema[page];
    }

    if (branch && branch.content instanceof Array) {
      collection = branch.content;
    }

    if (!collection) {
      return;
    }

    const element = collection.find(item => item[param] === value);

    if (element) {
      return element;
    }

    if (!element) {
      for (let nextBranch of collection) {
        const nested = this.findElementByParam(page, param, value, nextBranch);

        if (nested) {
          return nested;
        }
      }
    }
  }

  traverse(page, fn, branch) {
    const { schema } = this.state || {};

    if (!schema || typeof fn !== 'function') {
      return;
    }

    const schemaPage = schema[page];

    if (!schema || !schemaPage) {
      return;
    }

    let collection;

    if (!branch) {
      collection = schema[page];
    }

    if (branch && branch.content instanceof Array) {
      collection = branch.content;
    }

    if (!collection) {
      return;
    }
    
    collection.forEach(item => {
      fn(item);

      this.traverse(page, fn, item);
    });
  }

  getLibrary() {
    return this.state.library;
  }

  getLibraryElement(id) {
    return this.state.library.find(item => item.id === id);
  }

  createNewLibraryElement(presetName) {
    const { library } = this.state;
    const shape = {
      id: v4(),
      name: presetName || `New Component (${library.length + 1})`,
      dom: 'div',
      styling: `& {

}`
    };

    this.state.library.push(shape);

    this.state.projectDirty = true;

    return shape;
  }

  getBehaviours() {
    return this.state.behaviours;
  }

  createNewBehaviour(base = {}) {
    const { behaviours } = this.state;
    const shape = {
      id: v4(),
      name: `New Behaviour (${behaviours.length + 1})`,
      sequence: '',
      map: '',
      ...base
    };

    this.state.behaviours.push(shape);

    this.state.projectDirty = true;

    return shape;
  }

  removeBehaviour(id) {
    this.state.behaviours = this.state.behaviours.filter(behaviour => behaviour.id !== id);
    this.state.projectDirty = true;
  }

  saveBehaviours() {
    if (typeof window === 'undefined') {
      return;
    }

    sessionStorage.setItem(this.behavioursLocalKey, JSON.stringify(this.state.behaviours));
    sessionStorage.setItem(this.clearBehavioursLocalKey, JSON.stringify(
      this.state.behaviours.map(behaviour => ({
        ...behaviour,
        map: []
      }))
    ));

    this.state.projectDirty = true;
  }

  getConfig() {
    return this.state.config || {};
  }

  getFeatureToggle(id) {
    return this.state.config ? this.state.config[id] || false : false;
  }

  updateConfig(field, value) {
    this.state.config[field] = value;
  }

  saveConfig() {
    if (typeof window === 'undefined') {
      return;
    }

    sessionStorage.setItem(this.configLocalKey, JSON.stringify(this.state.config));

    this.state.projectDirty = true;
  }

  async saveAll({ local } = {}) {
    if (typeof window === 'undefined') {
      return;
    }

    sessionStorage.setItem(this.schemaLocalKey, JSON.stringify(this.state.schema));
    sessionStorage.setItem(this.libraryLocalKey, JSON.stringify(this.state.library));
    sessionStorage.setItem(this.behavioursLocalKey, JSON.stringify(this.state.behaviours));
    sessionStorage.setItem(this.clearBehavioursLocalKey, JSON.stringify(
      this.state.behaviours.map(behaviour => ({
        ...behaviour,
        map: []
      }))
    ));
    sessionStorage.setItem(this.configLocalKey, JSON.stringify(this.state.config));

    this.state.projectDirty = false;

    if (local !== true) {
      await this.updateServerState();
    }
  }

  async updateServerState() {
    return callApi(
      'project/schema',
      'POST',
      {
        schema: sessionStorage[this.schemaLocalKey],
        library: sessionStorage[this.libraryLocalKey],
        behaviours: sessionStorage[this.behavioursLocalKey],
        clearBehaviours: sessionStorage[this.clearBehavioursLocalKey],
        config: sessionStorage[this.configLocalKey]
      },
      {
        authorization: `Bearer ${sessionStorage[authTokenKey]}`
      }
    );
  }

  async publish() {
    return callApi(
      'project/publish',
      'POST',
      undefined,
      {
        authorization: `Bearer ${sessionStorage[authTokenKey]}`
      }
    );
  }
}

export default new StateService();
