import React from 'react';
import { VariableInput } from '../variable-input';
import { Row, Col } from 'antd';

export const renderScrollBlockProps = function () {
  const { tools } = this.state;
  const { selection } = tools;

  return (
    <Col span={8}>
      <Row gutter={4}>
        <VariableInput
          span={24}
          inputType="range"
          inputProps={{
            min: 0,
            max: 100,
            defaultValue: 100
          }}
          name="scroll speed"
          value={selection.branch.scrollSpeed}
          onChange={(value, params) => {
            this.updateSelectionParam('scrollSpeed', { target: { value }}, params);
          }}
        />
      </Row>
    </Col>
  );
}
