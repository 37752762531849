import React from 'react';
import { VariableInput } from '../variable-input';
import { Row, Col, message } from 'antd';
import StateService from '../../services/state.service';
import { Link } from 'gatsby';

export const renderStripeProps = function () {
  const { tools } = this.state;
  const { selection } = tools;
  const config = StateService.getConfig();
  const pages = StateService.getSchemaPages();

  if (!config.plugin_stripe) {
    return (
      <Col span={24} style={{ textAlign: 'center', top: 70, color: '#ccc' }}>
        <i className="fab fa-fw fa-stripe"></i> <Link to="/settings#content-apps">Click here to connect Stripe.</Link>
      </Col>
    );
  }

  return (
    <>
      <Col span={8}>
        <Row gutter={4}>
          <VariableInput
            span={24}
            name="product id (SKU)"
            value={selection.branch.productId}
            onChange={(value, params) => {
              if (params.variable) {
                return this.updateSelectionParam('productId', { target: { value }}, params);
              }

              if (!value.match(/.*?(_.*)/)) {
                message.warn('Invalid Stripe product id.');
                return;
              }

              const validated = `sku${value.match(/.*?(_.*)/)[1]}`;

              this.updateSelectionParam('productId', { target: { value: validated }}, params);
            }}
          />
          <VariableInput
            span={24}
            name="product quantity"
            value={selection.branch.productQuantity}
            onChange={(value, params) => {
              this.updateSelectionParam('productQuantity', { target: { value: parseInt(value, 10) }}, params);
            }}
          />
          <VariableInput
            span={24}
            name="when payment succeeds go to"
            inputType="select"
            value={selection.branch.successPage}
            onChange={(value, params) => {
              this.updateSelectionParam('successPage', { target: { value }}, params);
            }}
            hideNoneOption
          >
            <option value="">Starting Page</option>
            {
              pages && pages.map(page => (
                <option value={page}>{page}</option>
              ))
            }
          </VariableInput>
          <VariableInput
            span={24}
            name="when payment cancelled go to"
            inputType="select"
            value={selection.branch.cancelPage}
            onChange={(value, params) => {
              this.updateSelectionParam('cancelPage', { target: { value }}, params);
            }}
            hideNoneOption
          >
            <option value="">Starting Page</option>
            {
              pages && pages.map(page => (
                <option value={page}>{page}</option>
              ))
            }
          </VariableInput>
        </Row>
      </Col>
    </>
  );
}
