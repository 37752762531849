import React from 'react';
import { VariableInput } from '../variable-input';
import { Row, Col } from 'antd';

const rootGridSizes = {
  480: 'XS',
  640: 'S',
  760: 'S+',
  980: {
    label: <strong>MD</strong>
  },
  1280: 'MD+',
  1366: 'HD',
  1440: 'QHD',
  1920: 'FHD'
};

export const renderRootProps = function () {
  const { tools } = this.state;
  const { selection } = tools;

  return (
    <Col span={8}>
      <Row gutter={4}>
        <VariableInput
          span={24}
          name="page width"
          inputType="range"
          value={selection.branch.gridSize}
          onChange={(value, params) => {
            this.updateSelectionParam('gridSize', { target: { value }}, params);
          }}
          inputProps={{
            min: 480,
            max: 1920,
            marks: rootGridSizes,
            step: null,
            defaultValue: 980
          }}
        />
      </Row>
    </Col>
  );
}
