import 'regenerator-runtime';

import React from 'react';
import axios from 'axios';
import { addLocaleData } from 'react-intl';

import { WtlContext } from 'wtl-components-library/web/context/wtl-context';

import favIcon from './src/images/favicon.png';

// NOTE Add languages here (import intl locale to make messages work)
import enLocale from 'react-intl/locale-data/en';
import plLocale from 'react-intl/locale-data/pl';

import pl from './i18n/pl';
import en from './i18n/en';

export const localeData = { en, pl };
export const localeIntl = [
  ...enLocale,
  ...plLocale
];
export const availableLanguages = Object.keys(localeData);

addLocaleData(localeIntl);
// END of languages

if (typeof window !== 'undefined') {
  const aos = require('aos');
  require('aos/dist/aos.css');

  aos.init();

  window.wtlDebug = window.location.pathname.match(/^\/preview.*/g) ? 'false' : 'true';
}

export const config = {
  /**
   * Page URLs
   */
  SITE_URL: `https://app.wtlstudio.com`,
  API_URL: 'https://j81eal5nn6.execute-api.us-east-1.amazonaws.com/wtl-live/',
  TWITTER_URL: 'https://access.wtlstudio.com',

  /**
   * Metadata and SEO
   */
  FAV_ICON: favIcon,
  DEFAULT_LOCALE: 'en',
  DEFAULT_TITLE: `WTL Studio - Premium website builder for startups and business`,
  SEO_LOGO: 'https://s3.amazonaws.com/wtl-studio.public.wtlstudio.com/promotional-board-6.png',
  SEO_DESCRIPTION: `Everything you need to create a mobile-first HTML5 website. Intuitive drag-and-drop website builder, stylish layouts, professional support. All-in-one website platform for entrepreneurs, small business, and startups. No coding skills required. Free hosting & domain.`,
  SEO_KEYWORDS: [
    'wtl studio',
    'website builder',
    'wix alternative',
    'squarespace alternative',
    'premium website builder',
    'website editor',
    'how to build a website'
  ],
  /**
   * Plugin Configuration
   */
  enabledPlugins: [
    
  ],
  demoAccounts: [
    'demo@wtlstudio.com'
  ],

  /**
   * Third Party API
   */
  STRIPE_API_KEY: '',
  GOOGLE_TRACK_ID: 'UA-41810396-9',
  GOOGLE_MAPS_API_KEY: '',
  GOOGLE_SEARCH_CONSOLE_VERIFICATION_ID: ''
};

export const schemaSettings = {
  COLUMN_COUNT: 24
};

export const getApiEndpoint = (endpoint) => `${config.API_URL}${endpoint}`;

export const callApi = (endpoint, method = 'GET', params, headers) => {
  let call = axios({
    method: method.toLowerCase(),
    url: getApiEndpoint(endpoint),
    withCredentials: false,
    params: method === 'GET' ? params : {},
    data: method === 'POST' ? params : {},
    headers
  });

  return call
  .then(response => {
    return response.data;
  });
}

// NOTE Rendering Context Wrapper
export const WtlContextWrapper = ({ children }) => (
  <WtlContext.Provider value={{
    config,
    availableLanguages,
    localeData,
    localeIntl
  }}>
    <>
      {children}
    </>
  </WtlContext.Provider>
);

console.log(`
Thank you for choosing
__      _______ _      ___ _____ _   _ ___ ___ ___  
\\ \\    / /_   _| |    / __|_   _| | | |   \\_ _/ _ \\ 
 \\ \\/\\/ /  | | | |__  \\__ \\ | | | |_| | |) | | (_) |
  \\_/\\_/   |_| |____| |___/ |_|  \\___/|___/___\\___/ 
                                         ® 2016-2020
                               https://wtlstudio.com

Looking forward to join a great team and build something amazing with us ?
Apply on https://angel.co/company/wtl-studio/jobs !
`);
