import React from 'react';

export const WtlKeyCode = {
  off: false,
  on: true,
  unset: 2
};

export class WtlGlobalKeyboardListener extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      keys: {},
      listeners: [],
      metaKeys: []
    };
  }

  componentDidMount() {
    if (typeof window === 'undefined') {
      return;
    }

    window.addEventListener('keydown', this.onKeyDown.bind(this));
    window.addEventListener('keyup', this.onKeyUp.bind(this));
  }

  componentWillUnmount() {
    if (typeof window === 'undefined') {
      return;
    }

    window.removeEventListener('keydown', this.onKeyDown.bind(this));
    window.removeEventListener('keyup', this.onKeyUp.bind(this));
  }

  onKeyDown(event) {
    const { key } = event;
    const { keys } = this.state;
    const normalizedKey = `${key}`.toLowerCase();

    if (keys['meta'] || keys['control'] || keys['alt']) {
      this.state.metaKeys.push(normalizedKey);
    }

    if (this.state.keys[normalizedKey] !== WtlKeyCode.on) {
      this.state.keys[normalizedKey] = WtlKeyCode.on;
      this.checkListeners(event);
    }
  }

  onKeyUp(event) {
    const { key } = event;
    const { metaKeys } = this.state;
    const normalizedKey = `${key}`.toLowerCase();

    this.state.keys[normalizedKey] = WtlKeyCode.unset;

    if (!['alt', 'meta', 'control'].includes(normalizedKey) && metaKeys.length > 0) {
      metaKeys.forEach(savedKey => {
        this.state.keys[savedKey] = WtlKeyCode.unset;
      });

      this.state.metaKeys = [];
    }

    this.checkListeners(event);
  }

  checkListeners(event) {
    const { listeners, keys } = this.state;
    const { focusElement } = this.props;

    if (typeof document === 'undefined') {
      return;
    }

    (listeners || []).forEach(listener => {
      const listenerKeys = typeof listener.keys === 'string' ? [listener.keys] : listener.keys;

      if (listener.requireFocus && focusElement) {
        const activeElement = document.activeElement;
        
        if (activeElement !== focusElement) {
          return;
        }
      }

      let unsetInProgress = false;
      const condition = listenerKeys.reduce((met, keyString) => 
          met || keyString.split('+')
          .map(t => t.trim())
          .reduce((metString, currString) => {
            const key = keys[currString.toLowerCase()];

            if (key === WtlKeyCode.unset) {
              unsetInProgress = true;
            }

            return metString && (key === WtlKeyCode.on || key === WtlKeyCode.unset);
          }, true), false);

      if (condition) {
        const callback = listener[({
          [true]: 'on',
          [false]: 'off'
        })[!unsetInProgress]];
        
        if (typeof callback === 'function') {
          callback(event);
        }
      }
    });

    Object.keys(keys)
    .filter(id => this.state.keys[id] === WtlKeyCode.unset)
    .forEach(id => {
      this.state.keys[id] = WtlKeyCode.off;
    });
  }

  render() {
    const { listeners } = this.props;

    this.state.listeners = listeners;

    return null;
  }
}