import React from 'react';
import Rete from 'rete';
import { SimpleInput } from '../../../../styles/styled';
import { VariableInput } from '../../../variable-input';

export class StringControl extends Rete.Control {
  static component = ({ value, onChange }) => (
    <VariableInput
      span={24}
      value={value}
      onChange={(value, params) => onChange(value)}
      nonVariable
    />
  );

  constructor(emitter, key, node, readonly = false) {
    super(key);
    this.emitter = emitter;
    this.key = key;
    this.component = StringControl.component;

    const initial = node.data[key] || '';

    node.data[key] = initial;
    this.props = {
      readonly,
      value: initial,
      onChange: value => {
        this.setValue(value);
        this.emitter.trigger('process');
      }
    };
  }

  setValue(val) {
    this.props.value = val;
    this.putData(this.key, val);
    this.update();
  }
}