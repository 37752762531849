export const languages = {
  'en-us': 'English (United States)',
  'en-gb': 'English (United Kingdom)',
  'en': 'English (Universal)',
  'nl-nl': 'Dutch (Netherlands)',
  'nl-be': 'Dutch (Belgium)',
  'nl': 'Dutch (Universal)',
  'fr-fr': 'French (France)',
  'fr-be': 'French (Belgium)',
  'fr-ca': 'French (Canada)',
  'fr-lu': 'French (Luxembourg)',
  'fr-ch': 'French (Switzerland)',
  'fr': 'French (Universal)',
  'de-de': 'German (Germany)',
  'de-at': 'German (Austria)',
  'de-be': 'German (Belgium)',
  'de-li': 'German (Liechtenstein)',
  'de-lu': 'German (Luxembourg)',
  'de-ch': 'German (Switzerland)',
  'de': 'German (Universal)',
  'it-it': 'Italian (Italy)',
  'it-ch': 'Italian (Switzerland)',
  'it': 'Italian (Universal)',
  'ru-ru': 'Russian (Russia)',
  'ru-ua': 'Russian (Ukraine)',
  'ru': 'Russian (Universal)',
  'uk-ua': 'Ukrainian',
  'pl-pl': 'Polish',
  'es': 'Spanish (Universal)',
  'gsw-ch': 'Swiss German (Dialect)',
  'zh': 'Chinese (Universal)'
};

export const getLanguageNameFromCode = (code) => languages[code];
export const getLanguageCodeFromName = (name) => Object.keys(languages).find((code) => languages[code] === name);
