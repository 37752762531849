import Rete from 'rete';
import { EventSourceSocket, StringSocket } from '../sockets';
import { BaseLayout } from '../layouts/base.layout';
import { EventSourceControl } from '../controls/event-source.control';
import { ChangeVariableControl } from '../controls/change-variable.control';
import { StringControl } from '../controls/string.control';

export class ChangeVariableNode extends Rete.Component {
  constructor() {
    super('Variable');
    this.data.component = BaseLayout;
  }

  builder(node) {
    const inp1 = new Rete.Input('variable-id', 'Variable', StringSocket, false);
    const inp2 = new Rete.Input('variable-value', 'Value', StringSocket, false);
    const inp3 = new Rete.Input('event-source-in', '', EventSourceSocket, false);
    const out1 = new Rete.Output('variable-id', 'Id', StringSocket, false);
    const out2 = new Rete.Output('variable-ref', 'Reference', StringSocket, false);
    const out3 = new Rete.Output('then', '', EventSourceSocket, false);

    inp1.addControl(new ChangeVariableControl(this.editor, 'variable', node));

    return node
      .addControl(new ChangeVariableControl(this.editor, 'variable', node))
      .addInput(inp2)
      .addInput(inp3)
      .addOutput(out1)
      .addOutput(out2)
      .addOutput(out3);
  }

  worker(node, inputs, outputs) {
    const {
      variable
    } = node.data;

    node.data['set-id'] = variable.id;
    node.data['set-value'] = inputs['variable-value'].length ? inputs['variable-value'][0] : variable.value;

    outputs['variable-id'] = variable.id;
    outputs['variable-ref'] = `_$ref${variable.id}`;
  }
}