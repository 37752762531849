import React from 'react';
import { VariableInput } from '../variable-input';
import { Row, Divider, Col } from 'antd';
import { SimpleInput } from '../../styles/styled';
import { imageFileTypes } from '../../data/file-types.data';

export const renderCarouselProps = function () {
  const { tools } = this.state;
  const { selection } = tools;

  return (
    <Col span={8}>
      <Row gutter={4}>
        <VariableInput
          inputType="file"
          filterTypes={imageFileTypes}
          span={24}
          name="image (1/4)"
          value={selection.branch.image1}
          onChange={(value, params) => {
            this.updateSelectionParam('image1', { target: { value }}, params);
          }}
        />
        <VariableInput
          inputType="file"
          filterTypes={imageFileTypes}
          span={24}
          name="image (2/4)"
          value={selection.branch.image2}
          onChange={(value, params) => {
            this.updateSelectionParam('image2', { target: { value }}, params);
          }}
        />
        <VariableInput
          inputType="file"
          filterTypes={imageFileTypes}
          span={24}
          name="image (3/4)"
          value={selection.branch.image3}
          onChange={(value, params) => {
            this.updateSelectionParam('image3', { target: { value }}, params);
          }}
        />
        <VariableInput
          inputType="file"
          filterTypes={imageFileTypes}
          span={24}
          name="image (4/4)"
          value={selection.branch.image4}
          onChange={(value, params) => {
            this.updateSelectionParam('image4', { target: { value }}, params);
          }}
        />
        <Divider />
        <SimpleInput span={12}>
          <span>
            handles
          </span>
          <select
            value={selection.branch.displayHandles || 'both'}
            onChange={(e) => {
              this.updateSelectionParam('displayHandles', e);
              this.forceUpdate();
            }}
          >
            <option value="both">show prev-next handles</option>
            <option value="prev">show only prev handle</option>
            <option value="next">show only next handle</option>
            <option value="none">hide handles</option>
          </select>
        </SimpleInput>
        <SimpleInput span={12}>
          <span>
            handles mode
          </span>
          <select
            value={selection.branch.handlesMode || 'always-on'}
            onChange={(e) => {
              this.updateSelectionParam('handlesMode', e);
              this.forceUpdate();
            }}
          >
            <option value="always-on">always display handles</option>
            <option value="on-hover">display on mouse</option>
          </select>
        </SimpleInput>
        <SimpleInput span={12}>
            <span>handle icon</span>
            <input
              type="text"
              defaultValue="chevron-right"
              value={selection.branch.handleIcon && decodeURIComponent(selection.branch.handleIcon)}
              onChange={(e) => this.updateSelectionParam('handleIcon', e)}
            />
          </SimpleInput>
          <SimpleInput span={12}>
            <span>handle icon style</span>
            <select
              value={selection.branch.handleIconFamily || 'r'}
              onChange={(e) => this.updateSelectionParam('handleIconFamily', e)}
            >
              <option value="r">regular</option>
              <option value="s">solid</option>
              <option value="l">light</option>
              <option value="b">brand</option>
            </select>
          </SimpleInput>
          <Divider />
          <VariableInput
            span={12}
            name="width"
            value={selection.branch.width}
            onChange={(value, params) => {
              this.updateSelectionParam('width', { target: { value }}, params);
            }}
          />
          <VariableInput
            span={12}
            name="height"
            value={selection.branch.height}
            onChange={(value, params) => {
              this.updateSelectionParam('height', { target: { value }}, params);
            }}
          />
      </Row>
    </Col>
  );
}
