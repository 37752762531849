// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-common-3-d-js": () => import("/Users/Maciek/dev/wtl-creator/web/src/pages/common-3d.js" /* webpackChunkName: "component---src-pages-common-3-d-js" */),
  "component---src-pages-events-js": () => import("/Users/Maciek/dev/wtl-creator/web/src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("/Users/Maciek/dev/wtl-creator/web/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-library-js": () => import("/Users/Maciek/dev/wtl-creator/web/src/pages/library.js" /* webpackChunkName: "component---src-pages-library-js" */),
  "component---src-pages-login-js": () => import("/Users/Maciek/dev/wtl-creator/web/src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-preview-js": () => import("/Users/Maciek/dev/wtl-creator/web/src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-settings-js": () => import("/Users/Maciek/dev/wtl-creator/web/src/pages/settings.js" /* webpackChunkName: "component---src-pages-settings-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/Maciek/dev/wtl-creator/web/.cache/data.json")

