import React from 'react';
import styled from 'styled-components';
import { snapTo, snapToRounded } from '../../helpers/math/snap-to';

const RulerStep = styled.div`
  position: absolute;
  display: inline-block;
  top: ${p => p.top}px;
  left: ${p => p.left}px;
  ${p => ({
    'h': `
      width: ${p.primary ? 15 : 10}px;
      height: 1px;
    `,
    'v': `
      width: 1px;
      height: ${p.primary ? 15 : 10}px;
    `
  })[p.orientation]}
  background-color: ${p => p.primary ? '#000' : '#555'};
  z-index: 3;

  ${p => !isNaN(p.name) && `
    &:after {
      position: absolute;
      top: ${p.orientation === 'v' ? 19 : 0}px;
      left: ${p.orientation === 'h' ? 17 : 0}px;
      color: ${p.primary ? '#000' : '#555'};
      transform: ${p.orientation === 'v' ? 'translateX(-50%) rotate(90deg)' : 'translateY(-50%)'};
      font-size: 6px;
      z-index: 1;
      pointer-events: none;
      content '${p.name}';
    }
  `}
`;

const RulerShadow = styled.div`
  position: absolute;
  display: inline-block;
  top: ${p => p.top}px;
  left: ${p => p.left}px;
  ${p => ({
    'h': `
      width: 10px;
      height: ${p.size}px;
      transition: opacity .3s ease, width .1s ease;

      &:hover {
        width: 15px;
      }
    `,
    'v': `
      width: ${p.size}px;
      height: 10px;
      transition: opacity .3s ease, height .1s ease;

      &:hover {
        height: 15px;
      }
    `
  })[p.orientation]}
  background-color: #555;
  z-index: 2;
  opacity: .25;
  pointer-events: all;
  cursor: pointer;

  &:hover {
    opacity: .5;
  }
`;

const Guide = styled.div`
  position: absolute;
  display: inline-block;
  top: ${p => p.top}px;
  left: ${p => p.left}px;
  ${p => ({
    'h': `
      width: ${p.size}px;
      height: 1px;
    `,
    'v': `
      width: 1px;
      height: ${p.size}px;
    `
  })[p.orientation]}
  background-color: ${p => p.disabled ? 'none' : '#e833e5'};
  border-left: ${p => p.disabled ? 'dashed 1px #000' : 'none'};
  border-top: ${p => p.disabled ? 'dashed 1px #000' : 'none'};
  z-index: 3;

  .handle {
    position: relative;
    display: inline-block;
    padding: 2px;
    background-color: #fa8ef8;
    border: solid 1px #9e349c;
    border-radius: 50%;
    color: #9e349c;
    font-size: 9px;
    z-index: 1;
    cursor: pointer;
    pointer-events: all;
    ${p => ({
      'h': `
        transform: translateY(-50%);
      `,
      'v': `
        transform: translateX(-50%);
      `
    })[p.orientation]}
    opacity: 0;
  }

  &:hover {
    .handle {
      opacity: 1;
    }
  }
`;

export class WtlRulers extends React.Component {
  state = {
    mouseX: null,
    mouseY: null
  };

  render() {
    const {
      context,
      pagePreview,
      detailed,
      guides,
      onAddGuide,
      onRemoveGuide
    } = this.props;
    const {
      mouseX,
      mouseY
    } = this.state;

    if (!context || !pagePreview) {
      return null;
    }

    const pageRoot = pagePreview.querySelector('#root');

    if (!pageRoot) {
      return null;
    }

    const contextSize = context.getBoundingClientRect();
    const rootSize = pageRoot.getBoundingClientRect();

    const offsetY = rootSize.y - contextSize.y;
    const offsetX = (contextSize.width - rootSize.width) / 2;

    let stepsX, stepsY, ds, dn;

    if (detailed) {
      stepsX = Math.ceil(rootSize.width / 10) + 1;
      stepsY = Math.ceil(rootSize.height / 10) + 1;
      ds = 10;
      dn = 10;
    } else {
      stepsX = Math.round(rootSize.width / 100);
      stepsY = Math.round(rootSize.height / 100);
      ds = 100;
      dn = 1;
    }

    return (
      <>
        {!detailed && (<>
          <RulerShadow
            orientation="h"
            top={offsetY}
            left={0}
            size={rootSize.height}
            onMouseMove={(e) => {
              const { pageY } = e;

              this.state.mouseY = snapToRounded(pageY - (contextSize.y + offsetY), 100, 2);
              this.forceUpdate();
            }}
            onMouseLeave={() => {
              this.state.mouseY = null;
              this.forceUpdate();
            }}
            onClick={(e) => {
              onAddGuide({
                y: this.state.mouseY
              });
            }}
          />
          <RulerShadow
            orientation="v"
            top={0}
            left={offsetX}
            size={rootSize.width}
            onMouseMove={(e) => {
              const { pageX } = e;

              this.state.mouseX = snapToRounded(pageX - (contextSize.x + offsetX), 100, 2);
              this.forceUpdate();
            }}
            onMouseLeave={() => {
              this.state.mouseX = null;
              this.forceUpdate();
            }}
            onClick={(e) => {
              onAddGuide({
                x: this.state.mouseX
              });
            }}
          />
        </>)}
        {Array(stepsY).fill(0).map((_, index) => (
          <RulerStep
            orientation="h"
            primary={index % dn === 0}
            name={index % dn === 0 ? parseInt(index * ds, 10) : undefined}
            top={offsetY + index * ds}
            left={0}
          />
        ))}
        {Array(stepsX).fill(0).map((_, index) => (
          <RulerStep
            orientation="v"
            primary={index % dn === 0}
            name={index % dn === 0 ? parseInt(index * ds, 10) : undefined}
            top={0}
            left={offsetX + index * ds}
          />
        ))}
        <RulerStep
          orientation="h"
          primary
          name={rootSize.height}
          top={offsetY + rootSize.height}
          left={0}
        />
        <RulerStep
          orientation="v"
          primary
          name={rootSize.width}
          top={0}
          left={offsetX + rootSize.width}
        />
        {
          (guides || []).map(({ x, y }) => {
            const handle = (
              <div
                className="handle"
                onClick={(e) => {
                  e.preventDefault();

                  onRemoveGuide({ x, y });
                }}
              >
                <div className="fa fa-fw fa-times" />
              </div>
            );

            if (typeof x !== 'undefined') {
              return (
                <Guide orientation="v" top={0} left={offsetX + x} size={contextSize.height}>
                  {handle}
                </Guide>
              );
            } else if (typeof y !== 'undefined') {
              return (
                <Guide orientation="h" top={offsetY + y} left={0} size={contextSize.width}>
                  {handle}
                </Guide>
              );
            }
          })
        }
        {mouseX !== null && <Guide className="new-guide" orientation="v" top={0} left={offsetX + mouseX} size={contextSize.height} disabled />}
        {mouseY !== null && <Guide className="new-guide" orientation="h" top={offsetY + mouseY} left={0} size={contextSize.width} disabled />}
      </>
    );
  }
}
