import React from 'react';
import { Row, Col } from 'antd';
import { CAPanelBody } from './ca-panel';
import { Wtl3DScene } from '../../utils/three/wtl-3d-scene';
import * as Three from 'three';
import { VariableInput, uploadBase64 } from '../variable-input';
import { _3dModelFileTypes, imageFileTypes } from '../../data/file-types.data';
import { parseSchema } from '../../../common-schema/core/parse-schema';
import { materialPresets } from '../../data/common/materials';

export class CALibraryCustomModel extends React.Component {
  canvasRef = null;
  rootRef = null;
  sceneRef = null;
  cameraRef = null;
  rendererRef = null;

  constructor(props) {
    super(props);

    this.state = this.getDefaultState();
  }

  getDefaultState() {
    return {
      mode: 'material',
      id: '',
      modelUrl: '',
      modelScale: 15.0,
      materialId: 'furniture-basic-1',
      previewUrl: '',
      thumbnailShiftY: 0,
      textureUrl: '',
      pbrUrl: '',
      clearcoatUrl: '',
      normalsUrl: '',
      refractive: false,
      reflective: false,
      sharpenTexture: false,
      lightUrl: '',
      lightSrc: '',
      lightIntensity: 1.0,
      color: '',
      roughness: '',
      metalness: '',
      opacity: 1.0,
      clearcoat: '',
      clearcoatRoughness: '',
    };
  }

  resetState() {
    this.setState(this.getDefaultState());
  }

  reloadModelPreview() {
    const { modelUrl, modelScale, materialId, thumbnailShiftY } = this.state;
    const previewSchema = [
      {
        id: 'env',
        type: 'environment',
        backgroundType: 'color',
        backgroundColor: 'rgba(255,255,255,0)',
        sunColor: '#fffefa',
        content: [
          {
            id: 'test',
            type: 'object',
            positionX: 0,
            positionY: 0,
            positionZ: 0,
            modelScale: modelScale || 1.0,
            modelUrl: modelUrl,
            materialId: materialId,
            _dirty: 2
          }
        ]
      }
    ];

    parseSchema(previewSchema, {
      renderer: this.rendererRef,
      scene: this.sceneRef,
      camera: this.cameraRef,
      root: this.rootRef,
      renderQuality: 2
    });

    this.cameraRef.lookAt(new Three.Vector3(0, thumbnailShiftY, 0));
  }

  reloadMaterialPreview() {
    const {
      textureUrl,
      pbrUrl,
      clearcoatUrl,
      normalsUrl,
      refractive,
      lightUrl,
      lightSrc,
      lightIntensity,
      opacity,
      clearcoat,
      reflective,
      sharpenTexture,
    } = this.state;

    const previewSchema = [
      {
        id: 'env',
        type: 'environment',
        backgroundType: 'color',
        backgroundColor: 'rgba(255,255,255,0)',
        sunColor: '#fffefa',
        content: [
          {
            id: 'test',
            type: 'object',
            primitive: 'sphere',
            radius: 15.0,
            positionX: 0,
            positionY: 0,
            positionZ: 0,
            materialId: 'custom',
            textureUrl,
            pbrUrl,
            clearcoatUrl,
            normalsUrl,
            refractive,
            lightUrl,
            lightSrc,
            lightIntensity,
            opacity,
            clearcoat,
            reflective,
            sharpenTexture: false,
            _dirty: 2
          }
        ]
      }
    ];

    parseSchema(previewSchema, {
      renderer: this.rendererRef,
      scene: this.sceneRef,
      camera: this.cameraRef,
      root: this.rootRef,
      renderQuality: 2
    });

    this.cameraRef.lookAt(new Three.Vector3(0, 0, 0));
  }

  renderModelProps() {
    const { modelUrl, modelScale, materialId, thumbnailShiftY } = this.state;

    return (
      <>
        <VariableInput
          span={24}
          name="model file"
          inputType="file"
          filterTypes={_3dModelFileTypes}
          nonVariable
          value={modelUrl}
          onChange={(value) => {
            this.state.modelUrl = value;

            this.reloadModelPreview();
            this.forceUpdate();
          }}
        />
        <VariableInput
          span={24}
          name="material"
          inputType="select"
          nonVariable
          hideNoneOption
          value={materialId}
          onChange={(value) => {
            this.state.materialId = value;

            this.reloadModelPreview();
            this.forceUpdate();
          }}
        >
          {Object.keys(materialPresets).map(id => (
            <option value={id}>{id}</option>
          ))}
        </VariableInput>
        <VariableInput
          span={24}
          name="default scale"
          nonVariable
          inputType="range"
          inputProps={{
            min: 0.01,
            max: 10,
            step: 0.01
          }}
          value={modelScale}
          onChange={(value) => {
            this.state.modelScale = value;

            this.reloadModelPreview();
            this.forceUpdate();
          }}
        />
        <VariableInput
          span={24}
          name="thumbnail shift Y"
          nonVariable
          inputType="range"
          inputProps={{
            min: 0.01,
            max: 40,
            step: 0.01
          }}
          value={thumbnailShiftY}
          onChange={(value) => {
            this.state.thumbnailShiftY = value;

            this.reloadModelPreview();
            this.forceUpdate();
          }}
        />
      </>
    );
  }

  renderMaterialProps() {
    const {
      textureUrl,
      pbrUrl,
      clearcoatUrl,
      normalsUrl,
      refractive,
      lightUrl,
      lightSrc,
      lightIntensity,
      reflective,
      sharpenTexture,
    } = this.state;

    return (
      <>
        <VariableInput
          span={12}
          name="tex map"
          inputType="file"
          filterTypes={imageFileTypes}
          nonVariable
          value={textureUrl}
          onChange={(value) => {
            this.state.textureUrl = value;

            this.reloadMaterialPreview();
            this.forceUpdate();
          }}
        />
        <VariableInput
          span={12}
          name="normal map"
          inputType="file"
          filterTypes={imageFileTypes}
          nonVariable
          value={normalsUrl}
          onChange={(value) => {
            this.state.normalsUrl = value;

            this.reloadMaterialPreview();
            this.forceUpdate();
          }}
        />
        <VariableInput
          span={12}
          name="pbr map"
          inputType="file"
          filterTypes={imageFileTypes}
          nonVariable
          value={pbrUrl}
          onChange={(value) => {
            this.state.pbrUrl = value;

            this.reloadMaterialPreview();
            this.forceUpdate();
          }}
        />
        <VariableInput
          span={12}
          name="clearcoat map"
          inputType="file"
          filterTypes={imageFileTypes}
          nonVariable
          value={clearcoatUrl}
          onChange={(value) => {
            this.state.clearcoatUrl = value;

            this.reloadMaterialPreview();
            this.forceUpdate();
          }}
        />
        <VariableInput
          span={12}
          name="light map"
          inputType="file"
          filterTypes={imageFileTypes}
          nonVariable
          value={lightUrl}
          onChange={(value) => {
            this.state.lightUrl = value;

            this.reloadMaterialPreview();
            this.forceUpdate();
          }}
        />
        <VariableInput
          span={12}
          name="light intensity"
          nonVariable
          inputType="range"
          inputProps={{
            min: 0.01,
            max: 10,
            step: 0.01
          }}
          value={lightIntensity}
          onChange={(value) => {
            this.state.lightIntensity = value;

            this.reloadMaterialPreview();
            this.forceUpdate();
          }}
        />
        <VariableInput
          span={24}
          name="light color"
          inputType="color"
          nonVariable
          value={lightSrc}
          onChange={(value) => {
            this.state.lightSrc = value;

            this.reloadMaterialPreview();
            this.forceUpdate();
          }}
        />
        <VariableInput
          span={12}
          name="reflective"
          inputType="switch"
          nonVariable
          value={reflective}
          onChange={(value) => {
            this.state.reflective = value;

            this.reloadMaterialPreview();
            this.forceUpdate();
          }}
        />
        <VariableInput
          span={12}
          name="refractive"
          inputType="switch"
          nonVariable
          value={refractive}
          onChange={(value) => {
            this.state.refractive = value;

            this.reloadMaterialPreview();
            this.forceUpdate();
          }}
        />
        <VariableInput
          span={12}
          name="sharpenTexture"
          inputType="switch"
          nonVariable
          value={sharpenTexture}
          onChange={(value) => {
            this.state.sharpenTexture = value;

            this.reloadMaterialPreview();
            this.forceUpdate();
          }}
        />
      </>
    );
  }

  render() {
    const {
      mode,
      id,
      modelUrl,
      materialId,
      previewUrl,
      textureUrl,
      pbrUrl,
      clearcoatUrl,
      normalsUrl,
      refractive,
      lightUrl,
      lightSrc,
      lightIntensity,
      opacity,
      clearcoat,
      reflective,
      sharpenTexture
    } = this.state;

    return (
      <CAPanelBody margin="20px 25px">
        <Row>
          <Col span={24}>
            <Wtl3DScene
              width={256}
              height={256}
              renderQuality={2}
              rendererOptions={{
                alpha: true,
                preserveDrawingBuffer: true
              }}
              style={{
                textAlign: 'center',
                border: 'solid 1px #ccc'
              }}
              onSceneReady={({ renderer, scene, root, canvas, camera }) => {
                this.rendererRef = renderer;
                this.canvasRef = canvas;
                this.rootRef = root;
                this.cameraRef = camera;
                this.sceneRef = scene;

                this.rendererRef.alpha = true;

                camera.position.set(40.0, 30.0, 40.0);
                camera.lookAt(new Three.Vector3(0, 0, 0));
              }}
            />
          </Col>
          <VariableInput
            span={24}
            name="id"
            nonVariable
            value={id}
            onChange={(value) => this.setState({
              ...this.state,
              id: (value || '').trim().split(' ').join('-')
            })}
          />
          {mode === 'model' && this.renderModelProps()}
          {mode === 'material' && this.renderMaterialProps()}
          <VariableInput
            span={12}
            name="upload thumbnail"
            inputType="button"
            noTooltip
            onClick={async () => {
              if (!id) {
                if (!previewUrl) {
                  this.state.previewUrl = 'Missing ID';
                  this.forceUpdate();
                }
                return;
              }

              const { name, url } = await uploadBase64(`${id}-thumb-${Date.now()}.png`, this.canvasRef.toDataURL());

              this.state.previewUrl = url;
              this.forceUpdate();
            }}
          />
          <VariableInput
            span={12}
            name="thumbnail url"
            nonVariable
            value={previewUrl}
            onChange={(value) => this.setState({
              ...this.state,
              previewUrl: value
            })}
          />
          <VariableInput
            span={24}
            name="log schema"
            inputType="button"
            noTooltip
            onClick={() => {
              if (mode === 'model') {
              console.info(`'${id}': {
  previewUrl: '${previewUrl}',
  modelUrl: '${modelUrl}',
  modelScale: 4.0,
  materialId: '${materialId}'
},`);
              } else {
                console.info(`'${id}': {
  previewUrl: '${previewUrl}',
  textureUrl: '${textureUrl}',
  sharpenTexture: ${sharpenTexture ? 'true' : 'false'},
  pbrUrl: '${pbrUrl}',
  clearcoatUrl: '${clearcoatUrl}',
  normalsUrl: '${normalsUrl}',
  refractive: ${refractive},
  lightUrl: '${lightUrl}',
  lightSrc: '${lightSrc}',
  lightIntensity: ${lightIntensity},
  opacity: ${opacity},
  clearcoat: '${clearcoat}',
  reflective: ${reflective}
},`);
              }
            }}
          />
          <VariableInput
            span={24}
            name="reset"
            inputType="button"
            noTooltip
            onClick={() => {
              this.resetState();
            }}
          />
          <VariableInput
            span={24}
            name={`switch to ${mode === 'model' ? 'materials' : 'models'}`}
            inputType="button"
            noTooltip
            onClick={() => {
              this.state.mode = mode === 'model' ? 'material' : 'model';
              this.forceUpdate();
            }}
          />
        </Row>
      </CAPanelBody>
    );
  }
}