/* global performance */
import React from 'react';
import styled from 'styled-components';
import { Block } from '../block';
import { extractVariable } from '../../helpers/extract-variable';
import { iNaN } from '../../helpers/i-nan';

let Three;

try {
  Three = require('three');

  Three.OrbitControls = require('three/examples/jsm/controls/OrbitControls.js').OrbitControls;
  Three.RGBELoader = require('three/examples/jsm/loaders/RGBELoader.js').RGBELoader;
  Three.OBJLoader2 = require('three/examples/jsm/loaders/OBJLoader2.js').OBJLoader2;
  Three.EffectComposer = require('three/examples/jsm/postprocessing/EffectComposer.js').EffectComposer;
  Three.SSAOPass = require('three/examples/jsm/postprocessing/SSAOPass.js').SSAOPass;
} catch (error) {
  // TODO Ignore this block if ThreeJS is not used
  Three = null;
}

const isUsed = () => Three !== null;
const isDebug = typeof window !== 'undefined' && window.wtlDebug === 'true';

const CanvasContainer = styled.div`
  &, canvas {
    outline: none;
    cursor: default;
  }
`;

export class ContentAdvanced3dModel extends React.Component {
  constructor(props) {
    super(props);

    this.displayRef = React.createRef();
  }

  componentDidMount() {
    this.active = 0;
    this.renderer = null;
    this.composer = null;
    this.scene = null;
    this.camera = null;
    this.controls = null;
    this.light = null;
    this.model = null;
    this.colorTexture = null;
    this.envMapTexture = null;
    this.materialTexture = null;
    this.fallback = false;

    this.initScene();
  }

  showFallback() {
    this.fallback = true;
    this.forceUpdate();
  }

  hideFallback() {
    this.fallback = false;
    this.forceUpdate();
  }

  initScene() {
    if (!this.displayRef.current || this.active !== 0 || this.active === -1) {
      return;
    }

    const {
      envMapUrl,
      textureUrl,
      materialMapUrl,
      modelUrl
    } = this.props;

    const {
      TextureLoader,
      OBJLoader2
    } = Three;

    this.active = 1;

    this.model = null;
    this.colorTexture = null;
    this.envMapTexture = null;
    this.materialTexture = null;

    const promisables = [];

    const textureLoader = new TextureLoader();
    const modelLoader = new OBJLoader2();

    promisables.push(new Promise(done => {
      if (!materialMapUrl) {
        return done();
      }

      textureLoader.load(
        extractVariable(materialMapUrl),
        (materialTexture) => {
          this.materialTexture = materialTexture;

          done();
        },
        undefined,
        () => console.warn(`failed to load material: ${materialMapUrl}`)
      );
    }));

    promisables.push(new Promise(done => {
      if (!textureUrl) {
        return done();
      }

      textureLoader.load(
        extractVariable(textureUrl),
        (colorTexture) => {
          this.colorTexture = colorTexture;

          done();
        },
        undefined,
        () => console.warn(`failed to load texture: ${textureUrl}`)
      );
    }));

    promisables.push(new Promise(done => {
      if (!envMapUrl) {
        return done();
      }

      textureLoader.load(
        extractVariable(envMapUrl),
        (envMapTexture) => {
          this.envMapTexture = envMapTexture;
  
          done();
        },
        undefined,
        () => console.warn(`failed to load env map: ${envMapUrl}`)
      );
    }));

    promisables.push(new Promise(done => {
      if (!modelUrl) {
        return done();
      }

      modelLoader.load(
        extractVariable(modelUrl),
        (model) => {
          this.model = model;
  
          done();
        },
        undefined,
        () => console.warn(`failed to load model: ${modelUrl}`)
      );
    }));

    Promise.all(promisables)
    .then(() => {
      this.createScene();
    });
  }

  setMaterial() {
    if (!this.model) {
      return;
    }

    const modelMaterial = this.prepareMaterial();    

    if (this.model && this.model.material) {
      this.model.material = modelMaterial.clone();
    } else {
      if (this.model.children) {
        this.model.children.forEach((mesh) => {
          mesh.material = modelMaterial.clone();
        });
      }
    }
  }

  prepareMaterial() {
    const {
      Color,
      MeshPhysicalMaterial,
      DoubleSide
    } = Three;

    const pbrSpecs = {
      roughness: this.materialTexture || !this.props.roughness ? 1. : extractVariable(this.props.roughness) / 100.,
      metalness: this.materialTexture || !this.props.metalness ? 1. : extractVariable(this.props.metalness) / 100.
    };
    const pbrColor = new Color().setHSL(0., .4, .4);

    return new MeshPhysicalMaterial({
      roughness: pbrSpecs.roughness,
      metalness: pbrSpecs.metalness,
      color: this.colorTexture ? 0xffffff : pbrColor,
      map: this.colorTexture ? this.colorTexture : undefined,
      depthTest: true,
      envMap: this.scene.environment ? this.scene.environment.texture : undefined,
      envMapIntensity: 1.,
      aoMap: this.materialTexture ? this.materialTexture : undefined,
      metalnessMap: this.materialTexture ? this.materialTexture : undefined,
      roughnessMap: this.materialTexture ? this.materialTexture : undefined,
      transparent: true,
      alphaTest: .25,
      side: DoubleSide
    });
  }

  createScene() {
    const {
      WebGLRenderer,
      OrbitControls,
      PerspectiveCamera,
      Scene,
      Group,
      SphereBufferGeometry,
      Mesh,
      PointLight,
      HemisphereLight,
      ACESFilmicToneMapping,
      sRGBEncoding,
      PMREMGenerator,
      BufferAttribute,
      EffectComposer,
      SSAOPass
    } = Three;

    const {
      _3dOffsetX,
      _3dOffsetY,
      _3dOffsetZ,
      _3dAngleX,
      _3dAngleY,
      _3dAngleZ,
      _3dUserZoom,
      enableSSAO,
      SSAODetail,
      enableAA
    } = this.props;

    const useComposer = enableSSAO;

    if (!this.displayRef.current) {
      return;
    }

    const width = this.displayRef.current.parentNode.offsetWidth;
    const height = this.displayRef.current.parentNode.offsetHeight;

    let renderer;

    if (!this.renderer) {
      renderer = new WebGLRenderer({
        alpha: true,
        antialias: isDebug || enableAA === false ? false : true,
        precision: isDebug ? 'lowp' : 'highp',
        powerPreference: isDebug ? 'low-power' : 'default'
      });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(width, height);
      renderer.toneMapping = ACESFilmicToneMapping;
      renderer.toneMappingExposure = 1;
      renderer.outputEncoding = sRGBEncoding;

      this.displayRef.current.innerHTML = '';
      this.displayRef.current.appendChild(renderer.domElement);
      this.renderer = renderer;

      if (useComposer) {
        this.composer = new EffectComposer(this.renderer);
      }

      requestAnimationFrame(() => this.onAnimationStep());
    } else {
      renderer = this.renderer;
    }

    let camera;

    if (!this.camera) {
      camera = new PerspectiveCamera(
        27,
        width / height,
        1.,
        10000.
      );

      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;
      controls.dampingFactor = .05;
      controls.screenSpacePanning = false;
      controls.minDistance = .1;
      controls.maxDistance = 2000.;
      controls.maxPolarAngle = Math.PI * 2;
      controls.enableKeys = false;
      controls.enableZoom = _3dUserZoom || false;
      controls.enablePan = false;

      this.controls = controls;
      this.camera = camera;
    } else {
      camera = this.camera;
    }

    let scene;

    if (!this.scene) {
      scene = new Scene();

      scene.matrixAutoUpdate = false;

      this.scene = scene;

      if (this.composer) {
        const ssaoPass = new SSAOPass(this.scene, this.camera, width, height);
        ssaoPass.kernelRadius = SSAODetail ? SSAODetail / 100 : 1;
        ssaoPass.minDistance = 0.00001;
        ssaoPass.maxDistance = 1000.0;

        this.composer.addPass(ssaoPass);
      }
    } else {
      scene = this.scene;

      while(scene.children.length > 0){ 
        scene.remove(scene.children[0]); 
      }
    }

    const group = new Group();
    scene.add(group);

    let cubeRenderTarget;

    if (this.envMapTexture) {
      const pmremGenerator = new PMREMGenerator(renderer);
      pmremGenerator.compileEquirectangularShader();

      cubeRenderTarget = pmremGenerator.fromEquirectangular(this.envMapTexture);
      scene.environment = cubeRenderTarget.texture;

      this.envMapTexture.dispose();
      pmremGenerator.dispose();
    }

    const modelMaterial = this.prepareMaterial();    

    if (!this.model) {
      const sphereGeometry = new SphereBufferGeometry(80, 64, 32);
      sphereGeometry.setAttribute('uv2', new BufferAttribute(sphereGeometry.attributes.uv.array, 2));

      const pbrSphere = new Mesh(sphereGeometry, modelMaterial);
      this.camera.position.x = 0 - iNaN(parseFloat(extractVariable(_3dOffsetX))) / 10.;
      this.camera.position.y = 0 - iNaN(parseFloat(extractVariable(_3dOffsetY))) / 10.;
      this.camera.position.z = 1010. - iNaN(parseFloat(extractVariable(_3dOffsetZ))) / 10.;

      this.model = pbrSphere;
    } else {
      if (this.model.children) {
        this.model.children.forEach((mesh) => {
          mesh.material = modelMaterial.clone();

          if (mesh.geometry.attributes.uv) {
            mesh.geometry.setAttribute('uv2', new BufferAttribute(mesh.geometry.attributes.uv.array, 2));
          }
        });
      }
      this.camera.position.x = 0 - iNaN(parseFloat(extractVariable(_3dOffsetX))) / 10.;
      this.camera.position.y = 1. - iNaN(parseFloat(extractVariable(_3dOffsetY))) / 10.;
      this.camera.position.z = 10. - iNaN(parseFloat(extractVariable(_3dOffsetZ))) / 10.;
    }

    const degConverter = Math.PI / 180;

    this.model.rotation.x = iNaN(parseFloat(extractVariable(_3dAngleX))) * degConverter;
    this.model.rotation.y = iNaN(parseFloat(extractVariable(_3dAngleY))) * degConverter;
    this.model.rotation.z = iNaN(parseFloat(extractVariable(_3dAngleZ))) * degConverter;

    group.add(this.model);

    const light = new PointLight(0xffffcc, .9);

    this.light = light;

    scene.add(light);
    scene.add(new HemisphereLight(0xffffff, 0x0000ff, .1));

    this.active = 2;
  }

  timeStep = typeof performance !== 'undefined' ? performance.now() : 0;
  avgFps = [0];

  onAnimationStep() {
    if (typeof performance !== 'undefined') {
      const fps = performance.now();

      this.avgFps.push(Math.min(fps - this.timeStep, 60));
      this.avgFps = this.avgFps.slice(Math.max(this.avgFps.length - 30, 0));

      let avg = 0;

      this.avgFps.forEach(value => avg += (value / this.avgFps.length));

      const momentFps = 1 / avg * 1000;

      if (this.avgFps.length >= 10 && momentFps <= 25.0) {
        // this.fallback = true;

        // this.forceUpdate();
      }
      
      this.timeStep = performance.now();
    }

    const timer = Date.now() * 0.00025;

    if (this.active === 2) {
      const {
        _3dAngleSpeedX,
        _3dAngleSpeedY,
        _3dAngleSpeedZ
      } = this.props;
      const degConverter = Math.PI / 180;

      this.light.position.x = Math.sin( timer * 7 ) * 300;
      this.light.position.y = Math.cos( timer * 5 ) * 400;
      this.light.position.z = Math.cos( timer * 3 ) * 300;

      this.model.rotation.x += iNaN(parseFloat(extractVariable(_3dAngleSpeedX))) * degConverter;
      this.model.rotation.y += iNaN(parseFloat(extractVariable(_3dAngleSpeedY))) * degConverter;
      this.model.rotation.z += iNaN(parseFloat(extractVariable(_3dAngleSpeedZ))) * degConverter;

      this.controls.update();

      if (this.composer) {
        this.composer.render();
      } else {
        this.renderer.render(this.scene, this.camera);
      }
    }

    if (this.active === -1 || this.fallback || this.props.fallback || isDebug) {
      return;
    }

    requestAnimationFrame(() => this.onAnimationStep());
  }

  componentDidUpdate(prevProps) {
    if (!isUsed() || !this.displayRef.current || this.active === -1) {
      return;
    }

    if (
      (extractVariable(prevProps._3dKey) !== extractVariable(this.props._3dKey)) ||
      (extractVariable(prevProps.modelUrl) !== extractVariable(this.props.modelUrl)) || 
      (extractVariable(prevProps.envMapUrl) !== extractVariable(this.props.envMapUrl)) ||
      (extractVariable(prevProps.textureUrl) !== extractVariable(this.props.textureUrl)) || 
      (extractVariable(prevProps.materialMapUrl) !== extractVariable(this.props.materialMapUrl))
    ) {
      if (isDebug) {
        this.renderer = null;
        this.composer = null;
        this.scene = null;
      }

      this.active = 0;
    }

    if (this.active === 2) {
      const width = this.displayRef.current.parentNode.clientWidth - 5;
      const height = this.displayRef.current.parentNode.clientHeight - 5;

      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();

      this.renderer.setSize(width, height);

      if (this.composer) {
        this.composer.setSize(width, height);
      }
    } else {
      this.initScene();
    }
  }

  componentWillUnmount() {
    this.active = -1;
  }

  render() {
    const {
      _3dUserOrbit
    } = this.props;

    return (
      <Block {...this.props}>
        <CanvasContainer
          ref={this.displayRef}
          key={this.props.id || 1}
          style={{
            position: 'relative',
            maxWidth: '100%',
            maxHeight: '100%',
            width: '100%',
            height: '100%',
            pointerEvents: _3dUserOrbit && !isDebug ? 'all' : 'none',
            outline: 'none'
          }}
        />
      </Block>
    );
  }
}
