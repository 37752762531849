import Rete from 'rete';
import ConnectionPlugin from 'rete-connection-plugin';
import ReactRenderPlugin from 'rete-react-render-plugin';
import { EventSourceNode } from './nodes/event-source.node';
import { RefreshPageNode } from './nodes/refresh-page.node';
import { ChangeVariableNode } from './nodes/change-variable.node';
import { NavigateToNode } from './nodes/navigate-to.node';
import { ApiNode } from './nodes/api.node';
import { SendEmailNode } from './nodes/send-email';
import { ElementSourceNode } from './nodes/element-source.node';
import { BehaviourParamSourceNode } from './nodes/behaviour-param-source.node';
import { DebugNode } from './nodes/debug.node';
import { ChangeAppearanceNode } from './nodes/change-appearance.node';
import { ColorSourceNode } from './nodes/color-source.node';
import { StringSourceNode } from './nodes/string-source.node';
import { CssValueSourceNode } from './nodes/css-value-source.node';
import { IfNode } from './nodes/if.node';
import { CommentNode } from './nodes/comment.node';

let behaviourEditorInstance;

export async function createBehaviourEditor(container, initial, onChange) {
  if (behaviourEditorInstance) {
    behaviourEditorInstance._destroyed = true;
    behaviourEditorInstance.clear();
    behaviourEditorInstance.destroy();

    behaviourEditorInstance = null;
  }

  const components = [
    new EventSourceNode(),
    new RefreshPageNode(),
    new ChangeVariableNode(),
    new NavigateToNode(),
    new ApiNode(),
    new SendEmailNode(),
    new ElementSourceNode(),
    new BehaviourParamSourceNode(),
    new DebugNode(),
    new ChangeAppearanceNode(),
    new ColorSourceNode(),
    new StringSourceNode(),
    new CssValueSourceNode(),
    new IfNode(),
    new CommentNode()
  ];

  const editor = new Rete.NodeEditor('behaviour-editor@1.0.0', container);
  editor.use(ConnectionPlugin);
  editor.use(ReactRenderPlugin);

  const engine = new Rete.Engine('behaviour-editor@1.0.0');

  components.map(c => {
    editor.register(c);
    engine.register(c);
  });

  const initialNode = await components[0].createNode({ ['Event']: 'onClick' });
  const navNode = await components[3].createNode();

  await editor.clear();

  editor.on(
    'process nodecreated noderemoved connectioncreated connectionremoved',
    async () => {
      await engine.abort();
      await engine.process(editor.toJSON());
    }
  );

  editor.view.resize();
  editor.trigger('process');

  editor.on('nodecreated connectioncreated noderemoved connectionremoved nodedraged keyup', () => {
    if (behaviourEditorInstance && !behaviourEditorInstance._destroyed && typeof onChange === 'function') {
      onChange(editor.toJSON());
    }
  });

  if (initial) {
    await editor.fromJSON(JSON.parse(initial));
  } else {
    editor.addNode(initialNode);
  }

  behaviourEditorInstance = editor;

  return {
    editor,
    addNode: async (id) => {
      if (!components[id]) {
        return;
      }
      const { container } = editor.view.area;
      const [hw, hh] =  [container.clientWidth/2, container.clientHeight/2];
      const { x, y, k } = editor.view.area.transform;
          
      const center = [ (hw - x) / k, (hh - y) / k ];

      const newNode = await components[id].createNode();
      newNode.position = center;

      editor.addNode(newNode);
    }
  };
}
