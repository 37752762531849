import React from 'react';
import Rete from 'rete';
import { SimpleInput } from '../../../../styles/styled';
import { Select } from 'antd';

const { Option } = Select;

export class ConditionControl extends Rete.Control {
  static component = ({ value, onChange }) => (
    <Select
      search
      showArrow={false}
      placeholder="Select..."
      dropdownMatchSelectWidth={false}
      style={{
        width: '100%'
      }}
      onChange={(prop) => onChange(prop)}
      value={value}
    >
      <Option value="equal">value a / is equal to / value b</Option>
      <Option value="grtr">value a / is greater than / value b</Option>
      <Option value="grte">value a / is greater or equal to / value b</Option>
      <Option value="email">value a / is an email</Option>
      <Option value="date">value a / is a date</Option>
      <Option value="number">value a / is a number</Option>
      <Option value="username">value a / is a valid username</Option>
      <Option value="password">value a / is a secure password</Option>
      <Option value="url">value a / is a website url</Option>
      <Option value="time">value a / is a valid time</Option>
      <Option value="phone">value a / is a phone</Option>
      <Option value="exists">value a / is not empty</Option>
      <Option value="length">value a / has length of / value b</Option>
    </Select>
  );

  constructor(emitter, key, node, readonly = false) {
    super(key);
    this.emitter = emitter;
    this.key = key;
    this.component = ConditionControl.component;

    const initial = node.data[key];

    node.data[key] = initial;
    this.props = {
      readonly,
      value: initial,
      onChange: v => {
        this.setValue(v);
        this.emitter.trigger('process');
      }
    };
  }

  setValue(val) {
    this.props.value = val;
    this.putData(this.key, val);
    this.update();
  }
}