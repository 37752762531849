import * as Three from 'three';
import { parseObject } from './object';

export const parseTile = (branch, context, options) => {
  const { _selfKey, parent } = options;
  const {
    positionX,
    positionZ,
    color = 0x3399ff,
    previewOnly = false,
    material = 'unpainted-floor'
  } = branch;

  if (!parent) {
    return;
  }

  let instance = parent.getObjectByName(_selfKey);

  if (!instance) {
    instance = new Three.Group();

    const tile = parseObject({
      ...branch,
      sizeX: 10,
      sizeY: 2,
      sizeZ: 10,
      positionX: 0,
      positionY: 1.5,
      positionZ: 0,
      color,
      primitive: 'cube',
      opacity: previewOnly ? 0.5 : 1.0,
      materialId: material,
    }, context, {
      ...options,
      parent: instance,
      selectable: true,
      selectionTarget: false
    });
    tile.name = 'sideA';

    instance.position.x = positionX;
    instance.position.y = parent.position.y || 0;
    instance.position.z = positionZ;

    instance.selectable = true;
    instance.selectionTarget = true;
    parent.add(instance);
  }

  return instance;
};
