import React from 'react';
import styled from 'styled-components';
import {
  Layout,
  Menu,
  Icon,
  Row,
  Col,
  message,
  Tooltip,
} from 'antd';
import { AntWrapper } from '../components/ant-wrapper';
import { HeaderNav } from '../components/header-nav';
import { SimpleButton, SimpleInput } from '../styles/styled';
import StateService from '../services/state.service';
import EditorService from '../services/editor.service';

let AceEditor;

if (typeof window !== 'undefined') {
  AceEditor = require('react-ace').default;

  require('brace');
  require('brace/mode/sass');
  require('brace/theme/xcode');
} else {
  AceEditor = styled.div``;
}

const { SubMenu } = Menu;
const {
  Sider,
  Content
} = Layout;

const getStyledPreview = (base, styles) => styled[base]`${styles}`;

export default class Library extends React.Component {
  constructor(props) {
    super(props);

    const editedElement = StateService.getEditedElement('library');

    this.state = {
      editor: editedElement ?
        this.findLibraryElement(editedElement) : 
        null,
      library: []
    };

    StateService.fetchProject().then(() => {
      this.forceUpdate();
    });
  }

  createNewComponent() {
    const component = StateService.createNewLibraryElement();

    this.setState({
      editor: component
    });

    StateService.saveLibrary();
  }

  findLibraryElement(key) {
    const library = StateService.getLibrary();

    return library.find(({ id }) => id === key);
  }

  async onCleanLibrary() {
    await StateService.cleanLibrary();

    this.setState({
      editor: null,
      library: []
    });
  }

  selectComponent({ key }) {
    const component = this.findLibraryElement(key);

    if (!component) {
      return;
    }

    this.setState({
      editor: component
    });

    StateService.rememberEditedElement('library', key);
  }

  updateParam(prop, event, { parseValue } = {}) {
    if (event.preventDefault) {
      event.preventDefault();
    }

    const { target } = event;
    const { value } = target;
    const component = this.state.editor;

    let parsedValue = value;

    if (value && parseValue) {
      parsedValue = JSON.parse(value);
    }

    const libraryElement = this.findLibraryElement(component.id);
    
    if (!libraryElement) {
      return;
    }

    component[prop] = parsedValue;
    libraryElement[prop] = parsedValue;

    StateService.saveLibrary();

    this.forceUpdate();
  }

  renderEditorPreview() {
    const { editor } = this.state;
    const wrapper = getStyledPreview(editor.dom, editor.styling) || 'div';

    return (
      <Content
        style={{
          position: 'relative',
          background: '#fff',
          padding: 24,
          margin: '0 0 10px 0'
        }}
      >
        <Row gutter={4}>
          <Col span={24}>
            {
              React.createElement(wrapper, {}, editor.dom !== 'input' ? 'PREVIEW' : undefined)
            }
          </Col>
        </Row>
      </Content>
    );
  }

  renderEditor() {
    const { editor } = this.state;

    return (
      <Content
        style={{
          position: 'relative',
          background: '#fff',
          padding: 24,
          margin: 0
        }}
      >
        <Row gutter={4}>
          <SimpleInput span={24}>
            <span>style id</span>
            <input
              type="text"
              value={editor.id}
            />
          </SimpleInput>
        </Row>
        <Row gutter={4}>
          <Col span={2}>
            <SimpleInput span={24}>
              <span>representation</span>
              <select
                value={editor.dom || 'div'}
                onChange={(e) => this.updateParam('dom', e)}
              >
                <option disabled>-- Base --</option>
                <option value="div">div</option>
                <option value="span">span</option>
                <option value="a">a</option>
                <option disabled>-- Presentational --</option>
                <option value="img">img</option>
                <option value="video">video</option>
                <option value="i">i</option>
                <option value="b">b</option>
                <option disabled>-- Headings --</option>
                <option value="h1">h1</option>
                <option value="h2">h2</option>
                <option value="h3">h3</option>
                <option value="h4">h4</option>
                <option disabled>-- Forms --</option>
                <option value="button">button</option>
                <option value="input">input</option>
                <option value="textarea">textarea</option>
              </select>
            </SimpleInput>
          </Col>
          <Col span={22}>
            <SimpleInput span={24}>
              <span>component name</span>
              <input
                placeholder="Unnamed"
                value={editor.name}
                onChange={(e) => this.updateParam('name', e)}
              />
            </SimpleInput>
          </Col>
        </Row>
        <Row gutter={4}>
          <Col span={24}>
            <AceEditor
              placeholder="Add some styles..."
              mode="sass"
              theme="xcode"
              onChange={(e) => this.updateParam('styling', { target: { value: e } })}
              fontSize={14}
              showPrintMargin={true}
              showGutter={true}
              highlightActiveLine={true}
              value={editor.styling}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2
              }}
              style={{
                width: '100%'
              }}
            />
          </Col>
        </Row>
      </Content>
    );
  }

  componentDidMount() {
    EditorService.refreshAuth();
  }

  render() {
    if (!EditorService.isLoggedIn()) {
      EditorService.refreshAuth(async () => {
        this.forceUpdate();

        await StateService.fetchProject();

        this.forceUpdate();
      });

      return null;
    }

    try {
      const { editor } = this.state;
      const library = StateService.getLibrary();

      return (
        <AntWrapper style={{ minWidth: 1200 }}>
          <Layout style={{ width: '100vw', height: '100vh', boxSizing: 'border-box' }}>
            <HeaderNav service={StateService} />
            <Layout>
              <Sider width={250} style={{ background: '#fff', overflow: 'scroll' }}>
                <Menu
                  mode="inline"
                  defaultOpenKeys={[
                    'components'
                  ]}
                  style={{ height: '100%', borderRight: 0 }}
                  selectedKeys={editor ? [editor.id] : []}
                  onSelect={(e) => this.selectComponent(e)}
                >
                  <SubMenu
                    key="components"
                    title={
                      <span>
                        <Icon type="smile" />
                        Components
                      </span>
                    }
                    disabled={!library}
                  >
                    {library.map((item, index) => (
                      <Menu.Item
                        key={item.id}
                      >
                        <Icon
                          type={({
                            'div': 'build',
                            'span': 'build',
                            'a': 'link',
                            'img': 'picture',
                            'video': 'picture',
                            'i': 'picture',
                            'b': 'picture',
                            'button': 'select',
                            'input': 'select',
                            'textarea': 'select',
                            'h1': 'font-size',
                            'h2': 'font-size',
                            'h3': 'font-size',
                            'h4': 'font-size'
                          })[item.dom || 'div']}
                        />
                        {item.name || 'Unnamed'} <i style={{ opacity: .5 }}>({item.id})</i>
                      </Menu.Item>
                    ))}
                  </SubMenu>
                  <SubMenu
                    key="settings"
                    title={
                      <span>
                        <Icon type="smile" />
                        Settings
                      </span>
                    }
                  >
                    <Row gutter={4} style={{ padding: '0 20px', paddingBottom: 80 }}>
                      <Tooltip title="Clean library" placement="topLeft">
                        <SimpleButton onClick={() => this.onCleanLibrary()}>
                          <i className="far fa-trash-alt"></i>
                        </SimpleButton>
                      </Tooltip>
                    </Row>
                  </SubMenu>
                </Menu>
              </Sider>
              <Layout style={{ padding: 24 }}>
                <Row gutter={24} style={{ marginBottom: 10 }}>
                  <Col span={24}>
                    <Content
                      style={{
                        position: 'relative',
                        background: '#fff',
                        padding: 12,
                        margin: 0
                      }}
                    >
                      <i className="fa fa-fw fa-info-circle" /> Styles panel allows you to create additional CSS stylesheets for components.
                    </Content>
                  </Col>
                </Row>
                <Row gutter={24} style={{ marginBottom: 10 }}>
                  <Col span={24}>
                    <Content
                      style={{
                        position: 'relative',
                        background: '#fff',
                        padding: 12,
                        margin: 0
                      }}
                    >
                      <SimpleButton onClick={() => this.createNewComponent()}>
                        <i className="fal fa-shapes"></i> New Component
                      </SimpleButton>
                      <SimpleButton disabled>
                        <i className="fal fa-copy"></i> Duplicate
                      </SimpleButton>
                      <SimpleButton disabled>
                        <i className="fal fa-trash-alt"></i> Move to Trash
                      </SimpleButton>
                    </Content>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24}>
                    {editor && this.renderEditor()}
                  </Col>
                </Row>
              </Layout>
            </Layout>
          </Layout>
        </AntWrapper>
      );
    } catch (error) {
      if (typeof window === 'undefined') {
        return null;
      }
  
      message.error('Error, refresh page.', error);

      return null;
    }
  }
}
