import React from 'react';
import styled from 'styled-components';
import * as Three from 'three';
import { CAPanelBody, CAPanelH1 } from './ca-panel';
import { Row, Col, Slider } from 'antd';
import { ContextTools } from '../../pages/common-3d';

import WallsToolSvg from '../../images/common-app/icons/ICONS_COMMON_SVG-08.svg';
import FloorsToolSvg from '../../images/common-app/icons/ICONS_COMMON_SVG-13.svg';
import ArchesToolSvg from '../../images/common-app/icons/ICONS_COMMON_SVG-18.svg';
import PaintingToolSvg from '../../images/common-app/icons/ICONS_COMMON_SVG-09.svg';
import ContextToolSvg from '../../images/common-app/icons/ICONS_COMMON_SVG-04.svg';

import WallsToolBaseSvg from '../../images/common-app/icons/ICONS_COMMON_SVG-05.svg';
import WallsToolShiftSvg from '../../images/common-app/icons/ICONS_COMMON_SVG-14.svg';

import FloorsToolBaseSvg from '../../images/common-app/icons/ICONS_COMMON_SVG-10.svg';
import FloorsToolShiftSvg from '../../images/common-app/icons/ICONS_COMMON_SVG-15.svg';

import RemoveToolSvg from '../../images/common-app/icons/ICONS_COMMON_SVG-19.svg';

import { InvertedImage } from '../../utils/inverted-image';
import StateService from '../../services/state.service';
import { CAVariableInput } from './ca-variable-input';
import { CASmartToggle } from './ca-smart-toggle';
import { materialPresets } from '../../data/common/materials';
import { MaterialPreview } from './ca-filters';
import { WtlGlobalKeyboardListener } from '../layout/wtl-global-keyboard-listener';

const ArchCanvasContainer = styled.div`
  position: relative;
  display: block;
  width: 160px;
  height: 250px;
  margin: 0 auto;
  cursor: ${p => p.dragging ? 'pointer' : 'default'};
  z-index: 1;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, .1);
  margin-top: 20px;
`;

const ArchPointMarker = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #000;
  border-radius: 50%;
  left: ${p => p.x * 160}px;
  top: ${p => p.y * 250}px;
  z-index: 2;
  cursor: pointer;
`;

export class CAToolbox extends React.Component {
  archPreviewRef = React.createRef();
  state = {
    tab: '',
    archX1: .8,
    archY1: .1,
    archX2: .5,
    archY2: .5,
    draggingArchMarker: false
  };

  componentDidMount() {
    this.drawPreviewArch();
  }

  componentDidUpdate() {
    this.drawPreviewArch();
  }

  changeTab(tab) {
    const { onUpdated } = this.props;
    const { archX1, archY1, archX2, archY2 } = this.state;

    if (tab === this.state.tab) {
      onUpdated(ContextTools.select, {
        gridSnap: 1,
        showCursor: false
      });

      this.setState({
        ...this.state,
        tab: ''
      });
  
      setTimeout(() => {
        this.forceUpdate();
      }, 100);

      return;
    }

    switch (tab) {
      case 'walls':
        onUpdated(ContextTools.place_env, {
          gridSnap: 10,
          lockPan: true,
          lockRotate: true,
          showCursor: 'walls',
          envType: 'wall'
        });
      break;
      case 'floors':
        onUpdated(ContextTools.place_env, {
          gridSnap: 10,
          gridShiftX: 5,
          gridShiftZ: 5,
          gridSnapFn: 'floor',
          lockPan: true,
          lockRotate: true,
          showCursor: 'tile',
          envType: 'tile',
          addTrailingEnv: true,
          fillBulk: true
        });
      break;
      case 'arches':
        onUpdated(ContextTools.place_env, {
          gridSnap: 10,
          lockPan: true,
          lockRotate: true,
          showCursor: 'walls',
          envType: 'wall',
          archX1: archX1,
          archY1: archY1,
          archX2: archX2,
          archY2: archY2
        });
      break;
      case 'painting':
      break;
      case 'context':
        
      break;
    }

    this.setState({
      ...this.state,
      tab
    });

    setTimeout(() => {
      this.forceUpdate();
    }, 100);
  }

  renderWallsTab() {
    const { contextTool } = this.props;

    return (
      <div>
        <Row>
          <Col span={12}>
            <CAPanelH1>
              Walls
            </CAPanelH1>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'center' }}>
            <CAPanelBody display="flex" margin="20px auto" style={{ overflow: 'visible', width: '90%'}}>
              {[
                { icon: WallsToolBaseSvg, active: !contextTool.mode },
                { icon: WallsToolShiftSvg, active: contextTool.mode === 'bulk' },
                { icon: RemoveToolSvg, active: contextTool.mode === 'remove' }
              ].map(({ icon, onClick, active }) => (
                <div style={{ width: '100%' }}>
                  <InvertedImage
                    src={icon}
                    width="80"
                    height="80"
                    style={{
                      filter: [active ? '' : 'invert(100%)', 'drop-shadow(0px 1px 2px #dddddd)'].join(' '),
                      cursor: active ? 'default' : 'pointer'
                    }}  
                  />
                </div>
              ))}
            </CAPanelBody>
          </Col>
        </Row>
      </div>
    );
  }

  renderFloorsTab() {
    const { contextTool } = this.props;

    return (
      <div>
        <Row>
          <Col span={12}>
            <CAPanelH1>
              Floors
            </CAPanelH1>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'center' }}>
            <CAPanelBody display="flex" margin="20px auto" style={{ overflow: 'visible', width: '90%'}}>
              {[
                { icon: FloorsToolBaseSvg, active: !contextTool.mode },
                { icon: FloorsToolShiftSvg, active: contextTool.mode === 'bulk' },
                { icon: RemoveToolSvg, active: contextTool.mode === 'remove' }
              ].map(({ icon, active }) => (
                <div style={{ width: '100%' }}>
                  <InvertedImage
                    src={icon}
                    width="80"
                    height="80"
                    style={{
                      filter: [active ? '' : 'invert(100%)', 'drop-shadow(0px 1px 2px #dddddd)'].join(' '),
                      cursor: 'default'
                    }}
                  />
                </div>
              ))}
            </CAPanelBody>
          </Col>
        </Row>
      </div>
    );
  }

  onArchPointerMouseDown(event, side) {
    this.state.draggingArchMarker = side;
    this.forceUpdate();
  }

  onArchPointerMouseMove(event) {
    if (!this.state.draggingArchMarker) {
      return;
    }

    event.preventDefault();

    const side = this.state.draggingArchMarker;
    const { clientX, clientY } = event;
    const target = this.archPreviewRef.current.parentElement;
    const { left, top, width, height } = target.getBoundingClientRect();

    const x = Three.MathUtils.clamp((clientX - left) / width, 0, 1);
    const y = Three.MathUtils.clamp((clientY - top) / height, 0, 1);

    if (side === 'start') {
      this.state.archX1 = Math.max(x, this.state.archX2);
      this.state.archY1 = Math.min(y, this.state.archY2);

      this.forceUpdate();
    } else if (side === 'end') {
      this.state.archX2 = Math.min(x, this.state.archX1);
      this.state.archY2 = Math.max(y, this.state.archY1);

      this.forceUpdate();
    }
  }

  onArchPointerMouseUp(event, side) {
    const { onUpdated } = this.props;
    const { archX1, archY1, archX2, archY2 } = this.state;

    this.state.draggingArchMarker = false;

    onUpdated(ContextTools.place_env, {
      gridSnap: 10,
      lockPan: true,
      lockRotate: true,
      showCursor: 'walls',
      envType: 'wall',
      archX1: archX1,
      archY1: archY1,
      archX2: archX2,
      archY2: archY2
    });

    this.forceUpdate();
  }

  drawPreviewArch() {
    if (!this.archPreviewRef || !this.archPreviewRef.current) {
      return;
    }

    const { archX1, archY1, archX2, archY2 } = this.state;
    const ctx = this.archPreviewRef.current.getContext('2d');

    const previewWidth = 160;
    const previewHeight = 250;

    ctx.fillStyle = '#fff';
    ctx.strokeStyle = '#aaa';
    ctx.lineWidth = .5;

    ctx.clearRect(0, 0, previewWidth, previewHeight);

    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(previewWidth, 0);
    ctx.lineTo(previewWidth, archY1 * previewHeight + 5);
    ctx.lineTo(archX1 * previewWidth + 5, archY1 * previewHeight + 5);
    ctx.quadraticCurveTo(
      archX2 * previewWidth + 5, archY1 * previewHeight + 5,
      archX2 * previewWidth + 5, archY2 * previewHeight + 5
    );
    ctx.lineTo(archX2 * previewWidth + 5, previewHeight);
    ctx.lineTo(0, previewHeight);
    ctx.lineTo(0, 0);
    ctx.fill();

    ctx.fillStyle = 'rgba(238, 238, 238, .2)';

    ctx.beginPath();
    ctx.moveTo(previewWidth, archY1 * previewHeight + 5);
    ctx.lineTo(archX1 * previewWidth + 5, archY1 * previewHeight + 5);
    ctx.quadraticCurveTo(
      archX2 * previewWidth + 5, archY1 * previewHeight + 5,
      archX2 * previewWidth + 5, archY2 * previewHeight + 5
    );
    ctx.lineTo(archX2 * previewWidth + 5, previewHeight);
    ctx.lineTo(previewWidth, previewHeight);
    ctx.lineTo(previewWidth, archY1 * previewHeight + 5);
    ctx.fill();

    ctx.beginPath();
    ctx.moveTo(previewWidth, archY1 * previewHeight + 5);
    ctx.lineTo(archX1 * previewWidth + 5, archY1 * previewHeight + 5);
    ctx.quadraticCurveTo(
      archX2 * previewWidth + 5, archY1 * previewHeight + 5,
      archX2 * previewWidth + 5, archY2 * previewHeight + 5
    );
    ctx.lineTo(archX2 * previewWidth + 5, previewHeight);
    ctx.stroke();
  }

  renderArchesTab() {
    const { archX1, archY1, archX2, archY2 } = this.state;

    return (
      <div>
        <Row>
          <Col span={12}>
            <CAPanelH1>
              Arch Size
            </CAPanelH1>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <ArchCanvasContainer
              dragging={this.state.draggingArchMarker}
              onMouseLeave={(e) => this.onArchPointerMouseUp(e, 'any')}
              onMouseUp={(e) => this.onArchPointerMouseUp(e, 'any')}
              onMouseMove={(e) => this.onArchPointerMouseMove(e)}
            >
              <ArchPointMarker
                x={archX1}
                y={archY1}
                onMouseDown={(e) => this.onArchPointerMouseDown(e, 'start')}
                onMouseUp={(e) => this.onArchPointerMouseUp(e, 'start')}
              />
              <ArchPointMarker
                x={archX2}
                y={archY2}
                onMouseDown={(e) => this.onArchPointerMouseDown(e, 'end')}
                onMouseUp={(e) => this.onArchPointerMouseUp(e, 'end')}
              />
              <canvas
                ref={this.archPreviewRef}
                width={160}
                height={250}
              />
            </ArchCanvasContainer>
          </Col>
        </Row>
      </div>
    );
  }

  renderPaintingTab() {
    const { onUpdated, contextTool } = this.props;
    const materialOptions = [
      'marble',
      'red-paint',
      'blue-paint',
      'yellow-paint',
      'castle-wall',
      'castle-floor',
      'clear',
      'unpainted-wall',
      'unpainted-floor',
    ];

    return (
      <div><Row>
          <Col span={12}>
            <CAPanelH1>
              Select Material
            </CAPanelH1>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {materialOptions.filter(id => !!materialPresets[id].previewUrl)
              .map(id => {
                const material = materialPresets[id];

                return (
                  <Col span={8}>
                    <MaterialPreview
                      src={material.previewUrl}
                      width="100%"
                      selected={contextTool.paintMaterial === id}
                      onClick={() => {
                        if (contextTool.paintMaterial === id) {
                          onUpdated(ContextTools.select, {
                            gridSnap: 1,
                            showCursor: false
                          });
                        } else {
                          onUpdated(ContextTools.paint_env, {
                            gridSnap: 1,
                            showCursor: false,
                            lockPan: true,
                            lockRotate: true,
                            paintMaterial: id
                          });  
                        }
                      }}
                    />
                  </Col>
                );
              }
            )}
          </Col>
        </Row>
      </div>
    );
  }

  renderContextTab() {
    return (
      <div>
        :context:
      </div>
    );
  }

  render() {
    const { tab } = this.state;
    const { onUpdated, contextTool } = this.props;

    return (
      <CAPanelBody margin="20px 25px" style={{ overflow: 'visible' }}>
        <WtlGlobalKeyboardListener
          listeners={[
            {
              keys: ['escape'],
              off: () => {
                this.changeTab('');
              }
            }
          ]}
        />
        <Row>
          <Col span={12}>
            <CAPanelH1>
              Tools
            </CAPanelH1>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'center' }}>
            <CAPanelBody display="flex" margin="20px auto" style={{ overflow: 'visible', width: '90%'}}>
              {[
                { icon: WallsToolSvg, active: tab === 'walls', onClick: () => { this.changeTab('walls'); } },
                { icon: FloorsToolSvg, active: tab === 'floors', onClick: () => { this.changeTab('floors'); } },
                { icon: ArchesToolSvg, active: tab === 'arches', onClick: () => { this.changeTab('arches'); } },
                { icon: PaintingToolSvg, active: tab === 'painting', onClick: () => { this.changeTab('painting'); } },
              ].map(({ icon, onClick, active }) => (
                <div
                  style={{ width: '100%' }}
                  onClick={onClick}
                >
                  <InvertedImage
                    src={icon}
                    width="50"
                    height="50"
                    style={{
                      filter: [active ? '' : 'invert(100%)', 'drop-shadow(0px 1px 2px #dddddd)'].join(' '),
                      cursor: active ? 'default' : 'pointer'
                    }}  
                  />
                </div>
              ))}
            </CAPanelBody>
          </Col>
        </Row>
        {tab === 'walls' && this.renderWallsTab()}
        {tab === 'floors' && this.renderFloorsTab()}
        {tab === 'arches' && this.renderArchesTab()}
        {tab === 'painting' && this.renderPaintingTab()}
        {tab === 'context' && this.renderContextTab()}
      </CAPanelBody>
    );
  }
}