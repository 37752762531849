import React from 'react';
import styled from 'styled-components';

export const CAButtonDebug = styled.div`
  text-align: center;
  padding: 2px;
  font-size: 12px;
  letter-spacing: 1px;
  color: #000;
  border-bottom: solid 2px #000;
  cursor: pointer;

  &:hover {
    color: #0000ff;
    border-bottom: solid 2px #0000ff;
  }
`;

const ButtonWrapper = styled.div`
  box-sizing: border-box;
  padding: 6px;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 15px;
  color: #000;
  background-color: #fff;
  border-radius: 25px;
  cursor: ${p => p.onClick ? 'pointer' : 'default'};
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;

  &:hover {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, .1);
  }

  ${p => p.inverted && `
    color: #fff;
    background-color: #000;
  `}
`;

export class CAButton extends React.Component {
  state = {
    active: false
  };

  render() {
    const { children, style, onClick, dimShadow, active } = this.props;

    return (
      <ButtonWrapper
        style={style}
        onClick={onClick}
        inverted={active}
        dimShadow={dimShadow}
      >
        {children}
      </ButtonWrapper>
    );
  }
}
