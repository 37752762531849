import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  @keyframes wtlRevealContextMenu {
    0% {
      transform: translateX(0px);
    }

    25% {
      transform: translateX(-3px);
    }

    100% {
      transform: translateX(0px);
    }
  }

  position: absolute;
  left: ${p => p.offsetX + -10 + (p.order ? 35 : 0) + (Math.max(0, (p.order - 1)) * 30)}px;
  bottom: ${p => -p.offsetY + -15}px;
  filter: drop-shadow(-10px 10px 10px rgba(0, 0, 0, .2));
  border-radius: 50%;
  border: solid 2px #000;
  box-sizing: border-box;
  line-height: 0;
  pointer-events: all;
  z-index: ${p => 10101 - p.order};
  cursor: pointer;

  ${p => ({
    'warn': `
      & > * {
        background-color: #cf3d0c;
        transition: background-color 0s;
      }

      &:hover > * {
        background-color: #e05526;
        transition: background-color 0s;
      }
    `,
    'default': `
      & > * {
        background-color: rgb(1, 91, 197);
      }

      &:hover > * {
        background-color: rgb(28, 113, 214);
      }
    `
  })[p.type || 'default']}

  ${p => !p.action && !p.expanded && `
    &:hover {

      & > .reveal {
        max-width: 600px;
        padding-left: 12.5px;
        padding-right: 5px;
        transition:
          max-width .15s ease .075s,
          padding-left .15s ease .075s,
          padding-right .15s ease .075s,
          background-color .15s ease .075s;
      }
    }
  `}
`;

const Icon = styled.div`
  position: relative;
  display: inline-block;
  width: ${p => p.action ? 25 : 30}px;
  height: ${p => p.action ? 25 : 30}px;
  color: #fff;
  border-radius: 50%;
  z-index: 2;
  transition: background-color .15s ease;

  &:hover {
    transition: background-color .15s ease .1s;
  }

  i {
    position: absolute;
    display: inline-block;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`;

const Description = styled.div`
  position: absolute;
  top: -2px;
  left: 15px;
  display: inline-block;
  box-sizing: border-box;
  height: 34px;
  color: #fff;
  border-radius: 0 25px 25px 0;
  border: solid 2px #000;
  z-index: 1;
  overflow: hidden;
  pointer-events: none;
  transition:
    max-width .15s ease,
    padding-left .15s ease .1s,
    padding-right .15s ease .1s,
    background-color .15s ease;

  width: auto;
  max-width: 0%;
  padding-left: 0;
  padding-right: 0;

  span {
    position: relative;
    display: inline-block;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    white-space: nowrap;
    font-size: 11px;
  }
`;

const Items = styled.div`
  position: absolute;
  left: 30px;
  top: ${p => p.offsetY ? p.offsetY : 0}px;
  z-index: 3;
  background-color: transparent !important;
  transform: translateY(-50%) translateY(15px);
  line-height: 1.23;
`;

const ItemOption = styled.div`
  @keyframes wtlItemOptionSlide {
    from {
      transform: translateX(-5px);
      opacity: 0;
    }

    to {
      transform: translateX(0px);
      opacity: 1;
    }
  }

  background-color: rgb(1, 91, 197);
  color: #fff;
  cursor: pointer;
  white-space: nowrap;
  padding: 7px 10px;
  border-radius: 16px;
  border: solid 2px #000;
  box-sizing: border-box;
  font-size: 12px;
  margin: 4px;
  animation: wtlItemOptionSlide .15s ease ${p => ((parseFloat(p.order) || 0) / 5 + (!p.orderDelay ? 0.75 : 0)) / 10}s 1 both;
  text-align: center;

  &:hover {
    background-color: rgb(28, 113, 214);
  }

  b {
    font-weight: normal;
  }

  ${p => ({
    'warn': `
      background-color: #cf3d0c;

      &:hover {
        background-color: #e05526;
      }

      b {
        color: #ffde85;
        text-shadow: 0px 1px 1px rgba(0, 0, 0, .5);
      }
    `
  })[p.type]}
`;

const IconSpin = styled.i`
  @keyframes spinOnce {
    0% {
      transform: translateX(-50%) translateY(-50%) rotate(0deg);
      filter: blur(0px);
    }

    75% {
      filter: blur(1px);
    }

    100% {
      transform: translateX(-50%) translateY(-50%) rotate(-360deg);
      filter: blur(0px);
    }
  }

  /* animation: spinOnce .3s ease-in-out 1 both; */
`;

export const WtlContextMenuKeyword = styled.span`
  color: #5cffde;
  text-shadow: 0px 1px 0px #0d4f61;
`;

export class WtlContextMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: props.defaultExpanded || false,
      currentMenu: null,
      menus: this.mapMenuParents({
        children: props.contextMenu || []
      }),
      animate: props.defaultExpanded || false
    };

    if (props.defaultExpanded) {
      this.state.currentMenu = this.state.menus;
    }
  }

  mapMenuParents(menu) {
    menu.children = menu.children
      .filter(Boolean)
      .map(item => {
        if (item.children) {
          item.parentMenu = menu;

          return this.mapMenuParents(item);
        }

        return item;
      });

    return menu;
  }

  expandMenu(submenu) {
    this.setState({
      expanded: true,
      animate: true,
      currentMenu: submenu || this.state.menus
    });
  }

  collapseMenu(ignoreParent = false) {
    const { currentMenu } = this.state;

    if (currentMenu.parentMenu && !ignoreParent) {
      this.setState({
        expanded: true,
        currentMenu: currentMenu.parentMenu
      });
    } else {
      this.setState({
        expanded: false,
        currentMenu: this.state.menus
      });
    }
  }

  render() {
    const { expanded, currentMenu, animate } = this.state;
    const { contextMenu, contextActions, selection, pagePreview } = this.props;

    const previewSize = pagePreview.getBoundingClientRect();
    const selectionSize = selection.element.getBoundingClientRect();

    const selectionX = selectionSize.x - previewSize.x;
    const selectionY = selectionSize.y + pagePreview.scrollTop - previewSize.y;
    const selectionHeight = selectionSize.height;
    const selectionOffsetY = selectionY + selectionHeight;

    const offsetX = selectionX < 30 ? 30 : 0;
    let offsetY = 0;

    if (selectionHeight < 40) {
      offsetY = 20;
    } else {
      if (selectionY + selectionHeight < 20) {
        offsetY = 20;
      }
      
      if (selectionOffsetY >= previewSize.height - 40) {
        offsetY = -30;
      }
    }

    let itemsOffsetY = 0;
    const primaryButtonSize = 30;

    if (expanded && currentMenu) {
      const itemsHeight = currentMenu.children.length * 35;

      if (selectionOffsetY <= (itemsHeight / 2 + primaryButtonSize)) {
        itemsOffsetY = (itemsHeight / 2 + primaryButtonSize) - selectionOffsetY;
      }
      
      if (selectionOffsetY + (itemsHeight / 2) >= previewSize.height) {
        itemsOffsetY = (-itemsHeight / 2) + 7.5;
      }
    }

    const isWarningConfirmation = currentMenu && currentMenu.children ? (
      (currentMenu.children[0] || {}).type === 'warn'
    ) : false;

    let primaryButtonIcon;
    
    if (animate) {
      if (expanded) {
        if (isWarningConfirmation) {
          primaryButtonIcon = <IconSpin className="fas fa-arrow-left fa-fw" />
        } else {
          primaryButtonIcon = <IconSpin className="fas fa-arrow-left fa-fw" />;
        }
      } else {
        primaryButtonIcon = <IconSpin key={1} className="fas fa-pen fa-fw" />;
      }
    } else {
      primaryButtonIcon = <i key={1} className="fas fa-pen fa-fw" />;
    }

    return (
      <div>
        {contextMenu && contextMenu.length && <Wrapper
          expanded={expanded}
          order={0}
          offsetX={offsetX}
          offsetY={offsetY}
          type={isWarningConfirmation ? 'warn' : 'default'}
        >
          <Icon
            onClick={() => expanded ? this.collapseMenu() : this.expandMenu()}
          >
            {primaryButtonIcon}
          </Icon>
          <Description
            className="reveal"
            onClick={() => this.expandMenu()}
          >
            <span>
              Edit Element
            </span>
          </Description>
          {
            expanded && currentMenu && (
              <Items offsetY={itemsOffsetY}>
                {
                  currentMenu.children.map((item, index) => (
                    <ItemOption
                      order={index}
                      orderDelay={!!currentMenu.name}
                      key={`${currentMenu.name}-${index}`}
                      onClick={() => {
                        if (item.children) {
                          return this.expandMenu(item);
                        }

                        if (typeof item.onClick === 'function') {
                          item.onClick();
                          this.collapseMenu(true);
                        }
                      }}
                      type={item.type}
                    >
                      {item.name} {item.children && <i className="far fa-chevron-right fa-fw" style={{ position: 'relative', opacity: 0.5, marginLeft: -7, left: 7 }} />}
                    </ItemOption>
                  ))
                }
              </Items>
            )
          }
        </Wrapper>}
        {!expanded && (
          <>
            {(contextActions || [])
            .filter(Boolean)
            .map(({ icon, onClick }, index) => (
              <Wrapper
                offsetX={offsetX}
                offsetY={offsetY}
                order={index + 1}
                action
              >
                <Icon
                  onClick={() => onClick()}
                  action
                >
                  <i key={2 + index} className={`fas fa-${icon} fa-fw`} />
                </Icon>
              </Wrapper>
            ))}
          </>
        )}
      </div>
    );
  }
}
