export const applyToAll = (instance, fn) => {
  if (!instance) {
    return;
  }

  fn(instance);

  if (instance.children) {
    instance.children.forEach(child => {
      applyToAll(child, fn);
    });
  }
};