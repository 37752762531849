import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Modal, Row, Tabs, List, Table, Alert, Col, Popconfirm } from 'antd';
import { VariableInput } from '../variable-input';
import StateService from '../../services/state.service';
import { WtlTooltip } from './wtl-tooltip';
import { SimpleButton } from '../../styles/styled';

const { TabPane } = Tabs;

const WtlTabs = styled(Tabs)`
  .ant-tabs-nav {
    width: 100%;
    text-align: center;
  }

  .ant-tabs-tab {
    width: 45%;
  }

  .ant-tabs-bar {
    margin: 0;
    margin-bottom: 0;
  }
`;

const WtlTabBody = styled.div`
  padding: ${p => p.padding};
  border: solid 1px #eee;
  border-top: 0;
  border-radius: 0 0 4px 4px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
`;

const WtlSubpagesActions = styled.span`
  display: inline-block;
  white-space: nowrap;
  width: 100%;
  text-align: right;

  a {
    margin: 0 4px;
    color: #777;
    cursor: pointer;

    &:hover {
      color: #111;
    }

    &.warn {
      color: #841d3f;
    }

    &.primary {
      background-color: #4d83eb;
      color: #fff;
      border-radius: 4px;
      font-size: 9px;
      padding: 4px;
    }
  }
`;

const WtlSubpagesTitle = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  border: solid 1px rgba(0, 0, 0, 0);

  &:hover {
    border: solid 1px rgba(0, 0, 0, .1);
  }
`;

const subpagesTable = {
  columns: (props) => [
    {
      title: 'page',
      dataIndex: 'page',
      key: 'page',
      render: text => (
        <WtlSubpagesTitle onDoubleClick={() => props.onOpenPage(text)}>
          {text}
        </WtlSubpagesTitle>
      )
    },
    {
      title: 'actions',
      key: 'actions',
      dataIndex: 'page',
      render: text => (
        <WtlSubpagesActions>
          {text !== 'index' && <a className="warn">
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => props.onDeletePage(text)}
            >
              <WtlTooltip
                title="Delete..."
                placement="bottom"
              >
                <i className="fa fa-fw fa-times" />
              </WtlTooltip>
            </Popconfirm>
          </a>}
          <a onClick={() => props.onEditExistingPage(text, `${text}-copy`)}>
            <WtlTooltip
              title="Duplicate..."
              placement="bottom"
            >
              <i className="fa fa-fw fa-copy" />
            </WtlTooltip>
          </a>
          {text !== 'index' && <a onClick={() => props.onEditExistingPage(text)}>
            <WtlTooltip
              title="Settings..."
              placement="bottom"
            >
              <i className="fa fa-fw fa-pencil" />
            </WtlTooltip>
          </a>}
          <a className="primary" onClick={() => props.onOpenPage(text)}>
            <WtlTooltip
              title="Open in editor"
              placement="bottom"
            >
              <i className="fa fa-fw fa-chevron-right" />
            </WtlTooltip>
          </a>
        </WtlSubpagesActions>
      )
    }
  ]
};

class WtlSubpageForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      url: '',
      originUrl: undefined,
      title: '',
      tags: '',
      hidden: false,
      publishDate: moment().format('YYYY-MM-DD'),
      duplicateOf: null
    };
    
    const globalConfig = StateService.getConfig();
    const globalPresets = {
      title: globalConfig.title
    };

    this.state = {
      url: '',
      originUrl: undefined,
      title: globalPresets.title || '',
      tags: '',
      hidden: false,
      publishDate: moment().format('YYYY-MM-DD'),
      duplicateOf: null,
      compat_align_v1: false
    };

    if (props.preset) {
      const duplicatePresets = {
        title: globalConfig[`title_${props.preset.duplicateOf || props.preset.page}`],
        publishDate: `${globalConfig[`publish_${props.preset.duplicateOf || props.preset.page}`]}`,
        hidden: `${globalConfig[`publish_${props.preset.duplicateOf || props.preset.page}`]}` === '-1',
        tags: globalConfig[`tags_${props.preset.duplicateOf || props.preset.page}`],
        compat_align_v1: globalConfig[`compat_align_v1_${props.preset.duplicateOf || props.preset.page}`]
      };

      if (duplicatePresets.publishDate === '0' || duplicatePresets.publishDate === '-1') {
        duplicatePresets.publishDate = undefined;
      }

      this.state = {
        ...this.state,
        url: props.preset.page || '',
        originUrl: props.preset.page,
        title: props.preset.title || duplicatePresets.title || globalPresets.title || '',
        tags: props.preset.tags || duplicatePresets.tags || '',
        publishDate: duplicatePresets.publishDate || moment().format('YYYY-MM-DD'),
        hidden: duplicatePresets.hidden || false,
        compat_align_v1: typeof duplicatePresets.compat_align_v1 !== 'undefined' ? duplicatePresets.compat_align_v1 : globalConfig.compat_align_v1,
        duplicateOf: props.preset.duplicateOf || null,
      };
    }
  }

  render() {
    const {
      url,
      title,
      tags,
      hidden,
      publishDate,
      duplicateOf,
      compat_align_v1
    } = this.state;
    const {
      onOk,
      onCancel
    } = this.props;
    const globalConfig = StateService.getConfig();

    return (
      <Row gutter={4}>
        <VariableInput
          nonVariable
          span={24}
          name="URL"
          value={url}
          onChange={(value) => {
            this.setState({
              ...this.state,
              url: `${value}`.replace(/\s/gm, '-').toLowerCase()
            });
          }}
        />
        <VariableInput
          nonVariable
          span={24}
          name="title"
          value={title}
          onChange={(value) => {
            this.setState({ ...this.state, title: value });
          }}
        />
        <VariableInput
          nonVariable
          span={24}
          name="tags"
          inputType="tags"
          value={tags}
          onChange={(value) => {
            this.setState({ ...this.state, tags: value });
          }}
        />
        <VariableInput
          nonVariable
          noTooltip
          span={8}
          inputType="switch"
          inputProps={{
            size: 'large'
          }}
          name={
            <><i className="fa fa-fw fa-eye-slash" /> Hidden</>
          }
          value={hidden}
          onChange={(value) => {
            this.setState({ ...this.state, hidden: value });
          }}
        />
        <VariableInput
          nonVariable
          span={16}
          name="visible after"
          inputType="date"
          inputProps={{
            disabled: hidden
          }}
          value={publishDate}
          onChange={(value) => {
            this.setState({ ...this.state, publishDate: value });
          }}
        />
        {globalConfig.compat_align_v1 && <VariableInput
          nonVariable
          noTooltip
          span={8}
          inputType="switch"
          inputProps={{
            size: 'large'
          }}
          name={
            <><i className="fa fa-fw fa-eye-slash" /> Use legacy alignment</>
          }
          value={compat_align_v1}
          onChange={(value) => {
            this.setState({ ...this.state, compat_align_v1: value });
          }}
        />}
        <Col span={24} style={{ marginTop: 8 }}>
          {duplicateOf && (
            <>
              <Alert
                type="info"
                message={
                  <>
                    Duplicate contents from page <b>{duplicateOf}</b>.
                  </>
                }
                style={{
                  marginBottom: 4
                }}
              />
            </>
          )}
          <Alert type="info" message={
            <>
              You can add important SEO information after creating a subpage. Head to Settings for more SEO options.
            </>
          }/>
        </Col>
        <Col span={24} style={{ marginTop: 8 }}>
          {onCancel && <SimpleButton onClick={() => {
            onCancel();
          }}>
            Cancel
          </SimpleButton>}
          {onOk && <SimpleButton toggled onClick={() => {
            onOk(this.state);
          }}>
            Confirm
          </SimpleButton>}
        </Col>
      </Row>
    );
  }
}

export class WtlSubpagesModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showExistingPageEditor: 0,
      existingPagePreset: {}
    };
  }

  render() {
    const { onClose, onCreateOrUpdatePage } = this.props;
    const { showExistingPageEditor, existingPagePreset } = this.state;

    const pages = ['index', ...StateService.getSchemaPages()]
    .map(name => ({
      page: name
    }));

    return (
      <Modal
        title={
          <>
            Pages
          </>
        }
        visible
        closable
        maskClosable
        width={550}
        footer={false}
        onCancel={() => onClose()}
      >
        <WtlTabs type="card">
          <TabPane
            tab={
              ({
                0: <><i className="fa fa-fw fa-bars" /> Existing Pages</>,
                1: <><i className="fa fa-fw fa-pencil" /> Page Settings</>,
                2: <><i className="fa fa-fw fa-copy" /> Duplicate Page</>
              })[showExistingPageEditor]
            }
            key="1"
          >
            {!showExistingPageEditor && <WtlTabBody padding={0}>
              <Table
                columns={subpagesTable.columns({
                  ...this.props,
                  onEditExistingPage: (page, saveAs) => {
                    this.setState({
                      showExistingPageEditor: saveAs ? 2 : 1,
                      existingPagePreset: {
                        page: saveAs || page,
                        duplicateOf: saveAs ? page : undefined
                      }
                    });
                  }
                })}
                dataSource={pages}
                tableLayout="fixed"
                footer={false}
                showHeader={false}
                pagination={false}
              />
            </WtlTabBody>}
            {!!showExistingPageEditor && (
              <WtlTabBody padding="16px">
                <WtlSubpageForm
                  key={Date.now()}
                  preset={existingPagePreset}
                  onOk={(page) => {
                    onCreateOrUpdatePage(page);

                    this.setState({
                      showExistingPageEditor: 0,
                      existingPagePreset: {}
                    });
                  }}
                  onCancel={() => {
                    this.setState({
                      showExistingPageEditor: 0,
                      existingPagePreset: {}
                    });
                  }}
                />
              </WtlTabBody>
            )}
          </TabPane>
          <TabPane
            tab={
              <><i className="fa fa-fw fa-plus" /> New Page...</>
            }
            key="2"
          >
            <WtlTabBody padding="16px">
              <WtlSubpageForm
                key={Date.now()}
                onOk={(page) => {
                  onCreateOrUpdatePage(page);

                  setTimeout(() => {
                    onClose();
                  }, 10);
                }}
              />
            </WtlTabBody>
          </TabPane>
        </WtlTabs>
      </Modal>
    );
  }
}
