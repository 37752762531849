import React from 'react';
import { VariableInput } from '../variable-input';
import { Row, Col } from 'antd';
import { defaultFonts } from '../rich-text-editor';
import { SimpleInput, SimpleButton } from '../../styles/styled';
import StateService from '../../services/state.service';
import { Link } from 'gatsby';
import { SAFE_UNDEFINED } from '../../services/editor.service';

export const renderTextProps = function () {
  const { tools } = this.state;
  const { selection } = tools;
  const fontFamily = defaultFonts;
  const config = StateService.getConfig();
  const projectFonts = config.fonts || [];

  return (
    <Col span={8}>
      <Row gutter={4}>
        <VariableInput
          span={8}
          name="font size"
          inputType="css-prop"
          value={selection.branch.fontSize}
          onChange={(value, params) => {
            this.updateSelectionParam('fontSize', { target: { value }}, params);
          }}
        />
        <SimpleInput span={16}>
          <span>
            font family
            {' '}
            <Link to="/settings#content-fonts">
              (add more fonts)
            </Link>  
          </span>
          <select
            value={decodeURIComponent(selection.branch.fontFamily)}
            defaultValue="unset"
            onChange={(e) => this.updateSelectionParam('fontFamily', e)}
          >
            <option
              value={SAFE_UNDEFINED}
            >
              default
            </option>
            {
              projectFonts && (
                <option disabled>
                  --- Project Fonts --- 
                </option>
              )
            }
            {
              projectFonts.map(font => (
                <option
                  value={font.name}
                  style={{
                    fontFamily: font.name
                  }}
                >
                  {font.name.match(/([^(,\")]+)/)[0]}
                </option>
              ))
            }
            {
              projectFonts && (
                <option disabled>
                  --- Web Fonts --- 
                </option>
              )
            }
            {
              fontFamily.map(font => (
                <option
                  value={font}
                  style={{
                    fontFamily: font
                  }}
                >
                  {font.match(/([^(,\")]+)/)[0]}
                </option>
              ))
            }
          </select>
        </SimpleInput>
      </Row>
      <Row gutter={4}>
        {
          selection.branch.type === 'text' ? (
            <Link to="#">
              <SimpleButton onClick={() => {
                this.setState({
                  ...this.state,
                  panels: {
                    ...this.state.panels,
                    showRichTextEditor: true
                  }
                });
              }}>
                <i className="far fa-text-size"></i> Edit Text
              </SimpleButton>
            </Link>
          ) : (
            <VariableInput
              span={24}
              name="content"
              value={selection.branch.content}
              onChange={(value, params) => this.updateSelectionParam('content', { target: { value }}, params)}
              inputType="textarea"
              inputProps={{
                placeholder: selection.branch.content && typeof selection.branch.content !== 'string' && 'Add some text...',
                autoFocus: true
              }}
            />
          ) 
        }
      </Row>
    </Col>
  )
}