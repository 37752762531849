import React from 'react';
import { VariableInput } from '../variable-input';
import { Row, Col } from 'antd';

export const renderTimerProps = function () {
  const { tools } = this.state;
  const { selection } = tools;

  return (
    <>
      <Col span={8}>
        <Row gutter={4}>
          <VariableInput
            span={6}
            name="display date"
            value={selection.branch.showDate}
            inputType="switch"
            onChange={(value, params) => {
              this.updateSelectionParam('showDate', { target: { value }}, params);
            }}
          />
          <VariableInput
            span={18}
            name="date format"
            value={selection.branch.dateFormat}
            inputType="select"
            onChange={(value, params) => {
              this.updateSelectionParam('dateFormat', { target: { value }}, params);
            }}
          >
            <option value="yyyy.mm.dd">Europe</option>
            <option value="yyyy.dd.mm">US</option>
          </VariableInput>
        </Row>
      </Col>
      <Col span={8}>
        <Row gutter={4}>
          <VariableInput
            span={6}
            name="display time"
            value={selection.branch.showTime}
            inputType="switch"
            onChange={(value, params) => {
              this.updateSelectionParam('showTime', { target: { value }}, params);
            }}
          />
          <VariableInput
            span={18}
            name="timezone offset"
            value={selection.branch.timezoneOffset || 0}
            inputType="range"
            inputProps={{
              min: 0,
              max: 23
            }}
            onChange={(value, params) => {
              this.updateSelectionParam('timezoneOffset', { target: { value }}, params);
            }}
          />
          <VariableInput
            span={24}
            name="display seconds"
            value={selection.branch.showSeconds}
            inputType="switch"
            onChange={(value, params) => {
              this.updateSelectionParam('showSeconds', { target: { value }}, params);
            }}
          />
          <VariableInput
            span={12}
            name="hours with leading zero"
            value={selection.branch.leadingZeroHours}
            inputType="switch"
            onChange={(value, params) => {
              this.updateSelectionParam('leadingZeroHours', { target: { value }}, params);
            }}
          />
          <VariableInput
            span={12}
            name="minutes with leading zero"
            value={selection.branch.leadingZeroMinutes}
            inputType="switch"
            onChange={(value, params) => {
              this.updateSelectionParam('leadingZeroMinutes', { target: { value }}, params);
            }}
          />
          <VariableInput
            span={12}
            name="seconds with leading zero"
            value={selection.branch.leadingZeroSeconds}
            inputType="switch"
            onChange={(value, params) => {
              this.updateSelectionParam('leadingZeroSeconds', { target: { value }}, params);
            }}
          />
        </Row>
      </Col>
    </>
  );
}
