import Rete from 'rete';
import { EventSourceSocket, StringSocket } from '../sockets';
import { BaseLayout } from '../layouts/base.layout';
import { StringControl } from '../controls/string.control';

export class StringSourceNode extends Rete.Component {
  constructor() {
    super('Text Value');
    this.data.component = BaseLayout;
  }

  builder(node) {
    const out1 = new Rete.Output('value', 'Value', StringSocket, false);
    const ctrl = new StringControl(this.editor, 'Text', node);

    return node
      .addControl(ctrl)
      .addOutput(out1);
  }

  worker(node, inputs, outputs) {
    outputs['value'] = node.data['Text'];
  }
}
