import Rete from 'rete';
import { EventSourceSocket, StringSocket } from '../sockets';
import { BaseLayout } from '../layouts/base.layout';
import { EventSourceControl } from '../controls/event-source.control';
import { ConditionControl } from '../controls/condition.control';

export class IfNode extends Rete.Component {
  constructor() {
    super('If / Else');
    this.data.component = BaseLayout;
  }

  builder(node) {
    const inp1 = new Rete.Input('valueA', 'Value A', StringSocket);
    const inp2 = new Rete.Input('valueB', 'Value B', StringSocket);
    const inp3 = new Rete.Input('event-source-in', '', EventSourceSocket, false);
    const ctrl = new ConditionControl(this.editor, 'condition', node);
    const out1 = new Rete.Output('then-true', 'True', EventSourceSocket);
    const out2 = new Rete.Output('then-false', 'False', EventSourceSocket);

    return node
      .addInput(inp1)
      .addInput(inp2)
      .addInput(inp3)
      .addControl(ctrl)
      .addOutput(out1)
      .addOutput(out2);
  }

  worker(node, inputs, outputs) {
    node.data['value-a'] = inputs['valueA'][0];
    node.data['value-b'] = inputs['valueB'][0];
  }
}