import Rete from 'rete';
import { EventSourceSocket, StringSocket } from '../sockets';
import { BaseLayout } from '../layouts/base.layout';
import { EventSourceControl } from '../controls/event-source.control';
import { ChangeVariableControl } from '../controls/change-variable.control';
import { StringControl } from '../controls/string.control';
import { CssPropControl } from '../controls/css-prop.control';

export class ChangeAppearanceNode extends Rete.Component {
  constructor() {
    super('Update Appearance');
    this.data.component = BaseLayout;
  }

  builder(node) {
    const inp1 = new Rete.Input('element', 'Element', StringSocket, false);
    const inp2 = new Rete.Input('value', 'Value', StringSocket, false);
    const inp3 = new Rete.Input('event-source-in', '', EventSourceSocket, false);
    const out3 = new Rete.Output('then', '', EventSourceSocket, false);

    return node
      .addInput(inp1)
      .addControl(new CssPropControl(this.editor, 'property', node))
      .addInput(inp2)
      .addInput(inp3)
      .addOutput(out3);
  }

  worker(node, inputs, outputs) {
    node.data['element'] = inputs['element'][0];
    node.data['value'] = inputs['value'][0];
  }
}