import React from 'react';
import Rete from 'rete';
import { VariableInput } from '../../../variable-input';
import { Row } from 'antd';

export class CssValueControl extends Rete.Control {
  static component = ({ value, onChange }) => (
    <VariableInput
      span={24}
      value={value}
      onChange={(value, params) => onChange(encodeURIComponent(value))}
      inputType="css-prop"
      nonVariable
    />
  );

  constructor(emitter, key, node, readonly = false) {
    super(key);
    this.emitter = emitter;
    this.key = key;
    this.component = CssValueControl.component;

    const initial = node.data[key] || '';

    node.data[key] = initial;
    this.props = {
      readonly,
      value: initial,
      onChange: v => {
        this.setValue(v);
        this.emitter.trigger('process');
      }
    };
  }

  setValue(val) {
    this.props.value = val;
    this.putData(this.key, val);
    this.update();
  }
}