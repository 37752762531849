import React from 'react';
import Rete from 'rete';
import { SimpleInput } from '../../../../styles/styled';
import { Select } from 'antd';

const { Option } = Select;

const cssPropOptions = {
  'position / horizontal': 'cssPositionOffsetX',
  'position / vertical': 'cssPositionOffsetY',
  'background / type': 'cssBackgroundType',
  'background / color (primary)': 'cssBackgroundColor',
  'background / color (secondary)': 'cssBackgroundColorB',
  'background / gradient / type': 'cssBackgroundGradientDirection',
  'background / image': 'cssBackgroundImage',
  'background / image / position': 'cssBackgroundPosition',
  'background / image / repeat': 'cssBackgroundRepeat',
  'background / image / fit': 'cssBackgroundFit',
  'background / image / sharpen': 'cssBackgroundSharpen',
  'border / style': 'cssBorderStyle',
  'border / color': 'cssBorderColor',
  'border / thickness': 'cssBorderThickness',
  'border / edge angle': 'cssBorderRadius',
  'font / size': 'fontSize',
  'font / font face': 'fontFamily',
  'font / color': 'cssFontColor',
  'font / style': 'cssFontStyle',
  'font / letter spacing': 'cssLetterSpacing',
  'font / line height': 'cssLineHeight',
  'font / kerning': 'cssFontKerning',
  'font / paragraph indent': 'cssTextIndent',
  'size / width': 'cssWidth',
  'size / height': 'cssHeight',
  'size / auto-height': 'cssAutoHeight',
  'content / align': 'cssContentAlign',
  'effects / opacity': 'cssOpacity',
  'effects / blur': 'cssEffectBlur',
  'effects / drop shadow': 'cssEffectDropShadow',
  'effects / desaturate': 'cssEffectDesaturate',
  'effects / zoom': 'cssEffectZoom',
  'effects / rotate': 'cssEffectRotate',
  'effects / skew': 'cssEffectSkew'
};

export class CssPropControl extends Rete.Control {
  static component = ({ value, onChange }) => (
    <Select
      search
      showArrow={false}
      placeholder="Select..."
      dropdownMatchSelectWidth={false}
      style={{
        width: '100%'
      }}
      onChange={(prop) => onChange(prop)}
      value={value}
    >
    { 
      Object.keys(cssPropOptions).map(key => (
        <Option value={cssPropOptions[key]}>{key}</Option>
      ))
    }
    </Select>
  );

  constructor(emitter, key, node, readonly = false) {
    super(key);
    this.emitter = emitter;
    this.key = key;
    this.component = CssPropControl.component;

    const initial = node.data[key] || 'cssPositionOffsetX';

    node.data[key] = initial;
    this.props = {
      readonly,
      value: initial,
      onChange: v => {
        this.setValue(v);
        this.emitter.trigger('process');
      }
    };
  }

  setValue(val) {
    this.props.value = val;
    this.putData(this.key, val);
    this.update();
  }
}