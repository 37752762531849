import React from 'react';
import Rete from 'rete';
import { SimpleInput } from '../../../../styles/styled';
import VariablesService from '../../../../../wtl-schema/services/variable.service';
import { config } from '../../../../../config';
import StateService from '../../../../services/state.service';
import { Select } from 'antd';

const { Option } = Select;

export class ChangeVariableControl extends Rete.Control {
  static component = ({ value, onChange }) => (
    <>
      <div>
        <Select
          search
          showArrow={false}
          placeholder="Select..."
          dropdownMatchSelectWidth={false}
            style={{
            width: '100%'
          }}
          onChange={(id) => onChange({
            ...value,
            id
          })}
          value={value.id}
        >
          {Object.keys((VariablesService.vars || {}))
            .concat(Object.keys(StateService.getConfig().initialVariables || {}))
            .filter((item, index, array) => array.indexOf(item) === index)
            .map((key, index) => ({
              key: index,
              name: key,
              value: VariablesService.getVar(key)
            })).map(({ key, name, value }) => (
            <Option value={name}>
              <i className="fal fa-fw fa-link" style={{ fontSize: 12 }} /> {name}
            </Option>
          ))}
        </Select>
      </div>
    </>
  );

  constructor(emitter, key, node, readonly = false) {
    super(key);
    this.emitter = emitter;
    this.key = key;
    this.component = ChangeVariableControl.component;

    const initial = node.data[key] || { id: '', value: '' };

    node.data[key] = initial;
    this.props = {
      readonly,
      value: initial,
      onChange: (value) => {
        this.setValue(value);
        this.emitter.trigger('process');
      }
    };
  }

  setValue(val) {
    this.props.value = val;
    this.putData(this.key, val);
    this.update();
  }
}