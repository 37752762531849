import React from 'react';
import { VariableInput } from '../variable-input';
import { Row, Col } from 'antd';

export const renderYouTubeProps = function () {
  const { tools } = this.state;
  const { selection } = tools;

  return (
    <Col span={8}>
      <Row gutter={4}>
        <VariableInput
          span={24}
          name="video url"
          value={selection.branch.url}
          onChange={(value, params) => {
            this.updateSelectionParam('url', { target: { value }}, params);
          }}
        />
        <VariableInput
          span={12}
          name="start at"
          value={selection.branch.startAt}
          onChange={(value, params) => {
            this.updateSelectionParam('startAt', { target: { value }}, params);
          }}
        />
        <VariableInput
          span={12}
          name="show controls"
          value={selection.branch.showControls}
          inputType="switch"
          onChange={(value, params) => {
            this.updateSelectionParam('showControls', { target: { value }}, params);
          }}
        />
      </Row>
    </Col>
  );
}
