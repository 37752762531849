import React from 'react';
import styled from 'styled-components';
import { CAPanelBody, CAPanelH1 } from './ca-panel';
import { Row, Col } from 'antd';
import { models } from '../../data/common/models';
import { CAVariableInput } from './ca-variable-input';
import { MaterialPreview } from './ca-filters';
import { materialPresets } from '../../data/common/materials';

const ModelPreview = styled.img`
  filter: drop-shadow(0px 1px 2px #cccccc);
  margin: 10px 0 20px 0;
`;

const ObjectName = styled(CAPanelH1)`
  font-size: 26px;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const ObjectPrice = styled(CAPanelH1)`
  font-size: 21px;

  i {
    font-size: 18px;
    margin-right: -3px;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-top: solid 1px #333;
  margin: 15px 0;
`;

const DetailsRow = ({ label, value }) => (
  <Row style={{ textTransform: 'uppercase', fontSize: 9, margin: '10px 0' }}>
    <Col span={24} style={{ fontWeight: 'bold' }}>
      {label}:
    </Col>
    <Col span={24}>
      {value}
    </Col>
  </Row>
);

export class CAObjectDetails extends React.Component {
  getCurrencyIcon(name = 'eur') {
    switch (name) {
      case 'eur':
        return <i className="fal fa-euro-sign" />;
      case 'usd':
        return <i className="fal fa-dollar-sign" />;
      case 'yen':
        return <i className="fal fa-yen-sign" />;
      case 'rub':
        return <i className="fal fa-ruble-sign" />;
      case 'gbp':
        return <i className="fal fa-pound-sign" />;
      case 'ils':
        return <i className="fal fa-shekel-sign" />;
      default:
        return <i className="fal fa-euro-sign" />;
    }
  }

  getPriceFormat(value = 0) {
    return (
      <>
        {parseInt(value / 100, 10)},{`${value}`.substr(-2)}
      </>
    );
  }

  render() {
    const { object, updateSelectionParam } = this.props;

    if (!object) {
      return null;
    }

    const model = models[object.modelId];

    if (!model) {
      return null;
    }
    
    const details = model.details;
    
    if (!details) {
      return null;
    }

    const {
      materialId,
      sizeId
    } = object;
    const {
      name,
      owner,
      designer,
      shippingFrom,
      priceBase,
      priceCurrency,
      materialNames,
      materialOptions,
      measurementOptions,
      sizeOptions,
      taxRate,
    } = details;
    const measurements = measurementOptions[sizeId];

    return (
      <CAPanelBody
        style={{
          overflow: 'visible',
          width: 250
        }}
        margin="20px 25px"
      >
        <Row>
          <Col span={24} style={{ textAlign: 'center' }}>
            <ObjectName>
              {name}
            </ObjectName>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'center', textTransform: 'uppercase' }}>
            <ModelPreview src={object.previewUrl} width="125" />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <ObjectPrice>
              {this.getCurrencyIcon(priceCurrency)} {this.getPriceFormat(priceBase)}
            </ObjectPrice>
          </Col>
          <Col span={24}>
            <CAPanelH1 style={{ fontSize: '10px', textTransform: 'uppercase' }}>
              + shipping
            </CAPanelH1>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Divider />
          </Col>
        </Row>
        <DetailsRow label="Studio" value={owner} />
        <DetailsRow label="Designed by" value={designer} />
        <Row>
          <Col span={24}>
            <Divider />
          </Col>
        </Row>
        <Row>
          <CAVariableInput
            inputType="expand-box"
            name="Material"
          >
            {materialOptions.filter(id => !!materialPresets[id].previewUrl)
              .map(id => {
                const material = materialPresets[id];

                return (
                  <Col span={8}>
                    <MaterialPreview
                      src={material.previewUrl}
                      width="100%"
                      selected={id === materialOptions[materialId]}
                      onClick={() => {
                        let newMaterialId = materialOptions.findIndex(fid => fid === id);

                        if (newMaterialId === -1) {
                          newMaterialId = 0;
                        }

                        updateSelectionParam(
                          'materialId',
                          { target: { value: newMaterialId } },
                          { skipUpdate: false }
                        );

                        this.forceUpdate();
                      }}
                    />
                  </Col>
                );
              }
            )}
          </CAVariableInput>
          <CAVariableInput
            inputType="expand-box"
            name="Measurements"
          >
            {measurementOptions.map(([ x, y, z ], i) => {

              return (
                <CAVariableInput
                  span={24}
                  wrapperProps={{
                    style: { marginBottom: 5 }
                  }}
                  inputType="button"
                  inputProps={{
                    active: i === sizeId,
                    style: {
                      textAlign: 'center'
                    }
                  }}
                  onClick={() => {
                    object.size = sizeOptions[i];
                    object.measurements = measurementOptions[i];

                    updateSelectionParam(
                      'sizeId',
                      { target: { value: i } },
                      { skipUpdate: false }
                    );

                    this.forceUpdate();
                  }}
                >
                  {x} CM X {y} CM X {z} CM
                </CAVariableInput>
              );
            })}
          </CAVariableInput>
        </Row>
      </CAPanelBody>
    );
  }
}