import * as Three from 'three';

export let generalEnvMap = null;
export let generalRefMap = null;
export let envMapRegenerationQueue = [];
export let envMapRegenerator = null;

const envMapResolutions = [
  100,
  100,
  2000
];

const initEnvMapRegeneration = (context) => {
  if (generalEnvMap) {
    return;
  }

  const reflectionResolution = envMapResolutions[context.renderQuality || 0];
  generalEnvMap = new Three.CubeCamera(1., 1000., reflectionResolution, {
    generateMipmaps: true,
    minFilter: Three.LinearMipmapLinearFilter
  });
  context.scene.add(generalEnvMap);
  generalEnvMap.position.copy(context.camera.position);
  generalEnvMap.update(context.renderer, context.scene);

  generalRefMap = new Three.CubeCamera(1., 1000., reflectionResolution * 10, {
    generateMipmaps: true,
    minFilter: Three.LinearMipmapLinearFilter
  });
  context.scene.add(generalRefMap);
  generalRefMap.position.copy(context.camera.position);
  generalRefMap.update(context.renderer, context.scene);

  generalRefMap.renderTarget.texture.mapping = Three.CubeRefractionMapping;

  if (context.renderQuality < 2) {
    return;
  }

  // envMapRegenerator = setInterval(() => {
    if (context.controls.active === false) {
      generalEnvMap.position.copy(context.camera.position);
      generalEnvMap.update(context.renderer, context.scene);

      generalRefMap.position.copy(context.camera.position);
      generalRefMap.update(context.renderer, context.scene);

      envMapRegenerationQueue.some(({ instance, target, top }) => {
        if (context.controls.active !== false) {
          return true;
        }

        top.castShadow = false;
        top.receiveShadow = false;
        top.visible = false;

        if (target === 'camera') {
          instance.envMapGenerator.position.copy(context.camera.position);
        } else if (target === 'self') {
          instance.envMapGenerator.position.copy(instance.position);
          instance.envMapGenerator.position.add(new Three.Vector3(0.0, 3.0, 0.0));
        }

        instance.envMapGenerator.update(context.renderer, context.scene);

        top.castShadow = true;
        top.receiveShadow = true;
        top.visible = true;
      })
    }
  // }, 5000);
}

const applyEnvMap = (instance, envMap) => {
  if (instance.material) {
    instance.material.envMap = envMap;
  }

  if (instance.children) {
    const applySubEnvMap = (model) => {
      model.children.forEach((mesh) => {
        if (mesh.material) {
          mesh.material.envMap = mesh.material.refractionRatio < 1 ? generalRefMap.renderTarget.texture : envMap;

          if (mesh.geometry && mesh.geometry.attributes.uv) {
            mesh.geometry.setAttribute('uv2', new Three.BufferAttribute(mesh.geometry.attributes.uv.array, 2));
          }
        }

        if (mesh.children) {
          applySubEnvMap(mesh);
        }
      });
    };

    applySubEnvMap(instance);
  }
};

export const generateLocalEnvMap = (branch, instance, context, options = {}) => {
  const {
    scene,
    renderer
  } = context;
  const reflectionResolution = envMapResolutions[context.renderQuality || 0];

  initEnvMapRegeneration(context);

  if (typeof window === 'undefined') {
    applyEnvMap(instance, generalEnvMap.renderTarget.texture);

    return;
  }

  const useGeneralEnvMap = instance.material && (
    // instance.material.refractionRatio < 1 ||
    instance.material.roughness > 0.9
  );

  
  if (useGeneralEnvMap) {
    if (instance.material && instance.material.refractionRatio < 1) {
      applyEnvMap(instance, generalRefMap.renderTarget.texture);
    } else {
      applyEnvMap(instance, generalEnvMap.renderTarget.texture);
    }
    
    return;
  }
  
  if (!instance.envMapGenerator) {
    instance.envMapGenerator = new Three.CubeCamera(1., 1000., reflectionResolution, {
      generateMipmaps: true,
      minFilter: Three.LinearMipmapLinearFilter
    });
    scene.add(instance.envMapGenerator);
    
    instance.envMapGenerator.position.copy(context.camera.position);
    instance.envMapGenerator.update(renderer, scene);
    
    if (instance.material && instance.material.refractionRatio < 1) {
      instance.envMapGenerator.renderTarget.texture.mapping = Three.CubeRefractionMapping;
    }

    applyEnvMap(instance, instance.envMapGenerator.renderTarget.texture);

    if (instance.material) {
      if (instance.material.refractionRatio === 1) {
        envMapRegenerationQueue.push({ instance, target: 'self', top: options.top || instance });
      } else {
        envMapRegenerationQueue.push({ instance, target: 'camera', top: options.top || instance });
      }
    }
  }
}
