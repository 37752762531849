import React from 'react';
import styled from 'styled-components';
import { Node, Socket, Control } from 'rete-react-render-plugin';
import { Tooltip, Divider } from 'antd';
import { SimpleInput } from '../../../../styles/styled';

const BaseLayoutWrapper = styled.div`
  background: #fff !important;
  border: solid 1px #ccc !important;
  border-radius: 2px !important;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, .1);

  .title {
    text-align: center;
  }

  .socket {
    border: solid 1px #1890ff;
    border-radius: 12px;
    width: 12px;
    height: 12px;
    margin: 12px;
    background: #fff;

    &.input {
      margin-left: -6px;
    }

    &.output {
      margin-right: -6px;
    }
  }

  .title,
  .output-title,
  .input-title {
    color: #000 !important;
  }

  .control {
    width: 100%;
  }
`;

const RemoveNodeHandle = styled.div`
  position: absolute;
  top: -20px;
  right: -15px;
  margin: 7.5px;
  z-index: 1;
  transform: scale(.8);
  transition: transform .1s ease, opacity .1s ease;
  
  i {
    display: inline-block;
    background-color: #fff;
    border: solid 1px #ccc;
    font-size: 12px;
    width: 10px;
    height: 10px;
    line-height: 0;
    padding: 15px;
    padding-right: 19px;
    padding-left: 11px;
    border-radius: 50%;
  }

  &:hover {
    transform: scale(1.0);

    i {
      background-color: #ffcccc;
      border: solid 1px #ff0000;
      color: #ff0000;
    }
  }
`;

const InputName = styled.span`
  margin-left: 10px;
`;

const OutputName = styled.span`
  margin-right: 10px;
`;

const SimpleInlineInput = styled(SimpleInput)`
  display: inline-flex;
`;

export class BaseLayout extends Node {
  removeNode() {
    const { node, editor } = this.props;

    if (!editor || !node || typeof window === 'undefined') {
      return;
    }

    const confirm = window.confirm('Remove this node?');

    if (!confirm) {
      return;
    }

    editor.removeNode(node);
  }

  render() {
    const { node, bindSocket, bindControl } = this.props;
    const { outputs, controls, inputs, selected } = this.state;

    return (
      <BaseLayoutWrapper className={`node ${selected}`}>
        <div className="title">
          {node.name}
          <RemoveNodeHandle>
            <i
              className="fal fa-times"
              onClick={() => this.removeNode()}
            ></i>
          </RemoveNodeHandle>
        </div>
        {/* Controls */}
        {controls.map(control => (
          <div>
            <SimpleInput>
              <span
                style={{
                  margin: '0 18px'
                }}
              >
                {control.key}
              </span>
              <Control
                className="control"
                key={control.key}
                control={control}
                innerRef={bindControl}
              />
            </SimpleInput>
          </div>
        ))}
        {/* Inputs */}
        {inputs.map(input => (
          <div className="input" key={input.key}>
            <Socket type="input" socket={input.socket} io={input} innerRef={bindSocket} />
            {!input.showControl() && (
              <>
                {input.connections.length > 0 && (
                  <i className="fas fa-link"></i>
                )}
                {input.connections.length === 0 && (
                  <i className="fas fa-unlink" style={{ opacity: .5 }}></i>
                )}
                <InputName>
                  {input.name}
                </InputName>
              </>
            )}
            {input.showControl() && (
              <SimpleInlineInput>
                <span>
                  {input.name}
                </span>
                <Control
                  className="input-control"
                  control={input.control}
                  innerRef={bindControl}
                />
              </SimpleInlineInput>
            )}
          </div>
        ))}
        {/* Outputs */}
        {outputs.map((output) => (
          <div className="output" key={output.key}>
            <OutputName>
              {output.name}
            </OutputName>
            {output.connections.length > 0 && (
              <i className="fas fa-link"></i>
            )}
            {output.connections.length === 0 && (
              <i className="fas fa-unlink" style={{ opacity: .5 }}></i>
            )}
            <Socket type="output" socket={output.socket} io={output} innerRef={bindSocket} />
          </div>
        ))}
      </BaseLayoutWrapper>
    )
  }
}