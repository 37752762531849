import React from 'react';
import Rete from 'rete';
import { SimpleInput } from '../../../../styles/styled';
import { Select } from 'antd';

const { Option } = Select;

export class EventSourceControl extends Rete.Control {
  static component = ({ value, onChange }) => (
    <Select
      search
      showArrow={false}
      placeholder="Select..."
      dropdownMatchSelectWidth={false}
      style={{
        width: '100%'
      }}
      onChange={(prop) => onChange(prop)}
      value={value}
    >
      <Option value="onClick">click</Option>
      <Option value="onMouseEnter">mouse over</Option>
      <Option value="onMouseLeave">mouse out</Option>
      <Option value="onChange">value changed</Option>
    </Select>
  );

  constructor(emitter, key, node, readonly = false) {
    super(key);
    this.emitter = emitter;
    this.key = key;
    this.component = EventSourceControl.component;

    const initial = node.data[key] || 'onClick';

    node.data[key] = initial;
    this.props = {
      readonly,
      value: initial,
      onChange: v => {
        this.setValue(v);
        this.emitter.trigger('process');
      }
    };
  }

  setValue(val) {
    this.props.value = val;
    this.putData(this.key, val);
    this.update();
  }
}