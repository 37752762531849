export const imageFileTypes = [
  'image/jpeg',
  'image/jpg',
  'image/apng',
  'image/png',
  'image/bmp',
  'image/tiff',
  'image/gif',
  'image/svg+html',
  'image/webp',
  'image/x-icon'
];

export const videoFileTypes = [
  'video/webm',
  'video/ogg',
  'video/ogg; codecs=opus',
  'video/mp4'
];

export const fontFileTypes = [
  'font/ttf',
  'font/otf',
  'font/woff',
  'font/woff2'
];

export const _3dModelFileTypes = [
  'text/plain',
  'application/object',
  'application/x-tgif',
  'model/gltf+json',
  'model/gltf-binary',
  'image/svg+xml',
  'model/vnd.collada+xml'
];
