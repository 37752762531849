import React from 'react';
import { VariableInput } from '../variable-input';
import { Row, Col } from 'antd';
import StateService from '../../services/state.service';

export const renderPagePreviewProps = function () {
  const { tools } = this.state;
  const { selection } = tools;
  const config = StateService.getConfig();
  const pages = StateService.getSchemaPages();

  return (
    <Col span={24}>
      <Row gutter={4}>
        <Col span={8}>
          <VariableInput
            span={24}
            inputType="table"
            inputProps={[
              pages
            ]}
            name="webpage"
            value={(selection.branch.pages || []).map(({ url }) => url)}
            onChange={(value, params) => {
              this.updateSelectionParam('pages', { target: { value:
                (value || []).map(url => ({
                  url: url,
                  title: config[`title_${url}`] || config.title,
                  description: config[`description_${url}`] || config.description,
                  image: config[`previewImage_${url}`] || config.previewImage,
                }))
              }}, params);
            }}
          />
        </Col>
        <Col span={8}>
          <VariableInput
            span={24}
            inputType="switch"
            inputProps={{
              defaultChecked: true
            }}
            name="show title"
            value={selection.branch.showTitle !== false}
            onChange={(value, params) => {
              this.updateSelectionParam('showTitle', { target: { value }}, params);
            }}
          />
          <VariableInput
            span={24}
            inputType="switch"
            inputProps={{
              defaultChecked: true
            }}
            name="show description"
            value={selection.branch.showDescription !== false}
            onChange={(value, params) => {
              this.updateSelectionParam('showDescription', { target: { value }}, params);
            }}
          />
          <VariableInput
            span={24}
            inputType="switch"
            inputProps={{
              defaultChecked: true
            }}
            name="show url address"
            value={selection.branch.showUrl !== false}
            onChange={(value, params) => {
              this.updateSelectionParam('showUrl', { target: { value }}, params);
            }}
          />
          <VariableInput
            span={24}
            inputType="switch"
            inputProps={{
              defaultChecked: true
            }}
            name="show preview image"
            value={selection.branch.showImage !== false}
            onChange={(value, params) => {
              this.updateSelectionParam('showImage', { target: { value }}, params);
            }}
          />
        </Col>
        <Col span={8}>
          <VariableInput
            span={24}
            inputType="switch"
            name="invert list"
            value={selection.branch.invertList}
            onChange={(value, params) => {
              this.updateSelectionParam('invertList', { target: { value }}, params);
            }}
          />
          <VariableInput
            span={24}
            name="list spacing"
            value={selection.branch.listSpacing}
            onChange={(value, params) => {
              this.updateSelectionParam('listSpacing', { target: { value }}, params);
            }}
          />
        </Col>
      </Row>
    </Col>
  );
}
