import React from 'react';
import styled from 'styled-components';
import { CAModalBorderRadius, CAButtonBorderRadius } from './shared';

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 200px;
  min-height: 50px;
  height: ${p => p.expanded ? p.optionsCount * 44 + 52 : 50}px;
  font-size: 15px;
  font-family: Helvetica, Arial, 'sans-serif';
  color: #000;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
`;

const ToggleWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  background-color: #fff;
  width: 100%;
  height: 50px;
  border-radius: ${CAButtonBorderRadius};
  cursor: pointer;
  transition: width .1s ease,
    height .1s ease,
    top .1s ease,
    left .1s ease;
  z-index: 2;

  &:hover {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, .1);

    ${p => p.dangerous && `
      color: #fff;
      background-color: #ff6400;
    `}
  }

  ${p => p.inverted && `
    color: #fff;
    background-color: #000;
    width: calc(100% - 4px);
    height: 46px;
    top: 2px;
    left: 2px;
  `}
`;

const Label = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  white-space: nowrap;
  line-height: 0;
`;

const ListWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.75);
  width: 200px;
  min-height: 50px;
  border-radius: ${CAModalBorderRadius};
  padding-top: 48px;
  padding-bottom: 1px;
  z-index: 1;
  line-height: 0;
`;

const SecondaryOption = styled.div`
  position: relative;
  display: inline-block;
  background-color: #fff;
  width: 196px;
  height: 46px;
  margin-left: 2px;
  margin-top: 1.5px;
  margin-bottom: 1.5px;
  border-radius: ${CAButtonBorderRadius};
  cursor: pointer;
  z-index: 2;

  &:hover {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, .1);
  }
`;

export class CAMenuBlob extends React.Component {
  closeTimeout = null;

  constructor(props) {
    super(props);

    this.state = {
      expanded: false
    };
  }

  onMouseEnter() {
    if (this.closeTimeout) {
      this.closeTimeout = clearTimeout(this.closeTimeout);
    }
  }

  onMouseLeave() {
    if (this.closeTimeout || this.props.autoClose === false) {
      return;
    }

    this.closeTimeout = setTimeout(() => {
      this.setState({ expanded: false });
    }, 1000);
  }

  render() {
    const { expanded } = this.state;
    const { children, style, options, onClick, dangerous, expandedContent, autoClose, customLabel } = this.props;

    return (
      <>
        <Wrapper
          style={style}
          optionsCount={options ? options.length : 0}
          expanded={expanded}
          onMouseLeave={() => {
            this.onMouseLeave();
          }}
          onMouseEnter={() => {
            this.onMouseEnter();
          }}
        >
          <ToggleWrapper
            inverted={expanded}
            dangerous={dangerous}
            onClick={() => {
              if (!options && !expandedContent) {
                if (typeof onClick === 'function') {
                  onClick(false);
                }
              } else {
                if (typeof onClick === 'function') {
                  onClick(!expanded);
                }

                this.setState({ expanded: !expanded });
              }
            }}
          >
            {customLabel ? customLabel : (
              <Label>
                {children}
              </Label>
            )}
          </ToggleWrapper>
          {expanded && options && options.length && (
            <ListWrapper>
              {options.map(({ label }) => (
                <SecondaryOption>
                  <Label>
                    {label}
                  </Label>
                </SecondaryOption>
              ))}
            </ListWrapper>
          )}
        </Wrapper>
        {expanded && <div>{expandedContent}</div>}
      </>
    );
  }
}
