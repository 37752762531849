import React from 'react';
import styled from 'styled-components';
import {
  Layout,
  Menu,
  Icon,
  Row,
  Col,
  message,
  List,
  Divider,
  Table,
  Tooltip,
  Switch,
} from 'antd';
import { AntWrapper } from '../components/ant-wrapper';
import { HeaderNav } from '../components/header-nav';
import { SimpleButton, SimpleInput } from '../styles/styled';
import StateService from '../services/state.service';
import axios from 'axios';
import EditorService, { authTokenKey, livePreviewKey } from '../services/editor.service';
import { VariableInput } from '../components/variable-input';
import path from 'path';
import { languages, getLanguageNameFromCode } from '../data/languages.data';

import defaultFavIcon from '../images/favicon.png';
import { callApi } from '../../config';
import VariablesService from '../../wtl-schema/services/variable.service';
import { imageFileTypes, fontFileTypes } from '../data/file-types.data';
import { WtlTooltip } from '../components/layout/wtl-tooltip';

const { SubMenu } = Menu;
const {
  Sider,
  Content
} = Layout;

const WtlExplanationNote = styled.div`
  font-size: 12px;
  background-color: #eee;
  border-radius: 2px;
  padding: 10px 15px;
  line-height: 1.5;
`;

const defaultTitle = 'WTL Studio - Work In Progress';

const BrowserTabPreview = styled.div`
  display: flex;
  align-items: center;
  width: 300px;
  min-height: 40px;
  margin: 0 200px;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 4px 4px 0 0;
  border: solid 1px #777;
  border-bottom: 0;

  span, .mock-title {
    flex-grow: 2;
    white-space: nowrap;
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 15px;
  }

  .mock-favicon,
  .favicon-preview {
    display: inline-block;
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    background-color: #eee;
    border-radius: 2px;
    margin-right: 8px;
  }

  .mock-title {
    position: relative;

    &:after {
      position: absolute;
      top: -10px;
      left: 0;
      height: 20px;
      width: 200px;
      border-radius: 2px;
      background-color: #eee;
      z-index: 1;
      content: '';
    }
  }

  .fa-times {
    color: #ccc;
    margin-right: 8px;
  }
`;

const CDNZone = styled(Col)`
  padding: 20px;
  box-sizing: border-box;

  h3 {
    text-align: center;
  }

  i {
    color: #000;
    font-style: normal;
  }
`;

const CDNAvailabilityStatus = styled.div`
  font-size: 64px;
  text-align: center;
`;

const CDNStatus = styled.div`
  color: #ccc;
  text-align: center;
  margin: 10px;
`;

const SocialPreviewHr = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin-bottom: 6px;
`;

const SocialPreviewsList = styled(Col)`
  .google-title {
    color: #1a0dab;
    cursor: pointer;
    text-decoration: none;
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.1;
    margin-top: 6px;
    margin-bottom: 3px;

    &:hover {
      text-decoration: underline;
    }
  }

  .google-url {
    cursor: pointer;
    color: #006621;
    font-size: 16px;
    line-height: 1.5;

    span {

    }

    i {
      margin-left: 5px;
    }
  }

  .google-description {
    font-size: 14px;
    color: #545454;
  }

  .fb-wrapper {
    position: relative;
    display: block;
    width: 500px;
    box-sizing: border-box;
    background-color: #f2f3f5;
    margin-top: 6px;
    margin-bottom: 3px;
    border: solid 1px #dadde1;
    cursor: pointer;

    &:after {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .1);
      content: '';
      z-index: 1;
    }

    &:hover {
      &:after {
        opacity: .25;
      }
    }
  }

  .fb-image {
    position: relative;
    width: 500px;
    height: 262px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      left: 0;
      min-height: initial;
      vertical-align: bottom;
    }
  }

  .fb-meta {
    padding: 10px 12px;

    .fb-url {
      color: #606770;
      font-size: 12px;
      line-height: 16px;
      text-overflow: ellipsis;
      text-transform: uppercase;
      white-space: nowrap;
    }

    .fb-title {
      font-size: 16px;
      font-weight: bold;
      line-height: 20px;
      overflow: hidden;
      color: #1d2129;
      margin: 3px 0 0;
      padding-top: 2px;
    }

    .fb-description {
      color: #606770;
      font-size: 14px;
      line-height: 20px;
      word-break: break-word;
      overflow: hidden;
    }
  }

  .tw-wrapper {
    position: relative;
    display: block;
    width: 500px;
    box-sizing: border-box;
    background-color: #f5f5f5;
    margin-top: 6px;
    margin-bottom: 3px;
    border: solid 1px #dadde1;
    border-radius: 14px;
    overflow: hidden;
    cursor: pointer;
  }

  .tw-image {
    position: relative;
    width: 500px;
    height: 262px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      left: 0;
      min-height: initial;
      vertical-align: bottom;
    }
  }

  .tw-meta {
    padding: 10px 12px;

    .tw-url {
      color: rgb(101, 119, 134);
      font-size: 13px;
      line-height: 20px;
      text-overflow: ellipsis;
      text-transform: lowercase;
      white-space: nowrap;

      i {
        margin-right: 2px;
        font-size: 11px;
      }
    }

    .tw-title {
      font-size: 15px;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: rgb(20, 23, 26);
      margin: 3px 0 0;
      padding-top: 2px;
    }

    .tw-description {
      color: rgb(101, 119, 134);
      font-size: 15px;
      line-height: 20px;
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 40px;
    }
  }

  .ln-wrapper {
    position: relative;
    display: block;
    width: 500px;
    box-sizing: border-box;
    background-color: #f3f6f8;
    margin-top: 6px;
    margin-bottom: 3px;
    border: solid 1px #ccc;
    box-shadow: 0px 2px 3px #ccc;
    cursor: pointer;
  }

  .ln-image {
    position: relative;
    width: 500px;
    height: 262px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      left: 0;
      min-height: initial;
      vertical-align: bottom;
    }
  }

  .ln-meta {
    padding: 10px 12px;

    .tw-url {
      color: rgba(0, 0, 0, 0.6);
      font-size: 12px;
      line-height: 16px;
      text-transform: lowercase;
    }

    .ln-title {
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      color: #000;
      margin: 3px 0 0;
      padding-top: 2px;
      margin-bottom: 5px;
    }
  }
`;

const WtlBrandPluginIcon = styled.div`
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  border: solid 1px #ccc;
  border-radius: 4px;
  font-size: 11px;

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`;

export default class Settings extends React.Component {
  savePopUp = null;

  constructor(props) {
    super(props);

    this.state = {
      project: {},
      subpage: null,
      fonts: [],
      editor: {
        googleFontName: '',
        googleFontUrl: '',
        googleFontValid: 0
      },
      initialVariables: {},
      newVariableName: ''
    };

    StateService.fetchProject().then(() => {
      this.setState({
        ...this.state,
        ...StateService.getConfig()
      });
    });
  }

  selectSettingsCategory(event) {
    const { key } = event;

    if (typeof window === 'undefined') {
      return;
    }

    window.location.hash = key;
  }

  getImageAsBase64(image) {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
        message.success('Uploading file...');
      };
      reader.onerror = () => {
        message.error('Invalid file, try again.');
      };

      reader.readAsDataURL(image);
    });
  }

  async handleImageUpload(event) {
    const { target } = event;
    const { files } = target;

    if (!files) {
      return;
    }

    const file = files[0];
    const base64 = await this.getImageAsBase64(file);
    let upload;

    try {
      upload = await callApi(
        'project/upload',
        'POST',
        {
          base64: base64,
          filename: file.name
        },
        {
          authorization: `Bearer ${sessionStorage[authTokenKey]}`
        }
      );
    } catch {
      message.error('Upload failed, try again.');
      return;
    }

    message.success('File uploaded.');

    target.value = '';

    return upload.url || '';
  }

  onGoogleFontChange(event) {
    const { target } = event;
    const { value } = target;

    const targetUrl = value ? `https://fonts.googleapis.com/css?family=${value.replace(/\s/g, '+')}&display=swap` : '';

    this.setState({
      editor: {
        ...this.state.editor,
        googleFontName: (value || '').trim(),
        googleFontUrl: targetUrl,
        googleFontValid: 0
      }
    });

    if (!targetUrl) {
      return;
    }

    if (this.state.fonts.find(font => font.url === targetUrl)) {
      return this.setState({
        editor: {
          ...this.state.editor,
          googleFontValid: 2
        }
      });
    }

    axios.get(targetUrl).then(response => {
      if (response.status === 200) {
        this.setState({
          editor: {
            ...this.state.editor,
            googleFontValid: 1
          }
        });
      } else {
        this.setState({
          editor: {
            ...this.state.editor,
            googleFontValid: -1
          }
        });
      }
    })
    .catch(response => {
      this.setState({
        editor: {
          ...this.state.editor,
          googleFontValid: -1
        }
      });
    });
  }

  addGoogleFont() {
    const updatedFonts = [
      ...this.state.fonts,
      {
        name: this.state.editor.googleFontName,
        url: this.state.editor.googleFontUrl
      }
    ];

    this.setState({
      editor: {
        googleFontName: '',
        googleFontUrl: '',
        googleFontValid: 0
      },
      fonts: updatedFonts
    });

    StateService.updateConfig('fonts', updatedFonts);
    StateService.saveConfig();

    message.success('Font added to the project.');
  }

  addFont(name, url) {
    const updatedFonts = [
      ...this.state.fonts,
      { name, url }
    ];

    this.setState({
      fonts: updatedFonts
    });

    StateService.updateConfig('fonts', updatedFonts);
    StateService.saveConfig();

    message.success(`${name} added to the project.`);
  }

  updateConfig(prop, value, { parseValue, updateView } = {}) {
    let parsedValue = value;

    if (value && parseValue) {
      parsedValue = JSON.parse(value);
    }

    StateService.updateConfig(prop, parsedValue);
    StateService.saveAll();

    this.setState({
      ...this.state,
      ...StateService.getConfig()
    });

    if (this.savePopUp) {
      clearTimeout(this.savePopUp);
    }

    this.savePopUp = setTimeout(() => {
      if (EditorService.isDemo()) {
        message.info('These changes will not be saved in a demo project.');
        return;
      }

      message.success('Changes saved.');
    }, 1000);
  }

  removeGoogleFont(url) {
    const filteredFonts = this.state.fonts.filter(font => font.url !== url);

    this.setState({
      fonts: filteredFonts
    });

    StateService.updateConfig('fonts', filteredFonts);
    StateService.saveConfig();

    message.success('Font removed to the project.');
  }

  addNewVariable() {
    if (!this.state.newVariableName) {
      return;
    }

    const name = this.state.newVariableName;

    VariablesService.setVar(name, '');

    this.setState({
      ...this.state,
      newVariableName: ''
    });

    this.onVariableChange(name, '');
    StateService.saveConfig();
  }

  onVariableChange(name, initialValue) {
    if (initialValue) {
      this.state.initialVariables[name] = initialValue;
    } else {
      delete this.state.initialVariables[name];
    }

    StateService.updateConfig('initialVariables', this.state.initialVariables);
    StateService.saveConfig();

    this.forceUpdate();
  }

  renderSelectedCategory() {
    if (typeof window === 'undefined') {
      return null;
    }

    const category = (window.location.hash || '#project-basic-configuration').substr(1);

    switch (category) {
      case 'content-fonts':
        return this.renderContentFonts();
      case 'content-apps':
        return this.renderApps();
      case 'project-basic-configuration':
        return this.renderBasicConfiguration();
      case 'content-variables': 
        return this.renderVariablesView();
      case 'page-ranking-analytics':
        return this.renderPageRankingAnalytics();
      case 'page-ranking-keywords':
        return this.renderPageRankingKeywords();
      case 'project-cdn':
        return this.renderCdnView();
      case 'project-feature-toggle':
        return this.renderFeatureToggles();
    }

    return null;
  }

  renderFeatureToggles() {
    const featureToggles = [
      {
        id: 'feature_advanced_csseditor',
        name: <><i className="fas fa-tools" /> Show CSS Editor</>,
        description: 'Enable legacy style editor. May cause unexpected behaviour when used with Appearance Editor.'
      },
      {
        id: 'feature_advanced_behavioureditor',
        name: <><i className="fas fa-tools" /> Show Behaviours Editor</>,
        description: 'Enable legacy behaviours editor.'
      },
      {
        id: 'feature_debugtools',
        name: <><i className="fas fa-tools" /> Show Debug Tools</>,
        description: 'Enable debug tools. May cause application to work slower.'
      }
    ];

    const compatibilityToggles = [
      {
        id: 'compat_align_v1',
        name: 'Use legacy alignment',
        description: <>Align elements on the website according to their placement. Disabling legacy alignment may cause elements to not display or display improperly.</>
      }
    ];

    return (
      <>
        <h1>
          Features
        </h1>
        <Divider />
        <h2>
          Compatibility Features
        </h2>
        <span>
          Changing compatibility settings may require making some changes in the current website. Compatibility limits
          older websites access to possibly-breaking changes.
        </span>
        <div style={{ marginTop: 20 }}>{
          compatibilityToggles.map(({ id, name, description }) => (
            <Row gutter={4}>
              <VariableInput
                nonVariable
                span={8}
                name={name}
                value={this.state[id]}
                inputType="switch"
                onChange={(value) => {
                  this.updateConfig(id, value);
                }}
                inputProps={{
                  style: {
                    marginBottom: 10
                  },
                  size: 'default'
                }}
              />
              <Col span={16}>
                <WtlExplanationNote>
                  {description}
                </WtlExplanationNote>
              </Col>
            </Row>
          ))
        }
        </div>
        <Divider />
        <h2>
          Experimental Features
        </h2>
        <span>
          Test experimental features by enabling them in the project.
        </span>
        <div style={{ marginTop: 20 }}>
          {
            featureToggles.map(({ id, name, description }) => (
              <Row gutter={4}>
                <VariableInput
                  nonVariable
                  span={8}
                  name={name}
                  value={this.state[id]}
                  inputType="switch"
                  onChange={(value) => {
                    this.updateConfig(id, value);
                  }}
                  inputProps={{
                    style: {
                      marginBottom: 10
                    },
                    size: 'default'
                  }}
                />
                <Col span={16}>
                  <WtlExplanationNote>
                    {description}
                  </WtlExplanationNote>
                </Col>
              </Row>
            ))
          }
        </div>
      </>
    );
  }

  renderCdnView() {
    if (typeof window == 'undefined') {
      return;
    }

    const liveUrl = sessionStorage[livePreviewKey];

    return (
      <>
        <h1>
          Availability
        </h1>
        <h2>
          Live Address
        </h2>
        <span>
          {liveUrl && `${liveUrl}` !== 'null' ? (
            <>Your website is available at <a href={`https://${liveUrl}/`} target="_blank"><i className="fa fa-fw fa-external-link-square" /> https://{liveUrl}/</a></>
          ): (
            <>Website not yet published. <b><i className="fa fa-fw fa-upload" /> Publish</b> your website to see live url.</>
          )}
        </span>
        <Divider />
        <h2 style={{ marginTop: 20 }}>
          Content Delivery Network
        </h2>
        <span>
          Your can view any maintenance status and delivery issues below.
        </span>
        <div style={{ marginTop: 20 }}>
          <Row gutter={4}>
            <CDNZone span={6} status="200">
              <CDNAvailabilityStatus>
                <i className="fas fa-globe-americas"></i>
              </CDNAvailabilityStatus>
              <h3>
                North America & Canada
              </h3>
              <CDNStatus>
                Status: <i>200 OK</i>
              </CDNStatus>
            </CDNZone>
            <CDNZone span={6} status="200">
              <CDNAvailabilityStatus>
                <i className="fas fa-globe-americas"></i>
              </CDNAvailabilityStatus>
              <h3>
                South America
              </h3>
              <CDNStatus>
                Status: <i>200 OK</i>
              </CDNStatus>
            </CDNZone>
            <CDNZone span={6} status="200">
              <CDNAvailabilityStatus>
                <i className="fas fa-globe-europe"></i>
              </CDNAvailabilityStatus>
              <h3>
                United Kingdom
              </h3>
              <CDNStatus>
                Status: <i>200 OK</i>
              </CDNStatus>
            </CDNZone>
            <CDNZone span={6} status="200">
              <CDNAvailabilityStatus>
                <i className="fas fa-globe-europe"></i>
              </CDNAvailabilityStatus>
              <h3>
                Europe & Nordics
              </h3>
              <CDNStatus>
                Status: <i>200 OK</i>
              </CDNStatus>
            </CDNZone>
            <CDNZone span={6} status="200">
              <CDNAvailabilityStatus>
                <i className="fas fa-globe-europe"></i>
              </CDNAvailabilityStatus>
              <h3>
                Africa
              </h3>
              <CDNStatus>
                Status: <i>200 OK</i>
              </CDNStatus>
            </CDNZone>
            <CDNZone span={6} status="200">
              <CDNAvailabilityStatus>
                <i className="fas fa-globe-americas"></i>
              </CDNAvailabilityStatus>
              <h3>
                Asia & Oceania
              </h3>
              <CDNStatus>
                Status: <i>200 OK</i>
              </CDNStatus>
            </CDNZone>
          </Row>
        </div>
      </>
    )
  }

  renderApps() {
    const {
      plugin_google_adsense,
      plugin_stripe,
      plugin_fb_messenger,
      plugin_fb_messenger_theme_color,
      plugin_fb_messenger_greeting,
      plugin_trustpilot_meta,
      plugin_trustpilot_language
    } = this.state;

    return (
      <>
        <h1>
          Apps
        </h1>
        <span>
          Add third-party apps integrations to your website.
        </span>
        <Divider />
        <h2>
          <WtlBrandPluginIcon>
            <i className="fab fa-fw fa-google" />
          </WtlBrandPluginIcon> Google AdSense
        </h2>
        <div style={{ marginTop: 20 }}>
          <Row gutter={4}>
            <Col span={12}>
              <VariableInput
                nonVariable
                name="Publisher ID"
                value={plugin_google_adsense}
                inputProps={{
                  placeholder: 'pub-XXXXXXXXXXXXXXXX'
                }}
                onChange={(value) => {
                  this.updateConfig(
                    'plugin_google_adsense',
                    value
                  );
                }}
              />
            </Col>
          </Row>
        </div>
        <Divider />
        <h2>
          <WtlBrandPluginIcon>
            <i className="fab fa-fw fa-stripe" />
          </WtlBrandPluginIcon> Stripe Payments
        </h2>
        <div style={{ marginTop: 20 }}>
          <Row gutter={4}>
            <Col span={12}>
              <VariableInput
                nonVariable
                name="Publishable key"
                value={plugin_stripe}
                inputProps={{
                  placeholder: 'pk_XXXXXXXXXXXXXXXXXXXXXXXX'
                }}
                onChange={(value) => {
                  this.updateConfig(
                    'plugin_stripe',
                    value
                  );
                }}
              />
            </Col>
            <Col span={12}>
              <WtlExplanationNote>
                Sell directly on your website using Stripe. Requires Stripe account and active Checkout feature.<br/>
                You can find your <b>publishable key</b> in Stripe Dashboard <i className="fal fa-fw fa-arrow-right" /> Developers <i className="fal fa-fw fa-arrow-right"/> <a href="https://dashboard.stripe.com/apikeys" target="_blank">API keys</a>.
              </WtlExplanationNote>
            </Col>
          </Row>
        </div>
        <Divider />
        <h2>
          <WtlBrandPluginIcon>
            <i className="fab fa-fw fa-facebook-square" />
          </WtlBrandPluginIcon> Facebook Messenger for Pages
        </h2>
        <div style={{ marginTop: 20 }}>
          <Row gutter={4}>
            <Col span={12}>
              <Row gutter={4}>
                <VariableInput
                  nonVariable
                  name="Facebook Page ID"
                  value={plugin_fb_messenger}
                  inputProps={{
                    placeholder: 'xxxxxxxxxxxxxxx'
                  }}
                  onChange={(value) => {
                    this.updateConfig(
                      'plugin_fb_messenger',
                      value
                    );
                  }}
                />
                {plugin_fb_messenger && <VariableInput
                  nonVariable
                  span={8}
                  name="Pop-up color"
                  value={plugin_fb_messenger_theme_color || '#0084ff'}
                  inputType="color"
                  inputProps={{
                    disabled: !plugin_fb_messenger
                  }}
                  onChange={(value) => {
                    this.updateConfig(
                      'plugin_fb_messenger_theme_color',
                      value
                    );
                  }}
                />}
                {plugin_fb_messenger && <VariableInput
                  nonVariable
                  span={16}
                  name="Greeting"
                  value={plugin_fb_messenger_greeting}
                  inputProps={{
                    placeholder: 'Greeting message ...',
                    disabled: !plugin_fb_messenger,
                  }}
                  onChange={(value) => {
                    this.updateConfig(
                      'plugin_fb_messenger_greeting',
                      value
                    );
                  }}
                />}
              </Row>
            </Col>
            <Col span={12}>
              <WtlExplanationNote>
                Add Facebook Messenger for Pages to your website and allow visitors to contact you directly.<br/>
                Requires an active Facebook page. Add your website address to <b>White-listed domains</b> list in Settings <i className="fal fa-fw fa-arrow-right"/> Advanced messaging.<br/>
                To find your <b>Page ID</b> go to your page dashboard. Select About from the navigation. Page ID should be at the bottom of the list.
              </WtlExplanationNote>
            </Col>
          </Row>
        </div>
        <Divider />
        <h2>
          <WtlBrandPluginIcon>
            <i className="fas fa-fw fa-star" />
          </WtlBrandPluginIcon> Trustpilot
        </h2>
        <div style={{ marginTop: 20 }}>
          <Row gutter={4}>
            <Col span={12}>
              <Row gutter={4}>
                <VariableInput
                  nonVariable
                  name="Metatag Domain Verification"
                  value={plugin_trustpilot_meta}
                  inputProps={{
                    placeholder: 'xxxxx-xxxxx-xxxxx-xxxxx'
                  }}
                  onChange={(value) => {
                    this.updateConfig(
                      'plugin_trustpilot_meta',
                      value
                    );
                  }}
                />
                {plugin_trustpilot_meta && 
                  <VariableInput
                    span={24}
                    name="Widget language"
                    inputType="select"
                    hideNoneOption
                    inputProps={{
                      defaultValue: 'en-gb'
                    }}
                    nonVariable
                    value={plugin_trustpilot_language}
                    onChange={(value) => {
                      this.updateConfig(
                        'plugin_trustpilot_language',
                        value
                      );
                    }}
                  >
                    {Object.keys(languages).map((code) => 
                      <option value={code}>{languages[code]}</option>
                    )}
                  </VariableInput>
                }
              </Row>
            </Col>
            <Col span={12}>
              <WtlExplanationNote>
                Add Facebook Messenger for Pages to your website and allow visitors to contact you directly.<br/>
                Requires an active Facebook page. Add your website address to <b>White-listed domains</b> list in Settings <i className="fal fa-fw fa-arrow-right"/> Advanced messaging.<br/>
                To find your <b>Page ID</b> go to your page dashboard. Select About from the navigation. Page ID should be at the bottom of the list.
              </WtlExplanationNote>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  renderPageRankingKeywords() {
    const {
      title,
      favicon,
      description,
      previewImage,
      subpage
    } = this.state;
    const subpages = StateService.getSchemaPages();
    const isSubpageUsingDefaults = subpage !== null && typeof this.state[`title_${subpage}`] === 'undefined';
    const useDefaultValuesChecked = typeof this.state[`title_${subpage}`] === 'undefined';

    const getEditedPageKey = (configProp) => useDefaultValuesChecked ? configProp : (subpage === null ? configProp : `${configProp}_${subpage}`);

    const renderCutoffText = (text, maxLength) => {
      if (!text) {
        return '';
      }

      const cutoff = text.length >= (maxLength - 3);

      if (cutoff) {
        return `${text.substr(0, maxLength - 3)}...`;
      }

      return text;
    };

    return (
      <>
        <h1>
          Keywords & SEO
        </h1>
        <h2>
          Website Information
        </h2>
        <span>
          Define basic information and keywords associated with your website. You can see preview of how your website is displayed on the right.
        </span>
        <div style={{ marginTop: 20 }}>
          <Row gutter={4}>
            <Col span={12}>
              <SimpleInput span={16}>
                <span>
                  subpage
                </span>
                <select
                  value={subpage || -1}
                  onChange={event => {
                    const subpage = event.target.value;

                    if (subpage === '-1') {
                      this.setState({ ...this.state, subpage: null });
                    } else {
                      this.setState({ ...this.state, subpage: encodeURI(subpage) });
                    }
                  }}
                >
                  <option value={-1}>(all pages)</option>
                  {
                    subpages.map(page => (
                      <option value={page}>
                        {page.split(/\//gm).map((step, index) => 
                          `${index > 0 ? ' » ' : ''}${step}`
                        )}
                      </option>
                    ))
                  }
                </select>
              </SimpleInput>
              <Col span={16}>
                <SocialPreviewHr />
              </Col>
              {subpage && <SimpleInput span={16}>
                <Switch
                  checked={useDefaultValuesChecked}
                  size="small"
                  style={{ marginRight: 5 }}
                  onChange={value => {
                    if (value === false) {
                      this.state[`title_${subpage}`] = this.state.title || defaultTitle;
                      this.state[`description_${subpage}`] = this.state.description || '';
                      this.state[`previewImage_${subpage}`] = this.state.previewImage || '';
                      this.state[`language_${subpage}`] = this.state.language || '';
                    } else {
                      this.state[`title_${subpage}`] = undefined;
                      this.state[`description_${subpage}`] = undefined;
                      this.state[`previewImage_${subpage}`] = undefined;
                      this.state[`language_${subpage}`] = undefined;
                    }

                    this.forceUpdate();
                  }}
                />
                {' '}
                <label style={{ opacity: useDefaultValuesChecked ? '1.0' : '0.5' }}>use default values</label>
              </SimpleInput>}
              {!isSubpageUsingDefaults && <SimpleInput span={16}>
                <span>
                  title {!subpage && '(default)'}
                </span>
                <input
                  value={this.state[getEditedPageKey('title')] || ''}
                  onChange={(e) => {
                    this.updateConfig(
                      getEditedPageKey('title'),
                      e.target.value
                    );
                  }}
                />
              </SimpleInput>}
              {!isSubpageUsingDefaults && <SimpleInput span={16}>
                <span>
                  description {!subpage && '(default)'}
                </span>
                <textarea
                  onChange={(e) => {
                    this.updateConfig(
                      getEditedPageKey('description'),
                      e.target.value
                    );
                  }}
                  value={this.state[getEditedPageKey('description')] || ''}
                >
                </textarea>
              </SimpleInput>}
              {!isSubpageUsingDefaults && <VariableInput
                inputType="file"
                filterTypes={imageFileTypes}
                span={16}
                name={
                  `preview image ${!subpage ? '(default)' : ''}`
                }
                value={this.state[getEditedPageKey('previewImage')] || ''}
                onChange={(value, params) => {
                  this.updateConfig(
                    getEditedPageKey('previewImage'),
                    value
                  );
                }}
              />}
              {!isSubpageUsingDefaults && 
                <VariableInput
                  span={16}
                  name={
                    `language ${!subpage ? '(default)' : ''}`
                  }
                  inputType="select"
                  nonVariable
                  value={this.state[getEditedPageKey('language')] || ''}
                  onChange={(value, params) => {
                    this.updateConfig(getEditedPageKey('language'), value);
                  }}
                >
                  {Object.keys(languages).map((code) => 
                    <option value={code}>{languages[code]}</option>
                  )}
                </VariableInput>
              }
              {!isSubpageUsingDefaults && subpage && this.state.language && (this.state.language !== this.state[getEditedPageKey('language')]) &&
                <VariableInput
                  span={16}
                  name={
                    <div>
                      {getLanguageNameFromCode(this.state.language)} equivalent{' '}
                      <WtlTooltip
                        placement="top"
                        title="If visitor's language is not available for this subpage, point visitor to an equivalent subpage in a default language. Search engines display websites in visitor's language."
                      >
                        <i className="fa fa-fw fa-question-circle" />
                      </WtlTooltip>
                    </div>
                  }
                  inputType="select"
                  nonVariable
                  value={this.state[getEditedPageKey('alternate')] || ''}
                  onChange={(value, params) => {
                    this.updateConfig(getEditedPageKey('alternate'), value);
                  }}
                >
                  <option value="index">Starting Page</option>
                  {
                    subpages.map(page => (
                      <option value={page}>
                        {page.split(/\//gm).map((step, index) => 
                          `${index > 0 ? ' » ' : ''}${step}`
                        )}
                      </option>
                    ))
                  }
                </VariableInput>
              }
            </Col>
            <SocialPreviewsList span={12}>
              <Row>
                <Col span={24}>
                  <span>
                    <i className="fab fa-google" /> Google Search Engine
                  </span>
                </Col>
                <Col span={24} style={{ marginBottom: 20 }}>
                  <div className="google-title">
                    {renderCutoffText(this.state[getEditedPageKey('title')], 60)}
                  </div>
                  <div className="google-url">
                    <span>
                      https://{this.state.seoDomain || 'website'} {subpage ? renderCutoffText(subpage.split(/\//gm).map((step, index) => 
                        ` › ${step}`
                      ).join(''), 60): ''}
                    </span>
                    <i className="fas fa-caret-down"></i>
                  </div>
                  <div className="google-description">
                    {renderCutoffText(this.state[getEditedPageKey('description')], 158)}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <SocialPreviewHr />
                  <span>
                    <i className="fab fa-facebook-square" /> Facebook
                  </span>
                </Col>
                <Col span={24} style={{ marginBottom: 20 }}>
                  <div className="fb-wrapper">
                    {this.state[getEditedPageKey('previewImage')] && <div className="fb-image">
                      <img src={this.state[getEditedPageKey('previewImage')]} />
                    </div>}
                    <div className="fb-meta">
                      <div className="fb-url">
                        {this.state.seoDomain || 'website'}
                      </div>
                      <div className="fb-title">
                        {renderCutoffText(this.state[getEditedPageKey('title')], 90)}
                      </div>
                      <div className="fb-description">
                        {renderCutoffText(this.state[getEditedPageKey('description')], 125)}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <SocialPreviewHr/>
                  <span>
                    <i className="fab fa-twitter-square" /> Twitter
                  </span>
                </Col>
                <Col span={24} style={{ marginBottom: 20 }}>
                  <div className="tw-wrapper">
                    {this.state[getEditedPageKey('previewImage')] && <div className="tw-image">
                      <img src={this.state[getEditedPageKey('previewImage')]} />
                    </div>}
                    <div className="tw-meta">
                      <div className="tw-title">
                        {renderCutoffText(this.state[getEditedPageKey('title')], 90)}
                      </div>
                      <div className="tw-description">
                        {renderCutoffText(this.state[getEditedPageKey('description')], 125)}
                      </div>
                      <div className="tw-url">
                        <i className="fal fa-link"></i>
                        <span>
                          {this.state.seoDomain || 'website'}
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <SocialPreviewHr/>
                  <span>
                    <i className="fab fa-linkedin" /> LinkedIn
                  </span>
                </Col>
                <Col span={24} style={{ marginBottom: 20 }}>
                <div className="ln-wrapper">
                  {this.state[getEditedPageKey('previewImage')] && <div className="ln-image">
                    <img src={this.state[getEditedPageKey('previewImage')]} />
                  </div>}
                  <div className="ln-meta">
                    <div className="ln-title">
                      {renderCutoffText(this.state[getEditedPageKey('title')], 90)}
                    </div>
                    <div className="ln-url">
                      {this.state.seoDomain || 'website'}
                    </div>
                  </div>
                </div>
                </Col>
              </Row>
            </SocialPreviewsList>
          </Row>
        </div>
        <h2>
          Domain Address
        </h2>
        <span>
          Override domain address in SEO rich preview.
        </span>
        <div style={{ marginTop: 20 }}>
          <Row gutter={4}>
            <Col span={12}>
              <SimpleInput span={16}>
                <span>
                  address
                </span>
                <input
                  placeholder="(default website domain address)"
                  value={this.state.seoDomain || ''}
                  onChange={(e) => {
                    this.updateConfig(
                      getEditedPageKey('seoDomain'),
                      e.target.value
                    );
                  }}
                />
              </SimpleInput>
            </Col>
            <Col span={12}>
              <WtlExplanationNote>
                <b><i className="fa fa-exclamation-triangle"></i> Warning.</b> Overriding address may cause unexpected SEO issues.
              </WtlExplanationNote>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  renderPageRankingAnalytics() {
    const {
      plugin_ga
    } = this.state;

    return (
      <>
        <h1>
          Analytics
        </h1>
        <h2>
          Google Analytics
        </h2>
        <span>
          Connect your website to Google Analytics.
        </span>
        <div style={{ marginTop: 20 }}>
          <Row gutter={4}>
            <SimpleInput span={8}>
              <span>
                Tracking ID
              </span>
              <input
                value={plugin_ga || ''}
                onChange={(e) => {
                  this.updateConfig(
                    'plugin_ga',
                    e.target.value
                  );
                }}
              />
            </SimpleInput>
            <Col span={16}>
              <WtlExplanationNote>
                <b>Where to find Tracking ID?</b> Go to your Google Analytics console.<br/>
                Select Admin panel at the bottom of the navigation pane.<br/>
                In the middle column select property related to your website.<br/>
                Go to Property Settings. You should find your Tracking ID in the Basic Settings section.
              </WtlExplanationNote>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  renderVariablesView() {
    const { initialVariables } = this.state;

    const variables = Object.keys((VariablesService.vars || {}))
      .concat(Object.keys(initialVariables))
      .filter((item, index, array) => array.indexOf(item) === index)
      .map((key, index) => ({
        key: index,
        name: key,
        value: VariablesService.getVar(key)
      }));
    const variablesColumns = [
      {
        title: 'Variable',
        dataIndex: 'name',
        key: 'variable'
      },
      {
        title: 'Initial',
        key: 'initial-value',
        render: (text, { name }) => (
          <span>
            <SimpleInput>
              <input
                type="text"
                placeholder="Initial value..."
                value={initialVariables[name]}
                onChange={(e) => this.onVariableChange(name, e.target.value)}
              ></input>
            </SimpleInput>
          </span>
        )
      },
      {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        render: (value) => !`${value}`.trim() ? <i>(empty)</i> : value
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, { name }) => (
          <span>
            <SimpleButton
              onClick={() => {
                VariablesService.vars[name] = '';

                this.forceUpdate();
              }}
            >
              clear
            </SimpleButton>
          </span>
        )
      }
    ];

    return (
      <>
        <h1>
          Dynamic Properties
        </h1>
        <h2>
          Variables
        </h2>
        <span>
          Variables and values generated from events.
        </span>
        <div style={{ marginTop: 20 }}>
          <Row>
            <Col span={24}>
              <Table dataSource={variables} columns={variablesColumns} />
            </Col>
          </Row>
        </div>
        <Divider />
        <h2>
          Add Variable
        </h2>
        <div>
          <Row>
            <Col span={24}>
              <SimpleInput span={8}>
                <span>
                  Variable name
                </span>
                <input
                  type="text"
                  onChange={(e) => this.setState({ ...this.state, newVariableName: e.target.value })}
                />
              </SimpleInput>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <SimpleButton
                title="Add"
                onClick={() => this.addNewVariable()}
              >
                <i className="fal fa-plus"></i> Add
              </SimpleButton>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  renderContentFonts() {
    const { fonts, preloadFonts } = this.state;

    return (
      <>
        <h1>
          Fonts
        </h1>
        <h2>
          Preferences
        </h2>
        <Row gutter={4} style={{ margin: '20px 0' }}>
          <VariableInput
            name="preload fonts"
            inputType="switch"
            value={preloadFonts}
            onChange={(value) => this.updateConfig('preloadFonts', value)}
            span={8}
            inputProps={{
              style: {
                marginBottom: 10
              },
              size: 'default'
            }}
          />
          <Col span={16}>
            <WtlExplanationNote>
              Text on the website will wait until custom fonts are loaded. Prevents text to be displayed with wrong
              font styles. Prevents font pop-in. May slow down initial loading.
            </WtlExplanationNote>
          </Col>
        </Row>
        <Divider />
        <h2>
          Project Fonts
        </h2>
        <span>
          List of fonts used in this project.
        </span>
        <div style={{ marginTop: 20 }}>
          <Row>
            <Col span={24}>
              <List
                bordered
                dataSource={fonts}
                header={
                  <Row>
                    <Col span={6}>
                      name
                    </Col>
                    <Col span={14}>
                      url
                    </Col>
                  </Row>
                }
                renderItem={item => (
                  <List.Item
                    actions={[
                      <a onClick={() => this.removeGoogleFont(item.url)}>
                        remove
                      </a>
                    ]}
                  >
                    <Col span={6}>
                      {item.name}
                    </Col>
                    <Col span={14} style={{ whiteSpace: 'nowrap', overflow: 'scroll', opacity: .5 }}>
                      {item.url}
                    </Col>
                  </List.Item>
                )}
              />
            </Col>
          </Row>
          <Divider />
          <h2>
            Google Fonts
          </h2>
          <span>
            Add <a href="https://fonts.google.com" target="_blank">Google Fonts</a> to this project.
          </span>
          <Row gutter={4} style={{ margin: '20px 0' }}>
            <SimpleInput span={6}>
              <span>
                font name
              </span>
              <input
                defaultValue=""
                onBlur={(e) => this.onGoogleFontChange(e)}
              />
            </SimpleInput>
            <SimpleInput span={18}>
              <span>
                font url
                {' '}
                {
                  this.state.editor.googleFontValid === 1 && <div className="fas fa-check" style={{ color: '#0f0' }}></div>
                }
                {
                  this.state.editor.googleFontValid === -1 && <div className="fas fa-times" style={{ color: '#f00' }}></div>
                }
                {
                  this.state.editor.googleFontValid === 2 &&
                    (
                      <>
                        (<div className="fas fa-exclamation-triangle"></div>
                        font already in the project)
                      </>
                    )
                }
              </span>
              <input
                disabled
                defaultValue=""
                value={this.state.editor.googleFontUrl}
              />
            </SimpleInput>
            <Col span={4}>
              <SimpleButton
                disabled={this.state.editor.googleFontValid !== 1}
                title="Add font to project"
                onClick={() => this.addGoogleFont()}
              >
                <i className="fal fa-plus"></i> Add
              </SimpleButton>
            </Col>
          </Row>
          <Divider />
          <h2>
            Your Fonts
          </h2>
          <span>
            Add custom font files to this project.
          </span>
          <Row gutter={4} style={{ margin: '20px 0' }}>
            <VariableInput
              name="upload fonts"
              inputType="file"
              filterTypes={fontFileTypes}
              span={6}
              onChange={(url, { originalFilename } = {}) => {
                const fontName = 
                  originalFilename ?
                    (originalFilename.lastIndexOf('.') !== -1 ? originalFilename.substr(0, originalFilename.lastIndexOf('.')) : originalFilename) :
                    `"Project Font ${(this.state.fonts || []).length + 1}"`
                
                this.addFont(fontName, url);
              }}
            />
          </Row>
        </div>
      </>
    );
  }

  renderBasicConfiguration() {
    const {
      title,
      favicon
    } = this.state;

    return (
      <>
        <h1>
          Basic Configuration
        </h1>
        <h2>
          Title
        </h2>
        <span>
          Page title displayed in the browser tab.
        </span>
        <div style={{ marginTop: 20 }}>
          <Row>
            <SimpleInput span={8}>
              <span>
                title
              </span>
              <input
                value={title || defaultTitle}
                onChange={(e) => {
                  this.updateConfig(
                    'title',
                    e.target.value
                  );
                }}
              />
            </SimpleInput>
            <Col span={16}>
              <BrowserTabPreview>
                <div className="mock-favicon" />
                <span>
                  {title || defaultTitle}
                </span>
                <div className="far fa-times" />
              </BrowserTabPreview>
            </Col>
          </Row>
        </div>
        <Divider />
        <h2>
          Icon
        </h2>
        <span>
          A small image displayed next to the page title - Favicon.
        </span>
        <div style={{ marginTop: 20 }}>
          <Row>
            <VariableInput
              nonVariable
              inputType="file"
              filterTypes={imageFileTypes}
              span={8}
              name="icon"
              value={favicon || defaultFavIcon}
              onChange={(value, params) => {
                this.updateConfig('favicon', value);
              }}
            />
            <Col span={16}>
              <BrowserTabPreview>
                <img
                  className="favicon-preview"
                  src={favicon || defaultFavIcon}
                />
                <div className="mock-title" />
                <div className="fas fa-times" />
              </BrowserTabPreview>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  componentDidMount() {
    EditorService.refreshAuth();
  }

  render() {
    if (!EditorService.isLoggedIn()) {
      EditorService.refreshAuth(async () => {
        this.forceUpdate();

        await StateService.fetchProject();

        this.forceUpdate();
      });

      return null;
    }

    try {
      return (
        <AntWrapper style={{ minWidth: 1200 }}>
          <Layout style={{ width: '100vw', height: '100vh', boxSizing: 'border-box' }}>
            <HeaderNav service={StateService} />
            <Content style={{ padding: '40px' }}>
              <Layout style={{ background: '#fff' }}>
                <Sider width={200} style={{ background: '#fff' }}>
                  <Menu
                    mode="inline"
                    defaultSelectedKeys={[ typeof window !== 'undefined' && window.location.hash.substr(1) ]}
                    defaultOpenKeys={['project', 'page-ranking', 'content']}
                    style={{ height: '100%' }}
                    onSelect={(e) => this.selectSettingsCategory(e)}
                  >
                    <SubMenu
                      key="project"
                      title={
                        <span>
                          <Icon type="user" />
                          Project
                        </span>
                      }
                    >
                      <Menu.Item key="project-basic-configuration">Basic Configuration</Menu.Item>
                      <Menu.Item key="project-cdn">Availability</Menu.Item>
                      <Menu.Item disabled key="project-account">Account</Menu.Item>
                      <Menu.Item disabled key="project-billing">Billing</Menu.Item>
                      <Menu.Item key="project-feature-toggle">
                        Features <i className="fas fa-flask" />
                      </Menu.Item>
                    </SubMenu>
                    <SubMenu
                      key="page-ranking"
                      title={
                        <span>
                          <Icon type="user" />
                          Page Ranking
                        </span>
                      }
                    >
                      <Menu.Item key="page-ranking-keywords">Keywords & SEO</Menu.Item>
                      <Menu.Item key="page-ranking-analytics">Analytics</Menu.Item>
                    </SubMenu>
                    <SubMenu
                      key="content"
                      title={
                        <span>
                          <Icon type="user" />
                          Content
                        </span>
                      }
                    >
                      <Menu.Item key="content-apps">Apps</Menu.Item>
                      <Menu.Item disabled key="content-image-storage">Image Storage</Menu.Item>
                      <Menu.Item key="content-fonts">Fonts</Menu.Item>
                      <Menu.Item key="content-variables">Runtime Properties</Menu.Item>
                    </SubMenu>
                  </Menu>
                </Sider>
                <Content style={{ padding: '24px', minHeight: 280 }}>
                  {this.renderSelectedCategory()}
                </Content>
              </Layout>
            </Content>
          </Layout>
        </AntWrapper>
      );
    } catch (error) {
      if (typeof window === 'undefined') {
        return null;
      }

      message.error('Error, refresh page.', error);

      return null;
    }
  }
}
