import React from 'react';
import styled from 'styled-components';
import { Select, List, Tooltip } from 'antd';
import StateService from '../services/state.service';

import imgBlock from '../images/blocks/block.png';
import imgText from '../images/blocks/text.png';
import imgImage from '../images/blocks/image.png';
import imgVideo from '../images/blocks/video.png';
import imgInput from '../images/blocks/input.png';
import imgLink from '../images/blocks/link.png';
import imgIcon from '../images/blocks/icon.png';
import imgContactForm from '../images/blocks/contact-form.png';
import imgShape from '../images/blocks/shape.png';
import imgList from '../images/blocks/list.png';
import imgAdvancedClock from '../images/blocks/advanced-clock.png';
import imgAdvancedCarousel from '../images/blocks/advanced-carousel.png';
import imgAdvancedCollapsibleNavigation from '../images/blocks/advanced-collapsible-navigation.png';
import imgAdvancedTrustpilot from '../images/blocks/advanced-trust-pilot.png';
import imgAdvancedSocialShare from '../images/blocks/advanced-social-share.png';

import imgPresetColumn2 from '../images/blocks/preset-column-2.png';
import imgPresetColumn3 from '../images/blocks/preset-column-3.png';
import imgPresetImageHero from '../images/blocks/preset-image-hero.png';
import imgPresetImageLeft from '../images/blocks/preset-image-left.png';
import imgPresetImageRight from '../images/blocks/preset-image-right.png';
import imgPresetHeader1 from '../images/blocks/preset-header-1.png';
import imgPresetHeader2 from '../images/blocks/preset-header-2.png';
import imgPresetFooter1 from '../images/blocks/preset-footer-1.png';
import imgPresetFooter2 from '../images/blocks/preset-footer-2.png';
import { WtlTooltip } from './layout/wtl-tooltip';
import { groupBlocks } from '../data/block-types.data';
import { extractVariable } from '../../wtl-schema/helpers/extract-variable';

const { Option } = Select;

const encde = encodeURIComponent;
const imagePlaceholder = encde('https://cdn.wtlstudio.com/sample.wtlstudio.com/478ef8a8-f00f-4593-b84a-7051192fc7a3.png')

export const blockTypes = [
  {
    icon: 'layer-group',
    type: 'block',
    name: 'Group',
    tags: ['basic', 'layout', 'groups'],
    preview: imgBlock,
    description: 'Use groups to build basic shape of the website. Group can contain multiple elements and will adjust to screen size.'
  },
  {
    icon: 'layer-group',
    type: 'advanced-background-block',
    name: 'Background Group',
    tags: ['layout', 'groups'],
    preview: imgBlock,
    description: 'Background group covers entire width of website, regardless of root width. Behaves like normal, responsive group.'
  },
  {
    icon: 'sort-shapes-down',
    type: 'advanced-scroll-block',
    name: 'Scroll Group',
    tags: ['layout', 'groups'],
    preview: imgBlock,
    description: 'Scroll group scrolls content with the entire webpage. Behaves like normal group.'
  },
  {
    icon: 'running',
    type: 'advanced-animation-block',
    name: 'Animated Group',
    tags: ['media', 'interaction', 'groups'],
    preview: imgImage,
    description: 'Animated group uses keyframe animation to add motion to the website. You can also nest animated groups to create complex motions.'
  },
  {
    icon: 'layer-group',
    type: 'advanced-form-block',
    name: 'Form Group',
    tags: ['interaction', 'mail', 'groups'],
    preview: imgContactForm,
    description: 'Use form group to create custom forms. Behaves like normal group, but can contain only input fields.'
  },
  {
    icon: 'keyboard',
    type: 'advanced-input:text',
    name: 'Input: Short text',
    tags: ['input'],
    preview: imgInput,
    description: 'Allows visitors to input data in forms on the website.'
  },
  {
    icon: 'keyboard',
    type: 'advanced-input:select',
    name: 'Input: Select',
    tags: ['input'],
    preview: imgInput,
    description: 'Allows visitors to input data in forms on the website.'
  },
  {
    icon: 'keyboard',
    type: 'advanced-input:textarea',
    name: 'Input: Long text',
    tags: ['input'],
    preview: imgInput,
    description: 'Allows visitors to input data in forms on the website.'
  },
  {
    icon: 'keyboard',
    type: 'advanced-input:password',
    name: 'Input: Password',
    tags: ['input'],
    preview: imgInput,
    description: 'Allows visitors to input data in forms on the website.'
  },
  {
    icon: 'keyboard',
    type: 'advanced-input:date',
    name: 'Input: Date',
    tags: ['input'],
    preview: imgInput,
    description: 'Allows visitors to input data in forms on the website.'
  },
  {
    icon: 'keyboard',
    type: 'advanced-input:time',
    name: 'Input: Time',
    tags: ['input'],
    preview: imgInput,
    description: 'Allows visitors to input data in forms on the website.'
  },
  {
    icon: 'text-size',
    type: 'text',
    name: 'Text',
    tags: ['basic', 'typography'],
    preview: imgText,
    description: 'Use text elements to add rich text content to the website.'
  },
  {
    icon: 'image',
    type: 'image',
    name: 'Image',
    tags: ['basic', 'media'],
    preview: imgImage,
    description: 'Use image elements to add static images to the website.'
  },
  {
    icon: 'image',
    type: 'video',
    name: 'Video',
    tags: ['basic', 'media'],
    preview: imgVideo,
    description: 'Use video elements to add playable video to the website.'
  },
  {
    icon: 'link',
    type: 'link',
    name: 'Link Group',
    tags: ['basic', 'interaction', 'groups'],
    preview: imgLink,
    description: 'Use link group to connect with other websites. You can link subpages and external websites using links.'
  },
  {
    icon: 'text-size',
    type: 'icon',
    name: 'Icon',
    tags: ['basic', 'typography'],
    preview: imgIcon,
    description: 'Use icon element to add contextual images to text content and website sections.'
  },
  {
    icon: 'circle',
    type: 'shape:ellipse',
    name: 'Circle',
    tags: ['shapes'],
    preview: imgShape,
    description: 'Circle / Ellipse shape.'
  },
  {
    icon: 'square',
    type: 'shape:rectangle',
    name: 'Rectangle',
    tags: ['shapes'],
    preview: imgShape,
    description: 'Square / Rectangle shape.'
  },
  {
    icon: 'wave-triangle',
    type: 'shape:line',
    name: 'Line',
    tags: ['shapes'],
    preview: imgShape,
    description: 'Line shape.'
  },
  {
    icon: 'wave-sine',
    type: 'shape:wave',
    name: 'Wave Line',
    tags: ['shapes'],
    preview: imgShape,
    description: 'Sinusoidal wave line shape.'
  },
  {
    hidden: true,
    icon: 'window',
    type: 'root',
    name: 'Root',
    tags: ['layout'],
    preview: imgBlock,
    description: 'Website wrapper. Use root element to make website look better on large screens.'
  },
  {
    icon: 'puzzle-piece',
    type: 'advanced-contact-form',
    name: 'Contact Form',
    tags: ['interaction', 'mail'],
    preview: imgContactForm,
    description: 'Contact form allows visitors to contact you easily via email.'
  },
  {
    icon: 'puzzle-piece',
    type: 'advanced-vimeo',
    name: 'Vimeo',
    tags: ['plugin', 'media'],
    preview: imgVideo,
    description: 'Use Vimeo player plugin to embed videos hosted on Vimeo to the website.'
  },
  {
    icon: 'puzzle-piece',
    type: 'advanced-carousel',
    name: 'Carousel',
    tags: ['media'],
    preview: imgAdvancedCarousel,
    description: 'Use carousel to present multiple images at once. Carousel can consist of up-to 4 images. Visitors can scroll seamlessly through images using arrow keys.'
  },
  {
    icon: 'puzzle-piece',
    type: 'advanced-collapsible-navigation',
    name: 'Collapsible Navigation',
    tags: ['layout', 'mobile'],
    preview: imgAdvancedCollapsibleNavigation,
    description: 'Use collapsible navigation to make the website easier to use on mobile devices. Navigation links are collapsed by default, making the screen less crowdy on small screen devices.'
  },
  {
    icon: 'puzzle-piece',
    type: 'advanced-trust-pilot',
    name: 'Trustpilot',
    tags: ['plugin'],
    preview: imgAdvancedTrustpilot,
    description: 'Use Trustpilot plugin to embed Trustpilot rating on the website.'
  },
  {
    icon: 'puzzle-piece',
    type: 'advanced-social-share',
    name: 'Social Network Share Buttons',
    tags: ['interaction'],
    preview: imgAdvancedSocialShare,
    description: 'Use social network share buttons to allow visitors to share your website. This is very important for generatic organic traffic and awareness of your website.'
  },
  {
    icon: 'puzzle-piece',
    type: 'advanced-page-preview',
    name: 'Webpage Preview',
    tags: ['media'],
    preview: imgList,
    description: 'Use webpage preview to display title and description of another webpage. You can also display several webpages at once.'
  },
  {
    icon: 'puzzle-piece',
    type: 'advanced-timer',
    name: 'Timer',
    tags: ['media'],
    preview: imgAdvancedClock,
    description: 'Use timer to display countdown or clock preview.'
  },
  {
    icon: 'puzzle-piece',
    type: 'advanced-youtube',
    name: 'YouTube',
    tags: ['plugin', 'media'],
    preview: imgVideo,
    description: 'Use YouTube player plugin to embed videos hosted on YouTube to the website.'
  },
  {
    icon: 'cubes',
    type: 'advanced-3d-model',
    name: <>Real3D Display <i className="fas fa-fw fa-flask" /></>,
    tags: ['media'],
    preview: imgImage,
    description: 'Display realistic model of an object. User can rotate the model and see every detail of it.'
  },
  {
    icon: 'puzzle-piece',
    type: 'advanced-stripe',
    name: 'Stripe Product',
    tags: ['plugin', 'e-commerce'],
    preview: imgImage,
    description: 'Use Stripe to sell products online. Behaves like normal group.'
  },

  // Layout presets
  // {
  //   type: 'preset-column-1',
  //   name: 'Single Column',
  //   tags: ['layout'],
  //   preview: imgBlock,
  //   description: 'Add single column section.',
  //   preset: [
  //     {
  //       type: 'block',
  //       align: 'left',
  //       size: 24,
  //       content: []
  //     }
  //   ]
  // },
  // {
  //   type: 'preset-column-2',
  //   name: 'Double Column',
  //   tags: ['layout'],
  //   preview: imgPresetColumn2,
  //   description: 'Add section divided into two columns.',
  //   preset: [
  //     {
  //       type: 'block',
  //       align: 'left',
  //       size: 12,
  //       sizeTablet: 12,
  //       sizeMobile: 24,
  //       content: []
  //     },
  //     {
  //       type: 'block',
  //       align: 'left',
  //       size: 12,
  //       sizeTablet: 12,
  //       sizeMobile: 24,
  //       content: []
  //     }
  //   ]
  // },
  // {
  //   type: 'preset-column-3',
  //   name: 'Tripple Column',
  //   tags: ['layout'],
  //   preview: imgPresetColumn3,
  //   description: 'Add section divided into three columns.',
  //   preset: [
  //     {
  //       type: 'block',
  //       align: 'left',
  //       size: 8,
  //       sizeTablet: 8,
  //       sizeMobile: 24,
  //       content: []
  //     },
  //     {
  //       type: 'block',
  //       align: 'left',
  //       size: 8,
  //       sizeTablet: 8,
  //       sizeMobile: 24,
  //       content: []
  //     },
  //     {
  //       type: 'block',
  //       align: 'left',
  //       size: 8,
  //       sizeTablet: 8,
  //       sizeMobile: 24,
  //       content: []
  //     }
  //   ]
  // },
  // {
  //   type: 'preset-image-left',
  //   name: 'Image Section A',
  //   tags: ['layout', 'media'],
  //   preview: imgPresetImageLeft,
  //   description: 'Add section with single large image on the left. Use right column to add description.',
  //   preset: [
  //     {
  //       align: 'center',
  //       alignItemsVertically: 'center',
  //       size: 24,
  //       type: 'block',
  //       content: [
  //         {
  //           align: 'left',
  //           type: 'block',
  //           size: 12,
  //           sizeTablet: 12,
  //           sizeMobile: 24,
  //           customWrapperElement: 'wtl-preset-responsive-image-left',
  //           content: [
  //             {
  //               align: 'left',
  //               customAppearAnimation: 'fade',
  //               size: 24,
  //               type: 'image',
  //               url: imagePlaceholder
  //             }
  //           ]
  //         },
  //         {
  //           align: 'left',
  //           size: 12,
  //           sizeTablet: 12,
  //           sizeMobile: 24,
  //           type: 'block',
  //           content: [
  //             {
  //               align: 'left',
  //               customWrapperElement: 'wtl-preset-half-page-size',
  //               padding: encde('0 40px'),
  //               size: 24,
  //               type: 'block',
  //               content: [
  //                 {
  //                   align: 'left',
  //                   padding: encde('20px 0'),
  //                   type: 'text',
  //                   size: 24,
  //                   content: encde('Image title')
  //                 }
  //               ]
  //             }
  //           ]
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   type: 'preset-image-right',
  //   name: 'Image Section B',
  //   tags: ['layout', 'media'],
  //   preview: imgPresetImageRight,
  //   description: 'Add section with single large image on the right. Use left column to add description.',
  //   preset: [
  //     {
  //       align: 'center',
  //       alignItemsVertically: 'center',
  //       size: 24,
  //       type: 'block',
  //       content: [
  //         {
  //           align: 'right',
  //           size: 12,
  //           sizeTablet: 12,
  //           sizeMobile: 24,
  //           type: 'block',
  //           content: [
  //             {
  //               align: 'left',
  //               customWrapperElement: 'wtl-preset-half-page-size',
  //               padding: encde('0 40px'),
  //               size: 24,
  //               type: 'block',
  //               content: [
  //                 {
  //                   align: 'left',
  //                   padding: encde('20px 0'),
  //                   type: 'text',
  //                   size: 24,
  //                   content: encde('Image title')
  //                 }
  //               ]
  //             }
  //           ]
  //         },
  //         {
  //           align: 'left',
  //           type: 'block',
  //           size: 12,
  //           sizeTablet: 12,
  //           sizeMobile: 24,
  //           customWrapperElement: 'wtl-preset-responsive-image-right',
  //           content: [
  //             {
  //               align: 'left',
  //               customAppearAnimation: 'fade',
  //               size: 24,
  //               type: 'image',
  //               url: imagePlaceholder
  //             }
  //           ]
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   type: 'preset-image-hero',
  //   name: 'Image with Text',
  //   tags: ['layout', 'media', 'typography'],
  //   preview: imgPresetImageHero,
  //   description: 'Add single large image with text overlay.',
  //   preset: [
  //     {
  //       align: 'center',
  //       alignItemsVertically: 'center',
  //       padding: encde('80px'),
  //       size: 24,
  //       type: 'block',
  //       content: [
  //         {
  //           align: 'left',
  //           customWrapperElement: 'wtl-preset-background-image',
  //           size: 24,
  //           type: 'block'
  //         },
  //         {
  //           align: 'left',
  //           customWrapperElement: 'wtl-preset-page-size',
  //           size: 24,
  //           type: 'block',
  //           content: [
  //             {
  //               type: 'text',
  //               size: 24,
  //               align: 'center',
  //               content: encde('Image title'),
  //               padding: encde('40px 0px')
  //             }
  //           ]
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   type: 'preset-header-1',
  //   name: 'Header A',
  //   tags: ['layout'],
  //   preview: imgPresetHeader1,
  //   description: 'Add header section with logo and navigation. Navigation is displayed on the right. Collapsible navigation displayed on smaller devices.',
  //   preset: [
  //     {
  //       align: 'center',
  //       alignItemsVertically: 'center',
  //       customWrapperElement: 'wtl-preset-page-size',
  //       size: 24,
  //       type: 'block',
  //       content: [
  //         {
  //           align: 'center',
  //           size: -1,
  //           sizeTablet: 24,
  //           sizeMobile: 24,
  //           type: 'advanced-collapsible-navigation',
  //           content: [
  //             {
  //               type: 'link',
  //               size: 24,
  //               align: 'center',
  //               url: encde('/'),
  //               content: encde('Home'),
  //               padding: encde('20px 0'),
  //             }
  //           ]
  //         },
  //         {
  //           align: 'left',
  //           size: 6,
  //           sizeTablet: 24,
  //           sizeMobile: 24,
  //           type: 'block',
  //           content: [
  //             {
  //               align: 'left',
  //               size: 24,
  //               padding: encde('30px 20px 20px 20px'),
  //               type: 'image',
  //               url: imagePlaceholder,
  //               width: 100
  //             }
  //           ]
  //         },
  //         {
  //           align: 'right',
  //           padding: encde('20px'),
  //           size: 18,
  //           sizeTablet: -1,
  //           sizeMobile: -1,
  //           type: 'block',
  //           content: [
  //             {
  //               type: 'link',
  //               size: 0,
  //               align: 'center',
  //               url: encde('/'),
  //               content: encde('Home')
  //             }
  //           ]
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   type: 'preset-header-2',
  //   name: 'Header B',
  //   tags: ['layout'],
  //   preview: imgPresetHeader2,
  //   description: 'Add header section with logo and navigation. Navigation is displayed under the logo. Collapsible navigation displayed on smaller devices.',
  //   preset: [
  //     {
  //       align: 'center',
  //       alignItemsVertically: 'center',
  //       customWrapperElement: 'wtl-preset-page-size',
  //       size: 24,
  //       type: 'block',
  //       content: [
  //         {
  //           align: 'center',
  //           size: -1,
  //           sizeTablet: 24,
  //           sizeMobile: 24,
  //           type: 'advanced-collapsible-navigation',
  //           content: [
  //             {
  //               type: 'link',
  //               size: 24,
  //               align: 'center',
  //               url: encde('/'),
  //               content: encde('Home'),
  //               padding: encde('20px 0'),
  //             }
  //           ]
  //         },
  //         {
  //           align: 'left',
  //           size: 24,
  //           sizeTablet: 24,
  //           sizeMobile: 24,
  //           type: 'block',
  //           content: [
  //             {
  //               align: 'center',
  //               size: 24,
  //               padding: encde('30px 20px 20px 20px'),
  //               type: 'image',
  //               url: imagePlaceholder,
  //               width: 100
  //             }
  //           ]
  //         },
  //         {
  //           align: 'center',
  //           padding: encde('20px'),
  //           size: 24,
  //           sizeTablet: -1,
  //           sizeMobile: -1,
  //           type: 'block',
  //           content: [
  //             {
  //               type: 'link',
  //               size: 0,
  //               align: 'center',
  //               url: encde('/'),
  //               content: encde('Home')
  //             }
  //           ]
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   type: 'preset-footer-1',
  //   name: 'Footer A',
  //   tags: ['layout'],
  //   preview: imgPresetFooter1,
  //   description: 'Add footer section with logo and site map. Navigation is displayed on the right.',
  //   preset: [
  //     {
  //       align: 'left',
  //       padding: encde('40px'),
  //       size: 24,
  //       type: 'block',
  //       content: [
  //         {
  //           align: 'left',
  //           size: 24,
  //           type: 'block',
  //           customWrapperElement: 'wtl-preset-page-size',
  //           content: [
  //             {
  //               type: 'text',
  //               size: 24,
  //               align: 'left',
  //               content: encde('Footer title'),
  //               padding: encde('10px 0')
  //             },
  //             {
  //               type: 'block',
  //               size: 12,
  //               align: 'left',
  //               content: [
  //                 {
  //                   type: 'link',
  //                   size: 24,
  //                   align: 'left',
  //                   content: encde('Home'),
  //                   url: encde('/'),
  //                   padding: encde('5px 0')
  //                 }
  //               ]
  //             },
  //             {
  //               type: 'block',
  //               size: 12,
  //               align: 'right',
  //               content: [
  //                 {
  //                   align: 'right',
  //                   size: 24,
  //                   type: 'text',
  //                   content: encde('Footer description')
  //                 }
  //               ]
  //             },
  //             {
  //               align: 'center',
  //               type: 'block',
  //               size: 24,
  //               padding: encde('40px 0'),
  //               content: [
  //                 {
  //                   align: 'center',
  //                   size: 0,
  //                   type: 'text',
  //                   content: encde('© copyright credit')
  //                 }
  //               ]
  //             }
  //           ]
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   type: 'preset-footer-2',
  //   name: 'Footer B',
  //   tags: ['layout'],
  //   preview: imgPresetFooter2,
  //   description: 'Add footer section with logo and site map. Navigation is displayed under the logo.',
  //   preset: [
  //     {
  //       align: 'left',
  //       padding: encde('40px'),
  //       size: 24,
  //       type: 'block',
  //       content: [
  //         {
  //           align: 'left',
  //           size: 24,
  //           type: 'block',
  //           customWrapperElement: 'wtl-preset-page-size',
  //           content: [
  //             {
  //               type: 'text',
  //               size: 24,
  //               align: 'center',
  //               content: encde('Footer title'),
  //               padding: encde('10px 0')
  //             },
  //             {
  //               type: 'block',
  //               size: 12,
  //               align: 'left',
  //               content: [
  //                 {
  //                   type: 'link',
  //                   size: 24,
  //                   align: 'left',
  //                   content: encde('Home'),
  //                   url: encde('/'),
  //                   padding: encde('5px 0')
  //                 }
  //               ]
  //             },
  //             {
  //               type: 'block',
  //               size: 12,
  //               align: 'right',
  //               content: [
  //                 {
  //                   align: 'right',
  //                   size: 24,
  //                   type: 'text',
  //                   content: encde('Footer description')
  //                 }
  //               ]
  //             },
  //             {
  //               align: 'center',
  //               type: 'block',
  //               size: 24,
  //               padding: encde('40px 0'),
  //               content: [
  //                 {
  //                   align: 'center',
  //                   size: 0,
  //                   type: 'text',
  //                   content: encde('© copyright credit')
  //                 }
  //               ]
  //             }
  //           ]
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   type: 'preset-page-size-1',
  //   name: 'Page Grid (Small)',
  //   tags: ['layout'],
  //   preview: imgBlock,
  //   description: 'Column with page width limited to 960 pixels.',
  //   preset: [
  //     {
  //       type: 'block',
  //       align: 'center',
  //       size: 24,
  //       content: [
  //         {
  //           type: 'block',
  //           align: 'left',
  //           size: 24,
  //           customWrapperElement: 'wtl-preset-page-width-960',
  //           content: []
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   type: 'preset-page-size-2',
  //   name: 'Page Grid (Medium)',
  //   tags: ['layout'],
  //   preview: imgBlock,
  //   description: 'Column with page width limited to 1280 pixels.',
  //   preset: [
  //     {
  //       type: 'block',
  //       align: 'center',
  //       size: 24,
  //       content: [
  //         {
  //           type: 'block',
  //           align: 'left',
  //           size: 24,
  //           customWrapperElement: 'wtl-preset-page-width-1280',
  //           content: []
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   type: 'preset-page-size-1',
  //   name: 'Page Grid (XXL)',
  //   tags: ['layout'],
  //   preview: imgBlock,
  //   description: 'Column with page width limited to 1440 pixels.',
  //   preset: [
  //     {
  //       type: 'block',
  //       align: 'center',
  //       size: 24,
  //       content: [
  //         {
  //           type: 'block',
  //           align: 'left',
  //           size: 24,
  //           customWrapperElement: 'wtl-preset-page-width-1440',
  //           content: []
  //         }
  //       ]
  //     }
  //   ]
  // }
];

const blockCategories = blockTypes.reduce(
  (all, { tags }) => {
    const newTags = [];

    tags.forEach(tag => {
      if (all.indexOf(tag) === -1) {
        newTags.push(tag);
      }
    });

    return [ ...all, ...newTags ];
  },
  []
).concat([ 'reusable blocks']);

const WtlListContainer = styled.div`
  height: 211px;
  border: solid 1px #ccc;
  border-radius: 2px;
  overflow-y: scroll;

  .ant-list-item {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const WtlBlockListItem = styled(List.Item)`
  cursor: pointer !important;
  transition: background-color .3s ease;

  &:hover {
    background-color: #e6f7ff;
  }
`;

const WtlBlockDescription = styled.div`
  width: 151px;

  .block-description {
    margin: 6px 0;
  }
`;

export class BlockSelector extends React.Component {
  state = {
    selectedCategory: 'basic',
    filteredCategories: blockCategories,
    filteredBlocks: []
  };

  componentDidMount() {
    this.getFilteredBlocks('basic');
  }

  getFilteredBlocks(category) {
    if (category === 'reusable blocks') {
      const allSchema = StateService.getEntireSchema();

      let refs = {};
      let refCount = 1;

      const traverseSchema = (node) => {
        if (node._refSrc) {
          if (refs[node._refSrc]) {
            return;
          }

          const newNode = { ...node };
          newNode.id = undefined;

          let nodeName = blockTypes.find(item => item.type === [ node.type, node.variant ].filter(Boolean).join(':')) || {}

          refs[node._refSrc] = {
            type: node.type,
            name: (node._groupName ? node._groupName : (nodeName.name ? `Custom ${nodeName.name} ${refCount}` : undefined)) || `Custom Block ${refCount}`,
            preset: newNode,
            unwrappedPreset: true
          };
          refCount++;
        }

        if (groupBlocks.includes(node.type) && node.content instanceof Array) {
          node.content.forEach(traverseSchema);
        }
      };

      Object.keys(allSchema).forEach(page => {
        allSchema[page].forEach(_root => {
          traverseSchema(_root);
        });
      });

      const blocks = [];
      Object.keys(refs).forEach(key => {
        blocks.push(refs[key]);
      });

      this.setState({ filteredBlocks: blocks });
    } else {
      this.setState({
        filteredBlocks: blockTypes
          .filter(item => !item.hidden)
          .filter(({ tags }) => tags.indexOf(category) !== -1)
      });
    }
  }

  getFilteredCategories(value) {
    const matchRegex = new RegExp(`((^|\\s)${value}.*)|(.*${value}($|\\s))`, 'gm');

    this.setState({
      filteredCategories: blockCategories.filter(tag => {
        const containedBlockMatch = blockTypes.reduce((previous, { name, tags }) => {
          if (tags.indexOf(tag) !== -1) {
            return name.toLowerCase().match(matchRegex) || previous;
          }

          return previous;
        }, false);

        return tag.match(matchRegex) || containedBlockMatch;
      })
    });
  }

  render() {
    const { selectedCategory, filteredCategories, filteredBlocks } = this.state;
    const { onClick } = this.props;

    return (
      <div>
        <div>
          <Select
            showSearch
            value={selectedCategory}
            placeholder="Search category or block..."
            filterOption={false}
            onSearch={(value) => this.getFilteredCategories(value)}
            onChange={(value) => {
              this.getFilteredBlocks(value);

              this.setState({
                selectedCategory: value
              });
            }}
            style={{
              width: '100%'
            }}
          >
            {filteredCategories.map(tag => (
              <Option key={tag}>
                {tag}
              </Option>
            ))}
          </Select>
        </div>
        <WtlListContainer    
          style={{
            marginTop: 10
          }}
        >
          <List
            itemLayout="horizontal"
            size="small"
            dataSource={filteredBlocks}
            locale={{
              emptyText: selectedCategory === 'reusable blocks' ?
                <>
                  Use <b>Copy</b> <i className="fal fa-fw fa-arrow-right" /> <b>Convert to Reusable Block</b> to create custom blocks
                </> : 'No blocks of this type :(' 
            }}
            renderItem={item => (
              <WtlTooltip
                disabled={!item.preview || !item.description}
                placement="right"
                title={
                  <WtlBlockDescription>
                    <div className="block-preview">
                      <img src={item.preview} />
                    </div>
                    <div className="block-description">
                      {item.description}
                    </div>
                  </WtlBlockDescription>
                }
              >
                <WtlBlockListItem onClick={() => onClick(item.type, item.preset, item.unwrappedPreset)}>
                  {item.name}
                </WtlBlockListItem>
              </WtlTooltip>
            )}
          />
        </WtlListContainer>
      </div>
    )
  }
}