import * as Three from 'three';

export const parseGroup = (branch, context, options) => {
  const { _selfKey, parent } = options;
  const {
    positionX = 0,
    positionY = 0,
    positionZ = 0
  } = branch;

  if (!parent) {
    return;
  }

  let instance = parent.getObjectByName(_selfKey);

  if (!instance) {
    instance = new Three.Group();
    parent.add(instance);
  }

  instance.position.x = positionX;
  instance.position.y = positionY;
  instance.position.z = positionZ;

  return instance;
};
