import React from 'react';
import styled from 'styled-components';
import * as Three from 'three';
import { CAPanelBody, CAPanelH1 } from './ca-panel';
import { Row, Col, Slider } from 'antd';

import DaytimeIconSvg from '../../images/common-app/icons/ICONS_COMMON_SVG-07.svg';
import SceneIconSvg from '../../images/common-app/icons/ICONS_COMMON_SVG-12.svg';
import WeatherIconSvg from '../../images/common-app/icons/ICONS_COMMON_SVG-17.svg';
import SettingsIconSvg from '../../images/common-app/icons/ICONS_COMMON_SVG-03.svg';
import { InvertedImage } from '../../utils/inverted-image';
import DaytimeWheelBackground from '../../images/common-app/utils/daytime-wheel.png';
import StateService from '../../services/state.service';
import { CAVariableInput } from './ca-variable-input';
import { CAMenuBlob } from './ca-menu-blob';
import { CAColorPicker } from './ca-color-picker';
import { CASmartToggle } from './ca-smart-toggle';
import { materialPresets } from '../../data/common/materials';
import { MaterialPreview } from './ca-filters';

const DaySphereView = styled.div`
  position: relative;
  width: 250px;
  height: 250px;
  margin: 20px 0;
  background-color: transparent;
  border-radius: 50%;
  overflow: hidden;
`;

const DayWheel = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: inherit;
  height: inherit;
  background-image: url(${DaytimeWheelBackground});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 260px 260px;
  transform: translateX(-50%) translateY(-50%) rotate(${p => (p.angle || 0)}deg);
  z-index: 1;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, .1);
`;

const DayInput = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 2;

  .ant-slider-track {
    &, &:hover, &:active, &:focus, &:visited {
      background-color: #000 !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .ant-slider-handle {
    &, &:hover, &:active, &:focus, &:visited {
      border-color: #000 !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .ant-slider-rail {
    &, &:hover, &:active, &:focus, &:visited {
      background-color: #fff !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }
`;

const DaytimeValue = styled.div`
  position: absolute;
  display: inline-block;
  top: 70%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: border-box;
  padding: 6px;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 15px;
  color: #000;
  background-color: #fff;
  border-radius: 25px;
  cursor: default;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  z-index: 2;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, .1);
`;

export class CAEnvironment extends React.Component {
  state = {
    tab: 'daytime',
    daytime: 180
  };

  changeTab(tab) {
    this.setState({
      ...this.state,
      tab
    });

    setTimeout(() => {
      this.forceUpdate();
    }, 100);
  }

  getSelectedTime() {
    const da = this.state.daytime / 360;
    const dt = parseInt(da * 2400, 10);

    return [
      `00${parseInt(dt / 100, 10)}`.substr(-2),
      `00${parseInt(parseInt(`00${dt}`.substr(-2), 10) / 100 * 60, 10)}`.substr(-2)
    ].join(':');
  }

  renderDaytimeTab() {
    const {
      onEnvironmentUpdated,
      schema,
      project
    } = this.props;

    const environment = StateService.findElementById(project, 'env');

    return (
      <div>
        <DaySphereView>
          <DayInput>
            <Slider
              defaultValue={0}
              min={0}
              max={360}
              value={this.state.daytime}
              onChange={(value) => {
                this.setState({
                  ...this.state,
                  daytime: value
                });

                environment.daytimeOffset = value;
                onEnvironmentUpdated();
              }}
              tipFormatter={null}
            />
          </DayInput>
          <DayWheel
            angle={180 + (this.state.daytime || 0)}
          />
          <DaytimeValue>
            {this.getSelectedTime()}
          </DaytimeValue>
        </DaySphereView>
      </div>
    );
  }

  renderSceneTab() {
    const {
      onEnvironmentUpdated,
      project
    } = this.props;

    const environment = StateService.findElementById(project, 'env');
    const floor = StateService.findElementByParam(project, 'type', 'floor');
    const { materialId } = floor;

    const materialOptions = [
      'grass',
      'clear'
    ];

    return (
      <div>
        <Row>
          <CAVariableInput
            inputType="expand-box"
            name="Background Color"
          >
            <Col
              span={24}
              style={{
                marginBottom: 10,
                textAlign: 'center'
              }}
            >
              <CASmartToggle
                options={[
                  { label: 'ENVRMNT', selected: environment.backgroundType === 'simulated', onClick: () => {
                    environment.backgroundType = 'simulated';
                    onEnvironmentUpdated();
                  } },
                  { label: 'COLOR', selected: environment.backgroundType !== 'simulated' && environment.backgroundType !== 'gradient', onClick: () => {
                    environment.backgroundType = 'color';
                    onEnvironmentUpdated();
                  } },
                  { label: 'GRADIENT', selected: environment.backgroundType === 'gradient', onClick: () => {
                    environment.backgroundType = 'gradient';
                    onEnvironmentUpdated();
                  } }
                ]}
              />
            </Col>
            {(environment.backgroundType === 'color' || environment.backgroundType === 'gradient') && (
              <CAVariableInput
                span={environment.backgroundType === 'gradient' ? 12 : 24}
                inputType="color"
                value={environment.backgroundColor}
                onChange={(color) => {
                  environment.backgroundColor = color.hex;

                  setTimeout(() => {
                    onEnvironmentUpdated();
                  }, 100);
                }}
              />
            )}
            {environment.backgroundType === 'gradient' && (
              <CAVariableInput
                span={12}
                inputType="color"
                value={environment.backgroundColorB}
                onChange={(color) => {
                  environment.backgroundColorB = color.hex;

                  setTimeout(() => {
                    onEnvironmentUpdated();
                  }, 100);
                }}
              />
            )}
          </CAVariableInput>
          <CAVariableInput
            inputType="expand-box"
            name="Plane Texture"
          >
            {materialOptions.filter(id => !!materialPresets[id].previewUrl)
              .map(id => {
                const material = materialPresets[id];

                return (
                  <Col span={8}>
                    <MaterialPreview
                      src={material.previewUrl}
                      width="100%"
                      selected={floor.materialId === id}
                      onClick={() => {
                        floor.materialId = id;
                        floor._dirty = 2;
                        onEnvironmentUpdated();
                      }}
                    />
                  </Col>
                );
              }
            )}
          </CAVariableInput>
          <CAVariableInput
            inputType="expand-box"
            name="Plane Color"
          >
            <CAVariableInput
              span={24}
              inputType="color"
              value={floor.tintColor}
              onChange={(color) => {
                floor.tintColor = color.hex;
                floor._dirty = 2;
                
                setTimeout(() => {
                  onEnvironmentUpdated();
                }, 100);
              }}
            />
          </CAVariableInput>
        </Row>
      </div>
    );
  }

  renderWeatherTab() {
    return (
      <div>

      </div>
    )
  }

  renderSettingsTab() {
    const { renderer, onEnvironmentUpdated } = this.props;
    const toneMappingOptions = [
      { label: 'Flat', value: Three.CineonToneMapping },
      { label: 'Natural', value: Three.ACESFilmicToneMapping },
      { label: 'Cinematic', value: Three.NoToneMapping }
    ];

    return (
      <div>
        <Row>
          <CAVariableInput
            inputType="expand-box"
            name="Color Grading"
          >
            {toneMappingOptions.map(({ label, value }) => {
              return (
                <CAVariableInput
                  span={24}
                  wrapperProps={{
                    style: { marginBottom: 5 }
                  }}
                  inputType="button"
                  inputProps={{
                    active: renderer.toneMapping === value,
                    style: {
                      textAlign: 'center'
                    }
                  }}
                  onClick={() => {
                    renderer.toneMapping = value;
                    
                    onEnvironmentUpdated();
                    this.forceUpdate();
                  }}
                >
                  {label}
                </CAVariableInput>
              );
            })}
          </CAVariableInput>
        </Row>
      </div>
    );
  }

  render() {
    const { tab } = this.state;

    return (
      <CAPanelBody margin="20px 25px" style={{ overflow: 'visible' }}>
        <Row>
          <Col span={12}>
            <CAPanelH1>
              Environment
            </CAPanelH1>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'center' }}>
            <CAPanelBody display="flex" margin="20px auto" style={{ overflow: 'visible', width: '90%'}}>
              {[
                { icon: DaytimeIconSvg, active: tab === 'daytime', onClick: () => { this.changeTab('daytime'); } },
                { icon: SceneIconSvg, active: tab === 'scene',  onClick: () => { this.changeTab('scene'); } },
                { icon: SettingsIconSvg, active: tab === 'settings',  onClick: () => { this.changeTab('settings'); } }
              ].map(({ icon, onClick, active }) => (
                <div
                  style={{ width: '100%' }}
                  onClick={onClick}
                >
                  <InvertedImage
                    src={icon}
                    width="50"
                    height="50"
                    style={{
                      filter: [active ? '' : 'invert(100%)', 'drop-shadow(0px 1px 2px #dddddd)'].join(' '),
                      cursor: active ? 'default' : 'pointer'
                    }}  
                  />
                </div>
              ))}
            </CAPanelBody>
          </Col>
        </Row>
        {tab === 'daytime' && this.renderDaytimeTab()}
        {tab === 'scene' && this.renderSceneTab()}
        {tab === 'weather' && this.renderWeatherTab()}
        {tab === 'settings' && this.renderSettingsTab()}
      </CAPanelBody>
    );
  }
}