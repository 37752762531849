//   keyframes = [],
//   duration = 1,
//   delay = 0,
//   useStepFunction = false,
//   easeFunction = [0.25, 0.1, 0.25, 1.0], // use bezier, values for ease modes here -> https://developer.mozilla.org/en-US/docs/Web/CSS/animation-timing-function
//   iterationCount = 1,
//   alternate = false

import React from 'react';
import styled from 'styled-components';
import { VariableInput } from '../variable-input';
import { Row, Col, Tooltip } from 'antd';
import { BlockStyleEditor } from '../block-style-editor';

const Timeline = styled.div`
  display: inline-flex;
  height: 25px;
  width: calc(100% - 25px - 8px);
  justify-content: stretch;
  border: solid 1px #555;
`;

const ControlButton = styled.div`
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 8px;
  background-color: #eee;
  color: #333;
  font-size: 10px;
  border: solid 1px #ccc;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, .5);
  cursor: pointer;

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`;

const Keyframe = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #eee;
  border-right: solid 1px #ccc;
  cursor: pointer;
  transition: filter .1s ease;

  &:last-child {
    border-right: 0;
  }

  &:hover {
    filter: brightness(2.0);
  }

  ${p => ({
    active: `
      background-color: #ed58cc;
    `,
    filled: `
      background-color: #ad6fa0;
    `
  })[p.status]}

  ${p => p.darken && `
    filter: brightness(0.9);
  `}
`;

const KeyframeTiming = styled.div`
  vertical-align: bottom;

  .seconds {
    display: inline-block;
    font-size: 12px;
  }

  .milisec {
    display: inline-block;
    font-size: 10px;
    opacity: .55;
  }
`;

const PropsHeader = styled.div`
  font-size: 12px;
  font-weight: bold;

  a {
    font-weight: normal;
  }
`;

const refreshAnimationPreview = function () {
  const { tools } = this.state;
  const { selection } = tools;

  const selectionId = selection.branch.id;
  const selectionBranch = selection.branch;

  this.updateSelectionParam('keyframeUpdate', { target: { value: Date.now() }});

  setTimeout(() => {
    this.forceSelection(selectionId, selectionBranch);
  }, 150);
}

export const renderAnimationBlockProps = function () {
  const { tools } = this.state;
  const { selection, animationKeyframe } = tools;

  const duration = isNaN(selection.branch.duration) ? 1 : selection.branch.duration;
  const selectedFrame = animationKeyframe;
  const currentKeyframe = (selection.branch.keyframes || [])[selectedFrame];

  const filledFrames = (selection.branch.keyframes || []).map((frame, index) => frame ? `${index}` : undefined).filter(Boolean);
  const stopFramed = typeof selection.branch.stopFrame !== 'undefined';
  const filledStopFrame = filledFrames.includes(`${selectedFrame}`);
  
  return (
    <Col span={24}>
      <Row gutter={8}>
        <Col span={24}>
          <ControlButton onClick={() => {
            if (stopFramed) {
              this.updateSelectionParam('stopFrame', { target: { value: undefined }});
            } else {
              this.updateSelectionParam('stopFrame', { target: { value: 0 }});
            }

            refreshAnimationPreview.bind(this)();
          }}>
            <i className={`fa fa-fw fa-${stopFramed ? 'play' : 'pause'}`} />
          </ControlButton>
          <Timeline
            onRightClick={(e) => e.preventDefault()}
            onContextMenu={(e) => e.preventDefault()}
          >
            {Array(100).fill(0).map((_, index) => {
              const [ sec, milisec ] = ((index / 100) * duration).toFixed(2).split('.');

              return (
                <Tooltip
                  placement="top"
                  title={
                    <KeyframeTiming>
                      <div className="seconds">
                        {sec}
                      </div>
                      <div className="milisec">
                        :{milisec}
                      </div>
                      {` - ${
                        index === 0 ? 'start' : (index === 99 ? 'end' : `${index}%`)
                      }`}
                    </KeyframeTiming>
                  }
                >
                  <Keyframe
                    status={index === selectedFrame ? 'active' : (filledFrames.includes(`${index}`) ? 'filled' : '')}
                    darken={index % 10 === 0 || index === 99}
                    onMouseDown={() => {
                      this.state.tools.animationKeyframe = index;
                      this.updateSelectionParam('stopFrame', { target: { value: index }});
                      this.forceUpdate();
                    }}
                  />
                </Tooltip>
              );
            })}
          </Timeline>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={8}>
          <PropsHeader>
            selected frame {filledStopFrame && <a onClick={() => {
              this.updateSelectionParam(
                'keyframes',
                { target: { value: undefined }},
                { propIndex: selectedFrame }
              );
              refreshAnimationPreview.bind(this)();
            }}>(clear selected frame)</a>}
          </PropsHeader>
          <Row style={{ marginBottom: 16 }}>
            <Col span={24}>
              {stopFramed ? `${((selectedFrame / 100) * duration).toFixed(2)}s` : 'Playing...'}
            </Col>
          </Row>
          <PropsHeader>
            animation settings
          </PropsHeader>
          <Row gutter={4}>
            <VariableInput
              span={12}
              name="duration (sec)"
              value={selection.branch.duration}
              onChange={(value, params) => {
                this.updateSelectionParam('duration', { target: { value: Math.max(0, Math.min(9999, parseFloat(value))) }}, params);
                refreshAnimationPreview.bind(this)();
              }}
            />
            <VariableInput
              span={12}
              name="delay (sec)"
              value={selection.branch.delay}
              onChange={(value, params) => {
                this.updateSelectionParam('delay', { target: { value }}, params);
                refreshAnimationPreview.bind(this)();
              }}
            />
            <VariableInput
              span={12}
              name="timing curve"
              inputType="select"
              value={selection.branch.useStepFunction ? 'step' : selection.branch.easeFunction}
              onChange={(value, params) => {
                if (value === 'step') {
                  this.updateSelectionParam('useStepFunction', { target: { value: true }}, params);
                } else {
                  this.updateSelectionParam('useStepFunction', { target: { value: false }}, params);
                  this.updateSelectionParam('easeFunction', { target: { value }}, params);
                }

                refreshAnimationPreview.bind(this)();
              }}
            >
              <option value="step">step</option>
              <option value="0.25, 0.1, 0.25, 1.0">smooth</option>
              <option value="0.0, 0.0, 1.0, 1.0">linear</option>
            </VariableInput>
          </Row>
          <Row gutter={4}>
            <VariableInput
              span={12}
              name="loop indefinitely"
              inputType="switch"
              nonVariable
              value={selection.branch.iterationCount === 'infinite'}
              onChange={(value, params) => {
                if (value) {
                  this.updateSelectionParam('iterationCount', { target: { value: 'infinite' }}, params);
                } else {
                  this.updateSelectionParam('iterationCount', { target: { value: '1' }}, params);
                }

                refreshAnimationPreview.bind(this)();
              }}
            />
            <VariableInput
              span={12}
              name="repeat (times)"
              value={selection.branch.iterationCount}
              onChange={(value, params) => {
                this.updateSelectionParam('iterationCount', { target: { value }}, params);
                refreshAnimationPreview.bind(this)();
              }}
              inputProps={{
                disabled: selection.branch.iterationCount === 'infinite'
              }}
            />
            <VariableInput
              span={12}
              name="loop type"
              inputType="select"
              value={selection.branch.alternate === true ? 'alternate' : 'forward'}
              onChange={(value, params) => {
                this.updateSelectionParam('alternate', { target: { value: value === 'alternate' }}, params);
                refreshAnimationPreview.bind(this)();
              }}
            >
              <option value="forward">forward</option>
              <option value="alternate">alternate</option>
            </VariableInput>
          </Row>
        </Col>
        {stopFramed && <Col span={16}>
          <Row gutter={8}>
            <BlockStyleEditor
              selection={{
                branch: {
                  id: selection.branch.id,
                  type: selection.branch.type,
                  ...currentKeyframe
                }
              }}
              columns={2}
              showStateTransitions={false}
              showSharedStyles={false}
              updateSelectionParam={(id, event, params) => {
                const updatedKeyframes = {
                  ...currentKeyframe,
                  [id]: this.parseSelectionParam(event.target.value, params)
                };

                this.updateSelectionParam(
                  'keyframes',
                  { target: { value: updatedKeyframes }},
                  {
                    ...params,
                    propIndex: selectedFrame
                  }
                );
                refreshAnimationPreview.bind(this)();
              }}
            />
          </Row>
        </Col>}
      </Row>
    </Col>
  );
}
