import React from 'react';
import styled from 'styled-components';
import * as Three from 'three';
import { CustomPicker } from 'react-color';

import ColorWheelImage from '../../images/common-app/utils/color-wheel.png';
import { Row, Col } from 'antd';
import { CAVariableInput } from './ca-variable-input';

const PickerWrapper = styled.div`
  position: relative;
  height: 120px;
  width: 100%;
`;

const ColorWheel = styled.div`
  position: absolute;
  width: 100px;
  height: 100px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
  background-image: url(${ColorWheelImage});
  background-position: center;
  background-size: 100px 100px;
  z-index: 1;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, .1);
  cursor: pointer;
  pointer-events: none;
`;

const ActionWheel = styled.div`
  position: absolute;
  width: 90px;
  height: 90px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 50%;
  overflow: hidden;
  background-color: transparent;
  z-index: 2;
  cursor: pointer;
  pointer-events: all;
`;

const ColorPicker = styled.div`
  position: absolute;
  top: calc(50% + ${p => p.y}px);
  left: calc(50% + ${p => p.x}px);
  transform: translateX(-50%) translateY(-50%);
  width: 8px;
  height: 8px;
  background-color: #000;
  pointer-events: none;
  border-radius: 50%;
  overflow: hidden;
  z-index: 2;
`;

class CAColorPickerCls extends React.Component {
  pickingColor = false;
  state = {
    pickerX: .5,
    pickerY: .5,
    customVar1: '#ffffff',
    customVar2: false
  };

  constructor(props) {
    super(props);

    this.state.customVar1 = props.hex || '#ffffff';
  }

  componentDidMount() {
    if (this.props.hsl) {
      const { x, y } = this.hslToCoords(this.props.hsl);

      if (x !== this.state.pickerX || y !== this.state.pickerY) {
        this.state.pickerX = x;
        this.state.pickerY = y;
        this.forceUpdate();
      }
    }
  }

  componentDidUpdate() {
    if (this.props.hsl) {
      const { x, y } = this.hslToCoords(this.props.hsl);

      if (x !== this.state.pickerX || y !== this.state.pickerY) {
        this.state.pickerX = x;
        this.state.pickerY = y;
        this.forceUpdate();
      }
    }
  }

  onMouseDown(event) {
    this.pickingColor = true;
    this.pickColor(event);
  }

  onMouseUp(event) {
    this.pickingColor = false;
  }

  onMouseMove(event) {
    if (this.pickingColor) {
      this.pickColor(event);
    }
  }

  onClick(event) {
    this.pickColor(event);
  }

  pickColor(event) {
    const { clientX, clientY, target } = event;
    const { left, top, width, height } = target.getBoundingClientRect();
    const { onChange } = this.props;

    event.preventDefault();

    const x = Three.MathUtils.clamp((clientX - left) / width, 0, 1);
    const y = Three.MathUtils.clamp((clientY - top) / height, 0, 1);

    this.setState({
      ...this.state,
      pickerX: x,
      pickerY: y
    });

    const color = this.coordsToHSL(x, y);

    onChange(color);
  }

  coordsToHSL(x, y) {
    const v2 = new Three.Vector2(x - .5, y - .5);
    const vLength = Three.MathUtils.clamp(v2.length(), 0, 1);
    let vOriginAngle = v2.angle() - 3;

    vOriginAngle = vOriginAngle % (Math.PI * 2);
  
    if (vOriginAngle < 0) {
      vOriginAngle += Math.PI * 2;
    }

    return {
      h: vOriginAngle * 180 / Math.PI,
      s: Three.MathUtils.clamp(vLength * 2, 0, 1) * 100,
      l: 50 - vLength * 50
    };
  }

  hslToCoords({ h, s, l }) {
    const angle = h * Math.PI / 180;
    const offset = s / 2;

    const v2 = new Three.Vector2(offset + .5, .5);
    v2.rotateAround(new Three.Vector2(.5, .5), angle + 3);

    return v2;
  }

  render() {
    const { pickerX, pickerY, customVar1, customVar2 } = this.state;
    const { hex, onChange } = this.props;

    return (
      <>
        <Row>
          <CAVariableInput
            inputType="text"
            span={24}
            value={customVar2 ? customVar1 : hex}
            onChange={(value) => {
              this.state.customVar1 = value;
              this.forceUpdate();
            }}
            wrapperProps={{
              style: {
                width: 80
              }
            }}
            inputProps={{
              placeholder: '#ffffff',
              onFocus: (e) => {
                this.state.customVar1 = hex;
                this.state.customVar2 = true;
                this.forceUpdate();
              },
              onBlur: (e) => {
                this.state.customVar2 = false;
                this.forceUpdate();
                onChange(e.target.value);
              }
            }}
          />
        </Row>
        <Row>
          <Col span={24}>
            <PickerWrapper>
              <ColorWheel/>
              <ActionWheel
                onClick={(e) => this.onClick(e)}
                onMouseDown={(e) => this.onMouseDown(e)}
                onMouseUp={(e) => this.onMouseUp(e)}
                onMouseLeave={(e) => this.onMouseUp(e)}
                onMouseOut={(e) => this.onMouseUp(e)}
                onMouseMove={(e) => this.onMouseMove(e)}
              />
              <ColorPicker
                x={(pickerX - .5) * 90}
                y={(pickerY - .5) * 90}
              />
            </PickerWrapper>
          </Col>
        </Row>
      </>
    );
  }
}

export const CAColorPicker = CustomPicker(CAColorPickerCls);
