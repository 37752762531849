import React from 'react';
import styled from 'styled-components';
import { Tree, message } from 'antd';
import { blockTypes } from '../block-selector';
import { extractVariable } from '../../../wtl-schema/helpers/extract-variable';
import { groupBlocks } from '../../data/block-types.data';
import { WtlGroupDetailsModal } from './wtl-group-details-modal';

const { TreeNode } = Tree;

const TreeIcon = styled.i`
  position: relative;
  display: inline-block;
  padding: 2px;
  width: 20px;
  text-align: center;
  box-sizing: border-box;
  ${p => `color: ${p.backgroundColor};`}
`;

const IconNote = styled.span`
  position: absolute;
  bottom: -2px;
  right: -4px;
  display: inline-block;
  padding: 2px;
  background-color: #a3ffd7;
  color: #2f8761;
  box-shadow: 0px 1px 0px rgba(47, 135, 97, .5);
  font-size: 5px;
  border-radius: 2px;
  z-index: 1;
  font-family: Arial, Helvetica, sans-serif;
`;

const getBlockName = (context, block) => {
  const { onNodesUpdated, onSelectionAffected } = context.props;
  const type = blockTypes.find(item => item.type === [ block.type, block.variant ].filter(Boolean).join(':'));
  let label;

  if (type && ['text', 'link'].includes(block.type)) {
    const element = document.querySelector(`#${block.id}`);

    label = (element && element.textContent ? `"${element.textContent}"` : null) || type.name; 
  } else {
    label = type ? type.name : 'Unknown Element';
  }

  if (type && block.type !== 'root' && block._groupName) {
    label = block._groupName;
  }

  const isMaskEnabled = extractVariable(block.cssContentOverflow) && extractVariable(block.cssContentOverflowHover) && extractVariable(block.cssContentOverflowActive);

  return (
    <div>
      {block.type !== 'root' && <TreeIcon
        className={`fas fa-f fa-${block._locked ? 'lock': 'unlock'}-alt`}
        style={{
          opacity: block._locked ? 1.0 : 2.0,
          cursor: 'pointer'
        }}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();

          block._locked = !block._locked;

          onNodesUpdated();
          onSelectionAffected();
        }}
      />}
      {
        !['root', 'advanced-background-block'].includes(block.type) && groupBlocks.includes(block.type) && (
          <TreeIcon
            className="fas fa-f fa-mask"
            style={{
              opacity: isMaskEnabled ? 1.0 : .5,
              cursor: 'pointer'
            }}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();

              block.cssContentOverflow = !isMaskEnabled;
              block.cssContentOverflowHover = !isMaskEnabled;
              block.cssContentOverflowActive = !isMaskEnabled;

              onNodesUpdated();
            }}
          />
        )
      }
      <TreeIcon
        className={`fas fa-fw fa-${type ? type.icon : 'question'}`}
        backgroundColor={block._groupColor}
        style={{
          cursor: 'default'
        }}
      >
        {block._refSrc && <IconNote>
          REF
        </IconNote>}
      </TreeIcon>{' '}
      {label}{' '}
      <span style={{ opacity: 0.2, fontStyle: 'italic' }}>
        {block.id}
      </span>
    </div>
  )
};

const renderNode = (context, data = [], parent, onDoubleClick) => (
  [...data]
  .map(item => {
    if (!item._locked && item.content && groupBlocks.includes(item.type)) {
      return (
        <TreeNode
          key={item.id}
          title={
            <span onDoubleClick={() => {
              if (item.type !== 'root') {
                onDoubleClick();
              }
            }}>
              {getBlockName(context, item)}
            </span>
          }
          disabled={item._locked}
          branch={item}
          parent={parent}
        >
          {typeof item.content !== 'string' &&
            renderNode(
              context,
              item.type === 'root' ? item.content : [...item.content].reverse(),
              item,
              onDoubleClick
            )
          }
        </TreeNode>
      );
    } else {
      return (
        <TreeNode
          key={item.id}
          title={
            <span onDoubleClick={() => {
              if (item.type !== 'root') {
                onDoubleClick();
              }
            }}>
              {getBlockName(context, item)}
            </span>
          }
          disabled={item._locked}
          branch={item}
          parent={parent}
        />
      );
    }
  })
)

export class WtlSchemaTree extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showGroupEditModal: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.forceUpdate();
    }, 1000);
  }

  render() {
    const { schema, onNodeClick, onNodesUpdated, selection } = this.props;
    const { showGroupEditModal } = this.state;

    if (!schema) {
      return null;
    }

    const selectedId = selection && selection.branch ? selection.branch.id : undefined;

    if (!schema || !schema[0]) {
      return null;
    }

    return (
      <div onRightClick={(e) => {
        e.preventDefault();

        return false;  
      }}>
        {showGroupEditModal && <WtlGroupDetailsModal
          selection={selection}
          onClose={() => {
            this.state.showGroupEditModal = false;
            this.forceUpdate();
          }}
          onChange={() => {
            onNodesUpdated();
          }}
        />}
        <Tree
          key={selectedId}
          draggable
          blockNode
          defaultExpandParent={true}
          defaultSelectedKeys={[ selectedId ]}
          defaultExpandedKeys={[ schema[0].id, 'root', selectedId ]}
          onSelect={(keys, event) => {
            const node = event.node;

            if (!node) {
              return;
            }

            const branch = node.props.branch;
            const id = node.props.eventKey;          
            
            if (branch && id) {
              onNodeClick(id, branch);
            }
          }}
          onDrop={(info) => {
            const { node, dragNode, dropPosition, dropToGap } = info;

            const dropKey = node.props.eventKey;
            const dragKey = dragNode.props.eventKey;
            const dragObject = dragNode.props.branch;
            const dropObject = node.props.branch;

            if (!dropToGap && !groupBlocks.includes(dropObject.type)) {
              message.warn('Cannot move element there.');
              return;
            }

            const removeExisting = () => {
              if (dragNode.props.parent) {
                const itemIndex = dragNode.props.parent.content.findIndex((item) => 
                  item.id === dragKey
                );

                dragNode.props.parent.content.splice(itemIndex, 1);
              }
            };

            if (!dropToGap && !dropObject.content) {
              dropObject.content = [];
            }

            if (!dropToGap) {
              if (dropObject._locked) {
                message.warn('Cannot move to locked group.');
                return;
              }

              removeExisting();

              dropObject.content.push(dragObject);
            } else if ((node.props.children || []).length > 0 && node.props.expanded && dropPosition === 1) {
              removeExisting();

              dropObject.content.unshift(dragObject);
            } else {
              if (!node.props.parent || !node.props.parent.content) {
                message.warn('Cannot move element outside root.');

                return;
              }

              removeExisting();

              const itemIndex = node.props.parent.content.findIndex((item) => 
                item.id === dropKey
              );

              if (dropPosition === -1) {
                node.props.parent.content.splice(itemIndex + 1, 0, dragObject);
              } else {
                node.props.parent.content.splice(itemIndex, 0, dragObject);
              }
            }

            onNodesUpdated();
          }}
        >
          {renderNode(this, schema, null, () => {
            this.state.showGroupEditModal = true;
            this.forceUpdate();
          })}
        </Tree>
      </div>
    );
  }
}