import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { SimpleInput, SmallTable, SimpleButton } from '../styles/styled';
import { SAFE_UNDEFINED, authTokenKey } from '../services/editor.service';
import { message, Switch, Slider, Radio, Modal, DatePicker, Tag, Input } from 'antd';
import { callApi } from '../../config';
import { ColorPicker } from '../components/color-picker';
import { PositionPicker } from './position-picker';
import { WtlTooltip } from './layout/wtl-tooltip';
import { WtlFileUploadModal } from './layout/wtl-file-upload-modal';
import { extractVariable } from '../../wtl-schema/helpers/extract-variable';
import { WtlVariableModal } from './layout/wtl-variable-modal';

export const variablePrefix = '_$v';

export const cssPropTypes = [
  'px',
  '%'
];

const CssPropSelector = styled.div`
  position: absolute;
  display: inline-block;
  top: 0px;
  right: 0px;
  box-sizing: border-box;
  width: 32px;
  height: 100%;
  z-index: 1;

  select, option {
    margin: 0;
    background-color: #eee;
    color: #000;
  }
`;

const WtlSlider = styled(Slider)`
  display: inline-block;
  box-sizing: border-box;
  width: calc(100% - 14px);
  padding: 8px 4px;
  z-index: 1;
  margin-top: 12px !important;
  margin-bottom: 0 !important;
`;

const FileInput = styled.div`
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 0;
  margin-top: 8px;
  box-sizing: border-box;
  font-size: 11px;
  background-color: #fff;
  border: solid 1px #999;
  border-radius: 0;
  outline: none;

  &:hover:not(:disabled) {
    text-decoration: underline;
    cursor: pointer;
  }

  &:disabled {
    border-color: #eee;
  }

  .preview {
    display: inline-block;
    box-sizing: border-box;
    padding: 12px 18px;
    font-size: 16px;
    flex-shrink: 0;
  }

  .action {
    display: inline-block;
    flex-grow: 1;
    flex-shrink: 0;
    text-align: center;
    font-weight: bold;
  }

  .file-selected {
    color: #1890ff;
  }
`;

const FilePreview = styled.input`
  position: relative;
  display: block;
  width: 100%;
  margin: 0;
  margin-top: 0 !important;
  top: -1px;
  box-sizing: border-box;
  font-size: 11px;
  background-color: #eee !important;
  color: #777 !important;
  border: solid 1px #999;
  border-radius: 0;
  outline: none;
  padding: 4px 18px;
`;

export const isVar = (value) => typeof value === 'string' && value.startsWith(variablePrefix);

export const getFileAsBase64 = async (image) => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
      message.success('Uploading file...');
    };
    reader.onerror = () => {
      message.warn('Invalid file, try again.');
    };

    reader.readAsDataURL(image);
  });
}

export const uploadBase64 = async (name, base64) => {
  let upload;

  try {
    upload = await callApi(
      'project/upload',
      'POST',
      {
        base64: base64,
        filename: name
      },
      {
        authorization: `Bearer ${sessionStorage[authTokenKey]}`
      }
    );
  } catch {
    message.error('Upload failed, try again.');
    return;
  }

  return {
    name: name,
    url: upload.url || ''
  };
}

export const handleFileUpload = async (event) => {
  const { target } = event;
  const { files } = target;

  if (!files) {
    return;
  }

  const file = files[0];

  if (file.size >= 5000000) {
    message.warn('Maximum file size is 5mb.');

    throw new Error();
  }

  const base64 = await getFileAsBase64(file);
  const result = await uploadBase64(file.name, base64);

  message.success('File uploaded.');

  target.value = '';

  return result;
}

export class VariableInput extends React.Component {
  inputValueRef = null; // NOTE Used only for CSS props
  inputUnitsRef = null;

  constructor(props){
    super(props);
    this.checkRefs(props);

    this.state = {
      showFileUploadModal: false,
      showVariableModal: false
    };
  }

  componentDidUpdate() {
    this.checkRefs(this.props);
  }

  componentDidMount() {
    this.checkRefs(this.props);
  }

  checkRefs(props) {
    if (!this.inputValueRef && !this.inputUnitsRef) {
      this.inputValueRef = React.createRef();
      this.inputUnitsRef = React.createRef();

      this.forceUpdate();
    }
  }

  getUnits() {
    if (!this.inputUnitsRef || !this.inputUnitsRef.current) {
      return '';
    }

    return this.inputUnitsRef.current.value;
  }

  getCssValue() {
    if (!this.inputValueRef || !this.inputValueRef.current) {
      return '';
    }

    return this.inputValueRef.current.value;
  }

  onFileInputClick() {
    this.setState({
      showFileUploadModal: true
    });
  }

  onVariableClick() {
    this.setState({
      showVariableModal: true
    });
  }

  render() {
    const {
      name,
      value,
      onChange,
      span,
      inputType,
      inputProps,
      children,
      nonVariable,
      noTooltip,
      hideNoneOption,
      icon,
      onClick,
      tooltipProps,
      filterTypes
    } = this.props;
    const {
      showFileUploadModal,
      showVariableModal
    } = this.state;

    this.checkRefs(this.props);

    return (
      <>
        <SimpleInput
          span={span}
          className={typeof value === 'string' && value.startsWith(variablePrefix) ? 'use-variable' : ''}  
        >
          {inputType !== 'button' && <span>{name}</span>}
          {
            (inputType === 'input' || !inputType) && (
              <input
                type="text"
                defaultValue=""
                value={(isVar(value) ? value.substr(3) : (value ? decodeURIComponent(value) : ''))}
                onChange={(e) => onChange(isVar(value) ? '' : e.target.value, { variable: false })}
                {...inputProps}
              />
            )
          }
          {
            inputType === 'css-prop' && (
              <input
                ref={this.inputValueRef}
                type="text"
                value={value && !isNaN(parseFloat(value)) ? parseFloat(decodeURIComponent(value)) : undefined}
                onChange={(e) => onChange(
                  !isNaN(parseFloat(e.target.value)) ? `${e.target.value}${this.getUnits()}` : undefined,
                  { variable: false }
                )}
                {...inputProps}
              />
            )
          }
          {showFileUploadModal && (
            <WtlFileUploadModal
              onFileSelected={(url) => {
                onChange(url, { variable: false, originalFilename: null });
                this.setState({ showFileUploadModal: false });
              }}
              onFileUpload={async (e, done) => {
                try {
                  const { url, name } = await handleFileUpload(e);

                  onChange(isVar(value) ? '' : url, { variable: false, originalFilename: name });
                  done(url, { originalFilename: name });

                  this.setState({ showFileUploadModal: false });
                } catch (error) {
                  message.warn('Upload failed. Please try again.');
                }
              }}
              onClose={() => {
                this.setState({ showFileUploadModal: false });
              }}
              inputProps={inputProps}
              filterTypes={filterTypes}
            />
          )}
          {showVariableModal && (
            <WtlVariableModal
              onSelect={(variable) => {
                if (!variable) {
                  return;
                }

                onChange(`${variablePrefix}${variable}`, { variable: true });
                this.setState({ showVariableModal: false });
              }}
              onClose={() => {
                this.setState({ showVariableModal: false });
              }}
            />
          )}
          {
            inputType === 'file' && (
              <>
                <FileInput
                  onClick={() => {
                    this.onFileInputClick();  
                  }}
                >
                  <div className="preview">
                    <i className={`${value ? 'far fa-times file-selected' : 'fal fa-file'}`}
                      onClick={(e) => {
                        if (!value) {
                          return;
                        }

                        e.preventDefault();
                        e.stopPropagation();

                        onChange(undefined, { variable: false });
                      }}
                    />
                  </div>
                  <div className="action">
                    {value ? 'change' : 'choose'} file
                  </div>
                </FileInput>
                {value && (
                  <WtlTooltip
                    disabled={!extractVariable(value) || !extractVariable(value).match(/.*\.(png|jpe?g|gif|tif)$/)}
                    placement="top"
                    title={
                      <div>
                        <img
                          src={(() => {
                            if (!extractVariable(value)) {
                              return;
                            }

                            const preview = extractVariable(value).split('.');
                            preview.splice(preview.length - 1, 1, 'min.jpg');

                            return preview.join('.');
                          })()}
                          style={{
                            height: 100,
                            borderRadius: 4
                          }}
                        />
                      </div>
                    }
                  >
                    <FilePreview
                      readOnly={true}
                      type="text"
                      value={extractVariable(value)}
                      onClick={(e) => e.target.select()}
                    />
                  </WtlTooltip>
                )}
              </>
            )
          }
          {
            inputType === 'textarea' && (
              <textarea
                defaultValue=""
                value={
                  (isVar(value) ?
                    value.substr(3) :
                    (
                      typeof value !== 'string' ?
                      null :                                
                      decodeURIComponent(value) || ''
                    )) || ''
                }
                onChange={(e) => onChange(isVar(value) ? '' : e.target.value, { variable: false })}
                {...inputProps}
              />
            )
          }
          {
            inputType === 'select' && (
              isVar(value) ?
                (
                  <input
                    value={value.substr(3)}
                    onChange={(e) => onChange(SAFE_UNDEFINED, { variable: false })}
                  />
                ) :
                (
                  <select
                    value={decodeURIComponent(value) || SAFE_UNDEFINED}
                    onChange={(e) => onChange(e.target.value, { variable: false })}
                  >
                    {!hideNoneOption && <option value={SAFE_UNDEFINED}>(none)</option>}
                    {children}
                  </select>
                )
            )
          }
          {
            inputType === 'switch' && (
              <Switch
                checked={value || false}
                size="small"
                onChange={(checked, e) => onChange(checked, { variable: false })}
                {...inputProps}
              />
            )
          }
          {
            inputType === 'color' && (
              <ColorPicker
                color={(isVar(value) ? value.substr(3) : (value ? decodeURIComponent(value) : ''))}
                onChange={color => onChange(isVar(value) ? '' : color, { variable: false })}
                {...inputProps}
              />
            )
          }
          {
            inputType === 'position' && (
              <PositionPicker
                value={(isVar(value) ? value.substr(3) : (value ? decodeURIComponent(value) : ''))}
                onChange={value => onChange(isVar(value) ? '' : value, { variable: false })}
              />
            )
          }
          {
            !(inputType === 'table' || inputType === 'range' || inputType === 'switch' || inputType === 'position' || inputType === 'radio' || inputType === 'button' || inputType === 'tags' || nonVariable === true) && 
            (
              <div
                className="variable"
                onClick={() => {
                  this.onVariableClick();
                  {/* const variableName = typeof window !== 'undefined' && window.prompt('Use variable:');

                  if (!variableName) {
                    return;
                  }

                  onChange(`${variablePrefix}${variableName}`, { variable: true }); */}
                }}
                title="Use variable..."
              >
                <i className="fal fa-link"></i> var
              </div>
            )
          }
          {
            inputType === 'range' && (
              <WtlSlider
                defaultValue={0}
                value={value}
                onChange={value => onChange(value, { variable: false })}
                {...inputProps}
              />
            )
          }
          {
            inputType === 'css-prop' &&
            (
              <CssPropSelector>
                <select
                  ref={this.inputUnitsRef}
                  value={cssPropTypes.find((v) => decodeURIComponent(`${value}`).substr(-v.length, v.length) === v) || cssPropTypes[0]}
                  onChange={(e) => onChange(`${this.getCssValue()}${e.target.value}`, { variable: false })}
                >
                  {cssPropTypes.map(type => (
                    <option value={type}>{type}</option>
                  ))}
                </select>
              </CssPropSelector>
            )
          }
          {
            inputType === 'button' && (
              <WtlTooltip
                title={name}
                placement="right"
                disabled={noTooltip || !name || (inputProps && inputProps.disabled)}
                {...tooltipProps}
              >
                <SimpleButton
                  onClick={() => typeof onClick === 'function' ? onClick() : null}
                  background={(inputProps || {}).background}
                  color={(inputProps || {}).color}
                >
                  {icon ? <i className={`far fa-${icon}`}></i> : name || ''}
                </SimpleButton>
              </WtlTooltip>
            )
          }
          {
            inputType === 'tags' && (
              <div>
                {
                  (value ? decodeURIComponent(value) : '').split(',').filter(Boolean).map(tag => (
                    <Tag
                      color="green"
                      closable={!(inputProps && inputProps.readOnly)}
                      onClose={(e) => {
                        e.preventDefault();

                        const newTags = (value ? decodeURIComponent(value) : '').split(',')
                          .filter(item => item !== tag);

                        onChange(encodeURIComponent(newTags.join(',')), { variable: false });
                      }}
                    >
                      {tag}
                    </Tag>
                  ))
                }
                {!(inputProps && inputProps.readOnly) && <input
                  ref={this.inputValueRef}
                  type="text"
                  size="small"
                  placeholder="Add..."
                  style={{ width: 78 }}
                  onKeyUp={(e) => {
                    const fieldValue = e.target.value;
                    const addValue = e.key === 'Enter' || fieldValue.includes(',');

                    if (addValue) {
                      const validatedValue = encodeURIComponent(fieldValue.split(',')[0].trim().replace(/[\!\#\$\%\&\'\(\)\*\+\,\/\:\;\=\?\@\[\]\s]/gm, '-').toLocaleLowerCase());
                      
                      const currentTags = (value ? decodeURIComponent(value) : '').split(',');
                      currentTags.push(validatedValue);

                      onChange(encodeURIComponent(currentTags.filter(Boolean).join(',')), { variable: false });

                      e.target.value = '';
                    }
                  }}
                />}
              </div>
            )
          }
        </SimpleInput>
        {
            inputType === 'date' && (
              <DatePicker
                value={moment((isVar(value) ? value.substr(3) : (value ? decodeURIComponent(value) : '')))}
                onChange={(date, value) => onChange(isVar(value) ? -1 : value, { variable: false })}
                allowClear={false}
                {...inputProps}
              />
            )
          }
        {
          inputType === 'radio' && (
            <Radio.Group
              buttonStyle="solid"
              size="small"
              {...inputProps}
              value={decodeURIComponent(value)}
              onChange={(e) => onChange(e.target.value, { variable: false })}
            >
              {children}
            </Radio.Group>
          )
        }
        {
          inputType === 'table' && 
          (
            <>
              <SmallTable span={24}>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();

                    const props = [].concat(inputProps);

                    const newValue = props.map((name, index) => {
                      const field = e.target[`field${index}`];
                      const value = field.value;

                      if (typeof name === 'string') {
                        field.value = '';
                      } else {
                        field.value = name[0];
                      }

                      return value || '';
                    });

                    onChange((value || []).concat([ newValue ]), { variable: false });
                  }}
                >
                  <div style={{ position: 'relative' }}>
                    <table className="body">
                      <tr>
                        {
                          [].concat(inputProps).map((name, index) => (
                            <td>
                              {typeof name === 'string' && (
                                <input
                                  name={`field${index}`}
                                  type="text"
                                  placeholder={name}
                                ></input>
                              )}
                              {typeof name === 'object' && (
                                <select
                                  name={`field${index}`}
                                  type="text"
                                  defaultValue={name[0]}
                                >
                                  {name.map((option, index) => (
                                    <option value={option}>{option}</option>
                                  ))}
                                </select>
                              )}
                            </td>
                          ))
                        }
                      </tr>
                    </table>
                    <table className="actions always-on">
                      <tr>
                        <td>
                          <button type="submit">
                            <i
                              className="far fa-plus"
                            ></i>
                          </button>
                        </td>
                      </tr>
                    </table>
                  </div>
                </form>
              </SmallTable>
              <SmallTable span={24}>
                <div className="header"></div>
                <div style={{ position: 'relative' }}>
                  <table className="body">
                    {
                      !value || value.length === 0 && (
                        <tr>
                          <td style={{ textAlign: 'center', color: '#ccc' }}>
                            (empty)
                          </td>
                        </tr>
                      )
                    }
                    {
                      value && value.map((row) => (
                        <tr>
                          {
                            [].concat(row).map(subvalue => (
                              <td>
                                {subvalue || '(empty)'}
                              </td>
                            ))
                          }
                        </tr>
                      ))
                    }
                  </table>
                  <table className="actions">
                    {
                      value && value.map((row, index) => (
                        <tr>
                          <td>
                            <i
                              style={{ transitionDelay: '0s' }}
                              className="far fa-chevron-up"
                              disabled={index === 0}
                              onClick={async (e) => {
                                if (index === 0) {
                                  return;
                                }

                                value.splice(index - 1, 0, value.splice(index, 1)[0]);

                                onChange(value, { variable: false });
                              }}
                            ></i>
                            <i
                              style={{ transitionDelay: '.05s' }}
                              className="far fa-chevron-down"
                              disabled={index === value.length - 1}
                              onClick={async (e) => {
                                if (index === value.length - 1) {
                                  return;
                                }

                                value.splice(index + 1, 0, value.splice(index, 1)[0]);

                                onChange(value, { variable: false });
                              }}
                            ></i>
                            <i
                              style={{ transitionDelay: '.1s' }}
                              className="far fa-times"
                              onClick={async (e) => {
                                value[index] = undefined;

                                onChange(value.filter(Boolean), { variable: false });
                              }}
                            ></i>
                          </td>
                        </tr>
                      ))
                    }
                  </table>
                </div>
                <div className="footer"></div>
              </SmallTable>
            </>
          )
        }
      </>
    );
  }
}
