import React from 'react';
import styled from 'styled-components';
import { Modal, Row } from 'antd';
import { VariableInput } from '../variable-input';

export const WtlGroupDetailsModal = (props) => {
  const { onClose, selection, onChange } = props;

  if (!selection.branch) {
    return null;
  }

  return (
    <Modal
      title={
        <>
          Edit Group
        </>
      }
      visible
      closable
      maskClosable
      width={380}
      footer={false}
      onCancel={() => onClose()}
    >
      <Row gutter={4}>
        <VariableInput
          span={2}
          nonVariable
          inputType="color"
          name="color"
          inputProps={{
            rawValues: true
          }}
          value={selection.branch._groupColor}
          onChange={(value) => {
            selection.branch._groupColor = `rgba(${value.r}, ${value.g}, ${value.b}, .5)`;
            onChange();
          }}
        />
        <VariableInput
          span={22}
          nonVariable
          name="group name"
          value={selection.branch._groupName}
          inputProps={{
            placeholder: 'Group',
            autoFocus: true
          }}
          onChange={(value) => {
            selection.branch._groupName = value;
            onChange();
          }}
        />
      </Row>
    </Modal>
  );
};
