import React from 'react';
import { VariableInput } from '../variable-input';
import { Row, Col } from 'antd';
import { SimpleInput } from '../../styles/styled';

export const renderIconProps = function () {
  const { tools } = this.state;
  const { selection } = tools;

  return (
    <Col span={8}>
      <Row gutter={4}>
        <SimpleInput span={12}>
          <span>
            icon
            {' '}
            <a href="https://fontawesome.com/icons" target="_blank">
              (icon list)
            </a>
          </span>
          <input
            type="text"
            defaultValue="question-square"
            value={selection.branch.content}
            onChange={(e) => this.updateSelectionParam('content', e)}
          />
        </SimpleInput>
        <SimpleInput span={12}>
          <span>icon style</span>
          <select
            value={selection.branch.iconFamily || 'r'}
            onChange={(e) => this.updateSelectionParam('iconFamily', e)}
          >
            <option value="r">regular</option>
            <option value="s">solid</option>
            <option value="l">light</option>
            <option value="b">brand</option>
          </select>
        </SimpleInput>
      </Row>
    </Col>
  );
}