import Rete from 'rete';
import { EventSourceSocket, StringSocket } from '../sockets';
import { BaseLayout } from '../layouts/base.layout';
import { BehaviourParamSourceControl } from '../controls/behaviour-param-source.control';

export class BehaviourParamSourceNode extends Rete.Component {
  constructor() {
    super('Parameter');
    this.data.component = BaseLayout;
  }

  builder(node) {
    const out1 = new Rete.Output('behaviour-param', 'Value', StringSocket, false);

    const ctrl = new BehaviourParamSourceControl(this.editor, 'Parameter Id', node);

    return node
      .addControl(ctrl)
      .addOutput(out1);
  }

  worker(node, inputs, outputs) {
    outputs['behaviour-param'] = `_$bparam${node.data['Parameter Id']}`;
  }
}
